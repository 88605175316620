import { Pipe, PipeTransform } from '@angular/core';

// Use this pipe to highlight any characters from a text that are match to a specified query. Useful for text input filter components.
// Use it like this: <div [innerHTML]="text | highlightMatch : query"></div>
@Pipe({
    name: 'highlightMatch'
})
export class HighlightMatchPipe implements PipeTransform {

    transform(text: string, query: any): string {
        let pattern = query.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
        pattern = pattern.split(' ').filter((t) => {
            return t.length > 0;
        }).join('|');
        const regex = new RegExp(pattern, 'gi');

        return query ? text.replace(regex, (match) => `<span class="bold-font">${match}</span>`) : text;
    }
}
