import { Component, Input } from '@angular/core';
import { AppInsightsService } from '../services/app-insights.service';
import { environment } from '../../../environments/environment';
import { User } from '../../security/user';
import { IGoal } from './IGoal';


@Component({
    template: ''
})
export class SpcBaseComponent {

    @Input() user: User;
    public isSpcEnabled: boolean = this.getEnvironmentValue(environment.SPC.Enabled);
    public isNetSpcEnabled: boolean = this.getEnvironmentValue(environment.netSPC.Enabled); 
    //Use local Boost in payplans and paymemos
    public isPayLocalBoostEnabled: boolean = this.getEnvironmentValue(environment.Features.LocalBoostEnabled);
    //Use Aged Percent in payplans and paymemos
    public isPayAgedPercentEnabled: boolean = this.getEnvironmentValue(environment.Features.AgedPercentEnabled);
    
    public isNonTotalPayPlanPercentValidatorsEnabled = this.getEnvironmentValue(environment.Validators.PayPlan.NonTotalPercentValidatorsEnabled);
    public defaultElementToHide: string = "Local Boost";
    
    constructor(public appInsights: AppInsightsService) {}

    private getEnvironmentValue(setting : boolean): boolean {

        try {
            if (setting === null || setting === undefined) {
                return false; 
            }
            return setting;
        }
        catch(err)
        {
            this.appInsights.logErrorThrown(err + ' Failure to get environment value ' + setting, this.user);
            return false;
        }
    }

    public hideHtmlElementByName<T extends IGoal>(obj: T, goalNameToHide: string): boolean {
        return (obj && obj.groupMetric && obj.goalName && obj.goalName.toLowerCase().indexOf(goalNameToHide.toLowerCase()) > -1);
    }
}