import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppInsightsService } from '../shared/services/app-insights.service';
import { UserService } from '../security/user.service';
import { HelperMethodsService } from '../shared/services/helper-methods.service';
import { BaseComponent } from '../shared/base/base.component';
import { groupLevels } from '../shared/static-data/group-levels';
import { PayMemo } from '../models/incoming-dtos/pay-memo';
import { Observable, Subscription } from 'rxjs';
import { PayMemoService } from './pay-memo.service';
import { SubscriptionService } from '../shared/services/subscription.service';
import { LoadingSpinnerService } from '../shared/loading-spinner/loading-spinner.service';
import { SnackBarService } from '../shared/services/snack-bar.service';
import { PayMemoSaveDto } from '../models/outgoing-dtos/pay-memo-save-dto';
import { CoreFiltersService } from '../shared/services/core-filters.service';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'scoreboard-pay-memo',
  templateUrl: './pay-memo.component.html'
})
export class PayMemoComponent extends BaseComponent implements OnInit, OnDestroy {
  payMemoData = new Array<PayMemo>();
  payMemoData$ = new Observable<Array<PayMemo>>();
  payMemoSubscription = new Subscription();
  payMemoDataForSave = new Array<PayMemoSaveDto>();
  payMemoSave$ = new Observable<boolean>();
  payMemoSaveSubscription = new Subscription();

  coreFilterSubscription = new Subscription();

  constructor(public appInsights: AppInsightsService,
              public userService: UserService,
              public helperMethods: HelperMethodsService,
              public payMemoSvc: PayMemoService,
              private subscribeService: SubscriptionService,
              public loadingSpinnerSvc: LoadingSpinnerService,
              public snackBarSvc: SnackBarService,
              public coreFilterSvc: CoreFiltersService) {
                super(appInsights, userService);
              }

  ngOnInit() {
    this.trackUser();
    this.getFilterValues();
  }

  ngOnDestroy() {
    this.coreFilterSubscription.unsubscribe();
  }

  getFilterValues() {
    this.coreFilterSubscription = this.coreFilterSvc.filterValues$.subscribe(filterValues => {
        this.level = filterValues[0].level;
        this.levelValue = filterValues[0].levelValue;
        this.month = filterValues[0].month;
        this.year = filterValues[0].year;
        this.isEurope = filterValues[0].isEurope;
          if (filterValues[1] === '/pay-memo') {
          this.onSubmitSearch();
        }
    });
  }

  onSubmitSearch() {
    if (this.helperMethods.isStringNullOrEmpty(this.levelValue) || this.levelValue === groupLevels.invalid) {
      this.filterErrorMessage = 'You must select a valid ' + this.level + ' to submit your search.';
      this.userService.alertUser(this.filterErrorMessage);
    } else {
      this.getPayMemoData();
    }
  }

  onPayMemoDataChanged(payMemoTableData: PayMemoSaveDto[]) {
    this.payMemoDataForSave = payMemoTableData;
    this.payMemoSave$ = this.payMemoSvc.updatePayMemoData(this.payMemoDataForSave);
    this.subcribeToPayMemoDataForSave();
  }

  getPayMemoData() {
    this.payMemoData$ = this.payMemoSvc.getPayMemoData(this.level, this.levelValue, this.month, this.year);
    this.subscribeToPayMemoData();
  }

  subscribeToPayMemoData() {
    this.payMemoSubscription.unsubscribe();
    this.payMemoData = [];
    this.loadingSpinnerSvc.shouldShowLoading();

    this.payMemoSubscription = this.payMemoData$
      .pipe(
        finalize(() => {
          this.loadingSpinnerSvc.turnSpinnerOff();
        })
      )
      .subscribe(
        payMemo => {
          this.payMemoData = payMemo;
          if (this.helperMethods.isArrayNullOrEmpty(this.payMemoData)) {
            this.userService.alertUser('No Pay memo data found for the selected filters.');
          }
        },
        err => {
          this.subscribeService.onError(err, this.level, 'GetPayMemoData');
          this.loadingSpinnerSvc.turnSpinnerOff();
        }
      );
  }

  subcribeToPayMemoDataForSave() {
    this.payMemoSaveSubscription.unsubscribe();
    this.payMemoSaveSubscription = this.payMemoSave$
      .pipe(
        finalize(() => {
          this.snackBarSvc.openSnackBar('Save was successful.');
          this.appInsights.logUserAction('User saved pay memo records for group ' + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
        })
      )
      .subscribe(
        () => {
          this.getPayMemoData();
        },
        err => {
          this.subscribeService.onError(err, this.level, 'updatePayMemoData');
          this.loadingSpinnerSvc.turnSpinnerOff();
        }
      );
  }
}
