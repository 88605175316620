import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PayMemo } from '../models/incoming-dtos/pay-memo';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../security/user.service';
import { ErrorHandlingService } from '../shared/services/error-handling.service';
import { groupLevels } from '../shared/static-data/group-levels';
import { PayMemoSaveDto } from '../models/outgoing-dtos/pay-memo-save-dto';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PayMemoService {
    private baseUrl: string = environment.dotNetCoreUrl + 'coreapi/PayMemo/';
    private payMemoForCountryUrl: string = this.baseUrl + 'country';
    private payMemoForSvpTeamUrl: string = this.baseUrl + 'svpTeam';
    private payMemoForGroupUrl: string = this.baseUrl + 'group';
    private payMemoForUpdateUrl: string = this.baseUrl + 'update';

    payMemo$ = new Observable<PayMemo[]>();

    constructor(private httpClient: HttpClient,
        private userService: UserService,
        private errorService: ErrorHandlingService) { }

    getPayMemoData(level: string, levelValue: string, month: number, year: number): Observable<PayMemo[]> {
        switch (level) {
            case groupLevels.country:
                return this.payMemo$ = this.getPayMemoForCountry$(levelValue, month, year);
            case groupLevels.svpTeam:
                return this.payMemo$ = this.getPayMemoForSvpTeam$(levelValue, month, year);
            case groupLevels.group:
                return this.payMemo$ = this.getPayMemoForGroup$(levelValue, month, year);
        }
    }

    getPayMemoForCountry$(levelValue: string, month: number, year: number): Observable<PayMemo[]> {
        const url = this.payMemoForCountryUrl + '/' + levelValue + '/' + year + '/' + month;
        return this.httpClient.get<PayMemo[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getPayMemoForSvpTeam$(levelValue: string, month: number, year: number): Observable<PayMemo[]> {
        const url = this.payMemoForSvpTeamUrl + '/' + levelValue + '/' + year + '/' + month;
        return this.httpClient.get<PayMemo[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getPayMemoForGroup$(levelValue: string, month: number, year: number): Observable<PayMemo[]> {
        const url = this.payMemoForGroupUrl + '/' + levelValue + '/' + year + '/' + month;
        return this.httpClient.get<PayMemo[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    updatePayMemoData(payMemoData: PayMemoSaveDto[]): Observable<boolean> {
        return this.httpClient.put<boolean>(this.payMemoForUpdateUrl, payMemoData, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }
}
