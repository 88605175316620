import { Injectable } from '@angular/core';
import { DashboardTeamOverview } from '../../models/incoming-dtos/dashboard-team-overview';

@Injectable()
export class DashboardTeamOverviewService {
    filterOverviewData(selectedTeamId: number, dashboardOverviewData: DashboardTeamOverview[]): DashboardTeamOverview {
        let employeeOverviewData = new DashboardTeamOverview();
        dashboardOverviewData.forEach(employee => {
            if (employee.team.id === selectedTeamId) {
                employee.goalActuals = employee.goalActuals.filter(x => x.goalName !== 'Order of Merit')
                employeeOverviewData = employee;

            }
        });
        return employeeOverviewData;
    }
}
