import { GoalActual } from './goal-actual';
import { Employee } from './employee';

export class DashboardEmployeeOverview {
    dailySalesPace: number;
    salesPaceGoal: string;
    salesPaceOnTrack: boolean;
    rank: number;
    maxRank: number;
    goalActualDtos: GoalActual[];
    employeeDto: Employee;
    totalRankPoints: number;
}
