import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../shared/base/base.component';
import { AppInsightsService } from '../shared/services/app-insights.service';
import { Observable, Subscription } from 'rxjs';
import { Group } from '../models/incoming-dtos/group';
import { GroupTeam } from '../models/incoming-dtos/group-team';
import { SubscriptionService } from '../shared/services/subscription.service';
import { TeamAssignmentService } from './team-assignment.service';
import { UserService } from '../security/user.service';
import { HelperMethodsService } from '../shared/services/helper-methods.service';
import { groupLevels } from '../shared/static-data/group-levels';
import { Team } from '../models/incoming-dtos/team';
import { LoadingSpinnerService } from '../shared/loading-spinner/loading-spinner.service';
import { CoreFiltersService } from '../shared/services/core-filters.service';
import { finalize } from 'rxjs/operators';
import { StyleHelperService } from '../shared/services/style-helper.service';

@Component({
  selector: 'scoreboard-team-assignment',
  templateUrl: './team-assignment.component.html',
  styleUrls: ['./team-assignment.component.scss']
})

export class TeamAssignmentComponent extends BaseComponent implements OnInit, OnDestroy {
    groups$ = new Observable<Group[]>();
    groups = new Array<Group>();
    groupsSubscription = new Subscription();
    isNewTeam = false;
    newTeamId: number;
    newTeamToBeSelected: [number, number];
    isGroupLevel = false;
    saveCode: number;

    selectedTeamId: number;
    selectedGroupName: string;

    team$: Observable<Team>;
    team = new Team;
    teamSubscription = new Subscription();
    coreFilterSubscription = new Subscription();

    constructor(public appInsights: AppInsightsService,
                private subscribeService: SubscriptionService,
                private teamAssignmentSvc: TeamAssignmentService,
                public userService: UserService,
                public helperMethods: HelperMethodsService,
                public loadingSpinnerSvc: LoadingSpinnerService,
                public coreFilterSvc: CoreFiltersService,
                public styleHelper: StyleHelperService) {
                super(appInsights, userService);
                styleHelper.maxWidth = 680;
    }

    ngOnInit() {
        this.trackUser();
        this.getFilterValues();
    }

    ngOnDestroy() {
        this.coreFilterSubscription.unsubscribe();
    }

    getFilterValues() {
        this.coreFilterSvc.filterValues$.subscribe(filterValues => {
            this.level = filterValues[0].level;
            this.levelValue = filterValues[0].levelValue;
            this.month = filterValues[0].month;
            this.year = filterValues[0].year;
            this.isEurope = filterValues[0].isEurope;
            if (filterValues[1] === '/team-assignment') {
              this.onSubmitSearch();
            }
        });
    }

    onSelectedTeamChanged([teamId, groupName]) {
        this.selectedTeamId = teamId;
        this.selectedGroupName = groupName;
        this.getTeamDetails();
    }

    onTeamIdChanged(teamIdAndSaveCode: [number, number]) {
        this.getGroupsAndTeams();
        this.saveCode = teamIdAndSaveCode[1];
        if (teamIdAndSaveCode[0] > 0) {
            this.isNewTeam = true;
            this.newTeamId = teamIdAndSaveCode[0];
        }
    }

    getTeamDetails() {
        this.team$ = this.teamAssignmentSvc.getTeamDetails(this.selectedTeamId, this.selectedGroupName, this.month, this.year);
        this.subscribeToTeamDetails();
    }

    getGroupsAndTeams() {
        this.groups$ = this.teamAssignmentSvc.getGroupsAndTeams(this.level, this.levelValue, this.month, this.year);
        this.subscribeToGroupTeams();
    }

    subscribeToGroupTeams() {
        this.loadingSpinnerSvc.shouldShowLoading();
        this.groupsSubscription.unsubscribe();
        this.groups = [];
        this.team = new Team;
        this.newTeamToBeSelected = null;

        this.groupsSubscription = this.groups$
            .pipe(
                finalize(() => {
                    this.isNewTeam = false;
                })
            )
            .subscribe(
                groups => {
                    this.groups = groups;
                    if (this.isNewTeam) {
                        this.newTeamToBeSelected = [this.newTeamId, this.saveCode];
                    }
                    if (this.helperMethods.isArrayNullOrEmpty(groups) && this.level !== groupLevels.group) {
                        this.team = new Team;
                        this.userService.alertUser('No data found for the selected filters');
                        this.loadingSpinnerSvc.turnSpinnerOff();
                    } else {
                        if (this.groups[0].teams.length === 0) {
                            this.groups[0].teams.push({
                                id: 0,
                                name: ''
                            }as GroupTeam);
                        }
                    }
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'getGroupsAndTeams');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToTeamDetails() {
        this.teamSubscription.unsubscribe();
        this.team = new Team;

        this.teamSubscription = this.team$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                teamDetails => {
                    this.team = teamDetails;
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'getTeamDetails');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    onSubmitSearch() {
        if (this.helperMethods.isStringNullOrEmpty(this.levelValue) || this.levelValue === groupLevels.invalid) {
            this.filterErrorMessage = 'You must select a valid ' + this.level + ' to submit your search.';
            this.userService.alertUser(this.filterErrorMessage);
        } else {
            if (this.level === groupLevels.group) {
                this.isGroupLevel = true;
            } else {
                this.isGroupLevel = false;
            }
            this.getGroupsAndTeams();
        }
    }
}
