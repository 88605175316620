import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { SalesMilestone } from './../../../models/incoming-dtos/sales-milestone';
import { HelperMethodsService } from '../../../shared/services/helper-methods.service';

@Component({
    selector: 'scoreboard-team-milestone',
    templateUrl: './team-milestone.component.html',
    styleUrls: ['./team-milestone.component.scss']
})
export class TeamMilestoneComponent implements OnInit {
    @Input() milestoneDataTeam: Array<SalesMilestone>;
    @Input() payPlanId: number;
    @Input() mileStoneSalesVolumeTeam: number;
    @Input() currency: string;
    newMilestone: SalesMilestone = { id: 0, sales: 0, payout: 0, payPlanId: 0, recordStatus: false };
    indexToRemove: number;
    alert: string;
    alertText: string;
    @Output() isDirty = new EventEmitter();
    @Output() updatedMileStoneTeam = new EventEmitter<Array<SalesMilestone>>();

    constructor(public helperMethods: HelperMethodsService) {

    }

    ngOnInit() { }

    public addMilestone() {
        const mileStoneExists = this.milestoneDataTeam.filter(x => x.sales === this.newMilestone.sales);

        if (this.helperMethods.isNumberNullOrUndefined(this.newMilestone.sales) || this.helperMethods.isNumberNullOrUndefined(this.newMilestone.payout)) {
            this.setAlert('Please enter a number');
        } else {
            if (mileStoneExists.length === 0) {
                this.milestoneDataTeam.push({ sales: this.newMilestone.sales, payout: this.newMilestone.payout, payPlanId: this.payPlanId, id: 0, recordStatus: true });
                this.milestoneDataTeam.sort((n1, n2) => n1.sales - n2.sales);
                this.newMilestone = { id: 0, sales: 0, payout: 0, payPlanId: 0, recordStatus: false };
                this.resetAlert();
                this.updatedMileStoneTeam.emit(this.milestoneDataTeam);
            } else {
                this.setAlert('Milestone already exists!');
            }
        }

        this.isDirty.emit(true);
    }


    resetAlert() {
        this.alert = '';
        this.alertText = '';
    }
    setAlert(alertxt: string) {
        this.alert = 'alert alert-danger';
        this.alertText = alertxt;
    }

    removeMilestone(sales) {
        const result = this.milestoneDataTeam.filter(m => m.sales !== sales);
        this.milestoneDataTeam = result;
        this.updatedMileStoneTeam.emit(this.milestoneDataTeam);
        this.isDirty.emit(true);
    }

    getTargetMileStone(milestone: SalesMilestone) {
        const targetArray = this.milestoneDataTeam.filter(x => x.sales <= this.mileStoneSalesVolumeTeam);
        const max = Math.max.apply(Math, targetArray.map(function (o) { return o.sales; }));
        if (milestone.sales === max) {
            return 'my-1 text-center text-success';
        } else {
            return 'my-0 text-center';
        }
    }
}
