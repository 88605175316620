import {UserRole} from './user-role';
import { SecuredObject } from './secured-object';

export class User {
    id: number;
    firstName: string;
    middleName: string;
    lastName: string;
    preferredName: string;
    group: string;
    branch: string;
    country: string;
    eId: string;
    roles: Array<UserRole>;
    allSecuredObjects: Array<SecuredObject>;
}
