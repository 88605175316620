import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard.component';
import { DashboardRouteActivator } from './dashboard-route-activator';
import { DashboardEmployeeOverviewComponent } from './dashboard-employee-overview/dashboard-employee-overview.component';
import { DashboardEmployeeGraphComponent } from './dashboard-employee-graph/dashboard-employee-graph.component';
import { DashboardService } from './dashboard.service';
import { DashboardEmployeeOverviewService } from './dashboard-employee-overview/dashboard-employee-overview.service';
import { DashboardEmployeeGraphService } from './dashboard-employee-graph/dashboard-employee-graph.service';
import { DashboardTeamOverviewComponent } from './dashboard-team-overview/dashboard-team-overview.component';
import { DashboardTeamOverviewService } from './dashboard-team-overview/dashboard-team-overview.service';
import { DashboardTeamGraphComponent } from './dashboard-team-graph/dashboard-team-graph.component';
import { DashboardTeamGraphService } from './dashboard-team-graph/dashboard-team-graph.service';
import { DashboardGroupOverviewComponent } from './dashboard-group-overview/dashboard-group-overview.component';
import { PayMemoModalComponent } from './dashboard-employee-overview/pay-memo-modal/pay-memo-modal.component';
import { DashboardEmployeeExportService } from './dashboard-employee-overview/dashboard-employee-export.service';
import { DashboardGroupGraphComponent } from './dashboard-group-graph/dashboard-group-graph.component';
import { DashboardGroupGraphService } from './dashboard-group-graph/dashboard-group-graph.service';
import { DashboardGroupOverviewService } from './dashboard-group-overview/dashboard-group-overview.service';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild([
            { path: 'dashboard', component: DashboardComponent, canActivate: [DashboardRouteActivator] }
        ])
    ],
    declarations: [
        DashboardComponent,
        DashboardEmployeeOverviewComponent,
        DashboardEmployeeGraphComponent,
        DashboardTeamOverviewComponent,
        DashboardTeamGraphComponent,
        DashboardGroupOverviewComponent,
        PayMemoModalComponent,
        DashboardGroupGraphComponent
    ],
    providers: [
        DashboardRouteActivator,
        DashboardService,
        DashboardEmployeeOverviewService,
        DashboardEmployeeGraphService,
        DashboardTeamOverviewService,
        DashboardTeamGraphService,
        DashboardEmployeeExportService,
        PayMemoModalComponent,
        DashboardGroupGraphService,
        DashboardGroupOverviewService
    ]
})
export class DashboardModule { }
