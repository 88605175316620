import { Injectable, Input, Output, EventEmitter } from '@angular/core';
import { SalesMilestone } from '../../models/incoming-dtos/sales-milestone';
import { BuyerTier } from '../../models/incoming-dtos/buyer-tier';
import { EUPayPlanDto } from '../../models/outgoing-dtos/eupayplan-dto';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
// import { emit } from 'cluster';

@Injectable()
export class EuPayplanFormService {
    volumeCompensation: number;
    buyerAverage: number;
    constructor(private helperService: HelperMethodsService) {
    }

    getVolumeCompensation(payPerUnit: number, milestones?: Array<SalesMilestone>, milestonesTeam?: Array<SalesMilestone>, volumeGoal?: number, volumeGoalTeam?: number): number {
        let pay = 0;
        if (milestones != null) {
            milestones.forEach((value, index) => {
                if (volumeGoal >= value.sales) {
                    pay = pay + value.payout;
                }
            });
        }
        pay = pay + (volumeGoal * payPerUnit);

        let payTeam = 0;
        if (milestonesTeam != null) {
            milestonesTeam.forEach((value, index) => {
                if (volumeGoalTeam >= value.sales) {
                    payTeam = payTeam + value.payout;
                }
            });
        }

        this.volumeCompensation = pay + payTeam;
        return this.volumeCompensation;
    }

    getMilestoneCompensation(milestones: Array<SalesMilestone>, volumeGoal: number): number {
        let pay = 0;
        milestones.forEach((value) => {
            if (volumeGoal >= value.sales) {
                pay = pay + value.payout;
            }
        });
        return pay;
    }

    getBuyerAverage(tiers?: Array<BuyerTier>, tiersTeam?: Array<BuyerTier>, targetBuyers?: number, targetBuyersTeam?: number) {

        const twelve = 12;
        let buyerBonus = 0;
        if (tiers != null) {
            const targetArray = tiers.filter(x => x.averageBuyers <= targetBuyers);
            const maxaverageBuyer = Math.max.apply(Math, targetArray.map(function (o) { return o.averageBuyers; }));
            const maxbuyerTier = targetArray.filter(x => x.averageBuyers === maxaverageBuyer);
            buyerBonus = Number(maxbuyerTier.map(function (o) { return o.payout; }));
        }

        let buyerBonusTeam = 0;
        if (tiersTeam != null) {
            const targetArrayTeam = tiersTeam.filter(x => x.averageBuyers <= targetBuyersTeam);
            const maxaverageBuyerTeam = Math.max.apply(Math, targetArrayTeam.map(function (o) { return o.averageBuyers; }));
            const maxbuyerTierTeam = targetArrayTeam.filter(x => x.averageBuyers === maxaverageBuyerTeam);
            buyerBonusTeam = Number(maxbuyerTierTeam.map(function (o) { return o.payout; }));
        }

        this.buyerAverage = buyerBonus * twelve + buyerBonusTeam * twelve;
        return this.buyerAverage;
    }




    getTotalProjectedPay(payPlan: EUPayPlanDto) {
        const total = payPlan.basePay
            + payPlan.profitBonusAmount
            + this.buyerAverage
            + this.volumeCompensation;
        return total;

    }
}
