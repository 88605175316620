import { Component, Input } from '@angular/core';
import { DashboardEmployeeGraph } from '../../models/incoming-dtos/dashboard-employee-graph';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { DashboardEmployeeGraphService } from './dashboard-employee-graph.service';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { DashboardGraphData } from '../dashboard-graph-data';
import { StyleHelperService } from '../../shared/services/style-helper.service';
import { DashboardEmployeeOverview } from '../../models/incoming-dtos/dashboard-employee-overview';
import { DashboardEmployeeExportService } from '../dashboard-employee-overview/dashboard-employee-export.service';
import { AppInsightsService } from '../../shared/services/app-insights.service';
import { User } from '../../security/user';
import { environment } from '../../../environments/environment'

@Component({
    selector: 'scoreboard-dashboard-employee-graph',
    templateUrl: './dashboard-employee-graph.component.html',
    styleUrls: ['./dashboard-employee-graph.component.scss']
})
export class DashboardEmployeeGraphComponent {
    @Input() dashboardEmployeeOverviewData: DashboardEmployeeOverview[];
    @Input() dashboardEmployeeGraphData: DashboardEmployeeGraph[];
    @Input() levelValue: string;
    @Input() year: number;
    @Input() month: number;
    @Input() isEurope: boolean;
    @Input() user: User;

    selectedGoalId: number;
    selectedTeamId: number;
    isOrderOfMerit: boolean;
    orderOfMeritList: Array<number>;

    view: any;
    results = new Array<DashboardGraphData>();
    isAllNeeded = true;
    isMetricIdBack = false;
    isTeamIdBack = false;
    filteredDashboardGraphData = new Array<DashboardEmployeeGraph>();
    minValue: any;
    maxValue: any;
    gapValue = 10;
    selectedJobeCode: string;
    isJobCodeIdIdBack = false;
    domWidth = document.body.offsetWidth;

    constructor(public loadingSpinnerSvc: LoadingSpinnerService,
        public dashboardEmployeeGraphSvc: DashboardEmployeeGraphService,
        public helperMethods: HelperMethodsService,
        public styleHelper: StyleHelperService,
        public dashboardEmployeeExportSvc: DashboardEmployeeExportService,
        private appInsightsSvc: AppInsightsService) {
        styleHelper.maxWidth = 1023;
    }

    filterData() {
        this.filteredDashboardGraphData = this.dashboardEmployeeGraphData.filter(x => x.goalActual.goalId === this.selectedGoalId); // filtering master list of data on selected goal
        if (this.selectedTeamId > 0) {
            this.filteredDashboardGraphData = this.filteredDashboardGraphData.filter(x => x.teamId === this.selectedTeamId); // filtering list of one goal further based on selected team
        }
        if (this.selectedTeamId === -1) {
            this.filteredDashboardGraphData = this.filteredDashboardGraphData.filter(x => x.employee.groupName === this.levelValue.toUpperCase());
        }
        if (this.selectedJobeCode !== 'All Roles' && this.isEurope) {
            this.filteredDashboardGraphData = this.filteredDashboardGraphData.filter(x => x.employee.jobTitle === this.selectedJobeCode);
        }
        this.filteredDashboardGraphData = this.dashboardEmployeeGraphSvc.sortByRank(this.filteredDashboardGraphData);
        this.plugGraphData();
    }

    plugGraphData() {
        this.results = [];
        this.results = this.dashboardEmployeeGraphSvc.plugGraphData(this.filteredDashboardGraphData);
        const values = [];

        this.filteredDashboardGraphData.forEach((x) => {
            values.push(x.goalActual.goalValue);
            values.push(x.goalActual.actualValue + x.goalActual.pendingValue);
        });

        values.sort((x, y) => {
            return x - y;
        });
        this.minValue = values[0] - this.gapValue;
        values.reverse();
        this.maxValue = values[0];

        this.loadingSpinnerSvc.turnSpinnerOff();
    }

    onGoalFilterChanged(value: number) {
        this.selectedGoalId = value;
        this.isMetricIdBack = true;
        if (this.isEurope) {
            if (this.isTeamIdBack && this.isJobCodeIdIdBack) {
                this.filterData();
            }
        } else if (this.isTeamIdBack) {
            this.filterData();
        }
        this.orderOfMeritList = environment.orderOfMeritgoalIDs;
        if (this.orderOfMeritList.indexOf(this.selectedGoalId) > -1) {
            this.isOrderOfMerit = true;
        } else { this.isOrderOfMerit = false; }
    }

    onTeamFilterChanged(value: number) {
        this.selectedTeamId = value;
        this.isTeamIdBack = true;
        if (this.isEurope) {
            if (this.isMetricIdBack && this.isJobCodeIdIdBack) {
                this.filterData();
            }
        } else if (this.isMetricIdBack) {
            this.filterData();
        }
    }

    onRoleFilterChanged(value: string) {
        this.selectedJobeCode = value;
        this.isJobCodeIdIdBack = true;
        if (this.isMetricIdBack && this.isTeamIdBack) {
            this.filterData();
        }
    }

    getPaceArrow(isOnTrack: boolean): string {
        if (isOnTrack) {
            return 'icon-arrow-up';
        } else {
            return 'icon-arrow-down';
        }
    }

    calculateActualWidth(actualValue: number): string {
        return this.getPercentage(actualValue) + '%';
    }

    private getPercentage(value: number) {
        return (Math.abs(this.minValue) + +value) / (Math.abs(this.minValue) + this.maxValue) * 100;
    }

    calculateGoalWidth(goalValue: number): string {
        if (goalValue.toString() !== '-') {
            return this.getPercentage(goalValue) + '%';
        } else {
            return 0 + '%';
        }
    }

    calculatePendingWidth(actualValue: number, pendingValue: number): string {
        return this.getPercentage(+actualValue + +pendingValue) + '%';
    }

    isOnTrack(onTrack: boolean): string {
        if (onTrack) {
            return '#4CB050';
        } else {
            return '#F44236';
        }
    }

    isPendingOnTrack(onTrack: boolean): string {
        if (onTrack) {
            return '#A5D6A7';
        } else {
            return '#EF9A9A';
        }
    }

    onExport() {
        this.dashboardEmployeeExportSvc.isEurope = this.isEurope;
        this.dashboardEmployeeExportSvc.buildDashboardEmployeeExport(this.dashboardEmployeeOverviewData,
            this.levelValue, this.month, this.year);
        this.appInsightsSvc.logUserAction('User exported Dashboard Group Employees\'s Data '
            + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
    }

    checkViewState(): boolean {
        if (this.domWidth <= 1349) {
            return false;
        } else {
            return true;
        }
    }
}
