import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MetricFilterService } from './metric-filter.service';
import { GoalDto } from '../../models/outgoing-dtos/goal-dto';
import { Observable, Subscription } from 'rxjs';
import { SubscriptionService } from '../services/subscription.service';
import { HelperMethodsService } from '../services/helper-methods.service';

@Component({
  selector: 'scoreboard-metric-filter',
  templateUrl: './metric-filter.component.html',
  styleUrls: ['./metric-filter.component.scss']
})
export class MetricFilterComponent implements OnChanges {
  @Input() isEmployeeTab = false;
  @Input() isTeamTab = false;
  @Input() levelValue: string;
  @Output() filterChanged: EventEmitter<number>;
  goals = new Array<GoalDto>();
  goals$: Observable<GoalDto[]>;
  goalSubscription = new Subscription;
  selectedGoalId: number;

  constructor(public metricFilterService: MetricFilterService,
    private subscriptionService: SubscriptionService,
    private helperMethods: HelperMethodsService) {
    this.filterChanged = new EventEmitter<number>();
  }

  ngOnChanges() {
    this.goals$ = this.metricFilterService.getGoals(this.levelValue);
    this.subscribeToGoalsObservable();
  }

  subscribeToGoalsObservable() {
    this.goals = [];
    this.goalSubscription.unsubscribe();
    this.goalSubscription = this.goals$
      .subscribe(
        goals => {
          if (!this.helperMethods.isArrayNullOrEmpty(goals)) {
            if (this.isEmployeeTab) {
              this.goals = goals;
            } else if (this.isTeamTab) {
              this.goals = goals.filter(x => x.name !== 'Order of Merit');
            } else {
              this.goals = goals.filter(x => !x.name.includes('Growth Champion') && x.name !== 'Order of Merit');
            }
            this.selectedGoalId = this.goals[0].id;
            this.filterChanged.emit(this.selectedGoalId);
          }
        },
        err => {
          this.subscriptionService.onError(err, 'getGoals');
        }
      );
  }

  selectFilter(goal: GoalDto): any {
    this.selectedGoalId = goal.id;
    this.filterChanged.emit(this.selectedGoalId);
  }
}
