export class EmployeeGoalUpdateDto {
    id: number;
    goalId: number;
    employeeMonthId: number;
    goalName: string;
    goalValue: string;
    updatedBy: string;
    recordStatus: boolean;
    teamId: number;
    month: number;
    year: number;
    ehiGroupId: number;
}
