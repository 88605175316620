import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { unauthorized } from '../static-data/unauthorized';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorHandlingService {

    handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof HttpErrorResponse) {
            if ((error.status === 500) || (error.status === 502) || (error.status === 503)) {
                errMsg = 'We are currently experiencing server load issues. Please try narrowing your search or try again later.';
            } else if (error.status === 0) {
                errMsg = 'We are currently experiencing server load issues. Please wait while your request is being processed.';
            } else if (error.status === 401) {
                errMsg = unauthorized.unauthorized;
            } else {
                errMsg = error.message;
            }
        } else {
            errMsg = error.message ? error.message : error.toString();
        }
        const errorObj = [errMsg, error.status.toString()];
        return throwError(errorObj);
    }
}
