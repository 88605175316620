import { Component, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { SubscriptionService } from '../services/subscription.service';
import { Observable, Subscription } from 'rxjs';
import { Employee } from '../../models/incoming-dtos/employee';
import { EmployeeFilterService } from './employee-filter.service';
import { HelperMethodsService } from '../services/helper-methods.service';
import { User } from '../../security/user';


@Component({
  selector: 'scoreboard-employee-filter',
  templateUrl: './employee-filter.component.html'
})
export class EmployeeFilterComponent implements OnChanges {
  @Input() levelValue: string;
  @Input() year: number;
  @Input() month: number;
  @Input() user: User;
  @Output() onEmployeeChanged: EventEmitter<string>;
  employees = new Array<Employee>();
  employees$ = new Observable<Array<Employee>>();
  employeeSubscription = new Subscription;
  selectedEmpId: string;

  constructor(public employeeFilterService: EmployeeFilterService,
    private helperMethods: HelperMethodsService,
    private subscriptionService: SubscriptionService) {
    this.onEmployeeChanged = new EventEmitter<string>();
  }

  ngOnChanges() {
    this.employees$ = this.employeeFilterService.getEmployees(this.levelValue, this.year, this.month);
    this.subscribeToEmployeesObservable();
  }

  subscribeToEmployeesObservable(): any {
    this.employees = [];
    this.employeeSubscription.unsubscribe();
    this.employeeSubscription = this.employees$
      .subscribe(
        employees => {
          if (!this.helperMethods.isArrayNullOrEmpty(employees)) {
          this.employees = employees;
          this.setSelectedEmployee();
          this.onEmployeeChanged.emit(this.selectedEmpId);
          }
        },
        err => {
          this.subscriptionService.onError(err, 'getEmployees');
        }
      );
  }

  selectFilter(employee: Employee): any {
    this.selectedEmpId = employee.eId;
    this.onEmployeeChanged.emit(this.selectedEmpId);
  }

  setSelectedEmployee() {
    this.selectedEmpId = this.employees[0].eId;
    this.checkIfUserIsSelectableEmployee();
  }

  checkIfUserIsSelectableEmployee() {
    this.employees.forEach(employee => {
      const eId = 'E' + employee.eId;
      if (eId === this.user.eId) {
        this.selectedEmpId = employee.eId;
      }
    });
  }
}
