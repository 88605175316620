export const environment = {
    production: false,
    applicationInsightsKey: 'c040f0c8-7168-438b-a0bf-94e54d7ec105',
    tenant: '5a9bb941-ba53-48d3-b086-2927fea7bf01',
    audience: '46abcefc-6007-43c9-ac33-d8717008f774',
    dotNetCoreUrl: window.location.origin + '/',
    RoleAppStaticId: '1028347073',
    currencyMap: [
        { ngCode: 'CAD', ehiCode: 'CAN' },
        { ngCode: 'EUR', ehiCode: 'DEU' },
        { ngCode: 'EUR', ehiCode: 'FRA' },
        { ngCode: 'EUR', ehiCode: 'IRL' },
        { ngCode: 'EUR', ehiCode: 'ESP' },
        { ngCode: 'USD', ehiCode: 'USA' },
        { ngCode: 'GBP', ehiCode: 'GBR' }
    ],
    orderOfMeritgoalIDs: [
        644, 645, 646, 647, 648
    ],
    securedObjects: {
        corpUserSecObj: 'CorpUserSecObj',
        corporateAdminPageLink: 'CorporateAdminPageLink',
        dashboardPageLink: 'DashboardPageLink',
        gpPreferencesPageLink: 'GPPreferencesPageLink',
        employeeGoals: 'EmployeeGoals',
        teamGoals: 'TeamGoals',
        groupGoals: 'GroupGoals',
        paidTimeOffPageLink: 'PaidTimeOffPageLink',
        payPlanMemoPageLink: 'PayPlanMemoPageLink',
        payPlanSetupPageLink: 'PayPlanSetupPageLink',
        TeamSetupPageLink: 'TeamSetupPageLink',
        btnExportPayPlan: 'btnExportPayPlan',
        btnExportPayPlanMemo: 'btnExportPayPlanMemo',
        btnExportTimeOff: 'btnExportTimeOff',
        btnGrpMemoSaveFinal: 'btnGrpMemoSaveFinal',
        btnSaveAdmin: 'btnSaveAdmin',
        btnSavePayPlan: 'btnSavePayPlan',
        btnSavePayPlanMemo: 'btnSavePayPlanMemo',
        btnSavePreferences: 'btnSavePreferences',
        btnSaveTimeOff: 'btnSaveTimeOff',
        btnTeamSetupSave: 'btnTeamSetupSave',
        ddDashboardUserList: 'ddDashboardUserList',
    },
    SPC: {
        Enabled: true
    }, 
    netSPC: {
        Enabled: false
    },
    Features: {
        LocalBoostEnabled: false,
        AgedPercentEnabled: false
    },
    Validators: {
        PayPlan: {
            NonTotalPercentValidatorsEnabled: false
        }
    },
    msal: {
        production: false,
        redirectUrl: 'https://scoreboard-dev.rmkt.ehi.com/',
        logoutRedirectUrl: 'https://scoreboard-dev.rmkt.ehi.com/',
        clientId: '46abcefc-6007-43c9-ac33-d8717008f774',
        tenantId: '5a9bb941-ba53-48d3-b086-2927fea7bf01',
        msAuthority: 'https://login.microsoftonline.com',
        protectedResourceMap: [
            ['https://EnterpriseHoldings.onmicrosoft.com/46abcefc-6007-43c9-ac33-d8717008f774', ['user_impersonation']],
            ['https://EnterpriseHoldings.onmicrosoft.com/77cb7f6c-1201-4ebe-bb5c-da7914dc355f', ['user_impersonation']]
        ]
    },
    RSPUrl: 'https://www-rsp-dev.9875.ehiaws-nonprod.com/',
};
