import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingSpinnerComponent } from './loading-spinner.component';
import { SpinnerDetectionService } from './spinner-detection.service';

@Injectable()
export class LoadingSpinnerService {
    public isCurrentlyLoading = false;
    dialogRef: any;
    timer: any;

    constructor(public dialog: MatDialog,
                public spinnerDetectSvc: SpinnerDetectionService) {
    }

    shouldShowLoading() {
        this.timer = setTimeout(() => {
          this.turnSpinnerOn('Loading...');
        });
    }

    turnSpinnerOn(message: string) {
        this.spinnerDetectSvc.isLoading.next(true);
        if (!this.isCurrentlyLoading) {
            this.isCurrentlyLoading = true;
            this.dialogRef = this.dialog.open(LoadingSpinnerComponent, {
                width: '175px',
                height: '200px',
                data: message,
                disableClose: true
            });
        }
    }

    turnSpinnerOff() {
        this.spinnerDetectSvc.isLoading.next(false);
        this.isCurrentlyLoading = false;
        clearTimeout(this.timer);
    }
}
