import { catchError, filter, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from './user';
import { environment } from '../../environments/environment';
import { ErrorHandlingService } from '../shared/services/error-handling.service';
import { interval, Observable, Subscription, Subject, ReplaySubject, from } from 'rxjs';
import { AppInsightsService } from '../shared/services/app-insights.service';
import { unauthorized } from '../shared/static-data/unauthorized';
import { AlertModalComponent } from '../shared/alert-modal/alert-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { HelperMethodsService } from '../shared/services/helper-methods.service';
import { SecuredObject } from './secured-object';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, SilentRequest, InteractionStatus } from '@azure/msal-browser';
import { msalConfig } from '../app.module';


@Injectable()
export class UserService {
    private baseUrl: string = environment.dotNetCoreUrl;
    private userURL: string = this.baseUrl + 'coreapi/User';
    private user$: Observable<User>;
    private userSubscription = new Subscription();
    public haveUserRoles = false;
    private userSource: Subject<User> = new ReplaySubject(1);
    public userSource$ = this.userSource.asObservable();
    public user: User;
    private token: string;
    private token$: Observable<string>;
    private tokenSubscription = new Subscription();
    public isAuthenticated = false;
    private isWaitingOnToken = false;
    private authResponse: AuthenticationResult;

    private readonly _destroying$ = new Subject<void>();

    private silentRequest: SilentRequest = {
        scopes: ['user.read'],
        redirectUri : msalConfig.auth.redirectUri,
        account : this.msalSvc.instance.getAllAccounts().length > 0 ? this.msalSvc.instance.getAllAccounts()[0] : null 
    };

    private tokenRequest = {
        scopes: ['user.read']
    };
    
    constructor(private httpClient: HttpClient,
        private errorService: ErrorHandlingService,
        private msalSvc: MsalService,
        private appInsightsSvc: AppInsightsService,
        public dialog: MatDialog,
        private helperMethodsSvc: HelperMethodsService) {

        this.callMsalSubscriber();
    }

    callMsalSubscriber() {
        this.msalSvc.handleRedirectObservable().subscribe({
            next: (result: AuthenticationResult) => {
              // Perform actions related to user accounts here
              if (!result && !this.authResponse) {
                this.loginWithRedirect();
              } else {
                // store auth result
                this.msalSvc.instance.setActiveAccount(result.account);
                this.postLoginProcess(result);
              }
            },
            error: (error) => console.log(error)
        }); 
    }
 
    async loginWithRedirect() {
        await this.msalSvc.instance.acquireTokenSilent(this.silentRequest).then( resp => {
            this.authResponse = resp;
        }).catch( async () => {
            await this.msalSvc.instance.loginRedirect(this.tokenRequest);
        });
    };
 
    postLoginProcess(res: AuthenticationResult) {
        if (res) {
            this.authResponse = res;
            this.token = res.idToken;
            this.getUser();
            this.isAuthenticated = true;
            //this.refreshTokenAtInterval();
        }
    }

    getUser() {
        this.user$ = this.httpClient.get<User>(this.userURL, this.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
        this.subscribeToUserObservable();
    }

    subscribeToUserObservable() {
        this.userSubscription.unsubscribe(); // Unsubscribe in case there is an old or mistaken search in process

        this.userSubscription = this.user$.subscribe({
            next: ((emp: User) => {
                this.user = emp;
                this.checkRoles(); // This method throws the not authorized alert modal rather than doing it in method isAuthorized
                                            // because that method will cause an infinite amount of modals to show up and crash chrome.                
            }),
            error: ((err) => {
                if (err[0] === unauthorized.unauthorized) {
                    // this.router.navigate(['/session-expired']);
                } else {
                    this.alertUser(err[0]);
                }
            }), 
            complete: (() => {
                this.logUserRetrieval();
            })
        });
    }

    getHttpOptions() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Cache-Control': 'no-cache', // needed this for IE to prevent IE from caching restful api calls
                'Pragma': 'no-cache'
            })
        };
        httpOptions.headers = httpOptions.headers.append('Content-Type', 'application/json');
        httpOptions.headers = httpOptions.headers.append('Authorization', 'Bearer ' + this.token);
        httpOptions.headers = httpOptions.headers.append('EmailLocalPart', this.helperMethodsSvc.getEmailPart(this.authResponse.account.username, 0));
        httpOptions.headers = httpOptions.headers.append('EmailDomainPart', this.helperMethodsSvc.getEmailPart(this.authResponse.account.username, 1));

        return httpOptions;
    }

    checkAuthenticated() {
        if ((this.msalSvc.instance.getActiveAccount() == null) && !this.isWaitingOnToken) {

            this.token$ =  from(this.msalSvc.instance.acquireTokenSilent(this.silentRequest).then( (resp) => {
                return resp.idToken;
            }));
            this.subscribeToAcquireToken();
        }
    }

    refreshTokenAtInterval() {
        interval(1000 * 60 * 31).subscribe(() => {
            this.token$ = from(this.msalSvc.instance.acquireTokenSilent(this.silentRequest).then( resp => {
                return resp.idToken
            }));
            this.subscribeToAcquireToken();
        });
    }

    subscribeToAcquireToken() {
        this.tokenSubscription.unsubscribe();
        this.isWaitingOnToken = true;
        this.tokenSubscription = this.token$.subscribe({
            next: ( (newToken : string) => {
                        this.token = newToken;
                        this.isWaitingOnToken = false;
            }),
            error: ((err) => {
                this.appInsightsSvc.logErrorThrown(err + ' Failure to acquire new token from Active Directory', this.user);
                this.isWaitingOnToken = false;
            })
        })
    }

    // Check if user is authorized
    isAuthorized(): boolean {
        if (this.user) {
            if (this.user.roles.length > 0) {
                return true;
            }
        } else {
            return false;
        }
    }

    logUserRetrieval() : void {
        if (this.msalSvc.instance.getActiveAccount() != null) {
            const activeAccount: AccountInfo = this.msalSvc.instance.getActiveAccount();

            if (activeAccount.idTokenClaims)
            {
                if (this.helperMethodsSvc.isStringNullOrEmpty(this.user.eId) &&
                    this.helperMethodsSvc.isStringNullOrEmpty(this.user.firstName) &&
                    this.helperMethodsSvc.isStringNullOrEmpty(this.user.lastName)) {
                        this.appInsightsSvc.appInsights.trackEvent('Token is present but NO user could be retrieved',
                        {
                            'token info':  activeAccount.idTokenClaims.name +
                            activeAccount.idTokenClaims.iat + activeAccount.idTokenClaims.exp
                        });
                }
            }
        }
    }

    alertUser(message: string): void {
        this.dialog.open(AlertModalComponent, {
            width: '300px',
            height: '150px',
            data: { alertMessage: message }
        });
    }

    checkRoles() {
        if (this.user) {
            if (this.user.roles.length === 0) {
                this.haveUserRoles = false;
                this.appInsightsSvc.logUserAction('User is not authorized', this.user);
                this.alertUser('You are not authorized to access this application. \nContact your supervisor for access.');
            } else {
                this.haveUserRoles = true;
                this.getSecuredObjects(this.user);
                this.userSource.next(this.user);
                this.appInsightsSvc.logUserAction('User is authorized', this.user);
            }
        }
    }

    getSecuredObjects(user: User) {
        user.allSecuredObjects = new Array<SecuredObject>();
        user.roles.forEach(role => {
            role.securedObjects.forEach(so => user.allSecuredObjects.push(so));
        });
    }

    ngOnDestroy() {
        this._destroying$.next(undefined);
        this._destroying$.complete();
        this.tokenSubscription.unsubscribe();
        //this.msalSvc.logout();
    }
}
