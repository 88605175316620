import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MonthYear } from './month-year';

@Injectable()
export class DateFilterService {

    datePipe = new DatePipe('en-US');

    getMonthYears(date = new Date()): MonthYear[] {
        const monthYears = new Array<MonthYear>();

        // Month options go back 2 years starting from one month in the future.
        for (let i = -1; i < 25; i++) {
            date.setDate(1);
            const newDate = this.addMonths(date, -i);
            const monthYear = this.toMonthYear(newDate);
            monthYears.push(monthYear);
        }
        return monthYears;
    }

    private toMonthYear(date: Date): MonthYear {
        // Format date as: 'January 2017' (day = 1 if no day is passed, resulting in 'January 1, 2017', but I am removing the day portion)
        const monthYearPipe = this.datePipe.transform(new Date(date.getFullYear(), date.getMonth()), 'longDate', '', 'en-US').replace('1,', '');
        const split = monthYearPipe.split(' ');  // split month and year
        const monthYear = new MonthYear();
        monthYear.monthName = split[0];   // month name is for display
        monthYear.month = date.getMonth();   // month number is for api call
        monthYear.year = date.getFullYear();
        return monthYear;
    }

    private addMonths(date: Date, monthsToAdd: number): Date {
        const result = new Date(
            date.getFullYear(),
            date.getMonth() + monthsToAdd,
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds());
        return result;
    }
}
