import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../security/user.service';
import { BaseComponent } from '../shared/base/base.component';
import { AppInsightsService } from '../shared/services/app-insights.service';
import { HelperMethodsService } from '../shared/services/helper-methods.service';
import { groupLevels } from '../shared/static-data/group-levels';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SecuredObjectService } from '../security/secured-object.service';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { SubscriptionService } from '../shared/services/subscription.service';
import { LoadingSpinnerService } from '../shared/loading-spinner/loading-spinner.service';
import { DashboardService } from './dashboard.service';
import { DashboardEmployeeOverview } from '../models/incoming-dtos/dashboard-employee-overview';
import { DashboardEmployee } from '../models/incoming-dtos/dashboard-employee';
import { DashboardEmployeeGraph } from '../models/incoming-dtos/dashboard-employee-graph';
import { DashboardTeamOverview } from '../models/incoming-dtos/dashboard-team-overview';
import { DashboardTeamGraph } from '../models/incoming-dtos/dashboard-team-graph';
import { DashboardTeam } from '../models/incoming-dtos/dashboard-team';
import { DashboardGroupOverview } from '../models/incoming-dtos/dashboard-group-overview';
import { CoreFiltersService } from '../shared/services/core-filters.service';
import { DashboardGroup } from '../models/incoming-dtos/dashboard-group';
import { DashboardGroupGraph } from '../models/incoming-dtos/dashboard-group-graph';
import { finalize } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Component({
    selector: 'scoreboard-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {
    currentSelectedTab = 0;

    dashboardEmployeeOverviewData = new Array<DashboardEmployeeOverview>();
    dashboardEmployeeGraphData = new Array<DashboardEmployeeGraph>();
    dashboardEmployeeData$: Observable<DashboardEmployee>;
    dashboardEmployeeSubscription = new Subscription();

    dashboardTeamOverviewData = new Array<DashboardTeamOverview>();
    dashboardTeamGraphData = new Array<DashboardTeamGraph>();
    dashboardTeamData$: Observable<DashboardTeam>;
    dashboardTeamSubscription = new Subscription();

    dashboardGroupData$ = new Observable<DashboardGroup>();
    dashboardGroupSubscription = new Subscription();
    coreFilterSubscription = new Subscription();

    selectedTeamId: number;
    selectedGoalId: number;
    dashboardGroupOverviewData: DashboardGroupOverview;
    dashboardGroupGraphData: DashboardGroupGraph[];

    constructor(public userService: UserService,
        public appInsights: AppInsightsService,
        public helperMethods: HelperMethodsService,
        public secObjService: SecuredObjectService,
        public subscriptionSvc: SubscriptionService,
        public loadingSpinnerSvc: LoadingSpinnerService,
        public dashboardSvc: DashboardService,
        public coreFilterSvc: CoreFiltersService) {
        super(appInsights, userService);
    }

    ngOnInit() {
        if (this.coreFilterSvc.today < this.coreFilterSvc.disableDate) {
            this.getFilterValues();
        }
    }

    ngOnDestroy() {
        this.coreFilterSubscription.unsubscribe();
    }

    getFilterValues() {
        this.coreFilterSvc.filterValues$.subscribe(filterValues => {
            this.level = filterValues[0].level;
            this.levelValue = filterValues[0].levelValue;
            this.month = filterValues[0].month;
            this.year = filterValues[0].year;
            this.isEurope = filterValues[0].isEurope;
            if (filterValues[1] === '/dashboard') {
                this.onSubmitSearch();
            }
        });
    }

    onSubmitSearch() {
        if (this.helperMethods.isStringNullOrEmpty(this.levelValue) || this.levelValue === groupLevels.invalid) {
            this.filterErrorMessage = 'You must select a valid ' + this.level + ' to submit your search.';
            this.userService.alertUser(this.filterErrorMessage);
        } else {
            this.getDataForSelectedTab();
        }
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent) {
        this.currentSelectedTab = tabChangeEvent.index;
        this.getDataForSelectedTab();
    }

    getDataForSelectedTab() {
        switch (this.currentSelectedTab) {
            case 0: // Employee Tab
                this.getDashboardEmployeeData();
                this.tabData = 'User accessed Employee Dashboard Tab.';
                break;
            case 1: // Team Tab
                this.getDashboardTeamData();
                this.tabData = 'User accessed Team Dashboard Tab.';
                break;
            case 2: // Group Tab
                this.getDashboardGroupData();
                this.tabData = 'User accessed Group Dashboard Tab.';
                break;
        }
        this.trackUser();
    }

    getDashboardEmployeeData() {
        this.dashboardEmployeeData$ = this.dashboardSvc.getDashboardEmployeeData(this.levelValue, this.year, this.month);
        this.subscribeToDashboardEmployeeData();
    }

    getDashboardTeamData() {
        this.dashboardTeamData$ = this.dashboardSvc.getDashboardTeamData(this.levelValue, this.year, this.month);
        this.subscribeToDashboardTeamData();
    }

    getDashboardGroupData() {
        this.dashboardGroupData$ = this.dashboardSvc.getDashboardGroupData(this.levelValue, this.year, this.month);
        this.subscribeToDashboardGroupData();
    }

    subscribeToDashboardEmployeeData() {
        this.dashboardEmployeeOverviewData = [];
        this.dashboardEmployeeGraphData = [];
        this.dashboardEmployeeSubscription.unsubscribe();
        this.loadingSpinnerSvc.shouldShowLoading();

        this.dashboardEmployeeSubscription = this.dashboardEmployeeData$
            .pipe(finalize(() => {
                this.loadingSpinnerSvc.turnSpinnerOff();
            }))
            .subscribe(
                dashboardData => {
                    if (this.helperMethods.isArrayNullOrEmpty(dashboardData.dashboardOverviewDtos) &&
                        this.helperMethods.isArrayNullOrEmpty(dashboardData.dashboardEmployeeGraphDtos)) {
                        this.userService.alertUser('No data found for the selected filters.');
                        this.loadingSpinnerSvc.turnSpinnerOff();
                    } else {
                        this.dashboardEmployeeOverviewData = dashboardData.dashboardOverviewDtos;
                        this.dashboardEmployeeGraphData = dashboardData.dashboardEmployeeGraphDtos;
                    }
                },
                err => {
                    this.subscriptionSvc.onError(err, 'GetDashboardEmployeeData');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToDashboardTeamData() {
        this.dashboardTeamSubscription.unsubscribe();
        this.dashboardTeamOverviewData = [];
        this.dashboardTeamGraphData = [];
        this.loadingSpinnerSvc.shouldShowLoading();

        this.dashboardTeamSubscription = this.dashboardTeamData$
            .pipe(finalize(() => {
                this.loadingSpinnerSvc.turnSpinnerOff();
            }))
            .subscribe(
                dashboardData => {
                    if (this.helperMethods.isArrayNullOrEmpty(dashboardData.dashboardTeamOverviewDtos) &&
                        this.helperMethods.isArrayNullOrEmpty(dashboardData.dashboardTeamGraphDtos)) {
                        this.userService.alertUser('No data found for the selected filters.');
                        this.loadingSpinnerSvc.turnSpinnerOff();
                    } else {
                        this.dashboardTeamOverviewData = dashboardData.dashboardTeamOverviewDtos;
                        this.dashboardTeamGraphData = dashboardData.dashboardTeamGraphDtos;
                    }
                },
                err => {
                    this.subscriptionSvc.onError(err, 'GetDashboardTeamData');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToDashboardGroupData() {
        this.dashboardGroupSubscription.unsubscribe();
        this.dashboardGroupOverviewData = new DashboardGroupOverview();
        this.dashboardGroupGraphData = [];
        this.loadingSpinnerSvc.shouldShowLoading();

        this.dashboardGroupSubscription = this.dashboardGroupData$
            .pipe(finalize(() => {
                this.loadingSpinnerSvc.turnSpinnerOff();
            }))
            .subscribe(
                dashboardData => {
                    if (this.helperMethods.isArrayNullOrEmpty(dashboardData.dashboardGroupOverviewDtos) &&
                    this.helperMethods.isArrayNullOrEmpty(dashboardData.dashboardGroupGraphDtos)) {
                        this.userService.alertUser('No data found for the selected filters.');
                        this.loadingSpinnerSvc.turnSpinnerOff();
                    } else {
                        this.dashboardGroupOverviewData = dashboardData.dashboardGroupOverviewDtos;
                        this.dashboardGroupGraphData = dashboardData.dashboardGroupGraphDtos;
                    }
                },
                err => {
                    this.subscriptionSvc.onError(err, 'GetDashboardGroupData');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    navigateToRSP() {
        window.open(environment.RSPUrl);
    }
}
