import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../../security/user';
import { MatTableDataSource } from '@angular/material/table';
import { Admin } from '../../models/incoming-dtos/admin';
import { DatePipe } from '@angular/common';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { AdminTableService } from './admin-table.service';
import { SecuredObjectService } from '../../security/secured-object.service';

@Component({
  selector: 'scoreboard-admin-table',
  templateUrl: './admin-table.component.html',
  styleUrls: ['./admin-table.component.scss']
})
export class AdminTableComponent implements OnInit {

  @Input() adminData: Admin[];
  @Input() month: number;
  @Input() year: number;
  @Input() user: User;

 @Output() adminDataChange: EventEmitter<[Array<number>, Array<number>]>;
 @Output() groupNamesForLockOrUnlockChange: EventEmitter<[Array<string>, Array<string>]>;

  dataSource = new MatTableDataSource();
  displayedColumns = ['groupName', 'month', 'locked', 'lastUpdated', 'performedBy'];
  datePipe = new DatePipe('en-US');
  adminDataForLock = new Array<number>();
  groupNamesForLock = new Array<string>();
  adminDataForUnlock = new Array<number>();
  groupNamesForUnlock = new Array<string>();
  adminDataTuple = new Array<[Admin, boolean]>();

  constructor(private loadingSpinnerSvc: LoadingSpinnerService,
              private adminTableSvc: AdminTableService,
              public secObjService: SecuredObjectService) {
              this.adminDataChange = new EventEmitter<[Array<number>, Array<number>]>();
              this.groupNamesForLockOrUnlockChange = new EventEmitter<[Array<string>, Array<string>]>();
  }

  ngOnInit() {
    this.adminDataTuple = this.adminTableSvc.buildAdminDataTuple(this.adminData);
    this.getTableData();
  }

  getTableData() {
    this.dataSource.data = this.adminDataTuple;
  }

  onAdminSave() {
    this.loadingSpinnerSvc.turnSpinnerOn('Saving...');
    this.getDataForSave();
    this.adminDataChange.emit([this.adminDataForLock, this.adminDataForUnlock]);
    this.groupNamesForLockOrUnlockChange.emit([this.groupNamesForLock, this.groupNamesForUnlock]);
  }

  getDataForSave() {
    const dataForSave = new Array<Admin>();
    this.adminDataTuple.forEach(tuple => {
      if (tuple[0].locked !== tuple[1]) {
        dataForSave.push(tuple[0]);
      }
    });
    dataForSave.forEach(obj => {
      if (!obj.locked) {
        this.adminDataForLock.push(obj.ehiGroupId);
        this.groupNamesForLock.push(obj.groupName);
      } else {
        this.adminDataForUnlock.push(obj.ehiGroupId);
        this.groupNamesForUnlock.push(obj.groupName);
      }
    });
  }

}
