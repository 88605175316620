import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DecisionModalComponent } from './decision-modal.component';
import { Observable } from 'rxjs';

@Injectable()
export class DecisionModalService {
  constructor(public dialog: MatDialog) {

  }
  askUser(message: string, width: string): Observable<any> {
    const dialogRef = this.dialog.open(DecisionModalComponent, {
      data: { message: message },
      width: width
    });

    return dialogRef.afterClosed();
  }
}
