import { Injectable } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { CoreFiltersValues } from './core-filters-values';
import { HelperMethodsService } from './helper-methods.service';

@Injectable()
export class CoreFiltersService {
    private filterValues: Subject<[CoreFiltersValues, string]> = new ReplaySubject(1);
    public filterValues$ = this.filterValues.asObservable();
    private isGroupPrefPageSelected: Subject<boolean> = new ReplaySubject(1);
    public isGroupPrefPageSelected$ = this.isGroupPrefPageSelected.asObservable();

    today = new Date();
    disableDate = new Date();

    constructor(public helperMethods: HelperMethodsService) {
        this.disableDate.setFullYear(2019, 4, 20);
        this.disableDate.setHours(20);
        this.disableDate.setMinutes(0, 0, 0);
     }

    setFilterValues(filterLevel: string, filterLevelValue: string, filterMonth: number, filterYear: number, isEurope: boolean, url: string) {
        if (!this.helperMethods.isStringNullOrEmpty(filterLevel)
            && !this.helperMethods.isStringNullOrEmpty(filterLevelValue)
            && !this.helperMethods.isNumberNullOrUndefined(filterMonth)
            && !this.helperMethods.isNumberNullOrUndefined(filterYear)
            && !this.helperMethods.isStringNullOrEmpty(url)) {
            const filterValues = new CoreFiltersValues();
            filterValues.level = filterLevel;
            filterValues.levelValue = filterLevelValue;
            filterValues.month = filterMonth;
            filterValues.year = filterYear;
            filterValues.isEurope = isEurope;
            this.filterValues.next([filterValues, url]);
            }
    }

    setGroupPrefPage(isGroupPrefPage: boolean) {
        this.isGroupPrefPageSelected.next(isGroupPrefPage);
    }
}
