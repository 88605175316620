import { Injectable } from '@angular/core';

@Injectable()
export class HelperMethodsService {

    //extremely simple, literally something, @, something
    private emailRegex: string = '^.+@.+$';

    isStringNullOrEmpty(str: string): boolean {
        if (str === null || typeof str === 'undefined') { return true; }
        return str.replace(/^\s*$/, '').length < 1;
    }

    isArrayNullOrEmpty(array): boolean {
        return !Array.isArray(array) || !array.length;
        // array does not exist, is not an array, or is empty, null or undefined.
    }

    isNumberNullOrUndefined(num: number): boolean {
        if (num === null || typeof num === 'undefined') {
            return true;
        } else {
            return false;
        }
    }

    isPastMonthYear(month: number, year: number): boolean {
        const date = new Date();
        const currentMonth = date.getMonth() + 1; // Adding a month to match the past in value that represents the actual month number.
        const currentYear = date.getFullYear();
        const nextMonth = date.getMonth() + 2; // Adding two months to match the past in value that represents the actual month number.
        if (((month === currentMonth) || (month === nextMonth)) && (year === currentYear)) {
            return true;
        } else {
            return false;
        }
    }

    convertToString(convertToString: any): string {
        const type = typeof convertToString;
        if (type === 'number') {
            if (!this.isNumberNullOrUndefined(convertToString)) {
                return convertToString.toString();
            }
        }
        if (type === 'boolean') {
            return convertToString.toString();
        } else {
            return convertToString;
        }
    }

    compareNumbers(nmb1: number, nmb2: number): number {
        if (nmb1 > nmb2) {
            return -1;
        }
        if (nmb2 > nmb1) {
            return 1;
        }
        return 0;
    }

    getEmailPart(emailAddress: string, index: number): string {
        let result: string = null;
        if (this.isEmailAddressValid(emailAddress) && index > -1) {
            result = emailAddress.split("@")[index];
        }
        return result;
    }

    isEmailAddressValid(emailAddress: string): boolean {
        let found: boolean = false;

        if (!this.isStringNullOrEmpty(emailAddress)) {
            let regex = new RegExp(this.emailRegex);
            found = regex.test(emailAddress);
        }
        return found;
    }
}
