import { Component, OnInit, OnDestroy } from '@angular/core';
import { BaseComponent } from '../shared/base/base.component';
import { AppInsightsService } from '../shared/services/app-insights.service';
import { UserService } from '../security/user.service';
import { groupLevels } from '../shared/static-data/group-levels';
import { HelperMethodsService } from '../shared/services/helper-methods.service';
import { Admin } from '../models/incoming-dtos/admin';
import { Observable, Subscription } from 'rxjs';
import { AdminService } from './admin.service';
import { SubscriptionService } from '../shared/services/subscription.service';
import { LoadingSpinnerService } from '../shared/loading-spinner/loading-spinner.service';
import { SnackBarService } from '../shared/services/snack-bar.service';
import { PayplanSetupService } from '../payplan-setup/payplan-setup.service';
import { CoreFiltersService } from '../shared/services/core-filters.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'scoreboard-admin',
    templateUrl: './admin.component.html'
})
export class AdminComponent extends BaseComponent implements OnInit, OnDestroy {
    adminData = new Array<Admin>();
    adminData$ = new Observable<Array<Admin>>();
    adminSubscription = new Subscription();
    adminDataForLockSave = Array<number>();
    adminDataForUnlockSave = Array<number>();
    adminSaveForLock$ = new Observable<boolean>();
    adminSaveForUnlock$ = new Observable<boolean>();
    waitingForLock: boolean;
    waitingForUnlock: boolean;
    adminSaveForLockSubscription = new Subscription();
    adminSaveForUnlockSubscription = new Subscription();
    groupNamesForLock = new Array<string>();
    groupNamesForUnlock = new Array<string>();
    coreFilterSubscription = new Subscription();

    constructor(public appInsights: AppInsightsService,
        public helperMethods: HelperMethodsService,
        public userService: UserService,
        public adminSvc: AdminService,
        public subscibeSvc: SubscriptionService,
        private payplanSetupSvc: PayplanSetupService,
        public loadingSpinnerSvc: LoadingSpinnerService,
        public snackBarSvc: SnackBarService,
        public coreFilterSvc: CoreFiltersService) {
        super(appInsights, userService);
    }

    ngOnInit() {
        this.trackUser();
        this.getFilterValues();
    }

    ngOnDestroy() {
        this.coreFilterSubscription.unsubscribe();
    }

    getFilterValues() {
        this.coreFilterSvc.filterValues$.subscribe(filterValues => {
            this.level = filterValues[0].level;
            this.levelValue = filterValues[0].levelValue;
            this.month = filterValues[0].month;
            this.year = filterValues[0].year;
            this.isEurope = filterValues[0].isEurope;
            if (filterValues[1] === '/admin') {
                this.onSubmitSearch();
            }
        });
    }

    onSubmitSearch() {
        if (this.helperMethods.isStringNullOrEmpty(this.levelValue) || this.levelValue === groupLevels.invalid) {
            this.filterErrorMessage = 'You must select a valid ' + this.level + ' to submit your search.';
            this.userService.alertUser(this.filterErrorMessage);
        } else {
            this.getAdminData();
        }
    }

    getAdminData() {
        this.adminData$ = this.adminSvc.getAdminData(this.level, this.levelValue, this.month, this.year);
        this.subscribeToAdminData();
    }

    subscribeToAdminData() {
        this.adminSubscription.unsubscribe();
        this.adminData = [];
        this.loadingSpinnerSvc.shouldShowLoading();

        this.adminSubscription = this.adminData$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                admin => {
                    this.adminData = admin;
                    if (this.helperMethods.isArrayNullOrEmpty(this.adminData)) {
                        this.userService.alertUser('No Admin data found for the selected filters.');
                    }
                },
                err => {
                    this.subscibeSvc.onError(err, this.level, 'GetAdminData');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    onSaveAdmin(adminDataForSave: [Array<number>, Array<number>]) {
        this.adminDataForLockSave = adminDataForSave[0];
        this.adminDataForUnlockSave = adminDataForSave[1];
        if (this.adminDataForLockSave.length > 0) {
            this.adminSaveForLock$ = this.payplanSetupSvc.lockPayPlan(this.month, this.year, this.user.eId, this.adminDataForLockSave, this.isEurope);
            this.subscribeSaveForLockAdmin();
        }
        if (this.adminDataForLockSave.length === 0) {
            this.waitingForLock = true;
        }
        if (this.adminDataForUnlockSave.length > 0) {
            this.adminSaveForUnlock$ = this.payplanSetupSvc.unlockPayPlan(this.month, this.year, this.user.eId, this.adminDataForUnlockSave, this.isEurope);
            this.subscribeSaveForUnlockAdmin();
        }
        if (this.adminDataForUnlockSave.length === 0) {
            this.waitingForUnlock = true;
        }
    }

    onSaveAdminGetGroupNames(groupNamesFromSave: [Array<string>, Array<string>]) {
        this.groupNamesForLock = groupNamesFromSave[0];
        this.groupNamesForUnlock = groupNamesFromSave[1];
    }

    subscribeSaveForLockAdmin() {
        this.adminSaveForLockSubscription.unsubscribe();
        this.adminSaveForLockSubscription = this.adminSaveForLock$
            .pipe(
                finalize(() => {
                    this.adminSnackBar();
                    const groups = this.getGroupsBeingLockedOrUnlocked(this.groupNamesForLock);
                    this.appInsights.logUserAction('User locked on admin page for groups'
                        + groups + ' on ' + this.month + '/' + this.year, this.user);
                })
            )
            .subscribe(
                () => {
                    this.waitingForLock = true;
                    if (this.waitingForUnlock) {
                        this.getAdminData();
                    }
                },
                err => {
                    this.subscibeSvc.onError(err, this.level, 'LockPayPlan + UnlockPayPlan');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeSaveForUnlockAdmin() {
        this.adminSaveForUnlockSubscription.unsubscribe();
        this.adminSaveForUnlockSubscription = this.adminSaveForUnlock$
            .pipe(
                finalize(() => {
                    this.adminSnackBar();
                    const groups = this.getGroupsBeingLockedOrUnlocked(this.groupNamesForUnlock);
                    this.appInsights.logUserAction('User unlocked on admin page for groups'
                        + groups + ' on ' + this.month + '/' + this.year, this.user);
                })
            )
            .subscribe(
                () => {
                    this.waitingForUnlock = true;
                    if (this.waitingForLock) {
                        this.getAdminData();
                    }
                },
                err => {
                    this.subscibeSvc.onError(err, this.level, 'LockPayPlan + UnlockPayPlan');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    private adminSnackBar() {
        if (this.waitingForLock && this.waitingForUnlock) {
            this.snackBarSvc.openSnackBar('Lock/UnLock Groups Saved successfully');
        }
    }

    getGroupsBeingLockedOrUnlocked(groupNamesForAppInsights: string[]): string {
        let groupNames = '';
        groupNamesForAppInsights.forEach(group => {
            groupNames = groupNames + ' ' + group + ',';
        });
        return groupNames;
    }
}
