import { Injectable } from '@angular/core';
import { DashboardEmployeeOverview } from '../../models/incoming-dtos/dashboard-employee-overview';

@Injectable()
export class DashboardEmployeeOverviewService {
    filterOverviewData(selectedEId: string, dashboardOverviewData: DashboardEmployeeOverview[]): DashboardEmployeeOverview {
        let employeeOverviewData = new DashboardEmployeeOverview();
        dashboardOverviewData.forEach(employee => {
            if (employee.employeeDto.eId === selectedEId) {
                employeeOverviewData = employee;
            }
        });
        return employeeOverviewData;
    }
}
