import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Admin } from '../models/incoming-dtos/admin';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../security/user.service';
import { ErrorHandlingService } from '../shared/services/error-handling.service';
import { groupLevels } from '../shared/static-data/group-levels';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AdminService {
    private baseUrl: string = environment.dotNetCoreUrl + 'coreapi/Admin/';
    private adminForCountryUrl: string = this.baseUrl + 'country';
    private adminForSvpTeamUrl: string = this.baseUrl + 'svpTeam';
    private adminForGroupUrl: string = this.baseUrl + 'group';

    admin$ = new Observable<Admin[]>();

    constructor(private httpClient: HttpClient,
        private userService: UserService,
        private errorService: ErrorHandlingService) { }

    getAdminData(level: string, levelValue: string, month: number, year: number): Observable<Admin[]> {
        switch (level) {
            case groupLevels.country:
                return this.admin$ = this.getAdminForCountry$(levelValue, month, year);
            case groupLevels.svpTeam:
                return this.admin$ = this.getAdminForSvpTeam$(levelValue, month, year);
            case groupLevels.group:
                return this.admin$ = this.getAdminForGroup$(levelValue, month, year);
        }
    }

    getAdminForCountry$(levelValue: string, month: number, year: number): Observable<Admin[]> {
        const url = this.adminForCountryUrl + '/' + levelValue + '/' + year + '/' + month;
        return this.httpClient.get<Admin[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getAdminForSvpTeam$(levelValue: string, month: number, year: number): Observable<Admin[]> {
        const url = this.adminForSvpTeamUrl + '/' + levelValue + '/' + year + '/' + month;
        return this.httpClient.get<Admin[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getAdminForGroup$(levelValue: string, month: number, year: number): Observable<Admin[]> {
        const url = this.adminForGroupUrl + '/' + levelValue + '/' + year + '/' + month;
        return this.httpClient.get<Admin[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }
}
