import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { PayPlan } from '../../models/incoming-dtos/payplan';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NumberValidator } from '../../shared/validators/number-validator';
import { PayPlanSaveDto } from '../../models/outgoing-dtos/payplan-save-dto';
import { User } from '../../security/user';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { SpcBaseComponent } from '../../../app/shared/base/spc-base.component';
import { AppInsightsService } from '../../shared/services/app-insights.service';


@Injectable()
export class PayplanFormService extends SpcBaseComponent {
    pipe = new DatePipe('en-US');
    validationMessages: { [key: string]: { [key: string]: string } };
    requiredMessage = 'A valid value is required.';
    negativeMessage = 'Negative values are not allowed.';
    greaterThan25Message = 'Value cannot be over 25%';
    equal100PercentMessage = 'Must equal 100%';
    greaterThan70Message = 'Value cannot be over 70%';

    constructor(private customCurrencyPipe: CustomCurrencyPipe,
        private formBuilder: UntypedFormBuilder, 
        public appInsights: AppInsightsService) {
            super(appInsights);
         }

    mapPayPlanForm(payPlan: PayPlan, payPlanForm: UntypedFormGroup) {
        payPlanForm.patchValue({
            targetCompensation: payPlan.targetCompensation,
            vacationPay: payPlan.vacationPay,
            vacationDaysTypeId: payPlan.vacationDaysTypeId,
            basePay: payPlan.basePay,
            profitBonusAmount: payPlan.profitBonusAmount,
            profitBonusPercent: payPlan.profitBonusPercent,
            salesVolumeTarget: payPlan.salesVolumeTarget,
            salesVolumePayPerUnit: payPlan.salesVolumePayPerUnit,
            netSpcTarget: this.isSpcEnabled ? payPlan.netSpcTarget : 0,
            grossSpcTarget: this.isSpcEnabled ? payPlan.grossSpcTarget : 0,
            spcPayout: this.isSpcEnabled ? (payPlan.grossSpcPayout + payPlan.netSpcPayout) : 0,
            quadGrowthTarget: payPlan.quadGrowthTarget * 100,
            quadGrowthPayout: payPlan.quadGrowthPayout,
            dealersSoldToGrowthTarget: payPlan.dealersSoldToGrowthTarget * 100,
            dealersSoldToGrowthPayout: payPlan.dealersSoldToGrowthPayout,
            daysToDispositionTarget: payPlan.daysToDispositionTarget,
            daysToDispositionPayout: payPlan.daysToDispositionPayout,
            agedPercentageTarget: this.isPayAgedPercentEnabled ? payPlan.agedPercentageTarget * 100  : 0,
            agedPercentagePayout: this.isPayAgedPercentEnabled ? payPlan.agedPercentagePayout : 0,
            localBoostPercentTarget: this.isPayLocalBoostEnabled ? payPlan.localBoostPercentTarget * 100 : 0,
            localBoostPercentPayout: this.isPayLocalBoostEnabled ? payPlan.localBoostPercentPayout : 0,
            purchaseVolumeTarget: payPlan.purchaseVolumeTarget,
            purchaseVolumePayPerUnit: payPlan.purchaseVolumePayPerUnit,
            dealerSoldToAllTarget: payPlan.dealerSoldToAllTarget,
            dealerSoldToAllPayout: payPlan.dealerSoldToAllPayout,
            targetSpcType: payPlan.targetSpcType.toString(),
            startDate: this.pipe.transform(payPlan.employee.hireDate, 'longDate')
        });
    }

    getPayPlanValidationMessages() {

        if (this.isNonTotalPayPlanPercentValidatorsEnabled) {
            this.validationMessages = {
                quadGrowthPercent: {
                    greaterThan25: this.greaterThan25Message
                },
                dealerGrowthPercent: {
                    greaterThan25: this.greaterThan25Message
                },
                dispositionPercent: {
                    greaterThan25: this.greaterThan25Message
                },
                agedPercentagePercent: {
                    greaterThan25: this.greaterThan25Message
                },
                volumePercent: {
                    greaterThan70: this.greaterThan70Message
                }
            };

            if (this.isSpcEnabled){
                this.validationMessages = Object.assign(this.validationMessages, {                 
                    spcPercent: {
                        greaterThan70: this.greaterThan70Message
                    } 
                });
            } 
        }

        if (this.validationMessages == null || this.validationMessages == undefined) {
            this.validationMessages = {                 
                totalPercent: {
                    equal100Percent: this.equal100PercentMessage
                },
                totalPercentSummary: {
                    equal100Percent: this.equal100PercentMessage
                }
            };
        }
        else {
            this.validationMessages = Object.assign(this.validationMessages, {                 
                totalPercent: {
                    equal100Percent: this.equal100PercentMessage
                },
                totalPercentSummary: {
                    equal100Percent: this.equal100PercentMessage
                }
            });
        }
        return this.validationMessages;
    }

    getPayPlanFormDefinition(groupName: string) {
        return this.formBuilder.group({
            targetCompensation: '0',
            vacationPay: '0',
            vacationDaysTypeId: 0,
            basePay: '0',
            profitBonusAmount: '0',
            profitBonusPercent: '0',
            salesVolumeTarget: '0',
            salesVolumePayPerUnit: '0',
            purchaseVolumeTarget: '0',
            purchaseVolumePayPerUnit: '0',
            netSpcTarget: 0,
            grossSpcTarget: 0,
            spcPayout: '0',
            quadGrowthTarget: '0',
            quadGrowthPayout: '0',
            dealersSoldToGrowthTarget: '0',
            dealersSoldToGrowthPayout: '0',
            daysToDispositionTarget: '0',
            daysToDispositionPayout: '0',
            agedPercentageTarget: '0',
            agedPercentagePayout: '0',
            localBoostPercentTarget: '0',
            localBoostPercentPayout: '0',
            dealerSoldToAllPayout: '0',
            dealerSoldToAllTarget: '0',
            targetSpcType: 0,
            startDate: '',
            targetCompWithoutVac: '0',
            targetProduction: '0',
            salesVolumePayout: '0',
            salesVolumePayoutDisplay: '0',
            purchaseVolumePayout: '0',
            profitPercent: '0',
            volumePercent: this.isNonTotalPayPlanPercentValidatorsEnabled ? 
                        ['0', NumberValidator.greaterThan70(groupName)] : '0',
            spcPercent: this.isNonTotalPayPlanPercentValidatorsEnabled ? 
                        ['0', NumberValidator.greaterThan70(groupName)] : '0',
            quadGrowthPercent: this.isNonTotalPayPlanPercentValidatorsEnabled ? 
                        ['0', NumberValidator.greaterThan25()] : '0',
            dealerGrowthPercent: this.isNonTotalPayPlanPercentValidatorsEnabled ? 
                        ['0', NumberValidator.greaterThan25()] : '0',
            purchasePercent: '0',
            dispositionPercent: '0',
            agedPercentagePercent: '0',
            localBoostPercent: '0',
            totalPercent: ['0', NumberValidator.equal100Percent()],
            baseSummary: '0',
            basePercentSummary: '0',
            vacationSummary: '0',
            vacationPercentSummary: '0',
            profitSummary: '0',
            profitPercentSummary: '0',
            volumePaySummary: '0',
            volumePayPercentSummary: '0',
            spcSummary: '0',
            spcPercentSummary: '0',
            quadGrowthSummary: '0',
            quadGrowthPercentSummary: '0',
            dealerGrowthSummary: '0',
            dealerGrowthPercentSummary: '0',
            dispositionSummary: '0',
            dispositionPercentSummary: '0',
            agedPercentageSummary: '0',
            agedPercentagePercentSummary: '0',
            localBoostPercentSummary: '0',
            localBoostPercentagePercentSummary: '0',         
            purchaseVolumePaySummary: '0',
            purchaseVolumePayPercentSummary: '0',
                     
            dealerSoldToAllPercent: '0',
            dealerSoldToAllSummary: '0',
            dealerSoldToAllPercentSummary: '0',
            dealerSoldToAllPercentTarget:'0',
            dealerSoldToAllPercentPayout:'0', 


            totalSummary: '0',
            totalPercentSummary: ['0', NumberValidator.equal100Percent()]
        });
    }

    recalcPayPlanFormValues(payPlanForm: UntypedFormGroup) {
        payPlanForm.patchValue({
            targetCompensation: this.customCurrencyPipe.transform(payPlanForm.value.targetCompensation, '1.2-2'),
            vacationPay: this.customCurrencyPipe.transform(payPlanForm.value.vacationPay, '1.2-2'),
            basePay: this.customCurrencyPipe.transform(payPlanForm.value.basePay, '1.2-2'),
            salesVolumeTarget: this.customCurrencyPipe.transform(payPlanForm.value.salesVolumeTarget, '1.2-2'),
            salesVolumePayPerUnit: this.customCurrencyPipe.transform(payPlanForm.value.salesVolumePayPerUnit, '1.2-2'),
            spcPayout: this.isSpcEnabled ? this.customCurrencyPipe.transform(payPlanForm.value.spcPayout, '1.2-2')
                : '0.00',
            profitBonusAmount: this.customCurrencyPipe.transform(payPlanForm.value.profitBonusAmount, '1.2-2'),
            profitBonusPercent: this.customCurrencyPipe.transform(payPlanForm.value.profitBonusPercent, '1.5-5'),
            quadGrowthTarget: this.customCurrencyPipe.transform(payPlanForm.value.quadGrowthTarget, '1.0-0'),
            quadGrowthPayout: this.customCurrencyPipe.transform(payPlanForm.value.quadGrowthPayout, '1.2-2'),
            dealersSoldToGrowthTarget: this.customCurrencyPipe.transform(payPlanForm.value.dealersSoldToGrowthTarget, '1.0-0'),
            dealersSoldToGrowthPayout: this.customCurrencyPipe.transform(payPlanForm.value.dealersSoldToGrowthPayout, '1.2-2'),
            daysToDispositionTarget: this.customCurrencyPipe.transform(payPlanForm.value.daysToDispositionTarget, '1.1-1'),
            daysToDispositionPayout: this.customCurrencyPipe.transform(payPlanForm.value.daysToDispositionPayout, '1.2-2'),
            agedPercentageTarget: this.isPayAgedPercentEnabled ? this.customCurrencyPipe.transform(payPlanForm.value.agedPercentageTarget, '1.1-1') : '0.00',
            agedPercentagePayout: this.isPayAgedPercentEnabled ? this.customCurrencyPipe.transform(payPlanForm.value.agedPercentagePayout, '1.2-2') : '0.00',            
            localBoostPercentTarget: this.isPayLocalBoostEnabled ? this.customCurrencyPipe.transform(payPlanForm.value.localBoostPercentTarget, '1.2-2') : '0',
            localBoostPercentPayout: this.isPayLocalBoostEnabled ? this.customCurrencyPipe.transform(payPlanForm.value.localBoostPercentPayout, '1.2-2') : '0',
            dealerSoldToAllTarget: this.customCurrencyPipe.transform(payPlanForm.value.dealerSoldToAllTarget, '1.1-1'),
            dealerSoldToAllPayout: this.customCurrencyPipe.transform(payPlanForm.value.dealerSoldToAllPayout, '1.2-2'),

            purchaseVolumeTarget: this.customCurrencyPipe.transform(payPlanForm.value.purchaseVolumeTarget, '1.2-2'),
            purchaseVolumePayPerUnit: this.customCurrencyPipe.transform(payPlanForm.value.purchaseVolumePayPerUnit, '1.2-2')
        });

        // now we need to set values used by the totals
        payPlanForm.patchValue({
            targetCompWithoutVac: this.customCurrencyPipe.transform((payPlanForm.value.targetCompensation.replace(/,/g, '') - payPlanForm.value.vacationPay.replace(/,/g, '')), '1.2-2'),
            targetProduction: this.customCurrencyPipe.transform(payPlanForm.value.targetCompensation.replace(/,/g, '') - payPlanForm.value.vacationPay.replace(/,/g, '') - payPlanForm.value.basePay.replace(/,/g, ''), '1.2-2'),
            purchaseVolumePayout: this.customCurrencyPipe.transform(payPlanForm.value.purchaseVolumeTarget.replace(/,/g, '') * payPlanForm.value.purchaseVolumePayPerUnit.replace(/,/g, ''), '1.2-2'),
            
            salesVolumePayoutDisplay: this.customCurrencyPipe.transform(payPlanForm.value.salesVolumeTarget.replace(/,/g, '') * payPlanForm.value.salesVolumePayPerUnit.replace(/,/g, '')
                                + payPlanForm.value.purchaseVolumeTarget.replace(/,/g, '') * payPlanForm.value.purchaseVolumePayPerUnit.replace(/,/g, ''), '1.2-2'),

            salesVolumePayout: this.customCurrencyPipe.transform(payPlanForm.value.salesVolumeTarget.replace(/,/g, '') * payPlanForm.value.salesVolumePayPerUnit.replace(/,/g, ''), '1.2-2')
        });

        // now calculate the individual summary totals, need to split this out from the totals above since these use the fields above
        payPlanForm.patchValue({
            profitPercent: (Number(payPlanForm.value.targetProduction.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.profitBonusAmount.replace(/,/g, '') / payPlanForm.value.targetProduction.replace(/,/g, '') * 10000 / 100, '1.2-2'),

            spcPercent: this.isSpcEnabled ? (Number(payPlanForm.value.targetProduction.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.spcPayout.replace(/,/g, '') / payPlanForm.value.targetProduction.replace(/,/g, '') * 10000 / 100, '1.2-2')
                : '0.00',
            quadGrowthPercent: (Number(payPlanForm.value.targetProduction.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.quadGrowthPayout.replace(/,/g, '') / payPlanForm.value.targetProduction.replace(/,/g, '') * 10000 / 100, '1.2-2'),

            dealerGrowthPercent: (Number(payPlanForm.value.targetProduction.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.dealersSoldToGrowthPayout.replace(/,/g, '') / payPlanForm.value.targetProduction.replace(/,/g, '') * 10000 / 100, '1.2-2'),

            dispositionPercent: (Number(payPlanForm.value.targetProduction.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.daysToDispositionPayout.replace(/,/g, '') / payPlanForm.value.targetProduction.replace(/,/g, '') * 10000 / 100, '1.2-2'),

            agedPercentagePercent:  this.isPayAgedPercentEnabled ? (Number(payPlanForm.value.targetProduction.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.agedPercentagePayout.replace(/,/g, '') / payPlanForm.value.targetProduction.replace(/,/g, '') * 10000 / 100, '1.2-2')
                : '0.00',
            dealerSoldToAllPercent: (Number(payPlanForm.value.targetProduction.replace(/,/g, '')) === 0) ? '0.00' :
            this.customCurrencyPipe.transform(payPlanForm.value.dealerSoldToAllPayout.replace(/,/g, '') / payPlanForm.value.targetProduction.replace(/,/g, '') * 10000 / 100, '1.2-2'),

            localBoostPercent: this.isPayLocalBoostEnabled ? (Number(payPlanForm.value.targetProduction.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.localBoostPercentPayout.replace(/,/g, '') / payPlanForm.value.targetProduction.replace(/,/g, '') * 10000 / 100, '1.2-2') :
                '0.00',
                
        

            baseSummary: payPlanForm.value.basePay,

            basePercentSummary: (Number(payPlanForm.value.targetCompensation.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.basePay.replace(/,/g, '') / payPlanForm.value.targetCompensation.replace(/,/g, '') * 10000 / 100, '1.2-2'),

            vacationSummary: payPlanForm.value.vacationPay,

            vacationPercentSummary: (Number(payPlanForm.value.targetCompensation.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.vacationPay.replace(/,/g, '') / payPlanForm.value.targetCompensation.replace(/,/g, '') * 10000 / 100, '1.2-2'),

            profitSummary: payPlanForm.value.profitBonusAmount,

            profitPercentSummary: (Number(payPlanForm.value.targetCompensation.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.profitBonusAmount.replace(/,/g, '') / payPlanForm.value.targetCompensation.replace(/,/g, '') * 10000 / 100, '1.2-2'),

            volumePercent: (Number(payPlanForm.value.targetProduction.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(
                    (payPlanForm.value.salesVolumePayout.replace(/,/g, '')/ payPlanForm.value.targetProduction.replace(/,/g, '')) * 10000 / 100, '1.2-2'),
 
            volumePaySummary: (Number(payPlanForm.value.salesVolumePayout.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.salesVolumePayout.replace(/,/g, ''), '1.2-2'),

            volumePayPercentSummary: (Number(payPlanForm.value.targetCompensation.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(
                    (payPlanForm.value.salesVolumePayout.replace(/,/g, '') / payPlanForm.value.targetCompensation.replace(/,/g, '')) * 10000 / 100, '1.2-2'),

            spcSummary: this.isSpcEnabled ? payPlanForm.value.spcPayout : '0.00',

            spcPercentSummary: this.isSpcEnabled ? (Number(payPlanForm.value.targetCompensation.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.spcPayout.replace(/,/g, '') / payPlanForm.value.targetCompensation.replace(/,/g, '') * 10000 / 100, '1.2-2') : '0.00',

            quadGrowthSummary: payPlanForm.value.quadGrowthPayout,

            quadGrowthPercentSummary: (Number(payPlanForm.value.targetCompensation.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.quadGrowthPayout.replace(/,/g, '') / payPlanForm.value.targetCompensation.replace(/,/g, '') * 10000 / 100, '1.2-2'),

            dealerGrowthSummary: payPlanForm.value.dealersSoldToGrowthPayout,

            dealerGrowthPercentSummary: (Number(payPlanForm.value.targetCompensation.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.dealersSoldToGrowthPayout.replace(/,/g, '') / payPlanForm.value.targetCompensation.replace(/,/g, '') * 10000 / 100, '1.2-2'),

            dispositionSummary: payPlanForm.value.daysToDispositionPayout,

            dispositionPercentSummary: (Number(payPlanForm.value.targetCompensation.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.daysToDispositionPayout.replace(/,/g, '') / payPlanForm.value.targetCompensation.replace(/,/g, '') * 10000 / 100, '1.2-2'),

            agedPercentageSummary: this.isPayAgedPercentEnabled ? payPlanForm.value.agedPercentagePayout : '0.00',

            agedPercentagePercentSummary: this.isPayAgedPercentEnabled ? (Number(payPlanForm.value.targetCompensation.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.agedPercentagePayout.replace(/,/g, '') / payPlanForm.value.targetCompensation.replace(/,/g, '') * 10000 / 100, '1.2-2')
                : '0.00',

            localBoostPercentSummary: this.isPayLocalBoostEnabled ? payPlanForm.value.localBoostPercentPayout : '0.00',

            localBoostPercentagePercentSummary: this.isPayLocalBoostEnabled ? (Number(payPlanForm.value.targetCompensation.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.localBoostPercentPayout.replace(/,/g, '') / payPlanForm.value.targetCompensation.replace(/,/g, '') * 10000 / 100, '1.2-2')
                : '0.00',

                
                dealerSoldToAllSummary: payPlanForm.value.dealerSoldToAllPayout,

                dealerSoldToAllPercentSummary: (Number(payPlanForm.value.targetCompensation.replace(/,/g, '')) === 0) ? '0.00' :
                    this.customCurrencyPipe.transform(payPlanForm.value.dealerSoldToAllPayout.replace(/,/g, '') / payPlanForm.value.targetCompensation.replace(/,/g, '') * 10000 / 100, '1.2-2'),
    
            purchaseVolumePaySummary: payPlanForm.value.purchaseVolumePayout,

            purchasePercent: (Number(payPlanForm.value.targetProduction.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.purchaseVolumePayout.replace(/,/g, '') / payPlanForm.value.targetProduction.replace(/,/g, '') * 10000 / 100, '1.2-2'),

            purchaseVolumePayPercentSummary: (Number(payPlanForm.value.targetCompensation.replace(/,/g, '')) === 0) ? '0.00' :
                this.customCurrencyPipe.transform(payPlanForm.value.purchaseVolumePayout.replace(/,/g, '') / payPlanForm.value.targetCompensation.replace(/,/g, '') * 10000 / 100, '1.2-2')    

        });

        // totals of all the totals, splititng these out since pretty much everything else on the form needs to be set
        payPlanForm.patchValue({
            totalPercent: this.customCurrencyPipe.transform(
                Number(payPlanForm.value.profitPercent.replace(/,/g, '')) +
                Number(payPlanForm.value.volumePercent.replace(/,/g, '')) +
                Number(
                    this.isSpcEnabled ? payPlanForm.value.spcPercent.replace(/,/g, '') : '0'
                ) +
                Number(payPlanForm.value.quadGrowthPercent.replace(/,/g, '')) +
                Number(payPlanForm.value.dealerGrowthPercent.replace(/,/g, '')) +
                Number(payPlanForm.value.dispositionPercent.replace(/,/g, '')) +
                Number(this.isPayAgedPercentEnabled ? payPlanForm.value.agedPercentagePercent.replace(/,/g, '') : '0')+
                Number(this.isPayLocalBoostEnabled?payPlanForm.value.localBoostPercent.replace(/,/g, ''): '0') +
                Number(payPlanForm.value.dealerSoldToAllPercent.replace(/,/g, '')) +
                Number(payPlanForm.value.purchasePercent.replace(/,/g, '')), '1.0-0')
                
                ,
            totalSummary: this.customCurrencyPipe.transform(Number(payPlanForm.value.baseSummary.replace(/,/g, '')) +
                Number(payPlanForm.value.vacationSummary.replace(/,/g, '')) +
                Number(payPlanForm.value.profitSummary.replace(/,/g, '')) +
                Number(payPlanForm.value.volumePaySummary.replace(/,/g, '')) +
                Number(
                    this.isSpcEnabled ? payPlanForm.value.spcSummary.replace(/,/g, '') : '0'
                ) +
                Number(payPlanForm.value.quadGrowthSummary.replace(/,/g, '')) +
                Number(payPlanForm.value.dealerGrowthSummary.replace(/,/g, '')) +
                Number(payPlanForm.value.dispositionSummary.replace(/,/g, '')) +
                Number(payPlanForm.value.dealerSoldToAllSummary.replace(/,/g, '')) +
                
                Number(this.isPayAgedPercentEnabled ? payPlanForm.value.agedPercentageSummary.replace(/,/g, '') : '0') +
                Number(this.isPayLocalBoostEnabled ? payPlanForm.value.localBoostPercentSummary.replace(/,/g, '') : '0') +
                Number(payPlanForm.value.purchaseVolumePaySummary.replace(/,/g, '')), '1.2-2'),
            totalPercentSummary: this.customCurrencyPipe.transform(Number(payPlanForm.value.basePercentSummary.replace(/,/g, '')) +
                Number(payPlanForm.value.vacationPercentSummary.replace(/,/g, '')) +
                Number(payPlanForm.value.profitPercentSummary.replace(/,/g, '')) +
                Number(payPlanForm.value.volumePayPercentSummary.replace(/,/g, '')) +
                Number(
                    this.isSpcEnabled ? payPlanForm.value.spcPercentSummary.replace(/,/g, '') : '0'
                ) +
                Number(payPlanForm.value.quadGrowthPercentSummary.replace(/,/g, '')) +
                Number(payPlanForm.value.dealerGrowthPercentSummary.replace(/,/g, '')) +
                Number(payPlanForm.value.dispositionPercentSummary.replace(/,/g, '')) +
                Number(payPlanForm.value.dealerSoldToAllPercentSummary.replace(/,/g, '')) +
                Number(this.isPayAgedPercentEnabled ? payPlanForm.value.agedPercentagePercentSummary.replace(/,/g, '') : '0') +              
                Number(this.isPayLocalBoostEnabled ? payPlanForm.value.localBoostPercentagePercentSummary.replace(/,/g, ''): '0') +
                Number(payPlanForm.value.purchaseVolumePayPercentSummary.replace(/,/g, '')), '1.0-0')
        });
    }

    buildSaveDto(payPlanForm: UntypedFormGroup, user: User, payPlan: PayPlan): PayPlanSaveDto {
        const payPlanDto = new PayPlanSaveDto();
        payPlanDto.id = payPlan.id;
        payPlanDto.targetCompensation = Number(payPlanForm.value.targetCompensation.replace(/,/g, ''));
        payPlanDto.vacationPay = Number(payPlanForm.value.vacationPay.replace(/,/g, ''));
        payPlanDto.vacationDaysTypeId = payPlanForm.value.vacationDaysTypeId;
        payPlanDto.basePay = Number(payPlanForm.value.basePay.replace(/,/g, ''));
        payPlanDto.profitBonusAmount = Number(payPlanForm.value.profitBonusAmount.replace(/,/g, ''));
        payPlanDto.profitBonusPercent = Number(payPlanForm.value.profitBonusPercent.replace(/,/g, '')) / 100;
        payPlanDto.salesVolumeTarget = Number(payPlanForm.value.salesVolumeTarget.replace(/,/g, ''));
        payPlanDto.salesVolumePayPerUnit = Number(payPlanForm.value.salesVolumePayPerUnit.replace(/,/g, ''));
        payPlanDto.employeeMonthId = payPlan.employeeMonthId;

        payPlanDto.netSpcTarget = this.isSpcEnabled ? payPlanForm.value.netSpcTarget : 0;
        payPlanDto.grossSpcTarget = this.isSpcEnabled ? payPlanForm.value.grossSpcTarget : 0; 

        payPlanDto.quadGrowthTarget = Number(payPlanForm.value.quadGrowthTarget.replace(/,/g, '')) / 100;
        payPlanDto.quadGrowthPayout = Number(payPlanForm.value.quadGrowthPayout.replace(/,/g, ''));
        payPlanDto.dealersSoldToGrowthTarget = Number(payPlanForm.value.dealersSoldToGrowthTarget.replace(/,/g, '')) / 100;
        payPlanDto.dealersSoldToGrowthPayout = Number(payPlanForm.value.dealersSoldToGrowthPayout.replace(/,/g, ''));
        payPlanDto.daysToDispositionTarget = Number(payPlanForm.value.daysToDispositionTarget.replace(/,/g, ''));
        payPlanDto.daysToDispositionPayout = Number(payPlanForm.value.daysToDispositionPayout.replace(/,/g, ''));
        payPlanDto.agedPercentageTarget = Number(this.isPayAgedPercentEnabled ? payPlanForm.value.agedPercentageTarget.replace(/,/g, '') : 0) / 100;
        payPlanDto.agedPercentagePayout = Number(this.isPayAgedPercentEnabled ? payPlanForm.value.agedPercentagePayout.replace(/,/g, '') : 0); 

        payPlanDto.localBoostPercentTarget = Number(this.isPayLocalBoostEnabled ? payPlanForm.value.localBoostPercentTarget.replace(/,/g, '') : 0) / 100;
        payPlanDto.localBoostPercentPayout = Number(this.isPayLocalBoostEnabled ? payPlanForm.value.localBoostPercentPayout.replace(/,/g, '') : 0);

        payPlanDto.purchaseVolumeTarget = Number(payPlanForm.value.purchaseVolumeTarget.replace(/,/g, ''));

        payPlanDto.purchaseVolumePayPerUnit = Number(payPlanForm.value.purchaseVolumePayPerUnit.replace(/,/g, ''));
        payPlanDto.dealerSoldToAllTarget = Number(payPlanForm.value.dealerSoldToAllTarget.replace(/,/g, ''));

        payPlanDto.dealerSoldToAllPayout = Number(payPlanForm.value.dealerSoldToAllPayout.replace(/,/g, ''));
         
        payPlanDto.targetSpcType = Number(payPlanForm.value.targetSpcType);
        payPlanDto.updatedBy = user.eId;
        payPlanDto.createdBy = user.eId;

        if (payPlan.employee.isManager && this.isNetSpcEnabled) {                   
                payPlanDto.netSpcPayout = this.isSpcEnabled ? (Number(payPlanForm.value.spcPayout.replace(/,/g, '')) / 2) : 0;
                payPlanDto.grossSpcPayout = this.isSpcEnabled ? (Number(payPlanForm.value.spcPayout.replace(/,/g, '')) / 2) : 0;
        } else {
            payPlanDto.netSpcPayout = 0;
            payPlanDto.grossSpcPayout = this.isSpcEnabled ? Number(payPlanForm.value.spcPayout.replace(/,/g, '')) : 0;
        }
        return payPlanDto;
    }
}