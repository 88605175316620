import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppInsightsService } from '../shared/services/app-insights.service';
import { UserService } from '../security/user.service';
import { HelperMethodsService } from '../shared/services/helper-methods.service';
import { BaseComponent } from '../shared/base/base.component';
import { groupLevels } from '../shared/static-data/group-levels';
import { PaidTimeOff } from '../models/incoming-dtos/paid-time-off';
import { Observable } from 'rxjs';
import { PaidTimeOffService } from './paid-timeoff.service';
import { Subscription } from 'rxjs';
import { SubscriptionService } from '../shared/services/subscription.service';
import { LoadingSpinnerService } from '../shared/loading-spinner/loading-spinner.service';
import { User } from '../security/user';
import { SnackBarService } from '../shared/services/snack-bar.service';
import { PaidTimeOffExportService } from './paid-timeoff-export.service';
import { CoreFiltersService } from '../shared/services/core-filters.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'scoreboard-paid-timeoff',
  templateUrl: './paid-timeoff.component.html'
})
export class PaidTimeoffComponent extends BaseComponent implements OnInit, OnDestroy {
  paidTimeOffData = new Array<PaidTimeOff>();
  paidTimeOffData$ = new Observable<Array<PaidTimeOff>>();
  paidTimeOffSubscription = new Subscription();

  paidTimeOffDataForSave = new Array<PaidTimeOff>();
  paidTimeOffSave$ = new Observable<boolean>();
  paidTimeOffSaveSubscription = new Subscription();

  exportData$ = new Observable<Array<PaidTimeOff>>();
  exportData = new Array<PaidTimeOff>();
  exportSubscription = new Subscription();
  coreFilterSubscription = new Subscription();

  user = new User();

  constructor(public appInsights: AppInsightsService,
              public userService: UserService,
              public helperMethods: HelperMethodsService,
              public paidTimeOffSvc: PaidTimeOffService,
              public subscribeService: SubscriptionService,
              public loadingSpinnerSvc: LoadingSpinnerService,
              public snackBarSvc: SnackBarService,
              public paidTimeOffExport: PaidTimeOffExportService,
              public coreFilterSvc: CoreFiltersService) {
              super(appInsights, userService);
    }

  ngOnInit() {
    this.trackUser();
    this.getFilterValues();
  }

  ngOnDestroy() {
    this.coreFilterSubscription.unsubscribe();
  }

  getFilterValues() {
    this.coreFilterSvc.filterValues$.subscribe(filterValues => {
        this.level = filterValues[0].level;
        this.levelValue = filterValues[0].levelValue;
        this.month = filterValues[0].month;
        this.year = filterValues[0].year;
        this.isEurope = filterValues[0].isEurope;
        if (filterValues[1] === '/paid-timeoff') {
          this.onSubmitSearch();
        }
    });
  }

  onSubmitSearch() {
    if (this.helperMethods.isStringNullOrEmpty(this.levelValue) || this.levelValue === groupLevels.invalid) {
        this.filterErrorMessage = 'You must select a valid ' + this.level + ' to submit your search.';
        this.userService.alertUser(this.filterErrorMessage);
    } else {
      this.getPaidTimeOffData();
    }
  }

  onPaidTimeOffDataChanged(paidTimeOffTableData: any[]) {
    this.paidTimeOffDataForSave = paidTimeOffTableData;
    this.onSave();
  }

  onExportFromChild(shouldGetExportData: boolean) {
    if (shouldGetExportData) {
      this.getExportDataForPaidTimeOff();
    }
  }

  getExportDataForPaidTimeOff() {
    this.exportData$ = this.paidTimeOffSvc.getExportDataForPaidTimeOff(this.paidTimeOffData[0].ehiGroupId, this.year, this.month);
    this.subscribeToExportData();
  }

  subscribeToExportData() {
    this.exportSubscription.unsubscribe();
    this.exportData = [];

    this.exportSubscription = this.exportData$
      .pipe(
        finalize(() => {
          this.loadingSpinnerSvc.turnSpinnerOff();
        })
      )
      .subscribe(
        exportData => {
          this.exportData = exportData;
          this.appInsights.logUserAction('User exported paid time off for '
          + this.level + ': ' + this.levelValue + 'on ' + this.month + '/' + this.year, this.user);
          this.processPaidTimeOffExport();
        },
        err => {
          this.subscribeService.onError(err, this.level, 'GetExportDataForPaidTimeOff');
          this.loadingSpinnerSvc.turnSpinnerOff();
        }
      );
  }

  getPaidTimeOffData() {
    this.paidTimeOffData$ = this.paidTimeOffSvc.getPaidTimeOffForGroup$(this.levelValue, this.month, this.year);
    this.subscribeToPaidTimeOffData();
  }

  subscribeToPaidTimeOffData() {
    this.paidTimeOffSubscription.unsubscribe();
    this.paidTimeOffData = [];
    this.loadingSpinnerSvc.shouldShowLoading();

    this.paidTimeOffSubscription = this.paidTimeOffData$
      .pipe(
        finalize(() => {
          this.loadingSpinnerSvc.turnSpinnerOff();
        })
      )
      .subscribe(
        paidTimeOff => {
          this.paidTimeOffData = paidTimeOff;
          if (this.helperMethods.isArrayNullOrEmpty(this.paidTimeOffData)) {
            this.userService.alertUser('No Paid time off data found for the selected filters.');
          }
        },
        err => {
          this.subscribeService.onError(err, this.level, 'GetPaidTimeOffData');
          this.loadingSpinnerSvc.turnSpinnerOff();
        }
      );
  }

  onSave() {
    this.paidTimeOffSave$ = this.paidTimeOffSvc.updatePaidTimeOffData(this.paidTimeOffDataForSave, this.user.eId);
    this.subscribeToPaidTimeOffDataForSave();
  }

  subscribeToPaidTimeOffDataForSave() {
    this.paidTimeOffSaveSubscription.unsubscribe();
    this.paidTimeOffSaveSubscription = this.paidTimeOffSave$
      .pipe(
        finalize(() => {
          this.snackBarSvc.openSnackBar('Save was successful.');
        })
      )
      .subscribe(
        () => {
          this.appInsights.logUserAction('User saved paid time off for '
          + this.level + ': ' + this.levelValue + 'on ' + this.month + '/' + this.year, this.user);
          this.getPaidTimeOffData();
        },
        err => {
          this.subscribeService.onError(err, this.level, 'updatePaidTimeOffData');
          this.loadingSpinnerSvc.turnSpinnerOff();
        }
      );
  }

  processPaidTimeOffExport() {
    this.paidTimeOffExport.generateExport(this.exportData, this.levelValue, this.month, this.year);
  }
}
