import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ErrorHandlingService } from '../shared/services/error-handling.service';
import { UserService } from '../security/user.service';
import { Employee } from '../models/incoming-dtos/employee';
import { EmployeeGoal } from '../models/incoming-dtos/employee-goal';
import { User } from '../security/user';
import { EmployeeGoalUpdateDto } from '../models/outgoing-dtos/employee-goal-update-dto';
import { GroupPreference } from '../models/incoming-dtos/group-preference';
import { GroupPreferenceUpdateDto } from '../models/outgoing-dtos/group-preference-update-dto';
import { Team } from '../models/incoming-dtos/team';
import { TeamGoal } from '../models/incoming-dtos/team-goal';
import { TeamGoalUpdateDto } from '../models/outgoing-dtos/team-goal-update-dto';
import { Group } from '../models/incoming-dtos/group';
import { GroupGoal } from '../models/incoming-dtos/group-goal';
import { GroupGoalUpdateDto } from '../models/outgoing-dtos/group-goal-update-dto';
import { catchError } from 'rxjs/operators';

@Injectable()
export class GoalAssignmentService {
    private baseUrl: string = environment.dotNetCoreUrl + 'coreapi/';
    private getEmployeeGoalsUrl: string = this.baseUrl + 'Goals/employee';
    private saveEmployeeGoalsUrl: string = this.baseUrl + 'Goals/employee';
    private saveTeamGoalsUrl: string = this.baseUrl + 'Goals/team';
    private saveGroupGoalsUrl: string = this.baseUrl + 'Goals/group';
    private getGroupPreferencesUrl: string = this.baseUrl + 'Goals/groupPreferences';
    private saveGroupPreferencesUrl: string = this.baseUrl + 'Goals/saveGroupPreferences';
    private getTeamGoalsUrl: string = this.baseUrl + 'Goals/team';
    private getGroupGoalsUrl: string = this.baseUrl + 'Goals/group';

    constructor(private httpClient: HttpClient,
                private userService: UserService,
                private errorService: ErrorHandlingService) { }

    getGroupPreferences(groupName: string): Observable<GroupPreference[]> {
        const url = this.getGroupPreferencesUrl + '/' + groupName;
        return this.httpClient.get<GroupPreference[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getEmployeeGoals(groupName: string, year: number, month: number): Observable<Employee[]> {
        const url = this.getEmployeeGoalsUrl + '/' + groupName + '/' + year + '/' + month;
        return this.httpClient.get<Employee[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getTeamGoals(groupName: string, year: number, month: number): Observable<Team[]> {
        const url = this.getTeamGoalsUrl + '/' + groupName + '/' + year + '/' + month;
        return this.httpClient.get<Team[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getGroupGoals(groupName: string, year: number, month: number): Observable<Group[]> {
        const url = this.getGroupGoalsUrl + '/' + groupName + '/' + year + '/' + month;
        return this.httpClient.get<Group[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    saveEmployeeGoals(employeeGoalsForSave: EmployeeGoal[], user: User, autoGoals: boolean): Observable<boolean> {
        const employeeGoalUpdateDto = this.buildEmployeeGoalUpdateDto(employeeGoalsForSave, user);
        return this.httpClient.put<boolean>(this.saveEmployeeGoalsUrl + '/' + autoGoals, employeeGoalUpdateDto, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    saveTeamGoals(teamGoalsForSave: TeamGoal[], user: User): Observable<boolean> {
        const teamGoalUpdateDto = this.buildTeamGoalUpdateDto(teamGoalsForSave, user);
        return this.httpClient.put<boolean>(this.saveTeamGoalsUrl, teamGoalUpdateDto, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    saveGroupGoals(groupGoalsForSave: GroupGoal[], user: User, year: number, month: number): Observable<boolean> {
        const groupGoalUpdateDto = this.buildGroupGoalUpdateDto(groupGoalsForSave, user, year, month);
        return this.httpClient.put<boolean>(this.saveGroupGoalsUrl, groupGoalUpdateDto, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    saveGroupPreferences(saveGroupPrefDtos: GroupPreferenceUpdateDto[]): Observable<boolean> {
        return this.httpClient.put<boolean>(this.saveGroupPreferencesUrl, saveGroupPrefDtos, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    buildEmployeeGoalUpdateDto(employeeGoalsForSave: EmployeeGoal[], user: User): EmployeeGoalUpdateDto[] {
        const dataForSave = new Array<EmployeeGoalUpdateDto>();
        for (let i = 0; i < employeeGoalsForSave.length; i++) {
            employeeGoalsForSave[i].goalValue = this.checkGoalValue(employeeGoalsForSave[i].goalValue);
            dataForSave[i] = new EmployeeGoalUpdateDto();
            dataForSave[i].id = employeeGoalsForSave[i].id;
            dataForSave[i].goalId = employeeGoalsForSave[i].goalId;
            dataForSave[i].employeeMonthId = employeeGoalsForSave[i].employeeMonthId;
            dataForSave[i].goalName = employeeGoalsForSave[i].goalName;
            dataForSave[i].goalValue = employeeGoalsForSave[i].goalValue;
            dataForSave[i].updatedBy = user.eId;
            if (employeeGoalsForSave[i].goalValue === null) {
                dataForSave[i].recordStatus = false;
            } else {
                dataForSave[i].recordStatus = true;
            }
            dataForSave[i].teamId = employeeGoalsForSave[i].teamId;
            dataForSave[i].month = employeeGoalsForSave[i].month;
            dataForSave[i].year = employeeGoalsForSave[i].year;
            dataForSave[i].ehiGroupId = employeeGoalsForSave[i].ehiGroupId;
        }
        return dataForSave;
    }

    buildTeamGoalUpdateDto(teamGoalsForSave: TeamGoal[], user: User): TeamGoalUpdateDto[] {
        const dataForSave = new Array<TeamGoalUpdateDto>();
        for (let i = 0; i < teamGoalsForSave.length; i++) {
            teamGoalsForSave[i].goalValue = this.checkGoalValue(teamGoalsForSave[i].goalValue);
            dataForSave[i] = new TeamGoalUpdateDto();
            dataForSave[i].id = teamGoalsForSave[i].id;
            dataForSave[i].goalId = teamGoalsForSave[i].goalId;
            dataForSave[i].teamId = teamGoalsForSave[i].teamId;
            dataForSave[i].goalName = teamGoalsForSave[i].goalName;
            dataForSave[i].goalValue = teamGoalsForSave[i].goalValue;
            dataForSave[i].updatedBy = user.eId;
            if (teamGoalsForSave[i].goalValue === null) {
                dataForSave[i].recordStatus = false;
            } else {
                dataForSave[i].recordStatus = true;
            }
        }
        return dataForSave;
    }

    buildGroupGoalUpdateDto(groupGoalsForSave: GroupGoal[], user: User, year: number, month: number): GroupGoalUpdateDto[] {
        const dataForSave = new Array<GroupGoalUpdateDto>();
        for (let i = 0; i < groupGoalsForSave.length; i++) {
            groupGoalsForSave[i].goalValue = this.checkGoalValue(groupGoalsForSave[i].goalValue);
            dataForSave[i] = new GroupGoalUpdateDto();
            dataForSave[i].id = groupGoalsForSave[i].id;
            dataForSave[i].goalId = groupGoalsForSave[i].goalId;
            dataForSave[i].ehiGroupId = groupGoalsForSave[i].ehiGroupId;
            dataForSave[i].goalName = groupGoalsForSave[i].goalName;
            dataForSave[i].goalValue = groupGoalsForSave[i].goalValue;
            dataForSave[i].updatedBy = user.eId;
            dataForSave[i].month = month;
            dataForSave[i].year = year;
            if (groupGoalsForSave[i].goalValue === null) {
                dataForSave[i].recordStatus = false;
            } else {
                dataForSave[i].recordStatus = true;
            }
        }
        return dataForSave;
    }

    checkGoalValue(goalValue: any): any {
        if (goalValue === '') {
            return null;
        } else {
            // remove commas goalValue set in UpdateDto
            if(typeof(goalValue) === 'string') {
                return goalValue.replace(/\,/g, '');
            }
            return goalValue;
        }
    }
}
