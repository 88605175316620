import { Injectable } from '@angular/core';
import { PayMemo } from '../../models/incoming-dtos/pay-memo';
import { SpcBaseComponent } from '../../../app/shared/base/spc-base.component';
import { AppInsightsService } from '../../shared/services/app-insights.service';

@Injectable()
export class PayMemoTableService extends SpcBaseComponent {

    constructor(public appInsights: AppInsightsService) {
        super(appInsights);
    }

    buildPayMemoCopies(payMemos: PayMemo[]): PayMemo[] {
        const copies = new Array<PayMemo>();
        payMemos.forEach(memo => {
            const copy = new PayMemo();
            copy.id = memo.id;
            copy.teamName = memo.teamName;
            copy.payPlanId = memo.payPlanId;
            copy.ptoDaysUsed = memo.ptoDaysUsed;
            copy.ptoRate = memo.ptoRate;
            copy.ptoPay = memo.ptoPay;
            copy.actualSalesVolume = memo.actualSalesVolume;
            copy.actualSalesVolumeTeam = memo.actualSalesVolumeTeam;
            copy.payoutPerCar = memo.payoutPerCar;
            copy.salesVolumePayout = memo.salesVolumePayout;
            copy.grossSpcActual = this.isSpcEnabled ? memo.grossSpcActual : 0;
            copy.grossSpcTarget = this.isSpcEnabled ? memo.grossSpcTarget : 0;
            copy.grossSpcPayout = this.isSpcEnabled ? memo.grossSpcPayout : 0;
            copy.adjustedGrossSPCActual = this.isSpcEnabled ? memo.adjustedGrossSPCActual : 0;
            copy.grossSPCNationalActual = this.isSpcEnabled ? memo.grossSPCNationalActual : 0;


            copy.netSpcActual = this.isSpcEnabled ? memo.netSpcActual : 0;
            copy.netSpcTarget = this.isSpcEnabled ? memo.netSpcTarget : 0;
            copy.netSpcPayout = this.isSpcEnabled ? memo.netSpcPayout : 0;
            copy.quadGrowthActual = memo.quadGrowthActual * 100; // Multiplying these by 100 to show percentages as whole numbers.
            copy.quadGrowthTarget = memo.quadGrowthTarget * 100;
            copy.quadGrowthPayout = memo.quadGrowthPayout;
            copy.dealersSoldToGrowthActual = memo.dealersSoldToGrowthActual * 100;
            copy.dealersSoldToGrowthTarget = memo.dealersSoldToGrowthTarget * 100;
            copy.dealersSoldToGrowthPayout = memo.dealersSoldToGrowthPayout;
            copy.daysToDispositionActual = memo.daysToDispositionActual;
            copy.daysToDispositionTarget = memo.daysToDispositionTarget;
            copy.daysToDispositionPayout = memo.daysToDispositionPayout;
            copy.agedPercentageActual = memo.agedPercentageActual * 100;
            copy.agedPercentageTarget = memo.agedPercentageTarget * 100;
            copy.agedPercentagePayout = memo.agedPercentagePayout;
            copy.purchaseVolumeActual = memo.purchaseVolumeActual;
            copy.purchaseVolumeActualTeam = memo.purchaseVolumeActualTeam;
            copy.purchaseVolumePayPerUnit = memo.purchaseVolumePayPerUnit;
            copy.purchaseVolumePayPayout = memo.purchaseVolumePayPayout;
            copy.localBoostPercentActual = memo.localBoostPercentActual * 100;
            copy.localBoostPercentTarget = memo.localBoostPercentTarget * 100;
            copy.localBoostPercentPayout = memo.localBoostPercentPayout;
            copy.projectedPay = memo.projectedPay;
            copy.adjustments = memo.adjustments;
            copy.adjustedPay = memo.adjustedPay;
            copy.comments = memo.comments;
            copy.locked = memo.locked;
            copy.employee = memo.employee;
            copy.milestoneBonus = memo.milestoneBonus;
            copy.buyerAverage = memo.buyerAverage;
            copy.averageBuyerGoalBonus = memo.averageBuyerGoalBonus;
            copy.milestoneBonusTeam = memo.milestoneBonusTeam;
            copy.buyerAverageTeam = memo.buyerAverageTeam;
            copy.averageBuyerGoalBonusTeam = memo.averageBuyerGoalBonusTeam;
            copy.reversalAdjustment = memo.reversalAdjustment;

            copy.dealerSoldToAllActual = memo.dealerSoldToAllActual;
            copy.dealerSoldToAllTarget  = memo.dealerSoldToAllTarget ;
            copy.dealerSoldToAllPayout = memo.dealerSoldToAllPayout;



            

            copies.push(copy);
        });
        return copies;
    }
}
