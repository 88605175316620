import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { PayplanSetupComponent } from './payplan-setup.component';
import { PayplanSetupRouteActivator } from './payplan-setup-route-activator';
import { TeamEmployeesComponent } from './team-employees/team-employees.component';
import { PayplanSetupService } from './payplan-setup.service';
import { TeamEmployeesService } from './team-employees/team-employees.service';
import { PayplanFormComponent } from './payplan-form/payplan-form.component';
import { PayplanFormService } from './payplan-form/payplan-form.service';
import { PayplanFormExportService } from './payplan-form/payplan-form-export.service';
import { EuPayplanFormComponent } from './eupayplan-form/eupayplan-form.component';
import { EuPayplanFormService } from './eupayplan-form/eupayplan-form.service';
import { EuPayplanFormExportService } from './eupayplan-form/eupayplan-form-export.service';
import { MilestoneComponent } from './eupayplan-form/milestone/milestone.component';
import { BuyerTierComponent } from './eupayplan-form/buyer-tier/buyer-tier.component';
import { TeamBuyerTierComponent } from './eupayplan-form/buyer-tier/team-buyer-tier.component';
import { TeamMilestoneComponent } from './eupayplan-form/milestone/team-milestone.component';

@NgModule({
    imports: [
        SharedModule,
        ReactiveFormsModule,
        RouterModule.forChild([
            { path: 'payplan-setup', component: PayplanSetupComponent, canActivate: [PayplanSetupRouteActivator] }
        ])
    ],
    declarations: [
        PayplanSetupComponent,
        TeamEmployeesComponent,
        PayplanFormComponent,
        EuPayplanFormComponent,
        MilestoneComponent,
        BuyerTierComponent,
        TeamBuyerTierComponent,
        TeamMilestoneComponent
    ],
    providers: [
        PayplanSetupRouteActivator,
        PayplanSetupService,
        TeamEmployeesService,
        PayplanFormService,
        PayplanFormExportService,
        EuPayplanFormService,
        EuPayplanFormExportService
    ]
})
export class PayplanSetupModule { }
