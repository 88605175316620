import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SpinnerDetectionService {
    public isLoading: Subject<boolean> = new Subject;
    public isLoading$ = this.isLoading.asObservable();
    constructor() { }

}
