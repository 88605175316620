import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PaidTimeOff } from '../models/incoming-dtos/paid-time-off';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../security/user.service';
import { ErrorHandlingService } from '../shared/services/error-handling.service';
import { PaidTimeOffUpdateDto } from '../models/outgoing-dtos/update-paid-time-off-dto';
import { catchError } from 'rxjs/operators';

@Injectable()
export class PaidTimeOffService {
    private baseUrl: string = environment.dotNetCoreUrl + 'coreapi/PaidTimeOff/';
    private paidTimeOffForGroupUrl: string = this.baseUrl + 'group';
    private paidTimeOffForUpdateUrl: string = this.baseUrl + 'update';
    private paidTimeOffExportUrl: string = this.baseUrl + 'export';

    paidTimeOff$ = new Observable<PaidTimeOff[]>();

    constructor(private httpClient: HttpClient,
        private userService: UserService,
        private errorService: ErrorHandlingService) { }

    getPaidTimeOffForGroup$(levelValue: string, month: number, year: number): Observable<PaidTimeOff[]> {
        const url = this.paidTimeOffForGroupUrl + '/' + levelValue + '/' + year + '/' + month;
        return this.httpClient.get<PaidTimeOff[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    updatePaidTimeOffData(paidTimeOffData: PaidTimeOff[], updatedBy: string): Observable<boolean> {
        const paidTimeOffForUpdateDto = this.buildPaidTimeOffForUpdateDto(paidTimeOffData, updatedBy);
        return this.httpClient.put<boolean>(this.paidTimeOffForUpdateUrl, paidTimeOffForUpdateDto, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getExportDataForPaidTimeOff(ehiGroupId: number, year: number, month: number): Observable<PaidTimeOff[]> {
        const url = this.paidTimeOffExportUrl + '/' + ehiGroupId + '/' + year + '/' + month;
        return this.httpClient.get<PaidTimeOff[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    buildPaidTimeOffForUpdateDto(paidTimeOffData: PaidTimeOff[], updatedBy: string): PaidTimeOffUpdateDto[] {
        const paidTimeOffForUpdate = [];
        for (let i = 0; i < paidTimeOffData.length; i++) {
            paidTimeOffForUpdate[i] = new PaidTimeOffUpdateDto();
            paidTimeOffForUpdate[i].id = paidTimeOffData[i].id;
            paidTimeOffForUpdate[i].employeeId = paidTimeOffData[i].employeeId;
            paidTimeOffForUpdate[i].ehiGroupId = paidTimeOffData[i].ehiGroupId;
            paidTimeOffForUpdate[i].daysUsed = paidTimeOffData[i].monthDaysUsed;
            paidTimeOffForUpdate[i].month = paidTimeOffData[i].month;
            paidTimeOffForUpdate[i].year = paidTimeOffData[i].year;
            paidTimeOffForUpdate[i].updatedBy = updatedBy;
        }
        return paidTimeOffForUpdate;
    }
}
