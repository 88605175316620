export class PayMemoAllColumns {
    employeeId: string;
    employeeName: string;
    jobTitle: string;
    grpTeam: string;
    mtdPtoDays: number;
    ptoRate: string;
    ptoPay: string;
    volume: string;
    payPerCar: string;
    salesVolumePay: string
    grossSpc: string;
    adjustedGrossSPCActual: string;
    grossSPCNationalActual: string;
    grossBase: string;
    grossSpcPay: string;
    netSpc: string;
    netBase: string;
    netSpcPay: string;
    disposition: string;
    dispositionBase: string;
    dispositionPay: string;
 
    agedPercent: string;
    agedPercentBase: string;
    agedPercentPay: string;
  
    purchaseVolumeActual: string;
    purchaseVolumeActualTeam: string;
    purchaseVolumePayPerUnit: string;
    purchaseVolumePayPayout: string;

    dealerSoldToAllActual:string;
    dealerSoldToAllTarget:string;
    dealerSoldToAllPayout:string;
    localBoostPercentActual: string;
    localBoostPercentTarget: string;
    localBoostPercentPayout: string;
    projectedPay: string;
    adjustments: string;
    adjustedProdPay: string;
    comments: string;
}
