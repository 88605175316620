import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { MatTableDataSource } from '@angular/material/table';
import { PaidTimeOffTableService } from './paid-timeoff-table.service';
import { PaidTimeOff } from '../../models/incoming-dtos/paid-time-off';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { groupLevels } from '../../shared/static-data/group-levels';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { UserService } from '../../security/user.service';
import { User } from '../../security/user';
import { SecuredObjectService } from '../../security/secured-object.service';
import { StyleHelperService } from '../../shared/services/style-helper.service';

@Component({
  selector: 'scoreboard-paid-timeoff-table',
  templateUrl: './paid-timeoff-table.component.html',
  styleUrls: ['./paid-timeoff-table.component.scss']
})

export class PaidTimeoffTableComponent implements OnInit, OnChanges {
  @Input() paidTimeOffData: PaidTimeOff[];
  @Input() level: string;
  @Input() levelValue: string;
  @Input() month: number;
  @Input() year: number;

  @Output() paidTimeOffDataChanged: EventEmitter<any[]>;
  @Output() shouldGetExportData: EventEmitter<boolean>;

  user: User;
  shouldEnableInputAndSave = true;
  paidTimeOffDataForTable = new Array<PaidTimeOff>();
  paidTimeOffDataInView: PaidTimeOff[];
  dataSource = new MatTableDataSource();
  displayedColumns = ['groupName', 'name', 'jobTitle', 'monthDaysUsed', 'yearDaysUsed'];
  query = '';
  shouldEnableExport = false;
  hasUserInputData = false;
  employeeIdsForSave = new Array<number>();
  recordsForSave = new Array<PaidTimeOff>();

  constructor(private helperMethods: HelperMethodsService,
              private paidTimeOffTableSvc: PaidTimeOffTableService,
              private customCurrencyPipe: CustomCurrencyPipe,
              public loadingSpinnerSvc: LoadingSpinnerService,
              private userService: UserService,
              public secObjService: SecuredObjectService,
              public styleHelper: StyleHelperService) {
                this.paidTimeOffDataChanged = new EventEmitter<any[]>();
                this.shouldGetExportData = new EventEmitter<boolean>();
                styleHelper.maxWidth = 680;
  }

  ngOnInit() {
    this.userService.userSource$.subscribe(user => this.user = user);
    this.paidTimeOffDataForTable = this.paidTimeOffTableSvc.buildPaidTimeOffDataForTable(this.paidTimeOffData);
    this.getTableData();
  }

  ngOnChanges() {
    if (this.paidTimeOffData[0].locked) {
      this.shouldEnableInputAndSave = false;
      this.shouldEnableExport = false;
    } else {
      this.shouldEnableInputAndSave = true;
      this.shouldEnableExport = true;
    }
    if (this.shouldEnableExport) {
      this.checkLevelForExport();
    }
  }

  checkLevelForExport() {
    if (this.level === groupLevels.group) {
      this.shouldEnableExport = true;
    } else {
      this.shouldEnableExport = false;
    }
  }

  applyFilter(filterValue: string) {
    this.query = filterValue;
    if (!this.helperMethods.isStringNullOrEmpty(filterValue)) {
      this.dataSource.data = [];
      this.paidTimeOffDataInView = this.paidTimeOffTableSvc.filterPaidTimeOffData(filterValue, this.paidTimeOffDataForTable);
      this.dataSource.data = this.paidTimeOffDataInView;
    } else {
      this.dataSource.data = this.paidTimeOffDataForTable;
    }
  }

  getTableData() {
    this.dataSource.data = this.paidTimeOffDataForTable;
  }

  onExport() {
    this.loadingSpinnerSvc.turnSpinnerOn('Loading...');
    const dataCheck = this.dataSource.data;
    if (!this.helperMethods.isArrayNullOrEmpty(dataCheck)) {
      this.shouldGetExportData.emit(true);
    } else {
      this.shouldGetExportData.emit(false);
    }
  }

  onSave() {
    this.loadingSpinnerSvc.turnSpinnerOn('Saving...');
    this.recordsForSave = this.paidTimeOffTableSvc.getRecordsForSave(this.employeeIdsForSave, this.paidTimeOffDataForTable);
    this.paidTimeOffDataChanged.emit(this.recordsForSave);
  }

  pipeAdjustments(empId: number, val: number) {
    // apply rounding to 2 decimals, but then remove commas to convert back to a number
    const adj = this.customCurrencyPipe.transform(val.toString(), '1.2-2').replace(/,/g, '');
    this.paidTimeOffDataForTable.find(data => data.employeeId === empId).monthDaysUsed = Number(adj);
  }

  recordEmployeeIdsForSave(paidTimeOffRecord: PaidTimeOff) {
    this.employeeIdsForSave = this.paidTimeOffTableSvc.checkEmployeeIdsForSave(
      this.employeeIdsForSave, paidTimeOffRecord, this.paidTimeOffData);
    this.checkEmployeeIdsForSave();
  }

  checkEmployeeIdsForSave() {
    if (this.employeeIdsForSave.length > 0) {
      this.hasUserInputData = true;
    } else {
      this.hasUserInputData = false;
    }
  }
}
