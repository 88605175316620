import { Injectable } from '@angular/core';
import { PaidTimeOff } from '../../models/incoming-dtos/paid-time-off';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';

@Injectable()
export class PaidTimeOffTableService {

    constructor(public helperMethods: HelperMethodsService) {}

    buildPaidTimeOffDataForTable(paidTimeOffData: PaidTimeOff[]): PaidTimeOff[] {
        const paidTimeOffDataForTable = new Array<PaidTimeOff>();
        paidTimeOffData.forEach(row => {
            const paidTimeOff = new PaidTimeOff();
            paidTimeOff.daysUsed = row.daysUsed;
            paidTimeOff.ehiGroupId = row.ehiGroupId;
            paidTimeOff.employeeId = row.employeeId;
            paidTimeOff.firstName = row.firstName;
            paidTimeOff.groupName = row.groupName;
            paidTimeOff.id = row.id;
            paidTimeOff.jobTitle = row.jobTitle;
            paidTimeOff.lastName = row.lastName;
            paidTimeOff.month = row.month;
            paidTimeOff.monthDaysUsed = row.monthDaysUsed;
            paidTimeOff.year = row.year;
            paidTimeOff.yearDaysUsed = row.yearDaysUsed;
            paidTimeOffDataForTable.push(paidTimeOff);
        });

        return paidTimeOffDataForTable;
    }

    filterPaidTimeOffData(filterValue: string, tableData: any[]): any[] {
        const text = filterValue.toLowerCase();
        const filteredData = [];
        tableData.forEach(obj => {
            if (!this.helperMethods.isStringNullOrEmpty(obj.firstName) && !this.helperMethods.isStringNullOrEmpty(obj.lastName)) {
                const name = obj.firstName.toLowerCase() + ' ' + obj.lastName.toLowerCase();
                if (name.includes(text)) {
                    filteredData.push(obj);
                }
            }
        });
        if (!this.helperMethods.isArrayNullOrEmpty(filteredData)) {
            return filteredData;
        } else {
            return tableData = [];
        }
    }

    checkEmployeeIdsForSave(employeeIdsForSave: number[], paidTimeOffRecord: PaidTimeOff, paidTimeOffData: PaidTimeOff[]): number[] {
        paidTimeOffData.forEach(row => {
            if (row.employeeId === paidTimeOffRecord.employeeId && employeeIdsForSave.includes(paidTimeOffRecord.employeeId)) {
                if (row.monthDaysUsed.toString() === paidTimeOffRecord.monthDaysUsed.toString()) {
                    const index = employeeIdsForSave.indexOf(paidTimeOffRecord.employeeId);
                    employeeIdsForSave.splice(index, 1);
                }
            } else if (row.employeeId === paidTimeOffRecord.employeeId &&
                row.monthDaysUsed.toString() !== paidTimeOffRecord.monthDaysUsed.toString()) {
                employeeIdsForSave.push(paidTimeOffRecord.employeeId);
            }
        });

        return employeeIdsForSave;
    }

    getRecordsForSave(employeeIdsForSave: number[], paidTimeOffDataForTable: PaidTimeOff[]): PaidTimeOff[] {
        const recordsForSave = new Array<PaidTimeOff>();
        employeeIdsForSave.forEach(employeeId => {
            const record = paidTimeOffDataForTable.filter(x => x.employeeId === employeeId);
            recordsForSave.push(record[0]);
        });
        return recordsForSave;
    }
}
