import { Component, Input, OnChanges } from '@angular/core';
import { DashboardGroupOverview } from '../../models/incoming-dtos/dashboard-group-overview';
import { GoalActual } from '../../models/incoming-dtos/goal-actual';
import { StyleHelperService } from '../../shared/services/style-helper.service';
import { DashboardGroupOverviewService } from './dashboard-group-overview.service';

@Component({
  selector: 'scoreboard-dashboard-group-overview',
  templateUrl: './dashboard-group-overview.component.html',
  styleUrls: ['./dashboard-group-overview.component.scss']
})
export class DashboardGroupOverviewComponent implements OnChanges {
    @Input() dashboardGroupOverviewData: DashboardGroupOverview[];
    @Input() levelValue: string;
    @Input() month: number;
    @Input() year: number;
    @Input() isEurope: boolean;
    goalPrefix = new Array<string>();
    goalSuffix = new Array<string>();
    selectedGoalActuals = new Array<boolean>();
    aged = 'Aged %';
    deleteToSaleDays = 'Delete To Sale Days';
    localBoost = 'Local Boost as a % of DR Fleet';
    domWidth: number;
    dashboardSelectedGroup = new DashboardGroupOverview();

    constructor(public dashboardGroupOverviewSvc: DashboardGroupOverviewService,
        public styleHelper: StyleHelperService) {
            styleHelper.maxWidth = 1023;
        }

    ngOnChanges() {
        this.filterOverviewData();
        this.checkNullForGoalValues();
    }

    filterOverviewData(): any {
        this.dashboardSelectedGroup = this.dashboardGroupOverviewSvc.filterOverviewData(this.levelValue,
            this.dashboardGroupOverviewData);
    }

    checkNullForGoalValues() {
        this.dashboardGroupOverviewData.forEach(element => {
            element.goalActuals.forEach(goal => {
                    const index = element.goalActuals.indexOf(goal);
                        if (goal.goalValue === null) {
                            goal.goalValue = '-';
                            this.goalPrefix[index] = '';
                            this.goalSuffix[index] = '';
                        } else {
                            this.goalPrefix[index] = goal.prefix;
                            this.goalSuffix[index] = goal.suffix;
                        }
            });
            if (element.salesPaceGoal === null) {
                element.salesPaceGoal = '-';
            }
        });
    }

    getPaceArrow(): string {
        if (this.dashboardSelectedGroup.salesPaceOnTrack) {
            return 'icon-arrow-up';
        } else {
            return 'icon-arrow-down';
        }
    }

    getSymbol(goalActual: GoalActual): string {
        if (goalActual.onTrack) {
            return 'icon-arrow-up';
        } else {
            return 'icon-arrow-down';
        }
    }

    checkColumnSize(): string {
        const domWidth = document.body.offsetWidth;
        this.domWidth = domWidth;
        return this.determineScreenSize();
    }

    determineScreenSize(): string {
        if (this.domWidth >= 1500) {
            return 'col-sm-2'; // Desktop view
        } else if (this.domWidth <= 1499 && this.domWidth >= 1350) {
            return 'col-md-3'; // Laptop view
        } else if (this.domWidth <= 1349 && this.domWidth > 700) {
            return 'col-3'; // Tablet view
        } else {
            return 'col-5'; // Mobile view
        }
    }

    checkCountry(): string {
        const style = 'europe-padding';
        if (this.isEurope) {
            return style;
        } else {
            return '';
        }
    }
}
