import { GoalActual } from './goal-actual';
import { Group } from './group';

export class DashboardGroupOverview {
    dailySalesPace: number;
    salesPaceGoal: string;
    salesPaceOnTrack: boolean;
    rank: number;
    maxRank: number;
    goalActuals: GoalActual[];
    ehiGroup: Group;
}
