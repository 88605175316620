import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppInsightsService } from './services/app-insights.service';
import { BaseComponent } from './base/base.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { ErrorHandlingService } from './services/error-handling.service';
import { NavbarComponent } from './navbar/navbar.component';
import { MaterialModule } from './material/material.module';
import { HelperMethodsService } from './services/helper-methods.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GroupFilterComponent } from './group-filter/group-filter.component';
import { GroupFilterService } from './group-filter/group-filter.service';
import { SubscriptionService } from './services/subscription.service';
import { DateFilterComponent } from './date-filter/date-filter.component';
import { DateFilterService } from './date-filter/date-filter.service';
import { FooterComponent } from './footer/footer.component';
import { SnackBarService } from './services/snack-bar.service';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LoadingSpinnerService } from './loading-spinner/loading-spinner.service';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HighlightMatchPipe } from './pipes/highlight-match.pipe';
import { XlsxExportService } from './xlsx-export/xlsx-export.service';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { NoDecimalNumberOnlyDirective } from './directives/no-decimal-number-only.directive';
import { DecisionModalComponent } from './decision-modal/decision-modal.component';
import { CustomCurrencyPipe } from './pipes/custom-currency.pipe';
import { PositiveNumberOnlyDirective } from './directives/positive-number-only.directives';
import { EmployeeFilterComponent } from './employee-filter/employee-filter.component';
import { MetricFilterComponent } from './metric-filter/metric-filter.component';
import { TeamFilterComponent } from './team-filter/team-filter.component';
import { MetricFilterService } from './metric-filter/metric-filter.service';
import { TeamFilterService } from './team-filter/team-filter.service';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { EmployeeFilterService } from './employee-filter/employee-filter.service';
import { AddCommasPipe } from './pipes/add-commas.pipe';
import { CoreFiltersService } from './services/core-filters.service';
import { StyleHelperService } from './services/style-helper.service';
import { SpinnerDetectionService } from './loading-spinner/spinner-detection.service';
import { RoleFilterComponent } from './role-filter/role-filter.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        MaterialModule,
        ReactiveFormsModule,
        RouterModule.forChild([
            { path: 'contact-us', component: ContactUsComponent },
            { path: 'page-not-found', component: PageNotFoundComponent }
        ])
    ],
    exports: [
        CommonModule,
        FormsModule,
        BaseComponent,
        AlertModalComponent,
        BrowserAnimationsModule,
        NavbarComponent,
        MaterialModule,
        GroupFilterComponent,
        DateFilterComponent,
        FooterComponent,
        LoadingSpinnerComponent,
        HighlightMatchPipe,
        NumberOnlyDirective,
        NoDecimalNumberOnlyDirective,
        PositiveNumberOnlyDirective,
        CustomCurrencyPipe,
        EmployeeFilterComponent,
        MetricFilterComponent,
        TeamFilterComponent,
        PageNotFoundComponent,
        AddCommasPipe,
        RoleFilterComponent
    ],
    declarations: [
        BaseComponent,
        AlertModalComponent,
        NavbarComponent,
        GroupFilterComponent,
        DateFilterComponent,
        FooterComponent,
        LoadingSpinnerComponent,
        ContactUsComponent,
        HighlightMatchPipe,
        NumberOnlyDirective,
        NoDecimalNumberOnlyDirective,
        DecisionModalComponent,
        PositiveNumberOnlyDirective,
        CustomCurrencyPipe,
        EmployeeFilterComponent,
        CustomCurrencyPipe,
        MetricFilterComponent,
        TeamFilterComponent,
        CustomCurrencyPipe,
        PageNotFoundComponent,
        AddCommasPipe,
        RoleFilterComponent
    ],
    providers: [
        AppInsightsService,
        ErrorHandlingService,
        HelperMethodsService,
        GroupFilterService,
        SubscriptionService,
        DateFilterService,
        SnackBarService,
        LoadingSpinnerService,
        XlsxExportService,
        CustomCurrencyPipe,
        MetricFilterService,
        TeamFilterService,
        EmployeeFilterService,
        CoreFiltersService,
        StyleHelperService,
        SpinnerDetectionService
    ]
})
export class SharedModule { }
