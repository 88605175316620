import { Injectable } from '@angular/core';
import { Admin } from '../../models/incoming-dtos/admin';

@Injectable()
export class AdminTableService {
    buildAdminDataTuple(adminData: Admin[]): [Admin, boolean][] {
        const adminDataTuple = new Array<[Admin, boolean]>();
        adminData.forEach(obj => {
            const adminObj = new Admin();
            adminObj.ehiGroupId = obj.ehiGroupId;
            adminObj.groupName = obj.groupName;
            adminObj.lastUpdated = obj.lastUpdated;
            adminObj.locked = obj.locked;
            adminObj.month = obj.month;
            adminObj.performedBy = obj.performedBy;
            adminObj.year = obj.year;
            adminDataTuple.push([adminObj, obj.locked]);
        });
        return adminDataTuple;
    }
}
