import { NgModule } from '@angular/core';
import { AdminComponent } from './admin.component';
import { AdminTableComponent } from './admin-table/admin-table.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AdminRouteActivator } from './admin-route-activator';
import { AdminService } from './admin.service';
import { AdminTableService } from './admin-table/admin-table.service';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      { path: 'admin', component: AdminComponent, canActivate: [AdminRouteActivator] }
    ])
  ],
  declarations: [
    AdminComponent,
    AdminTableComponent
  ],
  providers: [
    AdminRouteActivator,
    AdminService,
    AdminTableService
  ]

})
export class AdminModule { }
