import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable()
export class SnackBarService {
    constructor(public snackBar: MatSnackBar) {}

    openSnackBar(message: string, action?: any) {
        this.snackBar.open(message, action, {
            duration: 2000
        });
    }
}
