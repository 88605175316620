import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { UserService } from '../../security/user.service';
import { DashboardRouteActivator } from '../../dashboard/dashboard-route-activator';
import { TeamAssignmentRouteActivator } from '../../team-assignment/team-assignment-route-activator';
import { PayplanSetupRouteActivator } from '../../payplan-setup/payplan-setup-route-activator';
import { PayMemoRouteActivator } from '../../pay-memo/pay-memo-route-activator';
import { PaidTimeoffRouteActivator } from '../../paid-timeoff/paid-timeoff-route-activator';
import { AdminRouteActivator } from '../../admin/admin-route-activator';
import { GoalAssignmentRouteActivator } from '../../goal-assignment/goal-assignment-route-activator';

@Component({
  selector: 'scoreboard-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Output() onPageChange: EventEmitter<string>;
  showContactUs = false;
  constructor(public userService: UserService,
              public dashboardActivator: DashboardRouteActivator,
              public goalAssignmentActivator: GoalAssignmentRouteActivator,
              public paidTimeOffActivator: PaidTimeoffRouteActivator,
              public teamAssignmentActivator: TeamAssignmentRouteActivator,
              public payPlanSetupActivator: PayplanSetupRouteActivator,
              public payMemoActivator: PayMemoRouteActivator,
              public adminActivator: AdminRouteActivator) {
    this.onPageChange = new EventEmitter<string>();

  }
  ngOnInit() {
    if (this.userService.user.country === 'USA' || this.userService.user.country === 'CAN') {
      this.showContactUs = true;
    }
  }
  onClickLink(url: string) {
    this.onPageChange.emit(url);
  }
}
