import { DaysUsedHistory } from './days-used-history';

export class PaidTimeOff {
    id: number;
    employeeId: number;
    ehiGroupId: number;
    firstName: string;
    lastName: string;
    groupName: string;
    jobTitle: string;
    monthDaysUsed: number;
    yearDaysUsed: number;
    month: number;
    year: number;
    daysUsed: DaysUsedHistory;
    locked: boolean;
}
