import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DateFilterService } from './date-filter.service';
import { MonthYear } from './month-year';

@Component({
  selector: 'scoreboard-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss']
})
export class DateFilterComponent implements OnInit {
  monthYears: MonthYear[];
  selectedMonthYear: MonthYear;
  @Output() monthYearChanged: EventEmitter<MonthYear>;

  constructor(private dateService: DateFilterService) {
    this.monthYearChanged = new EventEmitter<MonthYear>();
  }

  ngOnInit() {
    this.monthYears = this.dateService.getMonthYears();

    if (this.IsFirstOfTheMonth()) {
      this.selectedMonthYear = this.monthYears[2]; // default to previous month
    } else {
      this.selectedMonthYear = this.monthYears[1]; // default to current month
    }

    this.onSelectMonthYear();
  }

  onSelectMonthYear() {
    this.monthYearChanged.emit(this.selectedMonthYear);
  }

  IsFirstOfTheMonth(): boolean {
    return new Date().getDate() === 1;
  }
}
