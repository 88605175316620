import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { HelperMethodsService } from '../services/helper-methods.service';

@Injectable()
export class XlsxExportService {

    constructor (private helperMethods: HelperMethodsService) {

    }

    generateExport(dataForExport: any[], fileName: string, title?: string) {
        /* make the worksheet */
        let ws: any;
        if (!this.helperMethods.isStringNullOrEmpty(title)) {
            ws = this.getWorkSheetWithTitle(title, dataForExport);
        } else {
            ws = XLSX.utils.json_to_sheet(dataForExport);
        }

        ws['!cols'] = this.getColumnSizes(dataForExport);

        /* add to workbook */
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws);

        /* write workbook (use type 'binary') */
        const wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'binary'});

        /* generate a download */
        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            // tslint:disable-next-line:no-bitwise
            for (let i = 0; i !== s.length; ++i) { view[i] = s.charCodeAt(i) & 0xFF; }
            return buf;
        }

        FileSaver.saveAs(new Blob([s2ab(wbout)], {type: 'application/octet-stream'}), fileName + '.xlsx');
    }

    getWorkSheetWithTitle(title: string, dataForExport: any[]): XLSX.WorkSheet {
        const formatFileName = [];
        const ws = XLSX.utils.json_to_sheet(formatFileName); // Generate blank worksheet
        formatFileName.push({title}); // pushing a type any object with title in it since requirement is to be json object.
        XLSX.utils.sheet_add_json(ws, formatFileName, {skipHeader: true, origin: 'F1'}); // Append title into worksheet starting at cell F1 so that it's centered on the page.
        XLSX.utils.sheet_add_json(ws, dataForExport, {origin: 'A2'}); // Append data to worksheet starting on the second row in cell A2.
        return ws;
    }

    getColumnSizes(joinedItems: any[]): any {
        const colsArray = [];
        let firstRow = true;
        const keys = Object.keys(joinedItems[0]);
        const numCols = keys.length;
        joinedItems.forEach(row => {
            let colLength = 0;
            if (firstRow) {
                for (let index = 0; index < numCols; index++) {
                    let stringLength = row[keys[index]];
                    stringLength = this.helperMethods.convertToString(stringLength);
                    // Convert boolean and numbers to strings with convertToString method
                    if (this.helperMethods.isStringNullOrEmpty(stringLength)) {
                        colLength = keys[index].length;
                    } else {
                        if (keys[index].length > stringLength.length) {
                            colLength = keys[index].length;
                        } else {
                            colLength = stringLength.length;
                        }
                    }
                    colsArray.push({wch: colLength + 1});
                }
                firstRow = false;
            } else {
                for (let index = 0; index < numCols; index++) {
                    let stringLength = row[keys[index]];
                    stringLength = this.helperMethods.convertToString(stringLength);
                    if (this.helperMethods.isStringNullOrEmpty(stringLength)) {
                    } else {
                        colLength = stringLength.length;
                    }
                    if (colsArray[index].wch < colLength) {
                        colsArray[index].wch = colLength;
                    }
                }
            }
        });
        const finalColsArray = [];
        colsArray.forEach(col => {
            finalColsArray.push({wch: col.wch + 2}); // This adds a buffer to the end of the column. Change the number to adjust size.
        });
        return finalColsArray;
    }
}
