import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { UserService } from './security/user.service';
import { SharedModule } from './shared/shared.module';
import { MsalGuard, MsalGuardConfiguration, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { FormsModule } from '@angular/forms';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardRouteActivator } from './dashboard/dashboard-route-activator';
import { DashboardModule } from './dashboard/dashboard.module';
import { TeamAssignmentModule } from './team-assignment/team-assignment.module';
import { PayplanSetupModule } from './payplan-setup/payplan-setup.module';
import { PayMemoModule } from './pay-memo/pay-memo.module';
import { PaidTimeoffModule } from './paid-timeoff/paid-timeoff.module';
import { GoalAssignmentModule } from './goal-assignment/goal-assignment.module';
import { AdminModule } from './admin/admin.module';
import { SecuredObjectService } from './security/secured-object.service';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { environment } from '../environments/environment';
import { GlobalizationService } from './shared/services/globalization.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LogLevel, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

 export const msalConfig = {
    auth: {
       clientId: environment.msal.clientId,
       redirectUri: window.location.origin,
       authority: environment.msal.msAuthority + '/' + environment.msal.tenantId
    },
    cache: {
       cacheLocation: 'sessionStorage',
       storeAuthStateInCookie: isIE,
    },
   system: {	
       loggerOptions: {	
           loggerCallback: (level, message, containsPii) => {	
               if (containsPii) {		
                   return;		
               }		
               switch (level) {		
                   case LogLevel.Error:		
                       console.error(message);		
                       return;		
                   case LogLevel.Info:		
                       console.info(message);		
                       return;		
                   case LogLevel.Verbose:		
                       console.debug(message);		
                       return;		
                   case LogLevel.Warning:		
                       console.warn(message);		
                       return;		
               }	
           }	
       }	
   }
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);

    
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>(GetProtectedResourceMapList());
    return {
      interactionType: InteractionType.Redirect,
      protectedResourceMap
    };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return { 
                interactionType: InteractionType.Redirect,
                authRequest: {
                scopes: ['user.read']
            }
            //,loginFailedRoute: '/login-failed'
    };
}

export function GetProtectedResourceMapList(): [string, string[]][] {
 
    let result : [string,string[]][] = [];

    try
    {
        let resMap = environment.msal.protectedResourceMap;
        
        resMap.forEach((x,y) => {
            let arr : string[] = [];
            arr.push(x[1][0]);

            let str : string = x[0].toString();
            result.push([str, arr]);
        });
    } catch (error) {
        console.log('Error loading protected resource map from env');
    }
   
   return result;
};

@NgModule({
    imports: [
        BrowserModule,
        TranslateModule.forRoot(
            {
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            }
        ),
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        RouterModule.forRoot([
            { path: 'dashboard', component: DashboardComponent, canActivate: [DashboardRouteActivator] },
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            { path: '**', component: PageNotFoundComponent }
        ]),
        SharedModule,
        DashboardModule,
        TeamAssignmentModule,
        PayplanSetupModule,
        PayMemoModule,
        PaidTimeoffModule,
        GoalAssignmentModule,
        AdminModule,
        MsalModule.forRoot( new PublicClientApplication(msalConfig,), MSALGuardConfigFactory(), MSALInterceptorConfigFactory())
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        UserService,
        SecuredObjectService,
        DashboardRouteActivator,
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory },
        MsalService,
        MsalRedirectComponent,
        MsalGuard,
        GlobalizationService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
