import { Injectable } from '@angular/core';
import { Group } from '../../models/incoming-dtos/group';
import { groupLevels } from '../../shared/static-data/group-levels';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';

@Injectable()
export class GroupTeamsService {

    constructor(private helperMethods: HelperMethodsService) {}

    findTeamIndex(groups: Group[], newTeamToBeSelected: number): number {
        let index: number;
        groups[0].teams.forEach(team => {
            if (team.id === newTeamToBeSelected) {
                index = groups[0].teams.indexOf(team);
            }
        });
        return index;
    }

    findGroupIndex(groups: Group[], teamId: number): number {
        const index = groups.findIndex(g => g.teams.some(t => t.id === teamId));
        return index;
    }

    checkLevelAndDate(level: string, month: number, year: number): boolean {
        if (level === groupLevels.group) {
            return this.helperMethods.isPastMonthYear(month, year);
        } else {
            return false;
        }
    }

    findTeamId(groupIndex: number, teamIndex: number, groups: Group[]): number {
        const groupObj = groups[groupIndex];
        const teamObj = groupObj.teams[teamIndex];
        return teamObj.id;
    }

    findGroupId(groupIndex: number, groups: Group[]): string {
        const group = groups[groupIndex];
        return group.name;
    }
}
