import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlingService } from '../services/error-handling.service';
import { UserService } from '../../security/user.service';
import { GoalDto } from '../../models/outgoing-dtos/goal-dto';
import { environment } from '../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MetricFilterService {
  private baseUrl: string = environment.dotNetCoreUrl + 'coreapi/';
  private goalUrl: string = this.baseUrl + 'Dashboard/Goals';

  constructor(private httpClient: HttpClient, private userService: UserService,
    private errorService: ErrorHandlingService) { }

  getGoals(groupName: string): Observable<GoalDto[]> {
    return this.httpClient.get<GoalDto[]>(this.goalUrl + '/' + groupName, this.userService.getHttpOptions())
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
