import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '../security/user.service';
import { AppInsightsService } from '../shared/services/app-insights.service';
import { HelperMethodsService } from '../shared/services/helper-methods.service';
import { BaseComponent } from '../shared/base/base.component';
import { groupLevels } from '../shared/static-data/group-levels';
import { Employee } from '../models/incoming-dtos/employee';
import { Observable, Subscription } from 'rxjs';
import { LoadingSpinnerService } from '../shared/loading-spinner/loading-spinner.service';
import { GoalAssignmentService } from './goal-assignment.service';
import { EmployeeGoal } from '../models/incoming-dtos/employee-goal';
import { SnackBarService } from '../shared/services/snack-bar.service';
import { SubscriptionService } from '../shared/services/subscription.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { GroupPreference } from '../models/incoming-dtos/group-preference';
import { GroupPreferenceUpdateDto } from '../models/outgoing-dtos/group-preference-update-dto';
import { Team } from '../models/incoming-dtos/team';
import { TeamGoal } from '../models/incoming-dtos/team-goal';
import { Group } from '../models/incoming-dtos/group';
import { GroupGoal } from '../models/incoming-dtos/group-goal';
import { SecuredObjectService } from '../security/secured-object.service';
import { CoreFiltersService } from '../shared/services/core-filters.service';
import { finalize } from 'rxjs/operators';
import { GroupFilterService } from '../shared/group-filter/group-filter.service';
import { UpdateGroupDto } from '../models/outgoing-dtos/update-group-dto';

@Component({
  selector: 'scoreboard-goal-assignment',
  templateUrl: './goal-assignment.component.html',
  styleUrls: ['./goal-assignment.component.scss']
})
export class GoalAssignmentComponent extends BaseComponent implements OnInit, OnDestroy {
    employeeGoals = new Array<Employee>();
    employeeGoals$ = new Observable<Employee[]>();
    employeeGoalSubscription = new Subscription();

    teamGoals = new Array<Team>();
    teamGoals$ = new Observable<Team[]>();
    teamGoalSubscription = new Subscription();

    groupGoals = new Array<Group>();
    groupGoals$ = new Observable<Group[]>();
    groupGoalSubscription = new Subscription();

    groupPreferences = new Array<GroupPreference>();
    groupPreferences$ = new Observable<GroupPreference[]>();
    groupPreferenceSubscription = new Subscription();

    isEmployeeSaveSuccessful$ = new Observable<boolean>();
    isTeamSaveSuccessful$ = new Observable<boolean>();
    isGroupSaveSuccessful$ = new Observable<boolean>();
    employeeGoalSaveSubscription = new Subscription();
    teamGoalSaveSubscription = new Subscription();
    groupGoalSaveSubscription = new Subscription();

    employeeGoalsForSave = new Array<EmployeeGoal>();
    teamGoalsForSave = new Array<TeamGoal>();
    groupGoalsForSave = new Array<GroupGoal>();
    currentSelectedTab = 0;

    saveGroupPrefDtos = new Array<GroupPreferenceUpdateDto>();
    isGroupPrefSaved$ = new Observable<boolean>();
    isGroupPrefSubscription = new Subscription();
    coreFilterSubscription = new Subscription();
    saveGroupDto: UpdateGroupDto;
    isGroupSaved$ = new Observable<boolean>();
    groupSubscription = new Subscription();
    isGroupSaved: boolean;
    isAutoGenerateGoalsOn: boolean;
    
    constructor(public userService: UserService,
                public appInsights: AppInsightsService,
                public helperMethods: HelperMethodsService,
                public goalAssignmentSvc: GoalAssignmentService,
                public loadingSpinnerSvc: LoadingSpinnerService,
                public snackBarSvc: SnackBarService,
                public subscribeService: SubscriptionService,
                public employeeGoalSvc: GoalAssignmentService,
                public secObjService: SecuredObjectService,
                public coreFilterSvc: CoreFiltersService,
                public groupFilterService: GroupFilterService) {
                super(appInsights, userService);
    }

    ngOnInit() {
        this.getFilterValues();
    }

    ngOnDestroy() {
        this.coreFilterSubscription.unsubscribe();
    }

    getFilterValues() {
        this.coreFilterSvc.filterValues$.subscribe(filterValues => {
            this.level = filterValues[0].level;
            this.levelValue = filterValues[0].levelValue;
            this.month = filterValues[0].month;
            this.year = filterValues[0].year;
            this.isEurope = filterValues[0].isEurope;
            if (filterValues[1] === '/goal-assignment') {
              this.onSubmitSearch();
            }
        });
      }

    onSubmitSearch() {
        if (this.helperMethods.isStringNullOrEmpty(this.levelValue) || this.levelValue === groupLevels.invalid) {
            this.filterErrorMessage = 'You must select a valid ' + this.level + ' to submit your search.';
            this.userService.alertUser(this.filterErrorMessage);
        } else {
            this.getDataForSelectedTab();
        }
    }

    tabChanged(tabChangeEvent: MatTabChangeEvent) {
        this.currentSelectedTab = tabChangeEvent.index;
        this.getDataForSelectedTab();
    }

    getDataForSelectedTab() {
        switch (this.currentSelectedTab) {
            case 0:
                this.getGroupPreferences();
                this.tabData = 'User accessed Group Preferences Tab.';
                this.coreFilterSvc.setGroupPrefPage(true);
                break;
            case 1:
                this.getEmployeeGoals();
                this.tabData = 'User accessed Employee Goals Tab.';
                this.coreFilterSvc.setGroupPrefPage(false);
                break;
            case 2:
                this.getTeamGoals();
                this.tabData = 'User accessed Team Goals Tab.';
                this.coreFilterSvc.setGroupPrefPage(false);
                break;
            case 3:
                this.getGroupGoals();
                this.tabData = 'User accessed Group Goals Tab.';
                this.coreFilterSvc.setGroupPrefPage(false);
                break;
        }
        this.trackUser();
    }

    onGetAutoGenerateGoalStatus(autoGoals: boolean) {
        this.isAutoGenerateGoalsOn = autoGoals;
    }

    onAutoGoalsFlagChanged(autoGoals: boolean) {
        this.saveGroupDto = new UpdateGroupDto();
        this.saveGroupDto.autoGenerateGoals = autoGoals;
        this.saveGroupDto.id = this.groupPreferences[0].ehiGroupId;
        this.saveGroupDto.date = new Date().toLocaleDateString();
        this.saveGroup();
    }
    
    saveGroup() {
        this.isGroupSaved$ = this.groupFilterService.saveGroup(this.saveGroupDto);
        this.subscribeToIsGroupSaved();
    }
    
    subscribeToIsGroupSaved() {
        this.groupSubscription.unsubscribe();
        this.groupSubscription = this.isGroupSaved$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                isGroupSaved => {
                    this.isGroupSaved = isGroupSaved;
                },
                err => {
                    this.subscribeService.onError(err, 'SaveGroup');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    onSaveGroupPreferences(saveGroupPrefDtos: GroupPreferenceUpdateDto[]) {
        this.saveGroupPrefDtos = saveGroupPrefDtos;
        this.saveGroupPreferences();
    }

    onSaveEmployeeGoals(goalsToBeSaved: EmployeeGoal[]) {
        this.employeeGoalsForSave = goalsToBeSaved;
        this.saveEmployeeGoals();
    }

    onSaveTeamGoals(goalsToBeSaved: TeamGoal[]) {
        this.teamGoalsForSave = goalsToBeSaved;
        this.saveTeamGoals();
    }

    onSaveGroupGoals(goalsToBeSaved: GroupGoal[]) {
        this.groupGoalsForSave = goalsToBeSaved;
        this.saveGroupGoals();
    }

    getGroupPreferences() {
        this.groupPreferences$ = this.goalAssignmentSvc.getGroupPreferences(this.levelValue);
        this.subscribeToGroupPreferences();
    }

    getEmployeeGoals() {
        this.employeeGoals$ = this.goalAssignmentSvc.getEmployeeGoals(this.levelValue, this.year, this.month);
        this.subscribeToEmployeeGoals();
    }

    getTeamGoals() {
        this.teamGoals$ = this.goalAssignmentSvc.getTeamGoals(this.levelValue, this.year, this.month);
        this.subscribeToTeamGoals();
    }

    getGroupGoals() {
        this.groupGoals$ = this.goalAssignmentSvc.getGroupGoals(this.levelValue, this.year, this.month);
        this.subscribeToGroupGoals();
    }

    saveGroupPreferences() {
        this.isGroupPrefSaved$ = this.goalAssignmentSvc.saveGroupPreferences(this.saveGroupPrefDtos);
        this.subscribeToIsGroupPrefSaved();
    }

    saveEmployeeGoals() {
        this.isEmployeeSaveSuccessful$ = this.goalAssignmentSvc.saveEmployeeGoals(this.employeeGoalsForSave, this.user, this.isAutoGenerateGoalsOn);
        this.subscribeToEmployeeGoalSave();
    }

    saveTeamGoals() {
        this.isTeamSaveSuccessful$ = this.goalAssignmentSvc.saveTeamGoals(this.teamGoalsForSave, this.user);
        this.subscribeToTeamGoalSave();
    }

    saveGroupGoals() {
        this.isGroupSaveSuccessful$ = this.goalAssignmentSvc.saveGroupGoals(this.groupGoalsForSave, this.user, this.year, this.month);
        this.subscribeToGroupGoalSave();
    }

    subscribeToGroupPreferences() {
        this.loadingSpinnerSvc.shouldShowLoading();
        this.groupPreferenceSubscription.unsubscribe();
        this.groupPreferenceSubscription = this.groupPreferences$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                groupPreferences => {
                    this.groupPreferences = groupPreferences;
                    if (this.helperMethods.isArrayNullOrEmpty(groupPreferences)) {
                        this.userService.alertUser('No data found for the selected group.');
                    }
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'GetGroupPreferences');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToEmployeeGoals() {
        this.loadingSpinnerSvc.shouldShowLoading();
        this.employeeGoalSubscription.unsubscribe();
        this.employeeGoals = [];
        this.employeeGoalSubscription = this.employeeGoals$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                employeeGoals => {
                    this.employeeGoals = employeeGoals;
                    if (this.helperMethods.isArrayNullOrEmpty(this.employeeGoals)) {
                        this.userService.alertUser('No data found for Group ' + this.levelValue);
                    }
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'GetEmployeeGoals');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToTeamGoals() {
        this.loadingSpinnerSvc.shouldShowLoading();
        this.teamGoalSubscription.unsubscribe();
        this.teamGoals = [];
        this.teamGoalSubscription = this.teamGoals$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                teamGoals => {
                    this.teamGoals = teamGoals;
                    if (this.helperMethods.isArrayNullOrEmpty(this.teamGoals)) {
                        this.userService.alertUser('No data found for Group ' + this.levelValue);
                    }
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'GetTeamGoals');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToGroupGoals() {
        this.loadingSpinnerSvc.shouldShowLoading();
        this.groupGoalSubscription.unsubscribe();
        this.groupGoals = [];
        this.groupGoalSubscription = this.groupGoals$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                groupGoals => {
                    this.groupGoals = groupGoals;
                    if (this.helperMethods.isArrayNullOrEmpty(this.groupGoals)) {
                        this.userService.alertUser('No data found for Group ' + this.levelValue);
                    }
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'GetGroupGoals');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToIsGroupPrefSaved() {
        this.loadingSpinnerSvc.shouldShowLoading();
        this.isGroupPrefSubscription.unsubscribe();
        this.isGroupPrefSubscription = this.isGroupPrefSaved$
            .pipe(
                finalize(() => {
                })
            )
            .subscribe(
                () => {
                    if (!this.isAutoGenerateGoalsOn)
                    {
                        this.snackBarSvc.openSnackBar('Group Preferences for Group ' + this.levelValue + ' were saved successfully! Please allow a few minutes for goals to roll up to Team and Group.');
                    }
                    else 
                    {
                        this.snackBarSvc.openSnackBar('Group Preferences for Group ' + this.levelValue + ' were saved successfully!');
                    }

                    this.appInsights.logUserAction('User saved group preferences for group ' + this.levelValue, this.user);
                    this.getGroupPreferences();
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'SaveGroupPreferences');
                }
            );
    }

    subscribeToEmployeeGoalSave() {
        this.loadingSpinnerSvc.shouldShowLoading();
        this.employeeGoalSaveSubscription.unsubscribe();
        this.employeeGoalSaveSubscription = this.isEmployeeSaveSuccessful$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                () => {
                    this.snackBarSvc.openSnackBar('Employee Goals Saved Successfully!');
                    this.appInsights.logUserAction('User saved employee goals for group '
                     + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'SaveEmployeeGoals');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToTeamGoalSave() {
        this.loadingSpinnerSvc.shouldShowLoading();
        this.teamGoalSaveSubscription.unsubscribe();
        this.teamGoalSaveSubscription = this.isTeamSaveSuccessful$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                () => {
                    this.snackBarSvc.openSnackBar('Team Goals Saved Successfully!');
                    this.appInsights.logUserAction('User saved team goals for group '
                     + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'SaveTeamGoals');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToGroupGoalSave() {
        this.loadingSpinnerSvc.shouldShowLoading();
        this.groupGoalSaveSubscription.unsubscribe();
        this.groupGoalSaveSubscription = this.isGroupSaveSuccessful$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                () => {
                    this.snackBarSvc.openSnackBar('Group Goals Saved Successfully!');
                    this.appInsights.logUserAction('User saved group goals for group '
                     + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'SaveGroupGoals');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    hideFilterSectionFromNonCorpUser() {
        if (this.currentSelectedTab === 0 && !this.secObjService.hasCorpAccess()) {
            return {'display': 'none'};
        }
        return {};
    }
}
