import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'scoreboard-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  alertMessage: string;

  constructor(public dialogRef: MatDialogRef<AlertModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.alertMessage = this.data.alertMessage;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
