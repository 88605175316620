import { Injectable } from '@angular/core';
import { DashboardEmployeeGraph } from '../../models/incoming-dtos/dashboard-employee-graph';
import { DashboardGraphData } from '../dashboard-graph-data';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';

@Injectable()
export class DashboardEmployeeGraphService {

    constructor(public helperMethods: HelperMethodsService) {}

    plugGraphData(filteredDashboardGraphData: DashboardEmployeeGraph[]): DashboardGraphData[] {
        let graphData = new Array<DashboardGraphData>();
        filteredDashboardGraphData.forEach(result => {
            const actualValue = result.goalActual.actualValue != null ? result.goalActual.actualValue : 0;
            const pendingValue = result.goalActual.pendingValue > 0 ? result.goalActual.pendingValue : 0;
            const goalDisplayValue = this.checkGoalValue(result);
            const shouldShowPending = this.checkIfPendingIsNeeded(result);
            graphData.push({
                name: result.employee.firstName + ' ' + result.employee.lastName,
                jobTitle: result.employee.jobTitle,
                series: [
                    {
                    name: result.goalActual.onTrack ? 'Actual' : 'Actual*',
                    value: actualValue,
                    displayValue: actualValue.toString(),
                    shouldShow: true
                    },
                    {
                    name: result.goalActual.onTrack ? 'Pending' : 'Pending*',
                    value: pendingValue,
                    displayValue: pendingValue.toString(),
                    shouldShow: shouldShowPending
                    },
                    {
                    name: 'Goal',
                    value: result.goalActual.goalValue,
                    displayValue: goalDisplayValue,
                    shouldShow: true
                    }
                ],
                onTrack: result.goalActual.onTrack,
                rankPoint: result.goalActual.rankPoint,
                prefix: result.goalActual.prefix,
                suffix: result.goalActual.suffix
            });
        });
        graphData = this.checkForNegatives(graphData);
        return graphData;
    }

    checkForNegatives(graphData: DashboardGraphData[]): DashboardGraphData[] {
        let actualNegativeVal = this.getHighestNumber(graphData);
        const makeNegVal = actualNegativeVal * 2;
        actualNegativeVal = actualNegativeVal - makeNegVal;
        graphData.forEach(obj => {
            if (obj.series[2].value == null) {
                obj.series[2].value = 0;
            }

            if (obj.series[0].value < 0) { // negative
                obj.series[0].value = actualNegativeVal;
                if (obj.series[1].value < 0) { // negative
                    obj.series[1].value = 0;
                }
                if (obj.series[2].value < 0) { // negative
                    obj.series[2].value = 0;
                }
            }
            if (obj.series[2].value < 0) {
                obj.series[2].value = 0;
            }
            if (obj.series[0].value > obj.series[2].value) {
                obj.series[2].value = 0;
            }
            if ((obj.series[0].value < obj.series[2].value) && (obj.series[0].value > 0)) {
                obj.series[2].value = obj.series[2].value - obj.series[0].value - obj.series[1].value;
            }
        });
        return graphData;
    }

    getHighestNumber(graphData: DashboardGraphData[]): number {
        let highestActualNumber = 0;
        let actualNegativeVal = 0;
        graphData.forEach(obj => {
            if (obj.series[0].value > highestActualNumber) {
                highestActualNumber = obj.series[0].value;
                actualNegativeVal = highestActualNumber * .02;
            }
            if (obj.series[2].value > highestActualNumber) {
                highestActualNumber = obj.series[2].value;
                actualNegativeVal = highestActualNumber * .02;
            } else if (this.helperMethods.isNumberNullOrUndefined(actualNegativeVal)) {
                actualNegativeVal = -1;
            }
        });
        return actualNegativeVal;
    }

    checkGoalValue(result: DashboardEmployeeGraph): string {
        let value: string;
        if (result.goalActual.goalValue === null) {
            value = '-';
        } else {
            value = result.goalActual.goalValue.toString();
        }
        return value;
    }

    checkIfPendingIsNeeded(result: DashboardEmployeeGraph): boolean {
        return result.goalActual.goalName === 'Sales Volume';
    }

    sortByRank(filteredDashboardGraphData: DashboardEmployeeGraph[]): DashboardEmployeeGraph[] {
        return filteredDashboardGraphData.sort((a, b) => this.helperMethods.compareNumbers(b.goalActual.rankPoint, a.goalActual.rankPoint));
    }
}
