import { Injectable } from '@angular/core';
import { Team } from '../../models/incoming-dtos/team';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';

@Injectable()
export class TeamEmployeesService {

    constructor(private helperMethods: HelperMethodsService) {}

    findEmployeeIndex(teams: Team[], newEmployeeToBeSelected: number): number {
        let index: number;
        teams[0].employees.forEach(emp => {
            if (emp.id === newEmployeeToBeSelected) {
                index = teams[0].employees.indexOf(emp);
            }
        });
        return index;
    }

    findTeamIndex(teams: Team[], employeeMonthId: number): number {
        const index = teams.findIndex(t => t.employees.some(e => e.employeeMonthId === employeeMonthId));
        return index;
    }

    findEmployeeMonthId(teamIndex: number, employeeIndex: number, teams: Team[]): number {
        const team = teams[teamIndex];
        const employee = team.employees[employeeIndex];
        return employee.employeeMonthId;
    }

    findPayPlanId(teamIndex: number, employeeIndex: number, teams: Team[]): number {
        const team = teams[teamIndex];
        const employee = team.employees[employeeIndex];
        return employee.payPlanId;
    }
}
