import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { Group } from '../../models/incoming-dtos/group';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { TeamDetailsService } from '../team-details/team-details.service';
import { GroupTeamsService } from './group-teams.service';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';

@Component({
  selector: 'scoreboard-group-teams',
  templateUrl: './group-teams.component.html',
  styleUrls: ['./group-teams.component.scss']
})
export class GroupTeamsComponent implements OnChanges, OnInit {
    @Input() groups: Group[];
    @Input() level: string;
    @Input() month: number;
    @Input() year: number;
    @Input() newTeamToBeSelected: [number, number];

    selectedTeams = new Array<boolean>();
    selectedGroups = new Array<boolean>();
    currentGroupIndex: number;
    currentTeamIndex: number;
    isCreateTeam: boolean;
    allowCreateTeam = false;
    isCreateOrUpdate = false;

    @Output() selectedTeamChanged: EventEmitter<[number, string]>;

    constructor(public helperMethods: HelperMethodsService,
                private teamDetailsSvc: TeamDetailsService,
                private groupTeamsSvc: GroupTeamsService,
                public loadingSpinnerSvc: LoadingSpinnerService) {
                this.selectedTeamChanged = new EventEmitter<[number, string]>();
    }

    ngOnInit() {
        if ((!this.helperMethods.isArrayNullOrEmpty(this.groups)) && (this.groups[0].teams[0].id > 0)) {
            if (!this.isCreateOrUpdate) {
                this.setDefaultTeamAndEmit();
            }
        } else if (this.groups[0].teams[0].id === 0) {
            this.createNewTeam(); // Calling this when no teams exist in a group. Defaults to new team creation.
        }
    }

    ngOnChanges() {
        this.resetSelected();
        this.allowCreateTeam = this.groupTeamsSvc.checkLevelAndDate(this.level, this.month, this.year);
        if (this.newTeamToBeSelected !== null) {
            if (this.newTeamToBeSelected[1] === 0) { // ON DELETE
                this.setDefaultTeamAndEmit();
            }
            if (this.newTeamToBeSelected[1] === 1) { // ON CREATE
                this.selectTeamOnChanges();
                this.isCreateOrUpdate = true;
            }
            if (this.newTeamToBeSelected[1] === 2) { // ON UPDATE
                this.selectTeamOnChanges();
                this.isCreateOrUpdate = true;
            }
        }
    }

    checkSelectedTeam(groupIndex: number, teamIndex: number) {
        this.loadingSpinnerSvc.turnSpinnerOn('Loading...');
        this.isCreateTeam = false;
        this.selectedTeams = [];
        this.selectedGroups = [];
        this.currentTeamIndex = teamIndex;
        this.currentGroupIndex = groupIndex;
        this.selectedTeams[teamIndex] = true;
        this.selectedGroups[groupIndex] = true;
        const teamId = this.groupTeamsSvc.findTeamId(groupIndex, teamIndex, this.groups);
        const groupName = this.groupTeamsSvc.findGroupId(groupIndex, this.groups);
        this.selectedTeamChanged.emit([teamId, groupName]);
    }

    createNewTeam() {
        this.isCreateTeam = true;
        this.selectedTeams = [];
        this.selectedGroups = [];
        const teamId = 0;
        const groupName = this.groups[0].name;
        this.selectedTeamChanged.emit([teamId, groupName]);
    }

    setDefaultTeamAndEmit() {
        // setting first team in first group as selected team by default when loading into the page.
        let teamId: number;
        this.selectedGroups[0] = true;
        this.selectedTeams[0] = true;
        teamId = this.groups[0].teams[0].id;
        const groupName = this.groups[0].name;
        this.selectedTeamChanged.emit([teamId, groupName]);
    }

    selectTeamOnChanges() {
        let teamId: number;
        let teamIndex: number;
        let groupIndex: number;
        const newTeamId = this.newTeamToBeSelected[0];
        const saveCode = this.newTeamToBeSelected[1];
        if (saveCode === 1) {
            groupIndex = 0;
            teamIndex = this.groupTeamsSvc.findTeamIndex(this.groups, newTeamId);
        } else {
            groupIndex = this.groupTeamsSvc.findGroupIndex(this.groups, newTeamId);
            teamIndex = this.groups[groupIndex].teams.findIndex(t => t.id === newTeamId);
        }
        this.selectedGroups[groupIndex] = true;
        this.selectedTeams[teamIndex] = true;
        teamId = newTeamId;
        const groupName = this.groups[0].name;
        this.selectedTeamChanged.emit([teamId, groupName]);
    }

    resetSelected() {
        this.selectedTeams = new Array<boolean>();
        this.selectedGroups = new Array<boolean>();
    }
}
