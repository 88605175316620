import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { HelperMethodsService } from '../shared/services/helper-methods.service';

@Injectable()
export class SecuredObjectService {

    goalAssignmentSecObjectNames = [environment.securedObjects.gpPreferencesPageLink,
        environment.securedObjects.employeeGoals,
        environment.securedObjects.teamGoals,
        environment.securedObjects.groupGoals];

    constructor(private userService: UserService,
                private helperMethods: HelperMethodsService) {}

    hasCorpAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.corpUserSecObj);
        }
        return false;
    }

    // ************************Admin*******************************
    hasAdminPageAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.corporateAdminPageLink);
        }
        return false;
    }

    hasAdminSaveAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.btnSaveAdmin);
        }
        return false;
    }

    // *************************Dashboard*****************************
    hasDashboardPageAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.dashboardPageLink);
        }
        return false;
    }

    hasDashboardEmployeeFilterAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.ddDashboardUserList);
        }
        return false;
    }

    // ********************************Paid Time Off*******************************
    hasPaidTimeOffPageAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.paidTimeOffPageLink);
        }
        return false;
    }

    hasPaidTimeOffExportAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.btnExportTimeOff);
        }
        return false;
      }

    hasPaidTimeOffSaveAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.btnSaveTimeOff);
        }
        return false;
    }

    // ****************************Pay Memo****************************
    hasPayMemoPageAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.payPlanMemoPageLink);
        }
        return false;
    }

    hasPayMemoExportAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.btnExportPayPlanMemo);
        }
        return false;
    }

    hasPayMemoLockAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.btnGrpMemoSaveFinal);
        }
        return false;
    }

    hasPayMemoSaveAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.btnSavePayPlanMemo);
        }
        return false;
    }

    // ****************************Pay Setup****************************
    hasPaySetupPageAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.payPlanSetupPageLink);
        }
        return false;
    }

    hasPaySetupExportAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.btnExportPayPlan);
        }
        return false;
    }

    hasPaySetupSaveAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.btnSavePayPlan);
        }
        return false;
    }

    // **********************Team Assignment************************************
    hasTeamAssignmentPageAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.TeamSetupPageLink);
        }
        return false;
    }

    // *************************Goal Assignment*************************
    hasGoalAssignmentPageAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.isAuthenticated &&
               this.userService.user.allSecuredObjects.some(so =>
                this.goalAssignmentSecObjectNames.some(goalAssgnSoName =>
                    so.secObjectName === goalAssgnSoName));
        }
        return false;
    }

    hasGroupPreferencesPageAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.gpPreferencesPageLink);
        }
        return false;
    }

    hasEmployeeGoalsPageAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.employeeGoals);
        }
        return false;
    }

    hasTeamGoalsPageAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.teamGoals);
        }
        return false;
    }

    hasGroupGoalsPageAccess(): boolean {
        if (this.anySecuredObjects()) {
            return this.userService.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.groupGoals);
        }
        return false;
    }

    private anySecuredObjects(): boolean {
        if (this.userService.user) {
            return !this.helperMethods.isArrayNullOrEmpty(this.userService.user.allSecuredObjects);
        }
        return false;
    }
}
