import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BuyerTier } from '../../../models/incoming-dtos/buyer-tier';
import { HelperMethodsService } from '../../../shared/services/helper-methods.service';

@Component({
    selector: 'scoreboard-team-buyer-tier',
    templateUrl: './team-buyer-tier.component.html',
    styleUrls: ['./team-buyer-tier.component.scss']
})
export class TeamBuyerTierComponent implements OnInit {
    @Input() buyerTierDataTeam: Array<BuyerTier>;
    @Input() payPlanId: number;
    @Input() buyerAverageTarget: number;
    @Input() currency: string;
    @Input() buyerAverageTimePeriodTeam: string;
    newBuyerTier: BuyerTier = { id: 0, averageBuyers: 0, payout: 0, payPlanId: 0, recordStatus: false };
    alert: string;
    alertText: string;
    buyerAveragePayTimePeriodFYTDTeam: boolean;
    buyerAveragePayTimePeriodRolling12Team: boolean;

    @Output() updatedBuyerTierTeam = new EventEmitter<Array<BuyerTier>>();
    @Output() isDirty = new EventEmitter();
    @Output() buyerAveragePayTimePeriodTeam = new EventEmitter();

    constructor(public helperMethods: HelperMethodsService) {

    }
    ngOnInit() {
        const TwelveMthRoll = '12MthRoll';
        const FYTD = 'FYTD';

        if (this.buyerAverageTimePeriodTeam == null) {
            this.buyerAveragePayTimePeriodRolling12Team = true;
            this.buyerAveragePayTimePeriodFYTDTeam = false;
            this.isDirty.emit(true);
            this.buyerAveragePayTimePeriodTeam.emit(TwelveMthRoll);
        } else if (this.buyerAverageTimePeriodTeam === TwelveMthRoll) {
            this.buyerAveragePayTimePeriodRolling12Team = true;
            this.buyerAveragePayTimePeriodFYTDTeam = false;
        } else if (this.buyerAverageTimePeriodTeam === FYTD) {
            this.buyerAveragePayTimePeriodRolling12Team = false;
            this.buyerAveragePayTimePeriodFYTDTeam = true;
        }
    }

    public addBuyerTier() {
        const avgBuyerExists = this.buyerTierDataTeam.filter(x => x.averageBuyers === this.newBuyerTier.averageBuyers);

        if (this.helperMethods.isNumberNullOrUndefined(this.newBuyerTier.averageBuyers) || this.helperMethods.isNumberNullOrUndefined(this.newBuyerTier.payout)) {
            this.setAlert('Please enter a number');
        } else {
            if (avgBuyerExists.length === 0) {
                this.buyerTierDataTeam.push({ averageBuyers: this.newBuyerTier.averageBuyers, payout: this.newBuyerTier.payout, payPlanId: this.payPlanId, id: 0, recordStatus: true });
                this.buyerTierDataTeam.sort((n1, n2) => n1.averageBuyers - n2.averageBuyers);
                this.newBuyerTier = { id: 0, averageBuyers: 0, payout: 0, payPlanId: 0, recordStatus: false };
                this.resetAlert();
                this.updatedBuyerTierTeam.emit(this.buyerTierDataTeam);
            } else {
                this.setAlert('Buyer average already exists!');
            }

        }

        this.isDirty.emit(true);
    }


    RadioButtonChange(event) {
        const elementID = event.srcElement.id;
        const TwelveMthRoll = '12MthRoll';
        const FYTD = 'FYTD';
        const buyerAveragePayTimePeriodFYTD = 'buyerAveragePayTimePeriodFYTDTeam';
        const buyerAveragePayTimePeriodRolling12 = 'buyerAveragePayTimePeriodRolling12Team';

        if (elementID === buyerAveragePayTimePeriodFYTD) {
            this.buyerAveragePayTimePeriodFYTDTeam = true;
            this.buyerAveragePayTimePeriodRolling12Team = false;
            this.buyerAveragePayTimePeriodTeam.emit(FYTD);
        } else if (elementID === buyerAveragePayTimePeriodRolling12) {
            this.buyerAveragePayTimePeriodFYTDTeam = false;
            this.buyerAveragePayTimePeriodRolling12Team = true;
            this.buyerAveragePayTimePeriodTeam.emit(TwelveMthRoll);
        }
    }
    resetAlert() {
        this.alert = '';
        this.alertText = '';
    }
    setAlert(alertxt: string) {
        this.alert = 'alert alert-danger';
        this.alertText = alertxt;
    }
    removeTier(averageBuyers) {
        const result = this.buyerTierDataTeam.filter(x => x.averageBuyers !== averageBuyers);
        this.buyerTierDataTeam = result;
        this.updatedBuyerTierTeam.emit(this.buyerTierDataTeam);
        this.isDirty.emit(true);
    }

    getTargetBuyerTierStyle(avgBuyer: BuyerTier) {
        const targetArray = this.buyerTierDataTeam.filter(x => x.averageBuyers <= this.buyerAverageTarget);
        const max = Math.max.apply(Math, targetArray.map(function (o) { return o.averageBuyers; }));

        if (avgBuyer.averageBuyers === max) {
            return 'my-1 text-center text-success';
        } else {
            return 'my-0 text-center';
        }
    }
}
