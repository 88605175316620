import { GoalActual } from './goal-actual';
import { Team } from './team';

export class DashboardTeamOverview {
    dailySalesPace: number;
    salesPaceGoal: string;
    salesPaceOnTrack: boolean;
    rank: number;
    maxRank: number;
    goalActuals: GoalActual[];
    team: Team;
}
