import { Component, Input, Output, EventEmitter, ViewChildren, ElementRef, OnChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, FormControlName } from '@angular/forms';
import { PayPlan } from '../../models/incoming-dtos/payplan';
import { VacationDaysType } from '../../models/incoming-dtos/vacation-days-type';
import { SpcType } from '../../models/enums/spc-type';
import { DatePipe } from '@angular/common';
import { GenericValidator } from '../../shared/validators/generic-validator';
import { Observable, Subscription, fromEvent, merge } from 'rxjs';
import { PayplanFormService } from './payplan-form.service';
import { PayPlanSaveDto } from '../../models/outgoing-dtos/payplan-save-dto';
import { User } from '../../security/user';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { PayplanFormExportService } from './payplan-form-export.service';
import { SpcTier } from '../../models/incoming-dtos/spc-tier';
import { SubscriptionService } from '../../shared/services/subscription.service';
import { SecuredObjectService } from '../../security/secured-object.service';
import { AppInsightsService } from '../../shared/services/app-insights.service';
import { finalize, debounceTime } from 'rxjs/operators';
import { SpcBaseComponent } from '../../../app/shared/base/spc-base.component';

@Component({
  selector: 'scoreboard-payplan-form',
  templateUrl: './payplan-form.component.html',
  styleUrls: ['./payplan-form.component.scss']
})
export class PayplanFormComponent extends SpcBaseComponent implements OnChanges {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements: ElementRef[];
  @Input() payPlan: PayPlan;
  @Input() user: User;
  @Input() level: string;
  @Input() levelValue: string;
  @Input() month: number;
  @Input() year: number;
  @Output() onSavePayPlan: EventEmitter<PayPlanSaveDto>;

  payPlanForm: UntypedFormGroup;
  vacationDaysTypes: VacationDaysType[];
  grossSpcTargetValues: string[];
  netSpcTargetValues: string[];
  pipe = new DatePipe('en-US');
  selectedAdditionalTarget = '1';
  payPlanGrossSpcTiers$ = new Observable<SpcTier[]>();
  payPlanNetSpcTiers$ = new Observable<SpcTier[]>();
  payPlanDto = new PayPlanSaveDto();
  isPayPlanLocked = false;
  saveButtonText = 'Save';

  // Use with the generic validation message class
  displayMessage: { [key: string]: string } = {};
  validationMessages: { [key: string]: { [key: string]: string } };
  genericValidator: GenericValidator;
  payPlanGrossSpcTiers = new Array<SpcTier>();
  payPlanNetSpcTiers = new Array<SpcTier>();
  payPlanGrossSpcTiersSubscription = new Subscription();
  payPlanNetSpcTiersSubscription = new Subscription();

  constructor(private payplanFormSvc: PayplanFormService,
              private payPlanFormExportSvc: PayplanFormExportService,
              private subscribeService: SubscriptionService,
              private loadingSpinnerSvc: LoadingSpinnerService,
              public secObjService: SecuredObjectService,
              public appInsights: AppInsightsService) {
                super(appInsights);
              this.onSavePayPlan = new EventEmitter<PayPlanSaveDto>();
    this.initializeValidationMessages();
  }


  ngOnChanges() {
    this.setupPayPlanForm();
    this.checkIfPayPlanLocked();
  }

  checkIfPayPlanLocked() {
    if (this.payPlan.locked) {
      this.isPayPlanLocked = true;
      this.saveButtonText = 'Locked';
    } else {
      this.isPayPlanLocked = false;
      this.saveButtonText = 'Save';
    }
  }

  initializeValidationMessages() {
    // Defines all of the validation messages for the form.
    this.validationMessages = this.payplanFormSvc.getPayPlanValidationMessages();

    // Define an instance of the validator for use with this form,
    // passing in this form's set of validation messages.
    this.genericValidator = new GenericValidator(this.validationMessages);
  }

  setupPayPlanForm() {
    this.setPayPlanDefaults();
    this.payPlanForm = this.payplanFormSvc.getPayPlanFormDefinition(this.payPlan.employee.groupName);
    this.setDropDownValues();
    this.payplanFormSvc.mapPayPlanForm(this.payPlan, this.payPlanForm);
    this.recalcFormValues();
  }

  setDropDownValues() {
    this.vacationDaysTypes = this.payPlan.availableVacationDayTypes;
    if (this.isSpcEnabled) {
      this.grossSpcTargetValues = this.payPlan.availableSpcTargets;
      this.netSpcTargetValues = this.payPlan.availableSpcTargets;
    }
    else {
      this.grossSpcTargetValues = ['0']; 
      this.netSpcTargetValues = ['0'];    
    }
  }

  setPayPlanDefaults() {
    
    if (this.isSpcEnabled) {
      this.payPlan.targetSpcType = SpcType.country;
    }

    if (this.payPlan.vacationDaysTypeId === 0) {
      this.payPlan.vacationDaysTypeId = this.payPlan.availableVacationDayTypes[0].id;
    }

    if (this.payPlan.employee != null && this.payPlan.employee != undefined) {
      if (this.payPlan.employee.salesManager == null || this.payPlan.employee.salesManager == undefined) {
        this.payPlan.employee.salesManager = 'Unassigned';
      }
    }


    if (Number(this.payPlan.purchaseVolumePayPerUnit) > 0) {
      this.selectedAdditionalTarget = '4';
    }
    else if (this.isPayAgedPercentEnabled && this.payPlan.agedPercentagePayout > 0) {
      this.selectedAdditionalTarget = '2';
    }
    else if (this.isPayLocalBoostEnabled && Number(this.payPlan.localBoostPercentPayout) > 0) {
      this.selectedAdditionalTarget = '3';
    }
    else if (Number(this.payPlan.dealerSoldToAllPayout) > 0) {
      this.selectedAdditionalTarget = '5';
    }
    else {
      this.selectedAdditionalTarget = '1';
    }    
    
  }

  recalcFormValues() {
    this.payplanFormSvc.recalcPayPlanFormValues(this.payPlanForm);
    this.validateAllFields();
    this.gatherValidationMessages();
  }

  clearAdditionalTargets() {
    this.payPlanForm.patchValue({
      quadGrowthTarget: '0',
      quadGrowthPayout: '0',
      dealersSoldToGrowthTarget: '0',
      dealersSoldToGrowthPayout: '0',
      daysToDispositionTarget: '0',
      daysToDispositionPayout: '0',
      agedPercentageTarget: '0',
      agedPercentagePayout: '0',
      localBoostPercentTarget: '0',
      localBoostPercentPayout: '0',
      purchaseVolumeTarget: '0',
      purchaseVolumePayPerUnit : '0',
      dealerSoldToAllTarget : '0',
      dealerSoldToAllPayout : '0'     
      
    });

    this.recalcFormValues();
  }

  validateAllFields() {
    Object.keys(this.payPlanForm.controls).forEach(field => {
        const control = this.payPlanForm.get(field);
        if (control instanceof UntypedFormControl) {
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof UntypedFormGroup) {
            this.validateAllFields();
        }
    });
  }

  gatherValidationMessages() {
    if (this.formInputElements != null) {
      // Watch for the blur event from any input element on the form.
      const controlBlurs: Observable<any>[] = this.formInputElements
          .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

      // Merge the blur event observable with the valueChanges observable
      merge(this.payPlanForm.valueChanges, ...controlBlurs).pipe(
        debounceTime(0)).subscribe(value => {
          this.displayMessage = this.genericValidator.processMessages(this.payPlanForm);
      });
    }
  }

  onSave() {
    this.loadingSpinnerSvc.turnSpinnerOn('Saving...');
    this.payPlanDto = this.payplanFormSvc.buildSaveDto(this.payPlanForm, this.user, this.payPlan);
    this.payPlanForm.reset(this.payPlanForm.value);
    this.onSavePayPlan.emit(this.payPlanDto);
  }

  onExport() {
  
      this.loadingSpinnerSvc.turnSpinnerOn('Loading...');
      this.payPlanGrossSpcTiers$ = this.payPlanFormExportSvc.getSpcTiers(this.payPlanForm.value.grossSpcTarget, this.payPlan.employee.jobCodeId, this.payPlan.grossSpcPayout);
      this.subscribeToPayPlanSpcTiers();
    
  }

  subscribeToPayPlanSpcTiers() {
    this.payPlanGrossSpcTiersSubscription.unsubscribe();

 
      this.payPlanGrossSpcTiersSubscription = this.payPlanGrossSpcTiers$
        .subscribe(
            payPlanSpcTiers => {
                this.payPlanGrossSpcTiers = payPlanSpcTiers;
                this.payPlanNetSpcTiers$ = this.payPlanFormExportSvc.getSpcTiers(this.payPlanForm.value.netSpcTarget, this.payPlan.employee.jobCodeId, this.payPlan.netSpcPayout);
                this.subscribeToPayPlanNetSpcTiers();
            },
            err => {
                this.subscribeService.onError(err);
                this.loadingSpinnerSvc.turnSpinnerOff();
            }
        );
    
  }

  subscribeToPayPlanNetSpcTiers() {
    this.payPlanNetSpcTiersSubscription.unsubscribe();

   
      this.payPlanNetSpcTiersSubscription = this.payPlanNetSpcTiers$
        .pipe(
          finalize(() => {
            this.loadingSpinnerSvc.turnSpinnerOff();
          })
        )
        .subscribe(
            payPlanSpcTiers => {
                this.payPlanNetSpcTiers = payPlanSpcTiers;
                this.payPlanFormExportSvc.exportPayPlan(this.payPlan, this.payPlanForm, this.selectedAdditionalTarget, this.payPlanGrossSpcTiers, this.payPlanNetSpcTiers, this.isSpcEnabled, this.isNetSpcEnabled);
                this.appInsights.logUserAction('User exported pay plan for ' + this.payPlan.employee.firstName + ' ' + this.payPlan.employee.lastName +
                                               ' for ' + this.level + ': ' + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
            },
            err => {
                this.subscribeService.onError(err);
                this.loadingSpinnerSvc.turnSpinnerOff();
            }
        );
    
  }
}
