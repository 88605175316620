import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

// This pipe takes in a value and adds commas to it. Useful for better readability of large numbers.
// For example, passing in 255500 returns 255,500
// Using the currency pipe makes this quite simple since it already has a built-in regex for adding the commas.
@Pipe({ name: 'addCommas' })
export class AddCommasPipe implements PipeTransform {
    constructor() {}

    transform(value: any): string {
        if (value !== '-' && value != null) {
            return CurrencyPipe.prototype.transform(value, 'USD', 'symbol', '1.0-2', 'en-US').replace('$', '');
        } else {
            return value;
        }
    }
}
