import { Injectable } from '@angular/core';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { GroupGoalSave } from './group-goal-save';
import { Group } from '../../models/incoming-dtos/group';
import { GroupGoal } from '../../models/incoming-dtos/group-goal';

@Injectable()
export class GroupGoalService {

    constructor(public helperMethods: HelperMethodsService) {}

    checkIds(groupsForSave: GroupGoalSave[], groupId: number, goalId: number): GroupGoalSave[] {
        let updatedGroupsForSave = new Array<GroupGoalSave>();
        const groupIds = [];
        groupsForSave.forEach(group => {
            groupIds.push(group.groupId);
        });


        if (groupIds.includes(groupId)) {
            const index = groupIds.indexOf(groupId);
            if (groupsForSave[index].groupGoalIds.includes(goalId)) {
                updatedGroupsForSave = groupsForSave;
                // Return unchagned list if user changes the same value more than once without clicking save.
            } else {
                groupsForSave[index].groupGoalIds.push(goalId);
                updatedGroupsForSave = groupsForSave;
                // Adds a goal record to an group object if this is the first time the user changed the default value.
            }
        } else {
            const newIds = new GroupGoalSave();
            // Adds an group that hasn't been recorded yet if the user changes a goal value for the first time in the page.
            newIds.groupId = groupId;
            newIds.groupGoalIds = [goalId];
            groupsForSave.push(newIds);
            updatedGroupsForSave = groupsForSave;
        }

        return updatedGroupsForSave;
    }

    getGoals(groupGoals: Group[], groupsForSave: GroupGoalSave[]): GroupGoal[] {
        const goalsForSave = new Array<GroupGoal>();
        const groupIds = [];
        groupsForSave.forEach(group => {
            groupIds.push(group.groupId);
        });

        groupGoals.forEach(group => {
            if (groupIds.includes(group.id)) {
                let goalIds = [];
                const index = groupIds.indexOf(group.id);
                goalIds = groupsForSave[index].groupGoalIds;
                group.groupGoals.forEach(goal => {
                    if (goalIds.includes(goal.goalId)) {
                        goalsForSave.push(goal);
                    }
                });
            }
        });

        return goalsForSave;
    }
}
