import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { GroupPreference } from '../../models/incoming-dtos/group-preference';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { GroupPreferenceUpdateDto } from '../../models/outgoing-dtos/group-preference-update-dto';
import { GroupPreferencesService } from './group-preferences.service';
import { User } from '../../security/user';
import { UserService } from '../../security/user.service';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { GroupFilterService } from '../../shared/group-filter/group-filter.service';
import { Observable, Subscription } from 'rxjs';
import { Group } from '../../models/incoming-dtos/group';
import { finalize } from 'rxjs/operators';
import { SubscriptionService } from '../../shared/services/subscription.service';

@Component({
    selector: 'scoreboard-group-preferences',
    templateUrl: './group-preferences.component.html',
    styleUrls: ['./group-preferences.component.scss']
})
export class GroupPreferencesComponent implements OnChanges {
    @Input() levelValue: string;
    @Input() groupPreferences: GroupPreference[];
    @Input() user: User;
    @Output() autoGoalsFlagChanged: EventEmitter<boolean>;
    @Output() getAutoGenerateGoalStatus: EventEmitter<boolean>;

    sequences = new Array<number>();
    coreSequences = new Array<number>();
    isCoreGoals = new Array<boolean>();
    groupPrefTuples = new Array<[GroupPreference, boolean]>();
    saveGroupPrefDtos = new Array<GroupPreferenceUpdateDto>();
    isValidSave = false;
    shouldShowErrorMessage = new Array<boolean>();

    @Output() groupPrefSaveChanged = new EventEmitter<GroupPreferenceUpdateDto[]>();
    isSaveDisabled: boolean;
    isAutoGenerateGoalsChecked: boolean;
    group$: Observable<Group>;
    groupSubscription = new Subscription();

    constructor(public helperMethods: HelperMethodsService,
        public groupPreferencesService: GroupPreferencesService,
        public userService: UserService,
        public loadingSpinnerSvc: LoadingSpinnerService,
        public groupFilterService: GroupFilterService,
        public subscriptionService: SubscriptionService) {
        this.autoGoalsFlagChanged = new EventEmitter<boolean>();
        this.getAutoGenerateGoalStatus = new EventEmitter<boolean>();
    }

    subscribeToGetAutoGenerateGoalStatus() {
        this.groupSubscription.unsubscribe();

        this.groupSubscription = this.group$
            .pipe(
                finalize(() => {
                    this.getAutoGenerateGoalStatus.emit(this.isAutoGenerateGoalsChecked);
                })
            )
            .subscribe(
                group => {
                    this.isAutoGenerateGoalsChecked = group.autoGenerateGoals;
                },
                err => {
                    this.subscriptionService.onError(err, 'OnInit');
                }
            );
    }

    ngOnChanges() {
        this.isSaveDisabled = true;
        this.groupPrefTuples = this.groupPreferencesService.buildGroupPrefTuples(this.groupPreferences);
        this.buildSequenceList();
        this.group$ = this.groupFilterService.getGroup(this.groupPreferences[0].ehiGroupId);
        this.subscribeToGetAutoGenerateGoalStatus();
    }

    enableSave() {
        this.isSaveDisabled = false;
        this.isValidSave = true;
    }

    clearSequenceValue(pref: [GroupPreference, boolean], isSelected: boolean, index: number) {
        if (!pref[0].isCorpMetric) {
            if (isSelected) {
                pref[0].sequence = null;
            }
            this.validateSequences(pref, index);
        }
    }

    buildSequenceList() {
        let value = 0;
        this.coreSequences.length = 0;
        this.sequences.length = 0;
        this.groupPreferences.forEach(pref => {
            value = value + 1;
            if (pref.isCorpMetric) {
                this.coreSequences.push(value);
            } else {
                this.sequences.push(value);
            }
        });
    }

    onSave() {
        if (this.isValidSave) {
            this.isValidSave = this.groupPreferencesService.checkSequenceValue(this.groupPrefTuples);
            if (this.isValidSave) {
                this.shouldShowErrorMessage = new Array<boolean>();
                this.loadingSpinnerSvc.turnSpinnerOn('Saving...');
                this.saveGroupPrefDtos = [];
                this.saveGroupPrefDtos = this.groupPreferencesService.buildSaveData(this.groupPrefTuples, this.user, this.groupPreferences);
                this.groupPrefSaveChanged.emit(this.saveGroupPrefDtos);
                this.autoGoalsFlagChanged.emit(this.isAutoGenerateGoalsChecked);

            }
        } else {
            this.userService.alertUser('Error with goal orders. Please make a selection or correct issues before saving.');
        }
    }

    validateSequences(pref: [GroupPreference, boolean], index: number) {
        this.isSaveDisabled = false;
        this.isValidSave = this.groupPreferencesService.checkSequenceDuplicates(this.groupPrefTuples, pref[0]);
        if (!this.isValidSave && pref[0].sequence !== null) {
            this.shouldShowErrorMessage[index] = true;
            this.userService.alertUser('Goal Order ' + pref[0].sequence.toString() + ' is already taken. Please select a different value.');
        } else {
            this.shouldShowErrorMessage[index] = false;
        }
    }
}
