import { Component, OnInit, Input, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PayMemo } from '../../models/incoming-dtos/pay-memo';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { PayMemoSaveDto } from '../../models/outgoing-dtos/pay-memo-save-dto';
import { User } from '../../security/user';
import { PayMemoExportService } from './pay-memo-export.service';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { PayMemoTableService } from './pay-memo-table.service';
import { Observable, Subscription } from 'rxjs';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { SubscriptionService } from '../../shared/services/subscription.service';
import { DecisionModalService } from '../../shared/decision-modal/decision-modal.service';
import { PayplanSetupService } from '../../payplan-setup/payplan-setup.service';
import { SecuredObjectService } from '../../security/secured-object.service';
import { AppInsightsService } from '../../shared/services/app-insights.service';
import { finalize } from 'rxjs/operators';
import { StyleHelperService } from '../../shared/services/style-helper.service';
import { SpcBaseComponent } from '../../../app/shared/base/spc-base.component';

@Component({
    selector: 'scoreboard-pay-memo-table',
    templateUrl: './pay-memo-table.component.html',
    styleUrls: ['./pay-memo-table.component.scss']
})

export class PayMemoTableComponent extends SpcBaseComponent implements OnInit, AfterViewInit {
    @Input() payMemoData: PayMemo[];
    @Input() level: string;
    @Input() levelValue: string;
    @Input() month: number;
    @Input() year: number;
    @Input() user: User;
    @Input() isEurope: boolean;
    @Output() payMemoDataChanged: EventEmitter<PayMemoSaveDto[]>;

    payMemoDataForDisplay: PayMemo[];
    dataSource = new MatTableDataSource();
    displayedStickyColumns = ['id', 'name', 'jobTitle'];

    
    displayedColumns = ['team', 'ptoDaysUsed', 'ptoRate', 'ptoPay', 'actualSalesVolume', 'payoutPerCar', 'salesVolumePayout',
        'grossSpcActual', 'grossSPCNationalActual', 'adjustedGrossSPCActual','grossSpcTarget', 'grossSpcPayout', 
        /// 'netSpcActual', 'netSpcTarget', 'netSpcPayout', 
        'daysToDispositionActual', 'daysToDispositionTarget', 'daysToDispositionPayout',  
             
        // 'agedPercentageActual', 'agedPercentageTarget', 'agedPercentagePayout',
        // 'localBoostPercentActual',
        // 'localBoostPercentTarget', 'localBoostPercentPayout',
        'purchaseVolumeActual', 'purchaseVolumeActualTeam', 'purchaseVolumePayPerUnit', 'purchaseVolumePayPayout', 
        
        'dealerSoldToAllActual','dealerSoldToAllTarget', 'dealerSoldToAllPayout',
        
        'projectedPay', 'adjustments', 'adjustedPay', 'comments'];
        

    displayedColumnsEU = ['team', 'actualSalesVolume', 'actualSalesVolumeTeam', 'payoutPerCar', 'reversalAdjustment', 'milestoneBonus',
        'milestoneBonusTeam', 'salesVolumePayout', 'buyerAverage', 'buyerAverageTeam', 'averageBuyerGoalBonus', 'averageBuyerGoalBonusTeam',
        'projectedPay', 'adjustments', 'adjustedPay', 'comments'];

    displayedColumnsNoSPC = ['team', 'ptoDaysUsed', 'ptoRate', 'ptoPay', 'actualSalesVolume', 'payoutPerCar', 'salesVolumePayout',
        'daysToDispositionActual', 'daysToDispositionTarget', 'daysToDispositionPayout', 'agedPercentageActual', 'agedPercentageTarget', 'agedPercentagePayout',
        'purchaseVolumeActual', 'purchaseVolumeActualTeam', 'purchaseVolumePayPerUnit', 'purchaseVolumePayPayout', 'localBoostPercentActual',
        'localBoostPercentTarget', 'localBoostPercentPayout', 'projectedPay', 'adjustments', 'adjustedPay', 'comments'];

    lockToggle = false;
    toggleTitle = 'Lock';
    payMemoIds = new Array<number>();
    payMemoSaveDto = new Array<PayMemoSaveDto>();
    isPayPlanLocked$ = new Observable<boolean>();
    payMemoLockSubscription = new Subscription();
    leftTable: any;

    exportOptions = [
        { value: 1, viewValue: 'Pay Memo' },
        { value: 2, viewValue: 'Pay Memo (All Columns)' },
        { value: 3, viewValue: 'PeopleSoft' },
        { value: 4, viewValue: 'ProBusiness' }
    ];

    constructor(private loadingSpinnerSvc: LoadingSpinnerService,
        public payMemoExportSvc: PayMemoExportService,
        private customCurrencyPipe: CustomCurrencyPipe,
        private payMemoTableService: PayMemoTableService,
        public payPlanSrv: PayplanSetupService,
        public snackBarSvc: SnackBarService,
        private subscribeService: SubscriptionService,
        public decisionSvc: DecisionModalService,
        public secObjService: SecuredObjectService,
        private element: ElementRef,
        public appInsights: AppInsightsService,
        public styleHelper: StyleHelperService) {
            super(appInsights);
            this.payMemoDataChanged = new EventEmitter<PayMemoSaveDto[]>();
            styleHelper.maxWidth = 680;

           
    }

    ngAfterViewInit() {
        this.leftTable = this.element.nativeElement.querySelector('.catching-scrolling');
    }

    ngOnInit() {
        this.payMemoDataForDisplay = this.payMemoTableService.buildPayMemoCopies(this.payMemoData);
        this.payMemoDataForDisplay.forEach(memo => memo.adjustments = this.customCurrencyPipe.transform(memo.adjustments, '1.2-2'));
        this.getTableData();
        
        if (this.payMemoData.every(x => x.locked === true)) {
            this.lockToggle = true;
            this.toggleTitle = 'Locked';
        }
    }

    getTableData() {
        this.dataSource.data = this.payMemoDataForDisplay;
    }

    onPayMemoChange(payMemoId: number) {
        if (!this.payMemoIds.includes(payMemoId)) {
            this.payMemoIds.push(payMemoId);
        }
    }

    onExport(exportValue: number) {
        const today = new Date();
        if (this.payMemoData.some(xx => !xx.locked && this.compareDates(xx, today))) {
            this.askBeforeExport(exportValue);
        } else {
            this.export(exportValue);
        }
    }

    private compareDates(xx: PayMemo, today: Date): boolean {
        return new Date(xx.updatedTime).toDateString() !== today.toDateString();
    }

    private askBeforeExport(exportValue: number) {
        const ask$ = this.decisionSvc.askUser(`Some Pay Memos for the selected month have not been finalized. Are you sure you wish to proceed?`, '400px');
        ask$.pipe(finalize(() => {
        })).subscribe(result => {
            if (result) {
                this.export(exportValue);
            }
        });
    }

    private export(exportValue: number) {
        switch (exportValue) {
            case 1:
                this.payMemoExportSvc.buildPayMemoExport(this.payMemoData, this.levelValue, this.month, this.year);
                this.appInsights.logUserAction('User exported Pay Memo on group ' + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
                break;
            case 2:
                this.payMemoExportSvc.buildPayMemoExportWithAllColumns(this.payMemoData, this.levelValue, this.month, this.year, this.isEurope, this.payMemoData[0].employee.country);
                this.appInsights.logUserAction('User exported Pay Memo (All Columns) on group ' + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
                break;
            case 3:
                this.payMemoExportSvc.buildPayMemoPeoplesoftExport(this.payMemoData);
                this.appInsights.logUserAction('User exported PeopleSoft(Pay Memo) on group ' + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
                break;
            case 4:
                this.payMemoExportSvc.buildPayMemoProBusinessExport(this.payMemoData);
                this.appInsights.logUserAction('User exported ProBusiness(Pay Memo) on group ' + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
                break;
        }
    }

    isToggled() {
        this.lockToggle = true;
        this.isPayPlanLocked$ = this.payPlanSrv.lockPayPlan(this.month, this.year, this.user.eId, [this.payMemoData[0].ehiGroupId], this.isEurope);
        this.subcribeToPayMemoDataForLock();
        this.toggleTitle = 'Locked';
    }

    onPayMemoSave() {
        this.loadingSpinnerSvc.turnSpinnerOn('Saving...');
        const payMemoTableData = new Array<PayMemo>();
        this.dataSource.data.forEach(row => {
            payMemoTableData.push(row as PayMemo);
        });
        payMemoTableData.forEach(row => {
            if (this.payMemoIds.includes(row.id)) {
                const rowToBeSaved = new PayMemoSaveDto();
                rowToBeSaved.id = row.id;
                rowToBeSaved.adjustments = typeof row.adjustments === 'string' ? row.adjustments.replace(/,/g, '') : '0';
                rowToBeSaved.comments = row.comments;
                rowToBeSaved.updatedBy = this.user.eId;
                this.payMemoSaveDto.push(rowToBeSaved);
            }
        });
        this.payMemoDataChanged.emit(this.payMemoSaveDto);
    }

    pipeAdjustments(payMemoId: number, adj: string) {
        adj = this.customCurrencyPipe.transform(adj, '1.2-2');
        this.payMemoDataForDisplay.find(data => data.id === payMemoId).adjustments = adj;
    }

    confirmLock() {
        const ask$ = this.decisionSvc.askUser('Are you sure?', 'auto');

        ask$.pipe(finalize(() => {
        })).subscribe(result => {
            if (result) {
                this.loadingSpinnerSvc.turnSpinnerOn('Locking...');
                this.isToggled();
            }
        });
    }

    subcribeToPayMemoDataForLock(): any {
        this.payMemoLockSubscription.unsubscribe();
        this.payMemoLockSubscription = this.isPayPlanLocked$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                    this.snackBarSvc.openSnackBar('Lock was successful.');
                    this.appInsights.logUserAction('User locked pay memo on group ' + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
                }
                )
            )
            .subscribe(lockSuccessful => { },
                err => {
                    this.subscribeService.onError(err, this.level, 'updatePayMemoData');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    syncScroll(rightTable: any) {
        this.leftTable.scrollTop = rightTable.scrollTop;
    }
}
