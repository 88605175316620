import { Injectable } from '@angular/core';
import { ErrorHandlingService } from '../services/error-handling.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../security/user.service';
import { groupLevels } from '../static-data/group-levels';
import { catchError } from 'rxjs/operators';
import { UpdateGroupDto } from '../../models/outgoing-dtos/update-group-dto';
import { Group } from '../../models/incoming-dtos/group';

@Injectable()
export class GroupFilterService {
    private baseUrl: string = environment.dotNetCoreUrl + 'coreapi/';
    private countryUrl: string = this.baseUrl + 'Group/Countries';
    private svpTeamUrl: string = this.baseUrl + 'Group/SvpTeams';
    private groupUrl: string = this.baseUrl + 'Group/Groups';
    private europeLocationsUrl: string = this.baseUrl + 'Group/EuropeLocations';
    private saveGroupUrl: string = this.baseUrl + 'Group/UpdateGroup';
    private getGroupUrl: string = this.baseUrl + 'Group/';

    filterValues$ = new Observable<string[]>();

    constructor(private httpClient: HttpClient,
        private userService: UserService,
        private errorService: ErrorHandlingService) {
    }

    getFilterValues(selectedFilter: string) {
        switch (selectedFilter) {
            case groupLevels.group:
                this.filterValues$ = this.getGroups$();
                break;
            case groupLevels.svpTeam:
                this.filterValues$ = this.getSvpTeams$();
                break;
            case groupLevels.country:
                this.filterValues$ = this.getCountries$();
                break;
        }
        return this.filterValues$;
    }

    getCountries$(): Observable<Array<string>> {
        return this.httpClient.get<string[]>(this.countryUrl, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getSvpTeams$(): Observable<Array<string>> {
        return this.httpClient.get<string[]>(this.svpTeamUrl, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getGroups$(): Observable<Array<string>> {
        return this.httpClient.get<string[]>(this.groupUrl, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getEuropeLocations$(): Observable<Array<string>> {
        return this.httpClient.get<string[]>(this.europeLocationsUrl, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    saveGroup(saveGroupDto: UpdateGroupDto): Observable<boolean> {
        return this.httpClient.post<boolean>(this.saveGroupUrl, saveGroupDto, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }
    
    getGroup(ehiGroupId: number): Observable<Group> {
        return this.httpClient.get<Group>(this.getGroupUrl + ehiGroupId, this.userService.getHttpOptions())
        .pipe(
            catchError(this.errorService.handleError)
        );
    }
}
