import { Component } from '@angular/core';
import { AppInsightsService } from '../services/app-insights.service';
import { User } from '../../security/user';
import { UserService } from '../../security/user.service';

@Component({
    template: ''
})
export class BaseComponent {
    user: User;
    tabData: string;
    level: string;
    levelValue: string;
    month: number;
    year: number;
    filterErrorMessage: string;
    isEurope: boolean;

    constructor(public appInsights: AppInsightsService,
                public userService: UserService) {
    }

    logNavigation(user: User, tabData?: string) {
        this.appInsights.logPageView(user, tabData);
    }

    trackUser() {
        this.userService.userSource$.subscribe(user => {
            this.user = user;
        });
        this.logNavigation(this.user, this.tabData);
      }
}
