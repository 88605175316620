import { Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { User } from '../../security/user';
import { environment } from '../../../environments/environment';
import { HelperMethodsService } from './helper-methods.service';

@Injectable()
export class AppInsightsService {

    config: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: environment.applicationInsightsKey
    };

    appInsights = AppInsights;

    constructor(private helperMethods: HelperMethodsService) {
        this.appInsights.downloadAndSetup(this.config);
    }

    logPageView(user: User, tabData?: string, url?: string, properties?: any, measurements?: any, duration?: number) { // Currently being called during construction of any component extending BaseComponent
        const name = this.buildUserPropertyString(user, tabData);
        this.appInsights.trackPageView(name, url, properties, measurements, duration);
    }

    logUserAction(name: string, user: User) {
        const properties = this.buildUserPropertiesObj(user);
        this.appInsights.trackEvent(name, properties);
    }

    logErrorThrown(error: string, user: User) {
        error = 'ERROR: ' + error;
        const properties = this.buildUserPropertiesObj(user);
        this.appInsights.trackEvent(error, properties);
    }

    buildUserPropertyString(user: User, tabData?: string): string {
        tabData = this.checkTabData(tabData);
        const userType = typeof user;
        if (userType !== 'undefined' && user.roles.length > 0) { // Doing this check to avoid console errors until we look into random user being undefined after long inactivity.
            return user.eId + ' ' + user.lastName + ', ' + user.firstName + ', ' + user.group + ', ' + user.roles[0].description + ' ' + tabData;
        } else if (userType !== 'undefined' && user.roles.length === 0) {
            return user.eId + ' ' + user.lastName + ', ' + user.firstName + ', ' + user.group + ', ' + tabData;
        } else {
            return 'No user defined';
        }
    }

    checkTabData(tabData: string): any {
        if (!this.helperMethods.isStringNullOrEmpty(tabData)) {
            return tabData;
        } else {
            return '';
        }
    }

    buildUserPropertiesObj(user: User): any {
        const userType = typeof user;
        if (userType !== 'undefined' && user.roles.length > 0) {
            return {'user': user.eId + ' ' + user.lastName + ', ' + user.firstName + ', ' + user.group + ', ' + user.roles[0].description};
        } else if (userType !== 'undefined' && user.roles.length === 0) {
            return {'user': user.eId + ' ' + user.lastName + ', ' + user.firstName + ', ' + user.group};
        } else {
            return {'user': 'No user defined'};
        }
    }
}
