import { Injectable } from '@angular/core';
import { GroupPreference } from '../../models/incoming-dtos/group-preference';
import { GroupPreferenceUpdateDto } from '../../models/outgoing-dtos/group-preference-update-dto';
import { User } from '../../security/user';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';

@Injectable()
export class GroupPreferencesService {
    constructor(public helperMethods: HelperMethodsService) {}

    buildGroupPrefTuples(groupPreferences: GroupPreference[]): [GroupPreference, boolean][] {
        const groupPrefTuples = new Array<[GroupPreference, boolean]>();
        groupPreferences.forEach(pref => {
            const groupPref = new GroupPreference();
            groupPref.id = pref.id;
            groupPref.ehiGroupId = pref.ehiGroupId;
            groupPref.goalName = pref.goalName;
            if (pref.sequence === 9999) {
                groupPref.sequence = null;
            } else {
                groupPref.sequence = pref.sequence;
            }
            groupPref.selected = pref.selected;
            groupPref.isCorpMetric = pref.isCorpMetric;
            groupPrefTuples.push([groupPref, pref.selected]);
        });
        return groupPrefTuples;
    }

    // tslint:disable-next-line:max-line-length
    buildSaveData(groupPrefTuples: [GroupPreference, boolean][], user: User, groupPreferences: GroupPreference[]): GroupPreferenceUpdateDto[] {
        const groupPrefTuplesOrdered = this.checkOrderOfSequences(groupPrefTuples);
        const saveGroupPrefDtos = new Array<GroupPreferenceUpdateDto>();
        groupPrefTuplesOrdered.forEach(tuple => {
            if (tuple[0].selected !== tuple[1]) {
                const saveGroupPrefDto = new GroupPreferenceUpdateDto();
                saveGroupPrefDto.ehiGroupId = tuple[0].ehiGroupId;
                saveGroupPrefDto.goalName = tuple[0].goalName;
                saveGroupPrefDto.id = tuple[0].id;
                if (tuple[1]) {
                    saveGroupPrefDto.recordStatus = true;
                    saveGroupPrefDto.sequence = tuple[0].sequence;
                } else {
                    saveGroupPrefDto.recordStatus = false;
                    saveGroupPrefDto.sequence = 9999;
                }
                saveGroupPrefDto.updatedBy = user.eId;
                saveGroupPrefDtos.push(saveGroupPrefDto);
            } else if (tuple[1]) {
                const tupleToCheck = this.checkIfTupleShouldBeUpdated(tuple, groupPreferences);
                if (tupleToCheck[0].selected) {
                    const saveGroupPrefDto = new GroupPreferenceUpdateDto();
                    saveGroupPrefDto.ehiGroupId = tupleToCheck[0].ehiGroupId;
                    saveGroupPrefDto.goalName = tupleToCheck[0].goalName;
                    saveGroupPrefDto.id = tupleToCheck[0].id;
                    saveGroupPrefDto.recordStatus = true;
                    saveGroupPrefDto.sequence = tuple[0].sequence;
                    saveGroupPrefDto.updatedBy = user.eId;
                    saveGroupPrefDtos.push(saveGroupPrefDto);
                }
            }
        });
        return saveGroupPrefDtos;
    }

    checkOrderOfSequences(groupPrefTuples: [GroupPreference, boolean][]): [GroupPreference, boolean][] {
        const groupPrefTuplesOrdered = new Array<[GroupPreference, boolean]>();
        const groupPrefWithNullSeq = new Array<[GroupPreference, boolean]>();
        let startingSequenceNumber = 0;
        groupPrefTuples.forEach(tuple => {
            if (!tuple[0].isCorpMetric && tuple[0].sequence !== null) {
                groupPrefTuplesOrdered.push(tuple);
            }
            if (tuple[0].isCorpMetric) {
                startingSequenceNumber = startingSequenceNumber + 1;
            }
            if (!tuple[0].isCorpMetric && tuple[0].sequence === null) {
                groupPrefWithNullSeq.push(tuple);
            }
        });
        groupPrefTuplesOrdered.sort((a, b) => this.helperMethods.compareNumbers(b[0].sequence, a[0].sequence));
        const finalSequenceNumber = groupPrefTuples.length;
        for (let i = 0; i < groupPrefTuplesOrdered.length; i++) {
            startingSequenceNumber = startingSequenceNumber + 1;
            if (startingSequenceNumber < finalSequenceNumber) {
                groupPrefTuplesOrdered[i][0].sequence = startingSequenceNumber;
            }
        }
        groupPrefWithNullSeq.forEach(tuple => {
            groupPrefTuplesOrdered.push(tuple);
        });
        return groupPrefTuplesOrdered;
    }

    checkIfTupleShouldBeUpdated(tuple: [GroupPreference, boolean], groupPreferences: GroupPreference[]): [GroupPreference, boolean] {
        groupPreferences.forEach(pref => {
          if (pref.id === tuple[0].id) {
            if (pref.sequence !== tuple[0].sequence) {
              tuple[0].selected = true;
            } else {
              tuple[0].selected = false;
            }
          }
        });
        return tuple;
    }

    checkSequenceValue(groupPrefTuples: [GroupPreference, boolean][]): boolean {
        let isValidSave = true;
        groupPrefTuples.forEach(tuple => {
            if (tuple[1] && tuple[0].sequence === null) {
                isValidSave = false;
            }
        });
        return isValidSave;
    }

    checkSequenceDuplicates(groupPrefTuples: [GroupPreference, boolean][], pref: GroupPreference): boolean {
        const sequences = new Array<number>();
        groupPrefTuples.forEach(tuple => {
            if (tuple[0].goalName !== pref.goalName && tuple[0].sequence !== null) {
                sequences.push(tuple[0].sequence);
            }
        });

        if (sequences.includes(pref.sequence)) {
            return false;
        } else {
            return true;
        }
    }
}
