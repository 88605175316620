import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { DashboardEmployeeGraph } from '../../models/incoming-dtos/dashboard-employee-graph';

@Component({
    selector: 'scoreboard-role-filter',
    templateUrl: './role-filter.component.html',
    styleUrls: ['./role-filter.component.scss']
})
export class RoleFilterComponent implements OnChanges {
    roles: Array<any>;
    @Input() isAllNeeded: boolean;
    @Input() graphData: Array<DashboardEmployeeGraph>;
    @Output() filterChanged: EventEmitter<string>;
    selectedJobCode: number;

    constructor() {
        this.filterChanged = new EventEmitter<string>();
    }

    ngOnChanges() {
        this.roles = this.GetJobCodeIds();
    }

    GetJobCodeIds(): Array<any> {
        let returnJobCodes = [];
        let lookupNumber = 0;
        let exists = false;
        if (this.graphData) {
            this.graphData.forEach(x => {
                exists = returnJobCodes.some(function (y) { return y.name === x.employee.jobTitle; });
                if (!exists) {
                    returnJobCodes = returnJobCodes.concat({ id: x.employee.jobCodeId, lookupid: ++lookupNumber, name: x.employee.jobTitle });
                }
            });

            returnJobCodes = this.checkIfAllRolesIsNeeded(returnJobCodes);
            returnJobCodes = returnJobCodes.sort(function (a, b) { return a.id - b.id; });
            return returnJobCodes.map(s => ({id: s.lookupid, name: s.name}));
        }
        return null;
    }

    private checkIfAllRolesIsNeeded(returnJobCodes: any[]) {
        if (this.isAllNeeded) {
            this.selectedJobCode = 0;
            this.filterChanged.emit('All Roles');
            return returnJobCodes = returnJobCodes.concat({
                id: 0,
                lookupid: 0,
                name: 'All Roles'
            });
        }
    }

    selectFilter(jobCodeId: any): any {
        this.selectedJobCode = jobCodeId.id;
        this.filterChanged.emit(jobCodeId.name);
    }
}
