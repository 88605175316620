import { SpcBaseComponent } from './../../shared/base/spc-base.component';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PayPlan } from '../../models/incoming-dtos/payplan';
import jsPDF from 'jspdf';
import { Observable } from 'rxjs';
import { SpcTier } from '../../models/incoming-dtos/spc-tier';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../../security/user.service';
import { ErrorHandlingService } from '../../shared/services/error-handling.service';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { catchError } from 'rxjs/operators';



@Injectable()
export class PayplanFormExportService {
  private baseUrl: string = environment.dotNetCoreUrl + 'coreapi/PayPlan/';
  private isSpcEnabled: boolean = true;
  private isNetSpcEnabled: boolean = true;
  private getSpcTierUrl: string = this.baseUrl + 'spcTiers';
  constructor(private httpClient: HttpClient,
    private userService: UserService,
    private errorService: ErrorHandlingService,
    private customeCurrencyPipe: CustomCurrencyPipe) { }

  getSpcTiers(targetAmount: number, jobTitleId: number, targetPriceComp: number): Observable<SpcTier[]> {
    const url = this.getSpcTierUrl + '/' + targetAmount + '/' + jobTitleId + '/' + targetPriceComp;
    return this.httpClient.get<SpcTier[]>(url, this.userService.getHttpOptions())
      .pipe(
        catchError(this.errorService.handleError)
      );
  }

  exportPayPlan(payPlan: PayPlan, payPlanForm: UntypedFormGroup, selectedAdditionalTarget: string, 
                payPlanGrossSpcTiers: SpcTier[], payPlanNetSpcTiers: SpcTier[], isSpcEnabled: boolean, isNetSpcEnabled: boolean) {
    this.isSpcEnabled = isSpcEnabled;
    this.isNetSpcEnabled = isNetSpcEnabled;
    const pdfDoc = new jsPDF();
    /* BEGIN HEADER*/
    pdfDoc.setFontSize(11);
    // setFontStyle has been removed as of jsPDF 2.0.0
    pdfDoc.setFont('Times', 'bold');
    pdfDoc.text('Pay Plan Quotation', 90, 10);
    pdfDoc.setFont('Times','normal');
    pdfDoc.setFontSize(10);
    pdfDoc.text(payPlan.employee.firstName + ' ' + payPlan.employee.lastName + ' (' + payPlan.employee.jobTitle + ')', 40, 20);
    pdfDoc.text('Effective Date:', 140, 20);
    pdfDoc.setLineWidth(1);
    pdfDoc.lines([[0, 0], [35, 0]], 165, 21);
    pdfDoc.lines([[0, 0], [190, 0]], 10, 25);
    pdfDoc.setLineWidth(0.1);
    /* END HEADER */

    pdfDoc.text('Target Comp', 25, 35);
    pdfDoc.lines([[0, 0], [21, 0]], 25, 35.5);
    pdfDoc.text('$' + payPlanForm.value.targetCompensation, 48, 35);
    pdfDoc.text('Vaca Pay', 30, 45);
    pdfDoc.lines([[0, 0], [15, 0]], 30, 45.5);
    pdfDoc.text('$' + payPlanForm.value.vacationPay, 48, 45);
    pdfDoc.text('Target Comp w/o Vaca', 10, 55);
    pdfDoc.lines([[0, 0], [36, 0]], 10, 55.5);
    pdfDoc.text('$' + payPlanForm.value.targetCompWithoutVac, 48, 55);
    pdfDoc.text('Base', 38, 65);
    pdfDoc.lines([[0, 0], [8, 0]], 38, 65.5);
    pdfDoc.text('$' + payPlanForm.value.basePay, 48, 65);
    pdfDoc.text('Target Production', 18, 75);
    pdfDoc.lines([[0, 0], [28, 0]], 18, 75.5);
    pdfDoc.text('$' + payPlanForm.value.targetProduction, 48, 75);
    pdfDoc.text('Target Profit', 26, 85);
    pdfDoc.lines([[0, 0], [20, 0]], 26, 85.5);
    pdfDoc.text('Amt', 36, 90);
    pdfDoc.text('$' + payPlanForm.value.profitBonusAmount, 44, 90);
    pdfDoc.text('Pct', 37, 95);
    pdfDoc.text(this.customeCurrencyPipe.transform(payPlanForm.value.profitBonusPercent, '1.5-5') + '%', 44, 95);

    /* BEGIN SALES VOLUME SECTION */
    pdfDoc.text('Sales Volume', 95, 35);
    pdfDoc.lines([[0, 0], [12, 0]], 95, 35.5);
    pdfDoc.text('Annual Volume', 83, 40);
    pdfDoc.text(this.customeCurrencyPipe.transform(payPlanForm.value.salesVolumeTarget, '1.0-0'), 110, 40);
    pdfDoc.text('Per Unit', 94, 48);
    pdfDoc.text('$' + payPlanForm.value.salesVolumePayPerUnit, 110, 48);
    pdfDoc.text('Pay/Year', 92, 55);
    pdfDoc.text('$' + payPlanForm.value.salesVolumePayout, 110, 55);

    /* END SALES VOLUME SECTION */
    if (this.isSpcEnabled) {
     pdfDoc.text('Pricing', 96, 65);
    
     pdfDoc.lines([[0, 0], [11, 0]], 96, 65.5);

    
      pdfDoc.text('SPC By ' + this.getTargetSpcType(payPlanForm.value.targetSpcType), 110, 65);
    }

    /* BEGIN PURCHASE VOLUME SECTION */
    // pdfDoc.text('Purchase Volume', 96, 65);
    // pdfDoc.lines([[0, 0], [26, 0]], 96, 65.5);
    // pdfDoc.text('Annual Volume', 83, 70);
    // pdfDoc.text(this.customeCurrencyPipe.transform(payPlanForm.value.purchaseVolumeTarget, '1.0-0'), 110, 70);
    // pdfDoc.text('Per Unit', 95, 78);
    // pdfDoc.text('$' + payPlanForm.value.purchaseVolumePayPerUnit, 110, 78);
    // pdfDoc.text('Pay/Year', 93, 85);
    // pdfDoc.text('$' + payPlanForm.value.purchaseVolumePayout, 110, 85);

    /* END PURCHASE VOLUME SECTION */

    /* BEGIN % PRODUCTION PAY SECTION */
    pdfDoc.text('% Production Pay', 150, 35);
    pdfDoc.lines([[0, 0], [28, 0]], 150, 35.5);
    pdfDoc.text('Profit %', 162, 40);
    pdfDoc.text(payPlanForm.value.profitPercent, 177, 40);
    pdfDoc.text('Sales Vol %', 156, 46);
    pdfDoc.text(payPlanForm.value.volumePercent, 177, 46);
    // pdfDoc.text('Purchase Vol %', 150, 51);
    // pdfDoc.text(payPlanForm.value.purchasePercent, 177, 51);
    if (this.isSpcEnabled) {
      pdfDoc.text('SPC %', 163, 53);
      pdfDoc.text(payPlanForm.value.spcPercent, 177, 53);
    }
    switch (selectedAdditionalTarget) {
      case '1':      
        pdfDoc.text('Delete to Sale Days %', 141, 58);
        pdfDoc.text(payPlanForm.value.dispositionPercent, 177, 58);
        break;
      case '2':
        pdfDoc.text('Aged %', 162, 58);
        pdfDoc.text(payPlanForm.value.agedPercentagePercent, 177, 58);
        break;
        case '3':
          pdfDoc.text('Local Boost % of DR Fleet', 135, 58);
          pdfDoc.text(payPlanForm.value.localBoostPercent, 177, 58);
          break; 
          
          case '4':
          pdfDoc.text('Purchase Volume %', 145, 58);
          pdfDoc.text(payPlanForm.value.purchasePercent, 177, 58);
          break;       

          case '5':      
            pdfDoc.text('Dealer Sold To %', 145, 58);
            pdfDoc.text(payPlanForm.value.dealerSoldToAllPercent, 177, 58);
            break;
      default:
        break;
    }
    pdfDoc.text('Total %', 162, 64);
    pdfDoc.text(payPlanForm.value.totalPercent, 177, 64);
    /* END % PRODUCTION PAY SECTION */

    if (this.isSpcEnabled) {
    pdfDoc.text('Target Price Comp', 78, 75);
    pdfDoc.lines([[0, 0], [30, 0]], 78, 75.5);
    pdfDoc.text('$' + payPlanForm.value.spcPayout, 110, 75);

    pdfDoc.text('Base Tier', 92, 85);
    pdfDoc.lines([[0, 0], [15, 0]], 92, 85.5);
    pdfDoc.text('Gross', 97, 90);
    pdfDoc.text(payPlanForm.value.grossSpcTarget == null ? '0' : payPlanForm.value.grossSpcTarget.toString(), 110, 90);
    if (payPlan.employee.isManager && isNetSpcEnabled) {
      pdfDoc.text('Net', 101, 95);
      pdfDoc.text(payPlanForm.value.netSpcTarget == null ? '0' : payPlanForm.value.netSpcTarget.toString(), 110, 95);
    }
  }
    pdfDoc.text('Additional Target', 81, 100+10);
    pdfDoc.lines([[0, 0], [27, 0]], 81, 100.5+10);
    switch (selectedAdditionalTarget) {
      case '1':
        pdfDoc.text('Delete to Sale Days Target', 65, 105+10);
        pdfDoc.text(payPlanForm.value.daysToDispositionTarget, 110, 105+10);
        pdfDoc.text('Anticipated Monthly Payout', 65, 110+10);
        const daysToDispositionPayoutNumber = payPlanForm.value.daysToDispositionPayout.replace(/[^\d.]/g, '');
        pdfDoc.text('$' + this.customeCurrencyPipe.transform((+daysToDispositionPayoutNumber / 12), '1.2-2'), 110, 110+10);
        break;
      case '2':
        pdfDoc.text('Aged % Target', 84, 105+10);
        pdfDoc.text(payPlanForm.value.agedPercentageTarget + '%', 110, 105+10);
        pdfDoc.text('Anticipated Monthly Payout', 65, 110+10);
        const agedPercentagePayoutNumber = payPlanForm.value.agedPercentagePayout.replace(/[^\d.]/g, '');
        pdfDoc.text('$' + this.customeCurrencyPipe.transform((+agedPercentagePayoutNumber / 12), '1.2-2'), 110, 110+10);
        break;
      case '3':
        pdfDoc.text('Local Boost % Target', 74, 105+10);
        pdfDoc.text(payPlanForm.value.localBoostPercentTarget + '%', 110, 105+10);
        pdfDoc.text('Anticipated Monthly Payout', 65, 110+10);
        const localBoostPercentagePayoutNumber = payPlanForm.value.localBoostPercentPayout.replace(/[^\d.]/g, '');
        pdfDoc.text('$' + this.customeCurrencyPipe.transform((+localBoostPercentagePayoutNumber / 12), '1.2-2'), 110, 110+10);
        break;       
      case '4':
        pdfDoc.text('Purchase Volume', 84, 105+10);
        pdfDoc.text(payPlanForm.value.purchaseVolumeTarget, 110, 105+10);
        pdfDoc.text('Per Unit', 95, 110+10);
        pdfDoc.text('$' + payPlanForm.value.purchaseVolumePayPerUnit, 110, 110+10);
            /* BEGIN PURCHASE VOLUME SECTION */
    // pdfDoc.text('Purchase Volume', 96, 65);
    // pdfDoc.lines([[0, 0], [26, 0]], 96, 65.5);
    // pdfDoc.text('Annual Volume', 83, 70);
    // pdfDoc.text(this.customeCurrencyPipe.transform(payPlanForm.value.purchaseVolumeTarget, '1.0-0'), 110, 70);
    // pdfDoc.text('Per Unit', 95, 78);
    // pdfDoc.text('$' + payPlanForm.value.purchaseVolumePayPerUnit, 110, 78);
    // pdfDoc.text('Pay/Year', 93, 85);
    // pdfDoc.text('$' + payPlanForm.value.purchaseVolumePayout, 110, 85);
        pdfDoc.text('Anticipated Monthly Payout', 65, 115+10);
        const purchasevolPayoutNumber = payPlanForm.value.purchaseVolumePayout.replace(/[^\d.]/g, '');
        pdfDoc.text('$' + this.customeCurrencyPipe.transform((+purchasevolPayoutNumber / 12), '1.2-2'), 110, 115+10);
        break;      
        
        case '5':
        pdfDoc.text('Dealer Sold To', 74, 105+10);
        pdfDoc.text(payPlanForm.value.dealerSoldToAllTarget, 110, 105+10);
        pdfDoc.text('Anticipated Monthly Payout', 65, 110+10);
        const dealerSoldToAllPayoutNumber = payPlanForm.value.dealerSoldToAllPayout.replace(/[^\d.]/g, '');
        pdfDoc.text('$' + this.customeCurrencyPipe.transform((+dealerSoldToAllPayoutNumber / 12), '1.2-2'), 110, 110+10);
        break;  
      default:
        break;
    }

    /* BEGIN SUMMARY SECTION */
    pdfDoc.text('Summary', 163, 70);
    pdfDoc.lines([[0, 0], [15, 0]], 163, 70.5);
    pdfDoc.text('Base', 165, 75);
    pdfDoc.text('$' + payPlanForm.value.baseSummary, 180, 75);
    pdfDoc.text('Vacation', 159, 80);
    pdfDoc.text('$' + payPlanForm.value.vacationSummary, 180, 80);
    pdfDoc.text('Profit', 164, 85);
    pdfDoc.text('$' + payPlanForm.value.profitSummary, 180, 85);
    pdfDoc.text('Sales Vol Pay', 152, 90);
    pdfDoc.text('$' + payPlanForm.value.volumePaySummary, 180, 90);

    pdfDoc.setLineWidth(1);
    let additionalY = 100;

    if (this.isSpcEnabled)     
    {
      pdfDoc.text('Spc', 166, additionalY-5);
      pdfDoc.text('$' + payPlanForm.value.spcSummary, 180, additionalY-5);
    }
    switch (selectedAdditionalTarget) {

      case '1':
        pdfDoc.text('Delete to Sale Days', 141, additionalY);
        pdfDoc.text('$' + payPlanForm.value.daysToDispositionPayout, 180, additionalY);
        pdfDoc.lines([[0, 0], [60, 0]], 138, additionalY+1.5);
        this.AddTotal(pdfDoc, payPlanForm);
        break;
      case '2':
        pdfDoc.text('Aged', 165, additionalY);
        pdfDoc.text('$' + payPlanForm.value.agedPercentageSummary, 180, additionalY);
        pdfDoc.lines([[0, 0], [60, 0]], 138, additionalY+1.5);
        this.AddTotal(pdfDoc, payPlanForm);
        break;
      case '3':
        pdfDoc.text('Local Boost', 155, additionalY);
        pdfDoc.text('$' + payPlanForm.value.localBoostPercentSummary, 180, additionalY);
        pdfDoc.lines([[0, 0], [60, 0]], 138, additionalY+1.5);
        this.AddTotal(pdfDoc, payPlanForm);
        break;
        case '4':
          pdfDoc.text('Purch. Vol', 155, additionalY);
          pdfDoc.text('$' + payPlanForm.value.purchaseVolumePaySummary, 180, additionalY);
          pdfDoc.lines([[0, 0], [60, 0]], 138, additionalY+1.5);
          this.AddTotal(pdfDoc, payPlanForm);
          break;      
          
          case '5':
            pdfDoc.text('Dealer Sold', 155, additionalY);
            pdfDoc.text('$' + payPlanForm.value.dealerSoldToAllPercentSummary, 180, additionalY);
            pdfDoc.lines([[0, 0], [60, 0]], 138, additionalY+1.5);
            this.AddTotal(pdfDoc, payPlanForm);
            
          break;  
      default:
        break;
    }
    /* END SUMMARY SECTION */

    /* BEGIN FOOTER */
    pdfDoc.text('Approved By:', 10, 250);
    pdfDoc.lines([[0, 0], [80, 0]], 32, 251);
    pdfDoc.text('Date:', 130, 250);
    pdfDoc.lines([[0, 0], [58, 0]], 140, 251);
    pdfDoc.text('Next level of management:', 10, 260);
    pdfDoc.lines([[0, 0], [60, 0]], 52, 261);
    pdfDoc.text('Date:', 130, 260);
    pdfDoc.lines([[0, 0], [58, 0]], 140, 261);
    pdfDoc.setLineWidth(0.1);
    pdfDoc.text('This is confidential material. For internal use only.', 65, 290);
    /* END FOOTER */
    
    if (this.isSpcEnabled) {
      pdfDoc.setFont('Times', 'bold');

      pdfDoc.text('Gross SPC', 10, 120+10);
      
      pdfDoc.lines([[0, 0], [18, 0]], 10, 120.5+10);
      pdfDoc.setFont('Times', 'normal');
      pdfDoc.text('Bonus Tier Range', 10, 125+10);
      pdfDoc.lines([[0, 0], [28, 0]], 10, 125.5+10);
      pdfDoc.text('Annual Pay', 50, 125+10);
      pdfDoc.lines([[0, 0], [18, 0]], 50, 125.5+10);
      pdfDoc.text('Anticipated', 80, 125+10);
      pdfDoc.lines([[0, 0], [18, 0]], 80, 125.5+10);
      pdfDoc.setFillColor('0.80');
      pdfDoc.rect(10, 131+10, 38, 95, 'F');
      pdfDoc.text('Start', 10, 130+10);
      pdfDoc.lines([[0, 0], [8, 0]], 10, 130.5+10);
      pdfDoc.text('End', 28, 130+10);
      pdfDoc.lines([[0, 0], [6, 0]], 28, 130.5+10);
      pdfDoc.text('Per Tier', 50, 130+10);
      pdfDoc.lines([[0, 0], [13, 0]], 50, 130.5+10);
      pdfDoc.text('Monthly', 80, 130+10);
      pdfDoc.lines([[0, 0], [12, 0]], 80, 130.5+10);

      let endPosY = 135+10;

      payPlanGrossSpcTiers.forEach(element => {
        if (element.start.endsWith(')')) {
          pdfDoc.setTextColor(255, 0, 0);
        } else {
          pdfDoc.setTextColor(0, 0, 0);
        }

        if (element.selected) {
          pdfDoc.setFillColor(0, 0, 1, 0);
          pdfDoc.rect(10, endPosY - 4.4, 93, 6, 'DF');
        }
        pdfDoc.text(element.start, 10, endPosY);
        pdfDoc.text(element.end, 28, endPosY);
        //pdfDoc.text(element.perTier, 28, endPosY);
        pdfDoc.text(element.perTier.toString(), 50, endPosY);
        pdfDoc.text(element.monthly.toString(), 80, endPosY);

        endPosY += 5;
      });

      if (payPlan.employee.isManager && this.isNetSpcEnabled) {
        pdfDoc.setTextColor(0, 0, 0);
        pdfDoc.setFont('Times', 'bold');
        pdfDoc.text('Net SPC', 110, 120+10);
        pdfDoc.lines([[0, 0], [18, 0]], 110, 120.5+10);
        pdfDoc.setFont('Times', 'normal');
        pdfDoc.text('Bonus Tier Range', 110, 125+10);
        pdfDoc.lines([[0, 0], [28, 0]], 110, 125.5+10);
        pdfDoc.text('Annual Pay', 150, 125+10);
        pdfDoc.lines([[0, 0], [18, 0]], 150, 125.5+10);
        pdfDoc.text('Anticipated', 180, 125+10);
        pdfDoc.lines([[0, 0], [18, 0]], 180, 125.5+10);
        pdfDoc.setFillColor('0.80');
        pdfDoc.rect(110, 131+10, 38, 95, 'F');
        pdfDoc.text('Start', 110, 130+10);
        pdfDoc.lines([[0, 0], [8, 0]], 110, 130.5+10);
        pdfDoc.text('End', 128, 130+10);
        pdfDoc.lines([[0, 0], [6, 0]], 128, 130.5+10);
        pdfDoc.text('Per Tier', 150, 130+10);
        pdfDoc.lines([[0, 0], [13, 0]], 150, 130.5+10);
        pdfDoc.text('Monthly', 180, 130+10);
        pdfDoc.lines([[0, 0], [12, 0]], 180, 130.5+10);

        endPosY = 135+10;

        payPlanNetSpcTiers.forEach(element => {
          if (element.start.endsWith(')')) {
            pdfDoc.setTextColor(255, 0, 0);
          } else {
            pdfDoc.setTextColor(0, 0, 0);
          }

          if (element.selected) {
            pdfDoc.setFillColor(0, 0, 1, 0);
            pdfDoc.rect(110, endPosY - 4.4, 93, 6, 'DF');
          }
          pdfDoc.text(element.start, 110, endPosY);
          pdfDoc.text(element.end, 128, endPosY);
          pdfDoc.text(element.perTier.toString(), 150, endPosY);
          pdfDoc.text(element.monthly.toString(), 180, endPosY);

          endPosY += 5;
        });
      }
    }
      pdfDoc.save('ExportPayPlan.pdf');
  }

  public AddTotal(pdfDoc: any, payPlanForm: UntypedFormGroup) {
    pdfDoc.text('Total', 164, 110);
    pdfDoc.text('$' + payPlanForm.value.totalSummary, 180, 110);
  }

  getTargetSpcType(targetSpcType: string): string {
    let value = '';
    switch (targetSpcType) {
      case '1':
        value = 'Country';
        break;
      case '2':
        value = 'Market';
        break;
      default:
        break;
    }

    return value;
  }
}
