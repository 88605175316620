import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TeamAssignmentComponent } from './team-assignment.component';
import { TeamAssignmentRouteActivator } from './team-assignment-route-activator';
import { GroupTeamsComponent } from './group-teams/group-teams.component';
import { TeamAssignmentService } from './team-assignment.service';
import { TeamDetailsComponent } from './team-details/team-details.component';
import { TeamDetailsService } from './team-details/team-details.service';
import { ErrorStateMatcherHelper } from '../shared/reactive-forms/error-state-matcher-helper';
import { GroupTeamsService } from './group-teams/group-teams.service';
import { DecisionModalService } from '../shared/decision-modal/decision-modal.service';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild([
            { path: 'team-assignment', component: TeamAssignmentComponent, canActivate: [TeamAssignmentRouteActivator] }
        ])
    ],
    declarations: [
        TeamAssignmentComponent,
        GroupTeamsComponent,
        TeamDetailsComponent
    ],
    providers: [
        TeamAssignmentRouteActivator,
        TeamAssignmentService,
        TeamDetailsService,
        ErrorStateMatcherHelper,
        GroupTeamsService,
        DecisionModalService
    ]
})
export class TeamAssignmentModule { }
