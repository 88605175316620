import { Injectable } from '@angular/core';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { TeamGoalSave } from './team-goal-save';
import { Team } from '../../models/incoming-dtos/team';
import { TeamGoal } from '../../models/incoming-dtos/team-goal';

@Injectable()
export class TeamGoalService {

    constructor(public helperMethods: HelperMethodsService) {}

    checkIds(teamsForSave: TeamGoalSave[], teamId: number, goalId: number): TeamGoalSave[] {
        let updatedTeamsForSave = new Array<TeamGoalSave>();
        const teamIds = [];
        teamsForSave.forEach(team => {
            teamIds.push(team.teamId);
        });


        if (teamIds.includes(teamId)) {
            const index = teamIds.indexOf(teamId);
            if (teamsForSave[index].teamGoalIds.includes(goalId)) {
                updatedTeamsForSave = teamsForSave;
                 // Return unchagned list if user changes the same value more than once without clicking save.
            } else {
                teamsForSave[index].teamGoalIds.push(goalId);
                updatedTeamsForSave = teamsForSave;
                 // Adds a goal record to an team object if this is the first time the user changed the default value.
            }
        } else {
            const newIds = new TeamGoalSave();
             // Adds an team that hasn't been recorded yet if the user changes a goal value for the first time in the page.
            newIds.teamId = teamId;
            newIds.teamGoalIds = [goalId];
            teamsForSave.push(newIds);
            updatedTeamsForSave = teamsForSave;
        }

        return updatedTeamsForSave;
    }

    getGoals(teamGoals: Team[], teamsForSave: TeamGoalSave[]): TeamGoal[] {
        const goalsForSave = new Array<TeamGoal>();
        const teamIds = [];
        teamsForSave.forEach(team => {
            teamIds.push(team.teamId);
        });

        teamGoals.forEach(team => {
            if (teamIds.includes(team.id)) {
                let goalIds = [];
                const index = teamIds.indexOf(team.id);
                goalIds = teamsForSave[index].teamGoalIds;
                team.teamGoals.forEach(goal => {
                    if (goalIds.includes(goal.goalId)) {
                        goalsForSave.push(goal);
                    }
                });
            }
        });

        return goalsForSave;
    }
}
