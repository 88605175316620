import { Injectable } from '@angular/core';
import { XlsxExportService } from '../../shared/xlsx-export/xlsx-export.service';
import { PayMemoAllColumns } from './pay-memo-export-dtos/pay-memo-all-columns';
import { EUPayMemoAllColumns } from './pay-memo-export-dtos/EU-pay-memo-all-columns';
import { CurrencyPipe, getCurrencySymbol, DecimalPipe } from '@angular/common';
import { PayMemo } from '../../models/incoming-dtos/pay-memo';
import { PayMemoExport } from './pay-memo-export-dtos/pay-memo-export';
import { PayMemoPeoplesoftExport } from './pay-memo-export-dtos/pay-memo-peoplesoft-export-dtos';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { PayMemoProBusinessExport } from './pay-memo-export-dtos/pay-memo-probusiness-export';
import { GlobalizationService } from '../../shared/services/globalization.service';
import { SpcBaseComponent } from '../../../app/shared/base/spc-base.component';
import { AppInsightsService } from '../../shared/services/app-insights.service';

@Injectable()
export class PayMemoExportService extends SpcBaseComponent {

    constructor(private xlsxExportService: XlsxExportService,
        private helperMethods: HelperMethodsService,
        private currencyService: GlobalizationService,
        public appInsights: AppInsightsService) {
        super(appInsights);
    }

    buildPayMemoPeoplesoftExport(tableData: PayMemo[]) {
        const tableDataForExport = new PayMemoPeoplesoftExport();
        let prodPayoutTotals = 0;
        tableData.forEach(row => {
            prodPayoutTotals = prodPayoutTotals + row.adjustedPay;
            if (this.helperMethods.isStringNullOrEmpty(tableDataForExport.currency)) {
                if (row.employee.country === 'USA') {
                    tableDataForExport.currency = 'USD';
                } else {
                    tableDataForExport.currency = 'CAD';
                }
            }

            if (this.helperMethods.isStringNullOrEmpty(tableDataForExport.unit)) {
                tableDataForExport.unit = 'A00' + row.employee.groupName;
            }
        });
        if (tableDataForExport.currency === 'USD') {
            tableDataForExport.amount = CurrencyPipe.prototype.transform(prodPayoutTotals.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
        } else {
            tableDataForExport.amount = CurrencyPipe.prototype.transform(prodPayoutTotals.toString(), 'CAD', 'symbol', '1.2-2', 'en-CA');
        }
        tableDataForExport.ledger = 'Actuals';
        tableDataForExport.account = '603000';
        tableDataForExport.productCode = '';
        tableDataForExport.accountDescription = 'Remarketing Production Based Commission Accrual';

        const orderedDataForExport = this.sortExportDataForPeoplesoft(tableDataForExport);

        this.setHeadersForPayMemoPeoplesoftExport(orderedDataForExport);
    }

    sortExportDataForPeoplesoft(tableDataForExport: PayMemoPeoplesoftExport): PayMemoPeoplesoftExport {
        const orderedData = new PayMemoPeoplesoftExport();
        orderedData.unit = tableDataForExport.unit;
        orderedData.ledger = tableDataForExport.ledger;
        orderedData.account = tableDataForExport.account;
        orderedData.productCode = tableDataForExport.productCode;
        orderedData.currency = tableDataForExport.currency;
        orderedData.amount = tableDataForExport.amount;
        orderedData.accountDescription = tableDataForExport.accountDescription;

        return orderedData;
    }

    setHeadersForPayMemoPeoplesoftExport(tableDataForExport: PayMemoPeoplesoftExport) {
        const exportData = [];
        exportData.push(JSON.parse(JSON.stringify(tableDataForExport).replace('unit', 'Unit')
            .replace('ledger', 'Ledger')
            .replace('account', 'Account')
            .replace('productCode', 'ProductCode')
            .replace('currency', 'Currency')
            .replace('amount', 'Amount')
            .replace('accountDescription', 'Account Description')));

        const fileName = this.buildFileName('PayPlanMemoPeopleSoft_');
        this.xlsxExportService.generateExport(exportData, fileName);
    }

    buildPayMemoExportWithAllColumns(tableData: PayMemo[], levelValue: string, month: number, year: number, isEurope: boolean, countryCode: string) {

        const tableDataForExport = new Array<PayMemoAllColumns>();
        const tableDataForExportEU = new Array<EUPayMemoAllColumns>();
        const currency = this.currencyService.getCountryCurrency(countryCode);
        const locale = 'en-gb';

        // --------EU Only
        if (isEurope) {
            for (let i = 0; i < tableData.length; i++) {
                tableDataForExportEU[i] = new EUPayMemoAllColumns();
                tableDataForExportEU[i].employeeId = tableData[i].employee.eId;
                tableDataForExportEU[i].employeeName = tableData[i].employee.firstName + ' ' + tableData[i].employee.lastName;
                tableDataForExportEU[i].jobTitle = tableData[i].employee.jobTitle;
                tableDataForExportEU[i].grpTeam = '(' + tableData[i].employee.groupName + ') ' + tableData[i].teamName;
                tableDataForExportEU[i].volume = parseInt(tableData[i].actualSalesVolume.toString(), 10).toString();
                tableDataForExportEU[i].volumeTeam = parseInt(tableData[i].actualSalesVolumeTeam.toString(), 10).toString();
                tableDataForExportEU[i].payPerUnit = CurrencyPipe.prototype.transform(tableData[i].payoutPerCar.toString(), currency, 'symbol', '1.2-2', locale);
                tableDataForExportEU[i].reversalAdjustment = CurrencyPipe.prototype.transform(tableData[i].reversalAdjustment.toString(), currency, 'symbol', '1.2-2', locale);
                tableDataForExportEU[i].milestoneBonus = CurrencyPipe.prototype.transform(tableData[i].milestoneBonus.toString(), currency, 'symbol', '1.2-2', locale);
                tableDataForExportEU[i].milestoneBonusTeam = CurrencyPipe.prototype.transform(tableData[i].milestoneBonusTeam.toString(), currency, 'symbol', '1.2-2', locale);
                tableDataForExportEU[i].salesVolumePay = CurrencyPipe.prototype.transform(tableData[i].salesVolumePayout.toString(), currency, 'symbol', '1.2-2', locale);
                tableDataForExportEU[i].buyerAverage = parseInt(tableData[i].buyerAverage.toString(), 10).toString();
                tableDataForExportEU[i].buyerAverageTeam = parseInt(tableData[i].buyerAverageTeam.toString(), 10).toString();
                tableDataForExportEU[i].buyerBonus = CurrencyPipe.prototype.transform(tableData[i].averageBuyerGoalBonus.toString(), currency, 'symbol', '1.2-2', locale);
                tableDataForExportEU[i].buyerBonusTeam = CurrencyPipe.prototype.transform(tableData[i].averageBuyerGoalBonusTeam.toString(), currency, 'symbol', '1.2-2', locale);
                tableDataForExportEU[i].productionPay = CurrencyPipe.prototype.transform(tableData[i].projectedPay.toString(), currency, 'symbol', '1.2-2', locale);
                tableDataForExportEU[i].adjustments = CurrencyPipe.prototype.transform(tableData[i].adjustments.toString(), currency, 'symbol', '1.2-2', locale);
                tableDataForExportEU[i].adjustedProdPay = CurrencyPipe.prototype.transform(tableData[i].adjustedPay.toString(), currency, 'symbol', '1.2-2', locale);

                if (tableData[i].comments === null) {
                    tableData[i].comments = '';
                }
                tableDataForExportEU[i].comments = tableData[i].comments;
            }
            this.setHeadersForPayMemoAllColumnsEU(tableDataForExportEU, levelValue, month, year);
        } else {// --------US Only
            for (let i = 0; i < tableData.length; i++) {
                tableDataForExport[i] = new PayMemoAllColumns();
                tableDataForExport[i].employeeId = tableData[i].employee.eId;
                tableDataForExport[i].employeeName = tableData[i].employee.firstName + ' ' + tableData[i].employee.lastName;
                tableDataForExport[i].jobTitle = tableData[i].employee.jobTitle;
                tableDataForExport[i].grpTeam = '(' + tableData[i].employee.groupName + ') ' + tableData[i].teamName;
                tableDataForExport[i].mtdPtoDays = tableData[i].ptoDaysUsed;
                tableDataForExport[i].ptoRate = CurrencyPipe.prototype.transform(tableData[i].ptoRate.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                tableDataForExport[i].ptoPay = CurrencyPipe.prototype.transform(tableData[i].ptoPay.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                tableDataForExport[i].volume = tableData[i].actualSalesVolume.toString();
                tableDataForExport[i].payPerCar = CurrencyPipe.prototype.transform(tableData[i].payoutPerCar.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                tableDataForExport[i].salesVolumePay = CurrencyPipe.prototype.transform(tableData[i].salesVolumePayout.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                if(this.isSpcEnabled) {
                    tableDataForExport[i].grossSpc = CurrencyPipe.prototype.transform(tableData[i].grossSpcActual.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                    tableDataForExport[i].grossSPCNationalActual =  CurrencyPipe.prototype.transform(tableData[i].grossSPCNationalActual.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                    tableDataForExport[i].adjustedGrossSPCActual = CurrencyPipe.prototype.transform(tableData[i].adjustedGrossSPCActual.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                    tableDataForExport[i].grossBase = CurrencyPipe.prototype.transform(tableData[i].grossSpcTarget.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                    tableDataForExport[i].grossSpcPay = CurrencyPipe.prototype.transform(tableData[i].grossSpcPayout.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                    if (this.isNetSpcEnabled) {
                    tableDataForExport[i].netSpc = CurrencyPipe.prototype.transform(tableData[i].netSpcActual, 'USD', 'symbol', '1.2-2', 'en-US');
                    tableDataForExport[i].netBase = CurrencyPipe.prototype.transform(tableData[i].netSpcTarget, 'USD', 'symbol', '1.2-2', 'en-US');
                    tableDataForExport[i].netSpcPay = CurrencyPipe.prototype.transform(tableData[i].netSpcPayout.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                    }
                }
                tableDataForExport[i].disposition = CurrencyPipe.prototype.transform(tableData[i].daysToDispositionActual.toString(), 'USD', 'symbol', '1.0-1', 'en-US').replace('$', '');
                tableDataForExport[i].dispositionBase = CurrencyPipe.prototype.transform(tableData[i].daysToDispositionTarget.toString(), 'USD', 'symbol', '1.0-1', 'en-US').replace('$', '');
                tableDataForExport[i].dispositionPay = CurrencyPipe.prototype.transform(tableData[i].daysToDispositionPayout.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                if (this.isPayAgedPercentEnabled) {
                    tableDataForExport[i].agedPercent = CurrencyPipe.prototype.transform((tableData[i].agedPercentageActual * 100).toString(), 'USD', 'symbol', '1.0-2', 'en-US').replace('$', '');
                    tableDataForExport[i].agedPercentBase = CurrencyPipe.prototype.transform((tableData[i].agedPercentageTarget * 100).toString(), 'USD', 'symbol', '1.0-2', 'en-US').replace('$', '');
                    tableDataForExport[i].agedPercentPay = CurrencyPipe.prototype.transform(tableData[i].agedPercentagePayout.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                }
                tableDataForExport[i].purchaseVolumeActual = tableData[i].purchaseVolumeActual.toString();
                if(tableData[i].employee.isManager) {
                    tableDataForExport[i].purchaseVolumeActualTeam = tableData[i].purchaseVolumeActualTeam.toString();
                }
                tableDataForExport[i].purchaseVolumePayPerUnit = CurrencyPipe.prototype.transform(tableData[i].purchaseVolumePayPerUnit.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                tableDataForExport[i].purchaseVolumePayPayout = CurrencyPipe.prototype.transform(tableData[i].purchaseVolumePayPayout.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
              
                if (this.isPayLocalBoostEnabled) {
                    tableDataForExport[i].localBoostPercentActual = CurrencyPipe.prototype.transform(tableData[i].localBoostPercentActual.toString(), 'USD', 'symbol', '1.0-1', 'en-US').replace('$', '');
                    tableDataForExport[i].localBoostPercentTarget = CurrencyPipe.prototype.transform(tableData[i].localBoostPercentTarget.toString(), 'USD', 'symbol', '1.0-1', 'en-US').replace('$', '');
                    tableDataForExport[i].localBoostPercentPayout = CurrencyPipe.prototype.transform(tableData[i].localBoostPercentPayout.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                }
                

                tableDataForExport[i].dealerSoldToAllActual = CurrencyPipe.prototype.transform(tableData[i].dealerSoldToAllActual.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                tableDataForExport[i].dealerSoldToAllTarget = CurrencyPipe.prototype.transform(tableData[i].dealerSoldToAllTarget.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                tableDataForExport[i].dealerSoldToAllPayout = CurrencyPipe.prototype.transform(tableData[i].dealerSoldToAllPayout.toString(), 'USD', 'symbol', '1.2-2', 'en-US');

                tableDataForExport[i].projectedPay = CurrencyPipe.prototype.transform(tableData[i].projectedPay.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                tableDataForExport[i].adjustments = CurrencyPipe.prototype.transform(tableData[i].adjustments.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                tableDataForExport[i].adjustedProdPay = CurrencyPipe.prototype.transform(tableData[i].adjustedPay.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
                if (tableData[i].comments === null) {
                    tableData[i].comments = '';
                }
                tableDataForExport[i].comments = tableData[i].comments;
            }
            this.setHeadersForPayMemoAllColumns(tableDataForExport, levelValue, month, year);
        }
    }

    setHeadersForPayMemoAllColumnsEU(tableDataForExport: EUPayMemoAllColumns[], levelValue: string, month: number, year: number) {
        const exportData = [];
        tableDataForExport.forEach(element => {
            exportData.push(JSON.parse(JSON.stringify(element).replace('employeeId', 'Emp Id')
                .replace('employeeName', 'Name')
                .replace('jobTitle', 'Job Title')
                .replace('grpTeam', '(Grp)Team')
                .replace('volume', 'Volume')
                .replace('payPerUnit', 'Pay per Unit')
                .replace('reversalAdjustment', 'Reversal Adjustment')
                .replace('milestoneBonus', 'Milestone Bonus')
                .replace('salesVolumePay', 'Sales Volume Pay')
                .replace('buyerAverage', 'Buyer Average')
                .replace('buyerBonus', 'Buyer Bonus')
                .replace('productionPay', 'Production Pay Total')
                .replace('adjustments', 'Adjustments')
                .replace('adjustedProdPay', 'Adjusted Production Pay Total')
                .replace('comments', 'Comments')));
        });

        const fileName = this.buildFileName('PayPlanMemoAllColumns_');
        const date = this.getMonthWord(month, year);
        const title = 'Pay Memo (All Columns) - Group' + ' ' + levelValue + ' - ' + date;
        this.xlsxExportService.generateExport(exportData, fileName, title);
    }
    
    setHeadersForPayMemoAllColumns(tableDataForExport: PayMemoAllColumns[], levelValue: string, month: number, year: number) {
        const exportData = [];
        tableDataForExport.forEach(element => {
            exportData.push(JSON.parse(JSON.stringify(element).replace('employeeId', 'Emp Id')
                .replace('employeeName', 'Name')
                .replace('jobTitle', 'Job Title')
                .replace('grpTeam', '(Grp)Team')
                .replace('mtdPtoDays', 'MTD PTO Days')
                .replace('ptoRate', 'PTO Rate')
                .replace('ptoPay', 'PTO Pay')
                .replace('volume', 'Volume')
                .replace('payPerCar', 'Pay Per Car')
                .replace('salesVolumePay', 'Sales Volume Pay')
                .replace('grossSpc', 'Gross SPC')
                .replace('grossSPCNationalActual', 'National SPC')
                .replace('adjustedGrossSPCActual', 'Adjusted Gross SPC')
                .replace('grossBase', 'Gross Base')
                .replace('grossSpcPay', 'Gross SPC Pay')
                .replace('netSpc', 'Net SPC')
                .replace('netBase', 'Net Base')
                .replace('netSpcPay', 'Net SPC Pay')
                .replace('disposition', 'Delete to Sale Days')
                .replace('dispositionBase', 'Delete to Sale Days Target')
                .replace('dispositionPay', 'Delete to Sale Days Pay')
                
                // .replace('agedPercent', 'Aged %')
                // .replace('agedPercentBase', 'Aged % Target')
                // .replace('agedPercentPay', 'Aged % Pay')
                .replace('purchaseVolumeActual', 'Purchase Volume')
                .replace('purchaseVolumeActualTeam', 'Purchase Volume Team')
                .replace('purchaseVolumePayPerUnit', 'Purchase Volume Pay Per Unit')
                .replace('purchaseVolumePayPayout', 'Purchase Volume Pay')
                // .replace('localBoostPercentActual', 'Local Boost %')
                // .replace('localBoostPercentTarget', 'Local Boost % Target')
                // .replace('localBoostPercentPayout', 'Local Boost % Pay')
                .replace('dealerSoldToAllActual', 'Dealer Sold To')
                .replace('dealerSoldToAllTarget', 'Dealer Sold To Target')
                .replace('dealerSoldToAllPayout', 'Dealer Sold To Pay')
                
                .replace('projectedPay', 'Projected Pay')
                .replace('adjustments', 'Adjustments')
                .replace('adjustedProdPay', 'Adjusted Prod Pay')
                .replace('comments', 'Comments')));
        });

        const fileName = this.buildFileName('PayPlanMemoAllColumns_');
        const date = this.getMonthWord(month, year);
        const title = 'Pay Memo (All Columns) - Group' + ' ' + levelValue + ' - ' + date;
        this.xlsxExportService.generateExport(exportData, fileName, title);
    }

    buildPayMemoProBusinessExport(tableData: PayMemo[]) {
        const tableDataForExport = new Array<PayMemoProBusinessExport>();
        for (let i = 0; i < tableData.length; i++) {
            tableDataForExport[i] = new PayMemoProBusinessExport();
            tableDataForExport[i].compId = 'ERAC-0' + tableData[i].employee.groupName;
            tableDataForExport[i].peopleSoftId = tableData[i].employee.peopleSoftId;
            tableDataForExport[i].name = tableData[i].employee.firstName + ' ' + tableData[i].employee.lastName;
            tableDataForExport[i].earnCode = '294';
            if (tableData[i].employee.country === 'USA') {
                tableDataForExport[i].amount = CurrencyPipe.prototype.transform(tableData[i].adjustedPay.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
            } else {
                tableDataForExport[i].amount = CurrencyPipe.prototype.transform(tableData[i].adjustedPay.toString(), 'CAD', 'symbol', '1.2-2', 'en-CA');
            }
            tableDataForExport[i].hrlyRate = '';
            tableDataForExport[i].codeType = 'E';
            if (tableData[i].employee.country === 'USA') {
                tableDataForExport[i].amtType = '$';
            } else {
                tableDataForExport[i].amtType = 'CA$';
            }
            tableDataForExport[i].checkNo = '';
            tableDataForExport[i].hrBUnitWorkDiv = '';
            tableDataForExport[i].glProductWorkDept = '';
            tableDataForExport[i].glDeptIdWorkJob = '';
            tableDataForExport[i].glGroup = '';
            tableDataForExport[i].checkMessage = '';
            tableDataForExport[i].taxFrequency = '';
            tableDataForExport[i].oldRate = '';
            tableDataForExport[i].totals = '';
            tableDataForExport[i].bonusPerStartDate = '';
            tableDataForExport[i].bonusPerEndDate = '';
            tableDataForExport[i].weekIndicator = '';
        }

        this.setHeadersForPayMemoProbusiness(tableDataForExport);
    }

    setHeadersForPayMemoProbusiness(tableDataForExport: PayMemoProBusinessExport[]) {
        const exportData = [];
        tableDataForExport.forEach(element => {
            exportData.push(JSON.parse(JSON.stringify(element).replace('compId', 'Comp ID')
                .replace('peopleSoftId', 'PeopleSoftId')
                .replace('name', 'Name')
                .replace('earnCode', 'EarnCode')
                .replace('amount', 'Amount')
                .replace('hrlyRate', 'Hrly Rate')
                .replace('codeType', 'Code Type')
                .replace('amtType', 'Amt Type')
                .replace('checkNo', 'Check No')
                .replace('hrBUnitWorkDiv', 'HR B Unit Work Div')
                .replace('glProductWorkDept', 'GL Product Work Dept')
                .replace('glDeptIdWorkJob', 'GL Dept ID Work Job')
                .replace('glGroup', 'GL Group')
                .replace('checkMessage', 'Check Message')
                .replace('taxFrequency', 'Tax Frequency')
                .replace('oldRate', 'Old Rate')
                .replace('totals', 'Totals')
                .replace('bonusPerStartDate', 'Bonus Per Start Date')
                .replace('bonusPerEndDate', 'Bonus Per End Date')
                .replace('weekIndicator', 'Week Indicator')));
        });

        const fileName = this.buildFileName('PayPlanMemoProBus_');
        this.xlsxExportService.generateExport(exportData, fileName);
    }

    buildPayMemoExport(tableData: PayMemo[], levelValue: string, month: number, year: number) {
        const tableDataForExport = new Array<PayMemoExport>();
        for (let i = 0; i < tableData.length; i++) {
            tableDataForExport[i] = new PayMemoExport();
            tableDataForExport[i].group = tableData[i].employee.groupName;
            tableDataForExport[i].peopleSoftId = tableData[i].employee.eId;
            tableDataForExport[i].name = tableData[i].employee.firstName + ' ' + tableData[i].employee.lastName;
            tableDataForExport[i].projectedPay = CurrencyPipe.prototype.transform(tableData[i].projectedPay.toString(), 'USD', 'symbol', '1.2-2', 'en-US');
            tableDataForExport[i].adjustments = CurrencyPipe.prototype.transform(tableData[i].adjustments, 'USD', 'symbol', '1.2-2', 'en-US');
            tableDataForExport[i].adjustedProdPay = CurrencyPipe.prototype.transform(tableData[i].adjustedPay, 'USD', 'symbol', '1.2-2', 'en-US');
            tableDataForExport[i].comments = tableData[i].comments;
        }

        this.setHeadersForPayMemoExport(tableDataForExport, levelValue, month, year);
    }

    setHeadersForPayMemoExport(tableData: PayMemoExport[], levelValue: string, month: number, year: number) {
        const exportData = [];
        tableData.forEach(element => {
            exportData.push(JSON.parse(JSON.stringify(element).replace('group', 'Group')
                .replace('peopleSoftId', 'People Soft ID')
                .replace('name', 'Employee Name')
                .replace('projectedPay', 'Projected Pay')
                .replace('adjustments', 'Adjustments')
                .replace('adjustedProdPay', 'Adjusted Production Pay')
                .replace('comments', 'Comments')));
        });

        const fileName = this.buildFileName('PayPlanMemo_');
        const date = this.getMonthWord(month, year);
        const title = 'Pay Memo - Group' + ' ' + levelValue + ' - ' + date;
        this.xlsxExportService.generateExport(exportData, fileName, title);
    }

    buildFileName(title: string): string {
        const timeOfExport = new Date();
        const year = timeOfExport.getFullYear();
        const month = timeOfExport.getMonth() + 1;
        const day = timeOfExport.getDate();
        const hour = timeOfExport.getHours();
        const minute = timeOfExport.getMinutes();
        const second = timeOfExport.getSeconds();
        const monthString = this.checkNumber(month);
        const dayString = this.checkNumber(day);
        const hourString = this.checkNumber(hour);
        const minuteString = this.checkNumber(minute);
        const secondString = this.checkNumber(second);
        return title + year + '_' + monthString + '_' + dayString + '_' + hourString + '_' + minuteString + '_' + secondString;
    }

    checkNumber(num: number): string {
        if (num > 9) {
            return num.toString();
        } else {
            return '0' + num.toString();
        }
    }

    getMonthWord(month: number, year: number) {
        switch (month) {
            case 1:
                return 'January ' + year.toString();
            case 2:
                return 'February ' + year.toString();
            case 3:
                return 'March ' + year.toString();
            case 4:
                return 'April ' + year.toString();
            case 5:
                return 'May ' + year.toString();
            case 6:
                return 'June ' + year.toString();
            case 7:
                return 'July ' + year.toString();
            case 8:
                return 'August ' + year.toString();
            case 9:
                return 'September ' + year.toString();
            case 10:
                return 'October ' + year.toString();
            case 11:
                return 'November ' + year.toString();
            case 12:
                return 'December ' + year.toString();
        }
    }
}
