import { Component, DoCheck, OnInit } from '@angular/core';
import { AppInsightsService } from './shared/services/app-insights.service';
import { UserService } from './security/user.service';
import { HelperMethodsService } from './shared/services/helper-methods.service';
import { MonthYear } from './shared/date-filter/month-year';
import { CoreFiltersService } from './shared/services/core-filters.service';
import { DashboardRouteActivator } from './dashboard/dashboard-route-activator';
import { GoalAssignmentRouteActivator } from './goal-assignment/goal-assignment-route-activator';
import { PaidTimeoffRouteActivator } from './paid-timeoff/paid-timeoff-route-activator';
import { TeamAssignmentRouteActivator } from './team-assignment/team-assignment-route-activator';
import { PayplanSetupRouteActivator } from './payplan-setup/payplan-setup-route-activator';
import { PayMemoRouteActivator } from './pay-memo/pay-memo-route-activator';
import { AdminRouteActivator } from './admin/admin-route-activator';
import { Router } from '../../node_modules/@angular/router';
import { User } from './security/user';
import { groupLevels } from './shared/static-data/group-levels';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'scoreboard-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements DoCheck, OnInit {
  filterLevel: string;
  filterLevelValue = '';
  filterMonth: number;
  filterYear: number;
  isEurope: boolean;
  isApplyDisabled = true;
  isGroupLevel: boolean;
  isOkToSearch = false;
  shouldShowDateFilter = true;
  isContactUsPage = false;
  isGroupPrefPage = false;
  isInitialLoad = true;
  shouldUpdateUrlToStream = false;
  groupOnlyPages: string[] = ['/dashboard', '/goal-assignment', '/pay-memo', '/paid-timeoff'];
  countryPages: string[] = ['/team-assignment', '/payplan-setup', '/admin'];
  user: User;

  constructor(translate: TranslateService,
    private insightService: AppInsightsService, // Instantiate Application Insights monitoring.
    public userService: UserService,
    public helperMethods: HelperMethodsService,
    public coreFiltersSvc: CoreFiltersService,
    public dashboardActivator: DashboardRouteActivator,
    public goalAssignmentActivator: GoalAssignmentRouteActivator,
    public paidTimeOffActivator: PaidTimeoffRouteActivator,
    public teamAssignmentActivator: TeamAssignmentRouteActivator,
    public payPlanSetupActivator: PayplanSetupRouteActivator,
    public payMemoActivator: PayMemoRouteActivator,
    public adminActivator: AdminRouteActivator,
    public router: Router) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    translate.use('en');

  }

  ngDoCheck(): void {
    setTimeout(() => {
      this.userService.checkAuthenticated();
    }, 30000);
  }

  ngOnInit() {
    this.coreFiltersSvc.isGroupPrefPageSelected$.subscribe(isGroupPrefPage => {
      this.isGroupPrefPage = isGroupPrefPage;
      this.checkUrlToSetGroupFilter('/goal-assignment'); // isGroupPrefPage only gets updated when navigating to goal-assignment/group-preferences tab.
    });
    this.userService.userSource$.subscribe(user => {
      this.user = user; // This gets passed to the group filter.
    });
  }

  onPageChange(url: string) {
    this.shouldUpdateUrlToStream = this.determineToUpdateUrl(url);
    this.checkUrlToSetGroupFilter(url);
  }

  onFilterChange(filterValues: [string, string, boolean]) {
    this.filterLevel = filterValues[0];
    this.filterLevelValue = filterValues[1];
    this.isEurope = filterValues[2];
    if (this.filterLevelValue !== groupLevels.invalid) { // Fire a new data load if the selected group is a valid option.
      this.coreFiltersSvc.setFilterValues(this.filterLevel, this.filterLevelValue, this.filterMonth, this.filterYear, this.isEurope, this.router.url.replace('#', ''));
    }
  }

  onMonthYearChanged(value: MonthYear) {
    this.filterMonth = value.month + 1;
    this.filterYear = value.year;
    if (!this.isInitialLoad) { // If not initial load we are firing a new data load anytime user changes date filter.
      this.coreFiltersSvc.setFilterValues(this.filterLevel, this.filterLevelValue, this.filterMonth, this.filterYear, this.isEurope, this.router.url.replace('#', ''));
    } else {
      this.checkUrlToSetGroupFilter(this.router.url.replace('#', '')); // Need to check URL on initialLoad to determine what Group Filter will be set to.
    }
  }

  onIsApplyDisabledChanged(isApplyDisabledChanged: boolean) {
    this.isApplyDisabled = isApplyDisabledChanged;
  }

  onSubmitSearch() {
    // Everything goes to shared service into stream
    this.coreFiltersSvc.setFilterValues(this.filterLevel, this.filterLevelValue, this.filterMonth, this.filterYear, this.isEurope, this.router.url.replace('#', ''));
  }

  checkUrlToSetGroupFilter(url: string) {
    setTimeout(() => { // Using setTimeout due to cycle detection and the flow of how we check the url in onSubmitSearch()
      this.isInitialLoad = false;
      if (url === '/dashboard' || url === '/pay-memo' || url === '/paid-timeoff') {
        this.isGroupLevel = true;
        this.shouldShowDateFilter = true;
        this.isContactUsPage = false;
        // Dashboard and pay-memo pages are limited to the group level on the group filter
        this.updateUrl();
      } else if (url === '/goal-assignment') {
        this.checkGoalAssignmentPagesForGroupPrefPage();
        this.updateUrl();
      } else if (url === '/contact-us') {
        this.shouldShowDateFilter = false;
        this.isContactUsPage = true;
      } else {
        this.isGroupLevel = false;
        this.shouldShowDateFilter = true;
        this.isContactUsPage = false;
        this.updateUrl();
      }
    });
  }

  updateUrl() {
    if (this.shouldUpdateUrlToStream) { // We only update the url in coreFilterServices if user switches from one page to another where filters are the same.
      this.onSubmitSearch();
      this.shouldUpdateUrlToStream = false;
    }
  }

  checkGoalAssignmentPagesForGroupPrefPage() {
    this.isGroupLevel = true;
    this.isContactUsPage = false;
    if (!this.isGroupPrefPage) {
      this.shouldShowDateFilter = true;
    } else {
      this.shouldShowDateFilter = false;
    }
  }

  checkViewState(): boolean { // This hides one set of filters and shows the other due to how side nav works. Needed to injections of filters.
    const desktopDom = document.getElementById('desktop').offsetWidth;
    const mobileDom = document.getElementById('mobile').offsetWidth;
    if (desktopDom >= 1349) {
      return true;
    } else if (mobileDom < 1349) {
      return false;
    }
  }

  determineToUpdateUrl(url: string): boolean { // We only update the url in coreFilterServices if user switches from one page to another where filters are the same.
    const currentPage = this.router.url.replace('#', ''); // This method determines that.
    const pageNavigatingTo = url;
    if (this.groupOnlyPages.includes(currentPage) && this.groupOnlyPages.includes(pageNavigatingTo)) {
      return true;
    } else if (this.countryPages.includes(currentPage) && this.countryPages.includes(pageNavigatingTo)) {
      return true;
    } else {
      return false;
    }
  }

  isMobile(): boolean {
    if (window.outerWidth < 1349) {
      return true;
    }
    return false;
  }
}
