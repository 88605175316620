import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { TeamGoalService } from './team-goal.service';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { Team } from '../../models/incoming-dtos/team';
import { TeamGoal } from '../../models/incoming-dtos/team-goal';
import { TeamGoalSave } from './team-goal-save';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { StyleHelperService } from '../../shared/services/style-helper.service';
import { SpcBaseComponent } from '../../shared/base/spc-base.component';
import { AppInsightsService } from '../../shared/services/app-insights.service';

@Component({
  selector: 'scoreboard-team-goal',
  templateUrl: './team-goal.component.html',
  styleUrls: ['./team-goal.component.scss']
})
export class TeamGoalComponent extends SpcBaseComponent implements OnInit {
    @Input() teamGoals: Team[];
    grossSpcTargetValues = new Array<string>();
    teamsForSave = new Array<TeamGoalSave>();
    goalsToBeSaved = new Array<TeamGoal>();
    isSaveReady = false;
    @Output() teamGoalsChanged: EventEmitter<TeamGoal[]>;

    constructor(public helperMethods: HelperMethodsService,
                public teamGoalSvc: TeamGoalService,
                private customCurrencyPipe: CustomCurrencyPipe,
                public loadingSpinnerSvc: LoadingSpinnerService,
                public styleHelper: StyleHelperService,
                public appInsights: AppInsightsService) {
                    super(appInsights);
                this.teamGoalsChanged = new EventEmitter<TeamGoal[]>();
                styleHelper.maxWidth = 680;
    }

    ngOnInit() {
        this.removeEmptyValuesFromGoalValue();
    }

    recordGoalId(teamId: number, goalId: number) {
        this.isSaveReady = true;
        this.removeEmptyValuesFromGoalValue();
        if (this.helperMethods.isArrayNullOrEmpty(this.teamsForSave)) {
            const ids = new TeamGoalSave();
            ids.teamId = teamId;
            ids.teamGoalIds = [goalId];
            this.teamsForSave.push(ids);
        } else {
            this.teamsForSave = this.teamGoalSvc.checkIds(this.teamsForSave, teamId, goalId);
        }
    }

    onSave() {
        this.loadingSpinnerSvc.turnSpinnerOn('Saving...');
        this.goalsToBeSaved = this.teamGoalSvc.getGoals(this.teamGoals, this.teamsForSave);
        this.teamGoalsChanged.emit(this.goalsToBeSaved);
        this.teamsForSave = [];
        this.isSaveReady = false;
    }

    hideGoalByName(team: Team, idx: number) {
        if (team && team.name && team.teamGoals && team.teamGoals.length > 0 && idx < team.teamGoals.length) {
            return !this.hideHtmlElementByName(team.teamGoals[idx], this.defaultElementToHide);
        }
        return false;
    }

    removeEmptyValuesFromGoalValue() {
        this.teamGoals.forEach(team => {
            team.teamGoals.forEach(goal => {
                if (!goal.isSpc && goal.goalValue !== null) {
                    if (goal.goalName.startsWith('Age') || goal.goalName.startsWith('Delete')) {
                        goal.goalValue = this.customCurrencyPipe.transform(goal.goalValue, '1.1-1');
                    } else {
                        goal.goalValue = this.customCurrencyPipe.transform(goal.goalValue, '1.0-0');
                    }
                }
            });
        });
    }
}
