import { Injectable } from '@angular/core';
import { ErrorHandlingService } from './error-handling.service';
import { HelperMethodsService } from './helper-methods.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { unauthorized } from '../static-data/unauthorized';
import { AppInsightsService } from './app-insights.service';
import { User } from '../../security/user';
import { UserService } from '../../security/user.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class SubscriptionService {
    user: User;
    errorMessage: string;

    constructor(private errorService: ErrorHandlingService,
        private helperMethods: HelperMethodsService,
        private userService: UserService,
        private appInsights: AppInsightsService,
        public dialog: MatDialog) {
        this.userService.userSource$.subscribe(user => this.user = user);
    }

    onError(err: any, errorSourceLocation?: string, errorSource?: string) {
        this.errorMessage = '';
        if (!this.helperMethods.isStringNullOrEmpty(errorSource)) {
            this.errorMessage = this.createErrorSource(err[1], errorSourceLocation, errorSource);
            this.alertUserAndLogAppInsights(err);
        } else {
            this.errorMessage = this.createErrorSource(err[1], errorSourceLocation);
            this.alertUserAndLogAppInsights(err);
        }
    }

    alertUserAndLogAppInsights(err: any) {
        if (err[0] === unauthorized.unauthorized) {
            this.alertUser('You are not authorized.'); // Showing this until we implement a session expired page.
        } else {
            this.alertUser(err[0]);
        }
        this.appInsights.logErrorThrown(this.errorMessage, this.user);
    }

    onComplete(data: Array<any>, errMsg?: string) {
        if (this.helperMethods.isArrayNullOrEmpty(data)) {
            if (this.helperMethods.isStringNullOrEmpty(errMsg)) {
                errMsg = 'No data was found for the selected filters';
            }
            this.alertUser(errMsg);
        }
    }

    alertUser(message: string): void {
        this.dialog.open(AlertModalComponent, {
            width: '300px',
            height: '150px',
            data: { alertMessage: message }
        });
    }

    createErrorSource(errorCode: string, callLocation?: string, call?: string): string {
        if (!this.helperMethods.isStringNullOrEmpty(call)) {
            return errorCode + ' on ' + call + 'for' + callLocation;
        } else {
            return errorCode + ' on ' + callLocation;
        }
    }
}
