import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Employee } from '../../models/incoming-dtos/employee';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { EmployeeGoal } from '../../models/incoming-dtos/employee-goal';
import { EmployeeGoalSave } from './employee-goal-save';
import { EmployeeGoalService } from './employee-goal.service';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { StyleHelperService } from '../../shared/services/style-helper.service';
import { UserService } from '../../security/user.service';
import { AppInsightsService } from '../../shared/services/app-insights.service';
import { SpcBaseComponent } from '../../shared/base/spc-base.component';

@Component({
    selector: 'scoreboard-employee-goal',
    templateUrl: './employee-goal.component.html',
    styleUrls: ['./employee-goal.component.scss']
})
export class EmployeeGoalComponent extends SpcBaseComponent implements OnInit {
    @Input() employeeGoals: Employee[];
    grossSpcTargetValues = new Array<string>();
    employeesForSave = new Array<EmployeeGoalSave>();
    goalsToBeSaved = new Array<EmployeeGoal>();
    isSaveReady = false;
    @Output() employeeGoalsChanged: EventEmitter<EmployeeGoal[]>;

    constructor(public helperMethods: HelperMethodsService,
        public employeeGoalSvc: EmployeeGoalService,
        private customCurrencyPipe: CustomCurrencyPipe,
        public loadingSpinnerSvc: LoadingSpinnerService,
        public styleHelper: StyleHelperService,
        public userService: UserService,
        public appInsights: AppInsightsService) {
            super(appInsights);
        this.employeeGoalsChanged = new EventEmitter<EmployeeGoal[]>();
        styleHelper.maxWidth = 680;
    }

    ngOnInit() {
        this.removeEmptyValuesFromGoalValue();
    }

    recordGoalId(employeeId: number, goalId: number) {
        const temp = this.employeeGoals.filter(xx => xx.id === employeeId)[0].employeeGoals.filter(yy => yy.goalId === goalId)[0].goalValue;
        const isGroupMetric = this.employeeGoals.filter(xx => xx.id === employeeId)[0].employeeGoals.filter(yy => yy.goalId === goalId)[0].groupMetric;
        if (isGroupMetric) {
            this.employeeGoals.forEach(xx => xx.employeeGoals.forEach(yy => {
                if (yy.goalId === goalId) {
                    yy.goalValue = temp;
                    this.updateSaveList(xx.id, yy.goalId);
                }
            }));
        } else {
            this.updateSaveList(employeeId, goalId);
        }
    }

    private updateSaveList(employeeId: number, goalId: number) {
        this.isSaveReady = true;
        this.removeEmptyValuesFromGoalValue();
        if (this.helperMethods.isArrayNullOrEmpty(this.employeesForSave)) {
            const ids = new EmployeeGoalSave();
            ids.employeeId = employeeId;
            ids.employeeGoalIds = [goalId];
            this.employeesForSave.push(ids);
        } else {
            this.employeesForSave = this.employeeGoalSvc.checkIds(this.employeesForSave, employeeId, goalId);
        }
    }

    onSave() {
        if (this.isSaveReady) {
            this.loadingSpinnerSvc.turnSpinnerOn('Saving...');
            this.goalsToBeSaved = this.employeeGoalSvc.getGoals(this.employeeGoals, this.employeesForSave);
            this.employeeGoalsChanged.emit(this.goalsToBeSaved);
            this.employeesForSave = [];
            this.isSaveReady = false;
        }
    }

    hideGoalByName(emp: Employee, idx: number) {
        if (emp && emp.employeeGoals && emp.employeeGoals.length > 0 && idx <emp.employeeGoals.length) {
            return !this.hideHtmlElementByName(emp.employeeGoals[idx], this.defaultElementToHide);
        }
        return false;
    }

    removeEmptyValuesFromGoalValue() {
        this.employeeGoals.forEach(employee => {
            employee.employeeGoals.forEach(goal => {
                if (!goal.isSpc && goal.goalValue !== null) {
                    if (goal.goalValue !== '') {
                        if (goal.goalName.startsWith('Age') || goal.goalName.startsWith('Delete')) {
                            goal.goalValue = this.customCurrencyPipe.transform(goal.goalValue, '1.1-1');
                        } else {
                            goal.goalValue = this.customCurrencyPipe.transform(goal.goalValue, '1.0-0');
                        }
                    }
                }
            });
        });
    }
}
