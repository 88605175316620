import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlingService } from '../services/error-handling.service';
import { UserService } from '../../security/user.service';
import { environment } from '../../../environments/environment';
import { Employee } from '../../models/incoming-dtos/employee';
import { catchError } from 'rxjs/operators';

@Injectable()
export class EmployeeFilterService {
    private baseUrl: string = environment.dotNetCoreUrl + 'coreapi/';
    private employeesUrl: string = this.baseUrl + 'Dashboard/Employees';

    constructor(private httpClient: HttpClient, private userService: UserService, private errorService: ErrorHandlingService) { }

    getEmployees(groupName: string, year: number, month: number): Observable<Employee[]> {
        const url = this.employeesUrl + '/' + groupName + '/' + year + '/' + month;
        return this.httpClient.get<Employee[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }
}
