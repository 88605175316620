export class EUPayMemoAllColumns {
    employeeId: string;
    employeeName: string;
    jobTitle: string;
    grpTeam: string;
    volume: string;
    volumeTeam: string;
    payPerUnit: string;
    reversalAdjustment: string;
    milestoneBonus: string;
    milestoneBonusTeam: string;
    salesVolumePay: string;
    buyerAverage: string;
    buyerAverageTeam: string;
    buyerBonus: string;
    buyerBonusTeam: string;
    productionPay: string;
    adjustments: string;
    adjustedProdPay: string;
    comments: string;
}
