import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ErrorHandlingService } from '../shared/services/error-handling.service';
import { UserService } from '../security/user.service';
import { groupLevels } from '../shared/static-data/group-levels';
import { PayPlan } from '../models/incoming-dtos/payplan';
import { Team } from '../models/incoming-dtos/team';
import { PayPlanSaveDto } from '../models/outgoing-dtos/payplan-save-dto';
import { catchError } from 'rxjs/operators';
import { EUPayPlanDto } from '../models/outgoing-dtos/eupayplan-dto';

@Injectable()
export class PayplanSetupService {
    private baseUrl: string = environment.dotNetCoreUrl + 'coreapi/';
    private teamsForCountryUrl: string = this.baseUrl + 'PayPlan/country';
    private teamsForSvpTeamUrl: string = this.baseUrl + 'PayPlan/svpTeam';
    private teamsForGroupUrl: string = this.baseUrl + 'PayPlan/group';
    private getPayPlanUrl: string = this.baseUrl + 'PayPlan';
    private getEUPayPlanUrl: string = this.baseUrl + 'EUPayPlan';
    private updatePayPlanUrl: string = this.baseUrl + 'PayPlan/update';
    private updateEUPayPlanUrl: string = this.baseUrl + 'EUPayPlan/update';
    private createPayPlanUrl: string = this.baseUrl + 'PayPlan/create';
    private createEUPayPlanUrl: string = this.baseUrl + 'EUPayPlan/create';
    private lockPayPlanUrl: string = this.baseUrl + 'PayPlan/lock';
    private unlockPayPlanUrl: string = this.baseUrl + 'PayPlan/unlock';
    private lockEUPayPlanUrl: string = this.baseUrl + 'EUPayPlan/lock';
    private unlockEUPayPlanUrl: string = this.baseUrl + 'EUPayPlan/unlock';

    teams$ = new Observable<Team[]>();

    constructor(private httpClient: HttpClient,
        private userService: UserService,
        private errorService: ErrorHandlingService) { }

    getTeamsAndEmployees(level: string, levelId: string, month: number, year: number): Observable<Team[]> {
        switch (level) {
            case groupLevels.country:
                return this.teams$ = this.getTeamsAndEmployeesForCountry$(levelId, month, year);
            case groupLevels.svpTeam:
                return this.teams$ = this.getTeamsAndEmployeesForSvpTeam$(levelId, month, year);
            case groupLevels.group:
                return this.teams$ = this.getTeamsAndEmployeesForGroup$(levelId, month, year);
        }
    }

    getTeamsAndEmployeesForCountry$(levelId: string, month: number, year: number): Observable<Team[]> {
        const url = this.buildTeamAndEmployeeUrl(this.teamsForCountryUrl, levelId, month, year);
        return this.httpClient.get<Team[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getTeamsAndEmployeesForSvpTeam$(levelId: string, month: number, year: number): Observable<Team[]> {
        const url = this.buildTeamAndEmployeeUrl(this.teamsForSvpTeamUrl, levelId, month, year);
        return this.httpClient.get<Team[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getTeamsAndEmployeesForGroup$(levelId: string, month: number, year: number): Observable<Team[]> {
        const url = this.buildTeamAndEmployeeUrl(this.teamsForGroupUrl, levelId, month, year);
        return this.httpClient.get<Team[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    buildTeamAndEmployeeUrl(url: string, levelId: string, month: number, year: number): string {
        const urlWithParams = url + '/' + levelId + '/' + year + '/' + month;
        return urlWithParams;
    }

    getPayPlan(payPlanId: number, employeeMonthId: number): Observable<PayPlan> {
        const url = this.getPayPlanUrl + '/' + payPlanId + '/' + employeeMonthId;
        return this.httpClient.get<PayPlan>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }
    getEUPayPlan(payPlanId: number, employeeMonthId: number): Observable<EUPayPlanDto> {
        const url = this.getEUPayPlanUrl + '/' + payPlanId + '/' + employeeMonthId;
        return this.httpClient.get<EUPayPlanDto>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }


    updatePayPlan(payPlanDto: PayPlanSaveDto): Observable<boolean> {
        return this.httpClient.put<boolean>(this.updatePayPlanUrl, payPlanDto, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }
    updateEUPayPlan(payPlanDto: EUPayPlanDto): Observable<boolean> {

        return this.httpClient.put<boolean>(this.updateEUPayPlanUrl, payPlanDto, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    createPayPlan(payPlanDto: PayPlanSaveDto): Observable<boolean> {
        return this.httpClient.post<boolean>(this.createPayPlanUrl, payPlanDto, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }
    createEUPayPlan(payPlanDto: EUPayPlanDto): Observable<boolean> {
        return this.httpClient.post<boolean>(this.createEUPayPlanUrl, payPlanDto, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    lockPayPlan(month: number, year: number, updatedBy: string, ehiGroupIds: number[], isEurope: boolean): Observable<boolean> {
        const url = isEurope ? `${this.lockEUPayPlanUrl}/${month}/${year}/${updatedBy}` : `${this.lockPayPlanUrl}/${month}/${year}/${updatedBy}`;
        return this.httpClient.put<boolean>(url, ehiGroupIds, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    unlockPayPlan(month: number, year: number, updatedBy: string, ehiGroupIds: number[], isEurope: boolean): Observable<boolean> {
        const url = isEurope ? `${this.unlockEUPayPlanUrl}/${month}/${year}/${updatedBy}` : `${this.unlockPayPlanUrl}/${month}/${year}/${updatedBy}`;
        return this.httpClient.put<boolean>(url, ehiGroupIds, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }
}
