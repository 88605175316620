import { Component, Input, OnInit } from '@angular/core';
import { DashboardEmployeeOverview } from '../../models/incoming-dtos/dashboard-employee-overview';
import { GoalActual } from '../../models/incoming-dtos/goal-actual';
import { DashboardEmployeeOverviewService } from './dashboard-employee-overview.service';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { PayMemo } from '../../models/incoming-dtos/pay-memo';
import { Observable, Subscription } from 'rxjs';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { UserService } from '../../security/user.service';
import { SubscriptionService } from '../../shared/services/subscription.service';
import { DashboardService } from '../dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { PayMemoModalComponent } from './pay-memo-modal/pay-memo-modal.component';
import { User } from '../../security/user';
import { DashboardEmployeeExportService } from './dashboard-employee-export.service';
import { AppInsightsService } from '../../shared/services/app-insights.service';
import { SecuredObjectService } from '../../security/secured-object.service';
import { finalize } from 'rxjs/operators';
import { StyleHelperService } from '../../shared/services/style-helper.service';

@Component({
    selector: 'scoreboard-dashboard-employee-overview',
    templateUrl: './dashboard-employee-overview.component.html',
    styleUrls: ['./dashboard-employee-overview.component.scss']
})
export class DashboardEmployeeOverviewComponent implements OnInit {
    @Input() dashboardEmployeeOverviewData: DashboardEmployeeOverview[];
    @Input() levelValue: string;
    @Input() month: number;
    @Input() year: number;
    @Input() user: User;
    @Input() isEurope: boolean;

    selectedEId: string;
    goalPrefix = new Array<string>();
    goalSuffix = new Array<string>();
    payMemo = new PayMemo();
    payMemo$ = new Observable<PayMemo>();
    payMemoSubscription = new Subscription();

    dashboardSelectedEmployee = new DashboardEmployeeOverview();
    domWidth = document.body.offsetWidth;

    constructor(public dashboardEmpOverviewSvc: DashboardEmployeeOverviewService,
        public loadingSpinnerSvc: LoadingSpinnerService,
        public helperMethods: HelperMethodsService,
        public userService: UserService,
        public subscriptionSvc: SubscriptionService,
        private dashboardSvc: DashboardService,
        public dashboardEmployeeExportSvc: DashboardEmployeeExportService,
        private appInsightsSvc: AppInsightsService,
        public secObjectSvc: SecuredObjectService,
        public dialog: MatDialog,
        public styleHelper: StyleHelperService) {
        styleHelper.maxWidth = 1023;
    }

    ngOnInit() {
        if (!this.secObjectSvc.hasDashboardEmployeeFilterAccess()) {
            this.onEmployeeChanged(this.user.eId.replace('E', ''));
        }
    }

    onEmployeeChanged(selectedEmpId: string) {
        this.selectedEId = selectedEmpId;
        if (!this.helperMethods.isStringNullOrEmpty(this.selectedEId)) {
            this.filterOverviewData();
            this.checkNullForGoalValues();
        } else {
            this.dashboardEmployeeOverviewData = [];
        }
    }

    checkNullForGoalValues() {
        this.dashboardSelectedEmployee.goalActualDtos.forEach(goal => {
            const index = this.dashboardSelectedEmployee.goalActualDtos.indexOf(goal);
            if (goal.goalName === 'Order of Merit') {
                goal.goalValue = '';
                this.goalPrefix[index] = '';
                this.goalSuffix[index] = '';

            } else if (goal.goalValue === null) {
                goal.goalValue = '-';
                this.goalPrefix[index] = '';
                this.goalSuffix[index] = '';
            } else {
                this.goalPrefix[index] = goal.prefix;
                this.goalSuffix[index] = goal.suffix;
            }
        });
        if (this.dashboardSelectedEmployee.salesPaceGoal === null) {
            this.dashboardSelectedEmployee.salesPaceGoal = '-';
        }
    }

    getPaceArrow(): string {
        if (this.dashboardSelectedEmployee.salesPaceOnTrack) {
            return this.styleHelper.changeClass('col-3', 'col-1') + ' icon-arrow-up';
        } else {
            return this.styleHelper.changeClass('col-3', 'col-1') + ' icon-arrow-down';
        }
    }

    getSymbol(goalActual: GoalActual): string {
        if (goalActual.onTrack) {
            if (goalActual.goalName.includes('Reversal')) {
                if (Number(goalActual.goalValue) === goalActual.actualValue) {
                    return this.styleHelper.changeClass('col-2', 'col-1') + ' icon icon-arrow-up';
                }
                return this.styleHelper.changeClass('col-2', 'col-1') + ' icon icon-arrow-down';
            } else {
                return this.styleHelper.changeClass('col-2', 'col-1') + ' icon icon-arrow-up';
            }

        } else {
            if (goalActual.goalName.includes('Reversal')) {
                return this.styleHelper.changeClass('col-2', 'col-1') + ' icon icon-arrow-up';
            } else {
            return this.styleHelper.changeClass('col-2', 'col-1') + ' icon icon-arrow-down';
            }
        }
    }

    isReversedMetric(goalActual: GoalActual): boolean {
        return this.dashboardSvc.getAsterisk(goalActual);
    }

    filterOverviewData() {
        this.dashboardSelectedEmployee = this.dashboardEmpOverviewSvc.filterOverviewData(this.selectedEId,
            this.dashboardEmployeeOverviewData);
    }

    getPayMemoForSelectedEmployee() {
        this.payMemo$ = this.dashboardSvc.getPayMemoForSelectedEmployee(this.user.eId, this.year, this.month);
        this.subscribeToPayMemoData();
    }

    subscribeToPayMemoData() {
        this.payMemoSubscription.unsubscribe();
        this.payMemo = new PayMemo();
        this.loadingSpinnerSvc.turnSpinnerOn('Loading...');

        this.payMemoSubscription = this.payMemo$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                payMemo => {
                    if (payMemo === null) {
                        this.userService.alertUser('No pay memo data found.');
                        this.loadingSpinnerSvc.turnSpinnerOff();
                    } else {
                        this.payMemo = payMemo;
                        this.openPayMemoModal();
                    }
                },
                err => {
                    this.subscriptionSvc.onError(err, 'GetPayMemoForSelectedEmployee');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    openPayMemoModal() {
        this.dialog.open(PayMemoModalComponent, {
            width: '450px',
            data: { payMemo: this.payMemo, isEurope: this.isEurope }
        });
    }

    checkColumnSize(): string {
        return this.determineScreenSize();
    }

    determineScreenSize(): string {
        if (this.domWidth >= 1500) {
            return 'col-sm-2'; // Desktop view
        } else if (this.domWidth <= 1499 && this.domWidth >= 800) {
            return 'col-3'; // Laptop view
        } else {
            return 'col-5'; // Mobile view
        }
    }
}
