import { Component, Input, Output, EventEmitter, OnChanges, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import { GenericValidator } from '../../shared/validators/generic-validator';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { EuPayplanFormService } from './eupayplan-form.service';
import { User } from '../../security/user';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { EuPayplanFormExportService } from './eupayplan-form-export.service';
import { SpcTier } from '../../models/incoming-dtos/spc-tier';
import { SecuredObjectService } from '../../security/secured-object.service';
import { AppInsightsService } from '../../shared/services/app-insights.service';
import { SalesMilestone } from './../../models/incoming-dtos/sales-milestone';
import { BuyerTier } from './../../models/incoming-dtos/buyer-tier';
import { EUPayPlanDto } from './../../models/outgoing-dtos/eupayplan-dto';
import { GlobalizationService } from '../../shared/services/globalization.service';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
const FRA = 'FRA';
const ESP = 'ESP';
const fr = 'fr';
const es = 'es';
const en = 'en';

@Component({
    selector: 'scoreboard-eupayplan-form',
    templateUrl: './eupayplan-form.component.html',
    styleUrls: ['./eupayplan-form.component.scss']
})
export class EuPayplanFormComponent implements OnChanges, OnInit {
    @Input() code: string;
    @Input() payPlan: EUPayPlanDto;
    @Input() user: User;
    @Input() level: string;
    @Input() levelValue: string;
    @Input() month: number;
    @Input() year: number;

    @Output() onSavePayPlan: EventEmitter<EUPayPlanDto>;

    payPlanId = 1;
    pipe = new DatePipe('en_GB');
    payPlanDto = new EUPayPlanDto();
    isPayPlanLocked = false;
    saveButtonText = 'Save';
    totalAnnualPay: number;
    volumeCompensation: number;
    buyerAverage: number;
    effectiveDate: string;
    alertEffectiveDate: string;
    alertExpiryDate: string;
    alertTextEffectiveDate: string;
    alertTextExpiryDate: string;
    alertTargetVolume: string;
    alertTextTargetVolume: string;
    alertBasePay: string;
    alertTextBasePay: string;
    alertSalesManagerPay: string;
    alertSalesManagerPayFormat: string;
    isDirty: boolean;
    volumePayTeamOnly: boolean;
    volumePaySelfOnly: boolean;
    volumePayTeamSelf: boolean;
    volumePayNone: boolean;
    milestonePayTeamOnly: boolean;
    milestonePaySelfOnly: boolean;
    milestonePayTeamSelf: boolean;
    milestonePayNone: boolean;
    buyerAveragePayTeamOnly: boolean;
    buyerAveragePaySelfOnly: boolean;
    buyerAveragePayTeamSelf: boolean;
    buyerAveragePayNone: boolean;

    // Use with the generic validation message class
    displayMessage: { [key: string]: string } = {};
    validationMessages: { [key: string]: { [key: string]: string } };
    genericValidator: GenericValidator;
    currency: string;
    payPlanGrossSpcTiersSubscription = new Subscription();
    payPlanNetSpcTiersSubscription = new Subscription();
    payPlanGrossSpcTiers$ = new Observable<SpcTier[]>();
    payPlanNetSpcTiers$ = new Observable<SpcTier[]>();
    payPlanGrossSpcTiers = new Array<SpcTier>();
    payPlanNetSpcTiers = new Array<SpcTier>();
    payPlanForm: UntypedFormGroup;
    expiryDt: string;
    effectiveDt: string;
    currencySymbol: string;
    exportButtonTitle = 'EXPORT';

    constructor(private payplanFormSvc: EuPayplanFormService,
        private payPlanFormExportSvc: EuPayplanFormExportService,
        private loadingSpinnerSvc: LoadingSpinnerService,
        public secObjService: SecuredObjectService,
        public appInsights: AppInsightsService,
        private currencyService: GlobalizationService,
        private helperService: HelperMethodsService,
        private translate: TranslateService) {
        this.onSavePayPlan = new EventEmitter<EUPayPlanDto>();
    }

    ngOnInit() {
        this.currency = this.currencyService.getCountryCurrency(this.code);
        if (this.helperService.isArrayNullOrEmpty(this.payPlan.buyerTiersTeam)) {
            this.payPlan.buyerTiersTeam = new Array<BuyerTier>();
        }
        if (this.helperService.isArrayNullOrEmpty(this.payPlan.salesMilestonesTeam)) {
            this.payPlan.salesMilestonesTeam = new Array<SalesMilestone>();
        }
        if (this.helperService.isArrayNullOrEmpty(this.payPlan.buyerTiers)) {
            this.payPlan.buyerTiers = new Array<BuyerTier>();
        }
        if (this.helperService.isArrayNullOrEmpty(this.payPlan.salesMilestones)) {
            this.payPlan.salesMilestones = new Array<SalesMilestone>();
        }

        if (this.payPlan.employee.isManager) {
            this.SalesManagerPaySetUp();
        }
        this.setupTranslation(this.payPlan);
    }

    private setupTranslation(payPlan: EUPayPlanDto) {
        this.exportButtonTitle = 'TRANSLATING...';
        this.isDirty = true;
        if (payPlan.employee.country === FRA) {
          this.translate.use(fr);
          this.payPlanFormExportSvc.language = fr;
        } else if (payPlan.employee.country === ESP) {
          this.translate.use(es);
          this.payPlanFormExportSvc.language = es;
        } else {
          this.translate.use(en);
          this.payPlanFormExportSvc.language = en;
        }

        forkJoin(
            this.translate.get('payPlan'),
            this.translate.get('effectiveDate'),
            this.translate.get('baseSalary'),
            this.translate.get('percentageOfPay'),
            this.translate.get('targetedProfitBonus'),
            this.translate.get('percentageOfTargetGroupProfit'),
            this.translate.get('volumeBonus'),
            this.translate.get('buyerAverageBonus'),
            this.translate.get('targetVolume'),
            this.translate.get('targetBuyerAverage'),
            this.translate.get('payPerUnit'),
            this.translate.get('milestone'),
            this.translate.get('bonus'),
            this.translate.get('buyerAverage'),
            this.translate.get('targetedAnnualPay'),
            this.translate.get('employee'),
            this.translate.get('date'),
            this.translate.get('printName'),
            this.translate.get('approvedByFinancialController'),
            this.translate.get('approvedByGeneralManager'),
            this.translate.get('bonusPlanExpiresOn'),
            this.translate.get('disclaimer'),
            this.translate.get('disclaimer-discontinue'),
            this.translate.get('teamBuyerAverage'),
            this.translate.get('teamMilestone')
        ).pipe(
            map(([a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y]) => {
                this.payPlanFormExportSvc.txtPayplan = a.toString();
                this.payPlanFormExportSvc.txteffectiveDate = b.toString();
                this.payPlanFormExportSvc.txtbaseSalary = c.toString();
                this.payPlanFormExportSvc.txtpercentageOfPay = d.toString();
                this.payPlanFormExportSvc.txttargetedProfitBonus = e.toString();
                this.payPlanFormExportSvc.txtpercentageOfTargetGroupProfit = f.toString();
                this.payPlanFormExportSvc.txtvolumeBonus = g.toString();
                this.payPlanFormExportSvc.txtbuyerAverageBonus = h.toString();
                this.payPlanFormExportSvc.txttargetVolume = i.toString();
                this.payPlanFormExportSvc.txttargetBuyerAverage = j.toString();
                this.payPlanFormExportSvc.txtpayPerUnit = k.toString();
                this.payPlanFormExportSvc.txtmilestone = l.toString();
                this.payPlanFormExportSvc.txtbonus = m.toString();
                this.payPlanFormExportSvc.txtbuyerAverage = n.toString();
                this.payPlanFormExportSvc.txttargetedAnnualPay = o.toString();
                this.payPlanFormExportSvc.txtemployee = p.toString();
                this.payPlanFormExportSvc.txtdate = q.toString();
                this.payPlanFormExportSvc.txtprintName = r.toString();
                this.payPlanFormExportSvc.txtapprovedByFinancialController = s.toString();
                this.payPlanFormExportSvc.txtapprovedByGeneralManager = t.toString();
                this.payPlanFormExportSvc.txtbonusPlanExpiresOn = u.toString();
                this.payPlanFormExportSvc.txtdisclaimer = v.toString();
                this.payPlanFormExportSvc.txtdisclaimerDiscontinue = w.toString();
                this.payPlanFormExportSvc.txtteamBuyerAverage = x.toString();
                this.payPlanFormExportSvc.txtteamMilestone = y.toString();
                return { a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r, s, t, u, v, w, x, y };
            })
        ).subscribe(null, null, () => {
            this.exportButtonTitle = 'EXPORT';
            this.isDirty = false;
        });
    }

    InputChanged() {
        this.isDirty = true;
        this.resetAllAlerts();
        if (this.payPlan.salesVolumeTarget == null) {
            this.setAlertTargetVolume('Please enter a value for Target Volume');
        }
        if (this.payPlan.basePay == null) {
            this.setAlertBasePay('Please enter a value for Base Pay');
        }
        if (this.payPlan.effectiveDate == null) {
            this.setAlertEffectiveDate('Please enter a value for Effective Date');
        }
        if (this.payPlan.expiryDate == null) {
            this.setAlertExpiryDate('Please enter a value for Expiry Date');
        }
    }

    SalesManagerPaySetUp() {
        const valVolumePay = this.payPlan.volumePay;
        const valMilestonePay = this.payPlan.milestonePay;
        const valBuyerAveragePay = this.payPlan.buyerAveragePay;

        const TeamOnly = 'TeamOnly';
        const SelfOnly = 'SelfOnly';
        const TeamAndSelf = 'TeamAndSelf';
        const None = 'None';


        if (valVolumePay === TeamOnly) {
            this.volumePayTeamOnly = true;
            this.volumePaySelfOnly = false;
            this.volumePayTeamSelf = false;
            this.volumePayNone = false;
        } else if (valVolumePay === SelfOnly) {
            this.volumePayTeamOnly = false;
            this.volumePaySelfOnly = true;
            this.volumePayTeamSelf = false;
            this.volumePayNone = false;
        } else if (valVolumePay === TeamAndSelf) {
            this.volumePayTeamOnly = false;
            this.volumePaySelfOnly = false;
            this.volumePayTeamSelf = true;
            this.volumePayNone = false;
        } else if (valVolumePay === None) {
            this.volumePayTeamOnly = false;
            this.volumePaySelfOnly = false;
            this.volumePayTeamSelf = false;
            this.volumePayNone = true;
        }

        if (valMilestonePay === TeamOnly) {
            this.milestonePayTeamOnly = true;
            this.milestonePaySelfOnly = false;
            this.milestonePayTeamSelf = false;
            this.milestonePayNone = false;
        } else if (valMilestonePay === SelfOnly) {
            this.milestonePayTeamOnly = false;
            this.milestonePaySelfOnly = true;
            this.milestonePayTeamSelf = false;
            this.milestonePayNone = false;
        } else if (valMilestonePay === TeamAndSelf) {
            this.milestonePayTeamOnly = false;
            this.milestonePaySelfOnly = false;
            this.milestonePayTeamSelf = true;
            this.milestonePayNone = false;
        } else if (valMilestonePay === None) {
            this.milestonePayTeamOnly = false;
            this.milestonePaySelfOnly = false;
            this.milestonePayTeamSelf = false;
            this.milestonePayNone = true;
        }

        if (valBuyerAveragePay === TeamOnly) {
            this.buyerAveragePayTeamOnly = true;
            this.buyerAveragePaySelfOnly = false;
            this.buyerAveragePayTeamSelf = false;
            this.buyerAveragePayNone = false;
        } else if (valBuyerAveragePay === SelfOnly) {
            this.buyerAveragePayTeamOnly = false;
            this.buyerAveragePaySelfOnly = true;
            this.buyerAveragePayTeamSelf = false;
            this.buyerAveragePayNone = false;
        } else if (valBuyerAveragePay === TeamAndSelf) {
            this.buyerAveragePayTeamOnly = false;
            this.buyerAveragePaySelfOnly = false;
            this.buyerAveragePayTeamSelf = true;
            this.buyerAveragePayNone = false;
        } else if (valBuyerAveragePay === None) {
            this.buyerAveragePayTeamOnly = false;
            this.buyerAveragePaySelfOnly = false;
            this.buyerAveragePayTeamSelf = false;
            this.buyerAveragePayNone = true;
        }



    }

    RadioButtonChange(event) {
        const elementID = event.srcElement.id;
        const TeamOnly = 'TeamOnly';
        const SelfOnly = 'SelfOnly';
        const TeamAndSelf = 'TeamAndSelf';
        const None = 'None';

        this.isDirty = true;
        if (elementID === 'volumePayTeamOnly') {
            this.volumePayTeamOnly = true;
            this.volumePaySelfOnly = false;
            this.volumePayTeamSelf = false;
            this.volumePayNone = false;
            this.payPlan.volumePay = TeamOnly;
        } else if (elementID === 'volumePaySelfOnly') {
            this.volumePayTeamOnly = false;
            this.volumePaySelfOnly = true;
            this.volumePayTeamSelf = false;
            this.volumePayNone = false;
            this.payPlan.volumePay = SelfOnly;
        } else if (elementID === 'volumePayTeamSelf') {
            this.volumePayTeamOnly = false;
            this.volumePaySelfOnly = false;
            this.volumePayTeamSelf = true;
            this.volumePayNone = false;
            this.payPlan.volumePay = TeamAndSelf;
        } else if (elementID === 'volumePayNone') {
            this.volumePayTeamOnly = false;
            this.volumePaySelfOnly = false;
            this.volumePayTeamSelf = false;
            this.volumePayNone = true;
            this.payPlan.volumePay = None;
        } else if (elementID === 'milestonePayTeamOnly') {
            this.milestonePayTeamOnly = true;
            this.milestonePaySelfOnly = false;
            this.milestonePayTeamSelf = false;
            this.milestonePayNone = false;
            this.payPlan.milestonePay = TeamOnly;
        } else if (elementID === 'milestonePaySelfOnly') {
            this.milestonePayTeamOnly = false;
            this.milestonePaySelfOnly = true;
            this.milestonePayTeamSelf = false;
            this.milestonePayNone = false;
            this.payPlan.milestonePay = SelfOnly;
        } else if (elementID === 'milestonePayTeamSelf') {
            this.milestonePayTeamOnly = false;
            this.milestonePaySelfOnly = false;
            this.milestonePayTeamSelf = true;
            this.milestonePayNone = false;
            this.payPlan.milestonePay = TeamAndSelf;
        } else if (elementID === 'milestonePayNone') {
            this.milestonePayTeamOnly = false;
            this.milestonePaySelfOnly = false;
            this.milestonePayTeamSelf = false;
            this.milestonePayNone = true;
            this.payPlan.milestonePay = None;
        } else if (elementID === 'buyerAveragePayTeamOnly') {
            this.buyerAveragePayTeamOnly = true;
            this.buyerAveragePaySelfOnly = false;
            this.buyerAveragePayTeamSelf = false;
            this.buyerAveragePayNone = false;
            this.payPlan.buyerAveragePay = TeamOnly;
        } else if (elementID === 'buyerAveragePaySelfOnly') {
            this.buyerAveragePayTeamOnly = false;
            this.buyerAveragePaySelfOnly = true;
            this.buyerAveragePayTeamSelf = false;
            this.buyerAveragePayNone = false;
            this.payPlan.buyerAveragePay = SelfOnly;
        } else if (elementID === 'buyerAveragePayTeamSelf') {
            this.buyerAveragePayTeamOnly = false;
            this.buyerAveragePaySelfOnly = false;
            this.buyerAveragePayTeamSelf = true;
            this.buyerAveragePayNone = false;
            this.payPlan.buyerAveragePay = TeamAndSelf;
        } else if (elementID === 'buyerAveragePayNone') {
            this.buyerAveragePayTeamOnly = false;
            this.buyerAveragePaySelfOnly = false;
            this.buyerAveragePayTeamSelf = false;
            this.buyerAveragePayNone = true;
            this.payPlan.buyerAveragePay = None;

        }
    }

    SetBuyerAverageForPayPlan(buyerAveragePay: string) {
        const TeamOnly = 'TeamOnly';
        const SelfOnly = 'SelfOnly';
        const None = 'None';

        if (buyerAveragePay === SelfOnly) {
            this.payPlan.buyerTiersTeam = [];
            this.payPlan.buyerAverageTargetTeam = 0;
        } else if (buyerAveragePay === TeamOnly) {
            this.payPlan.buyerTiers = [];
            this.payPlan.buyerAverageTarget = 0;
        } else if (buyerAveragePay === None) {
            this.payPlan.buyerTiers = [];
            this.payPlan.buyerTiersTeam = [];
            this.payPlan.buyerAverageTarget = 0;
            this.payPlan.buyerAverageTargetTeam = 0;
        }
    }

    SetMilestonAverageForPayPlan(salesMilestone: string) {
        const TeamOnly = 'TeamOnly';
        const SelfOnly = 'SelfOnly';
        const None = 'None';

        if (salesMilestone === SelfOnly) {
            this.payPlan.salesMilestonesTeam = [];
            this.payPlan.salesVolumeTargetTeam = 0;
        } else if (salesMilestone === TeamOnly) {
            this.payPlan.salesMilestones = [];
            this.payPlan.salesVolumeTarget = 0;
        } else if (salesMilestone === None) {
            this.payPlan.salesMilestonesTeam = [];
            this.payPlan.salesMilestones = [];
            this.payPlan.salesVolumeTargetTeam = 0;
            this.payPlan.salesVolumeTarget = 0;
        }
    }



    CurrencyInputChanged(val) {
        const num = val.replace(/[£,€$]/g, '');
        const result = Number(num);
        if (isNaN(result)) {
            return 0;
        }
        return result;
    }

    IsDirty(isDirty: boolean) {
        this.isDirty = isDirty;
    }

    ngOnChanges() {
        const datePipe = new DatePipe('en-GB');
        this.checkIfPayPlanLocked();
        this.effectiveDt = datePipe.transform(this.payPlan.effectiveDate, 'yyyy-MM-dd');
        this.expiryDt = datePipe.transform(this.payPlan.expiryDate, 'yyyy-MM-dd');
    }

    checkIfPayPlanLocked() {
        if (this.payPlan.locked) {
            this.isPayPlanLocked = true;
            this.saveButtonText = 'Locked';
        } else {
            this.isPayPlanLocked = false;
            this.saveButtonText = 'Save';
        }
    }

    ConvertDateToUTC(dt: Date) {
        return new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), 10, 0, 0);
    }

    onSaveEuPayPlan() {

        this.payPlan.effectiveDate = this.ConvertDateToUTC(new Date(this.effectiveDt));
        this.payPlan.expiryDate = this.ConvertDateToUTC(new Date(this.expiryDt));

        const lastDayOfMonth = new Date(this.payPlan.expiryDate.getUTCFullYear(), this.payPlan.expiryDate.getUTCMonth() + 1, 0, 10, 0, 0);

        const daysDiff = (+this.payPlan.expiryDate - +this.payPlan.effectiveDate) / 1000 / 60 / 60 / 24;

        if (this.payPlan.effectiveDate.getDate() !== 1) {
            this.setAlertEffectiveDate('Effective date needs to be 1st of the month');
        } else if (this.payPlan.expiryDate.getDate() !== lastDayOfMonth.getDate()) {
            this.setAlertExpiryDate('Expiry date must end on last day of month');
        } else if (this.payPlan.expiryDate <= this.payPlan.effectiveDate) {
            this.setAlertExpiryDate('Expiry date must be greater than effective date');
            this.setAlertEffectiveDate('Expiry date must be greater than effective date');
        } else if (daysDiff >= 366) {
            this.setAlertExpiryDate('Period between effective date and expiry date should not be longer than 12 months ');
            this.setAlertEffectiveDate('Period between effective date and expiry date should not be longer than 12 months ');
        } else if (this.payPlan.salesVolumeTarget == null) {
            this.setAlertTargetVolume('Please enter a value for Target Volume');
        } else if (this.payPlan.basePay == null) {
            this.setAlertBasePay('Please enter a value for Base Pay');
        } else if ((this.payPlan.employee.isManager) && (this.payPlan.buyerAveragePay == null || this.payPlan.milestonePay == null || this.payPlan.volumePay == null)) {
            this.setAlertSalesManagerPay('Please set values for [Buyer Average Pay], [Milestone Pay] and [Volume Pay]');
        } else {

            this.loadingSpinnerSvc.turnSpinnerOn('Saving...');
            if (this.payPlan.id === 0) {
                this.payPlan.createdBy = this.user.eId;
            } else {
                this.payPlan.updatedBy = this.user.eId;
            }
            this.SetBuyerAverageForPayPlan(this.payPlan.buyerAveragePay);
            this.SetMilestonAverageForPayPlan(this.payPlan.milestonePay);
            this.onSavePayPlan.emit(this.payPlan);
            this.isDirty = false;
        }
    }

    toDate(dateStr: string): Date {
        const [year, month, day]: any = dateStr.split('-');
        return new Date(year, month - 1, day);
    }



    onExportEUPayPlan() {
        const currencySymbols = {
            'USD': '$',
            'EUR': '€',
            'GBP': '£'
        };
        this.currencySymbol = currencySymbols[this.currency];
        this.payPlanFormExportSvc.exportPayPlan(this.payPlan, this.volumeCompensation, this.buyerAverage, this.totalAnnualPay, this.currencySymbol);
        this.loadingSpinnerSvc.turnSpinnerOff();

        this.appInsights.logUserAction('User exported pay plan for ' + this.payPlan.employee.firstName + ' ' + this.payPlan.employee.lastName +
            ' for ' + this.level + ': ' + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
    }


    updatedPayPlanMilestone(updatedMilestone: Array<SalesMilestone>) {
        this.payPlan.salesMilestones = updatedMilestone;
        this.isDirty = true;
    }
    updatedPayPlanMilestoneTeam(updatedMilestoneTeam: Array<SalesMilestone>) {
        this.payPlan.salesMilestonesTeam = updatedMilestoneTeam;
        this.isDirty = true;
    }
    updatePayPlanBuyerTier(updatedBuyerTier: Array<BuyerTier>) {
        this.payPlan.buyerTiers = updatedBuyerTier;
        this.isDirty = true;
    }
    updatePayPlanBuyerTierTeam(updatedBuyerTierTeam: Array<BuyerTier>) {
        this.payPlan.buyerTiersTeam = updatedBuyerTierTeam;
        this.isDirty = true;
    }
    updatePayPlanBuyerAverageTimePeriod(timeperiod: string) {
        this.payPlan.buyerAveragePayTimePeriod = timeperiod;
        this.isDirty = true;
    }
    updatePayPlanBuyerAverageTimePeriodTeam(timeperiodTeam: string) {
        this.payPlan.buyerAveragePayTimePeriodTeam = timeperiodTeam;
        this.isDirty = true;
    }

    getVolumeCompensation(payPlan: EUPayPlanDto) {
        const TeamOnly = 'TeamOnly';
        const SelfOnly = 'SelfOnly';
        const TeamAndSelf = 'TeamAndSelf';

        if (payPlan.employee.isManager) {
            if (payPlan.milestonePay === TeamOnly) {
                // tslint:disable-next-line:max-line-length
                this.volumeCompensation = this.payplanFormSvc.getVolumeCompensation(payPlan.salesVolumePayPerUnit, null, this.helperService.isArrayNullOrEmpty(payPlan.salesMilestonesTeam) ? [] : payPlan.salesMilestonesTeam, 0, payPlan.salesVolumeTargetTeam);
            } else if (payPlan.milestonePay === SelfOnly) {
                this.volumeCompensation = this.payplanFormSvc.getVolumeCompensation(payPlan.salesVolumePayPerUnit, this.helperService.isArrayNullOrEmpty(payPlan.salesMilestones) ? [] : payPlan.salesMilestones, null, payPlan.salesVolumeTarget, 0);
            } else if (payPlan.milestonePay === TeamAndSelf) {
                // tslint:disable-next-line:max-line-length
                this.volumeCompensation = this.payplanFormSvc.getVolumeCompensation(payPlan.salesVolumePayPerUnit, this.helperService.isArrayNullOrEmpty(payPlan.salesMilestones) ? [] : payPlan.salesMilestones, this.helperService.isArrayNullOrEmpty(payPlan.salesMilestonesTeam) ? [] : payPlan.salesMilestonesTeam, payPlan.salesVolumeTarget, payPlan.salesVolumeTargetTeam);
            } else {
                this.volumeCompensation = this.payplanFormSvc.getVolumeCompensation(payPlan.salesVolumePayPerUnit, this.helperService.isArrayNullOrEmpty(payPlan.salesMilestones) ? [] : payPlan.salesMilestones, null, payPlan.salesVolumeTarget, 0);
            }
        } else {
            this.volumeCompensation = this.payplanFormSvc.getVolumeCompensation(payPlan.salesVolumePayPerUnit, this.helperService.isArrayNullOrEmpty(payPlan.salesMilestones) ? [] : payPlan.salesMilestones, null, payPlan.salesVolumeTarget, 0);
        }

        return this.volumeCompensation;
    }



    getBuyerAverage(payPlan: EUPayPlanDto) {
        const TeamOnly = 'TeamOnly';
        const SelfOnly = 'SelfOnly';
        const TeamAndSelf = 'TeamAndSelf';

        if (payPlan.employee.isManager) {
            if (payPlan.buyerAveragePay === TeamOnly) {
                this.buyerAverage = this.payplanFormSvc.getBuyerAverage(null, this.helperService.isArrayNullOrEmpty(payPlan.buyerTiersTeam) ? [] : payPlan.buyerTiersTeam, null, payPlan.buyerAverageTargetTeam);
            } else if (payPlan.buyerAveragePay === SelfOnly) {
                this.buyerAverage = this.payplanFormSvc.getBuyerAverage(this.helperService.isArrayNullOrEmpty(payPlan.buyerTiers) ? [] : payPlan.buyerTiers, null, payPlan.buyerAverageTarget, null);
            } else if (payPlan.buyerAveragePay === TeamAndSelf) {
                // tslint:disable-next-line:max-line-length
                this.buyerAverage = this.payplanFormSvc.getBuyerAverage(this.helperService.isArrayNullOrEmpty(payPlan.buyerTiers) ? [] : payPlan.buyerTiers, this.helperService.isArrayNullOrEmpty(payPlan.buyerTiersTeam) ? [] : payPlan.buyerTiersTeam, payPlan.buyerAverageTarget, payPlan.buyerAverageTargetTeam);
            } else {
                this.buyerAverage = this.payplanFormSvc.getBuyerAverage(this.helperService.isArrayNullOrEmpty(payPlan.buyerTiers) ? [] : payPlan.buyerTiers, null, payPlan.buyerAverageTarget, null);
            }
        } else {
            this.buyerAverage = this.payplanFormSvc.getBuyerAverage(this.helperService.isArrayNullOrEmpty(payPlan.buyerTiers) ? [] : payPlan.buyerTiers, null, payPlan.buyerAverageTarget, null);
        }

        return this.buyerAverage;
    }

    getTotalPay(payPlan: EUPayPlanDto) {
        this.totalAnnualPay = this.payplanFormSvc.getTotalProjectedPay(payPlan);
        return this.totalAnnualPay;
    }

    getEffectiveDate(payplan: EUPayPlanDto) {
        this.effectiveDate = this.payPlanFormExportSvc.getEffectiveDate(payplan.updatedTime);
        return this.effectiveDate;
    }

    resetAlertEffectiveDate() {
        this.alertEffectiveDate = '';
        this.alertTextEffectiveDate = '';
    }
    resetAllAlerts() {
        this.resetAlertEffectiveDate();
        this.resetAlertExpiryDate();
        this.resetAlertBasePay();
        this.resetAlertTargetVolume();
        this.resetAlertSalesManagerPay();
    }

    setAlertEffectiveDate(alertxt: string) {
        this.alertEffectiveDate = 'alert alert-danger';
        this.alertTextEffectiveDate = alertxt;
    }
    setAlertTargetVolume(alerttxt: string) {
        this.alertTargetVolume = 'alert alert-danger';
        this.alertTextTargetVolume = alerttxt;
    }
    setAlertBasePay(alerttxt: string) {
        this.alertBasePay = 'alert alert-danger';
        this.alertTextBasePay = alerttxt;
    }
    setAlertSalesManagerPay(alerttxt: string) {
        this.alertSalesManagerPay = alerttxt;
        this.alertSalesManagerPayFormat = 'alert alert-danger';
    }
    resetAlertSalesManagerPay() {
        this.alertSalesManagerPay = '';
        this.alertSalesManagerPayFormat = '';
    }

    resetAlertTargetVolume() {
        this.alertTargetVolume = '';
        this.alertTextTargetVolume = '';
    }
    resetAlertBasePay() {
        this.alertBasePay = '';
        this.alertTextBasePay = '';
    }
    resetAlertExpiryDate() {
        this.alertExpiryDate = '';
        this.alertTextExpiryDate = '';
    }

    setAlertExpiryDate(alertxt: string) {
        this.alertExpiryDate = 'alert alert-danger';
        this.alertTextExpiryDate = alertxt;
    }



}
