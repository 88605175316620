import { NgModule } from '@angular/core';

// All Angular Material components are being imported here and allows any component in this application to use them by importing the shared module.

import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { MatInputModule } from '@angular/material/input';

import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatFormFieldModule } from '@angular/material/form-field';

import { MatRadioModule } from '@angular/material/radio';

import { MatSelectModule } from '@angular/material/select';

import { MatMenuModule } from '@angular/material/menu';

import { MatToolbarModule } from '@angular/material/toolbar'

import { MatSidenavModule } from '@angular/material/sidenav'

import { MatCardModule } from '@angular/material/card'

import { MatDividerModule } from '@angular/material/divider'

import { MatExpansionModule } from '@angular/material/expansion'

import { MatListModule } from '@angular/material/list'

import { MatTabsModule } from '@angular/material/tabs'

import { MatButtonModule } from '@angular/material/button'

import { MatIconModule } from '@angular/material/icon'

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'

import { MatDialogModule } from '@angular/material/dialog'

import { MatSnackBarModule } from '@angular/material/snack-bar'

import { MatTooltipModule } from '@angular/material/tooltip'

import { MatSortModule } from '@angular/material/sort'

import { MatTableModule } from '@angular/material/table'


@NgModule({
  imports: [
    MatAutocompleteModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatListModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSortModule,
    MatTableModule
  ],
  exports: [
    MatAutocompleteModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatCardModule,
    MatDividerModule,
    MatExpansionModule,
    MatListModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatSortModule,
    MatTableModule
  ]
})
export class MaterialModule { }
