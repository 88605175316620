import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Team } from '../../models/incoming-dtos/team';
import { Employee } from '../../models/incoming-dtos/employee';
import { TeamAssignmentService } from '../team-assignment.service';
import { Observable, Subscription } from 'rxjs';
import { User } from '../../security/user';
import { TeamDetailsService } from './team-details.service';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { SubscriptionService } from '../../shared/services/subscription.service';
import { UserService } from '../../security/user.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { SnackBarService } from '../../shared/services/snack-bar.service';
import { Group } from '../../models/incoming-dtos/group';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { environment } from '../../../environments/environment';
import { AppInsightsService } from '../../shared/services/app-insights.service';
import { finalize } from 'rxjs/operators';
import { DecisionModalService } from '../../shared/decision-modal/decision-modal.service';

@Component({
  selector: 'scoreboard-team-details',
  templateUrl: './team-details.component.html',
  styleUrls: ['./team-details.component.scss']
})
export class TeamDetailsComponent implements OnInit, OnChanges {
    @Input() team: Team;
    @Input() selectedTeamId: number;
    @Input() selectedGroupName: string;
    @Input() user: User;
    @Input() month: number;
    @Input() year: number;
    @Input() groups: Group[];

    managers: Employee[];
    employees: Employee[];
    teamName: string;

    selectedEmployee = new Array<boolean>();
    selectedManager: Employee;
    selectedManagerId: number;

    teamObs$: Observable<any>;
    teamDetailsSubscription = new Subscription();

    isDragging: boolean;
    isSaveDeleteClickable = false;
    unassignedManager = {
        id: null,
        selected: false
    } as Employee;

    teamNameFormControl = new UntypedFormControl('', [
        Validators.required
    ]);

    @Output() teamIdChanged: EventEmitter<[number, number]>;

    constructor(private teamAssignmentSvc: TeamAssignmentService,
                private teamDetailsSvc: TeamDetailsService,
                private helperMethods: HelperMethodsService,
                private subscribeService: SubscriptionService,
                private userService: UserService,
                public snackBarSvc: SnackBarService,
                public loadingSpinnerSvc: LoadingSpinnerService,
                public decisionSvc: DecisionModalService,
                public appInsights: AppInsightsService) {
                this.teamIdChanged = new EventEmitter<[number, number]>();
    }

    ngOnInit() {
        this.setManagers();
        this.setEmployees();
        this.teamName = this.team.name;
    }

    ngOnChanges() {
        this.isSaveDeleteClickable = this.helperMethods.isPastMonthYear(this.month, this.year);
    }

    setManagers() {
        this.managers = this.team.managers;
        this.selectedManager = this.unassignedManager;
        this.managers.forEach(manager => {
            if (manager.selected) {
                this.selectedManager = manager;
            }
        });
    }

    setEmployees() {
        this.employees = this.team.employees;
        this.employees.forEach(employee => {
            const i = this.employees.indexOf(employee);
            if (employee.selected) {
                this.selectedEmployee[i] = true;
            } else {
                this.selectedEmployee[i] = false;
            }
        });
    }

    onSave() {
        this.teamName = this.teamName.trim();
        const teamNameValidationCode = this.teamDetailsSvc.validateTeamName(this.teamName, this.groups, this.selectedGroupName, this.team.name);
        switch (teamNameValidationCode) {
            case 0:
                this.userService.alertUser('Team name already exists for group ' + this.selectedGroupName + '.');
                break;
            case 1:
                this.userService.alertUser('You must enter a team name in order to save it.');
                break;
            case 2:
                this.userService.alertUser('Invalid character in team name.');
                break;
            case 3:
                if (this.team.id === 0) {
                    this.createNewTeam();
                } else {
                    this.updateTeamDetails();
                }
                break;
        }
    }

    hasSaveSecObject(): boolean {
        if (this.user && !this.helperMethods.isArrayNullOrEmpty(this.user.allSecuredObjects)) {
            return this.user.allSecuredObjects.some(so => so.secObjectName === environment.securedObjects.btnTeamSetupSave);
        }
        return false;
      }

    updateTeamDetails() {
        this.loadingSpinnerSvc.turnSpinnerOn('Saving...');
        const selectedEmployees = this.teamDetailsSvc.getSelectedEmployees(this.employees);
        if (this.selectedManager.id === null) {
            this.teamObs$ = this.teamAssignmentSvc.updateTeamDetails(this.teamName, null, this.selectedGroupName, this.user.eId, selectedEmployees, this.month, this.year, this.team.id);
        } else {
            this.teamObs$ = this.teamAssignmentSvc.updateTeamDetails(this.teamName, this.selectedManager, this.selectedGroupName, this.user.eId, selectedEmployees, this.month, this.year, this.team.id);
        }
        this.subscribeToUpdatedTeam();
    }

    createNewTeam() {
        this.loadingSpinnerSvc.turnSpinnerOn('Saving...');
        const selectedEmployees = this.teamDetailsSvc.getSelectedEmployees(this.employees);
        if (this.selectedManager.id === null) {
            this.teamObs$ = this.teamAssignmentSvc.createNewTeam(this.teamName, null, this.selectedGroupName, this.user.eId, selectedEmployees, this.month, this.year);
        } else {
            this.teamObs$ = this.teamAssignmentSvc.createNewTeam(this.teamName, this.selectedManager, this.selectedGroupName, this.user.eId, selectedEmployees, this.month, this.year);
        }
        this.subscribeToNewTeam();
    }

    onDelete() {
        const ask$ = this.decisionSvc.askUser('All pay plans for employees on this team will be lost as well. Do you want to continue?', 'auto');
        ask$.pipe(finalize(() => {
        })).subscribe(result => {
            if (result) {
                this.loadingSpinnerSvc.turnSpinnerOn('Deleting...');
                this.teamObs$ = this.teamAssignmentSvc.deleteTeam(this.team.id, this.user.eId);
                this.subscribeToDeleteTeam();
            }
        });
    }

    subscribeToNewTeam() {
        this.teamDetailsSubscription.unsubscribe();
        this.isSaveDeleteClickable = false;
        this.teamDetailsSubscription = this.teamObs$
            .pipe(
                finalize(() => {
                    this.isSaveDeleteClickable = true;
                })
            )
            .subscribe(
                newTeam => {
                    this.teamIdChanged.emit([newTeam, 1]);
                    if (newTeam > 0) {
                        this.snackBarSvc.openSnackBar('Team ' + this.teamName + ' was saved successfully.');
                        this.appInsights.logUserAction('User created team ' + this.teamName + ' on group ' + this.selectedGroupName + ' on ' + this.month + '/' + this.year, this.user);
                    }
                },
                err => {
                    this.subscribeService.onError(err, 'createNewTeam');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToUpdatedTeam() {
        this.teamDetailsSubscription.unsubscribe();
        this.isSaveDeleteClickable = false;
        this.teamDetailsSubscription = this.teamObs$
            .pipe(
                finalize(() => {
                    this.isSaveDeleteClickable = true;
                })
            )
            .subscribe(
                updatedTeam => {
                    this.teamIdChanged.emit([updatedTeam, 2]);
                    this.snackBarSvc.openSnackBar('Team ' + this.teamName + ' was updated successfully.');
                    this.appInsights.logUserAction('User updated team ' + this.teamName + ' on group ' + this.selectedGroupName + ' on ' + this.month + '/' + this.year, this.user);
                },
                err => {
                    this.subscribeService.onError(err, this.team.id.toString(), 'updateTeamDetails');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToDeleteTeam() {
        this.teamDetailsSubscription.unsubscribe();
        this.isSaveDeleteClickable = false;
        this.teamDetailsSubscription = this.teamObs$
            .pipe(
                finalize(() => {
                    this.isSaveDeleteClickable = true;
                })
            )
            .subscribe(
                object => {
                    this.teamIdChanged.emit([0, 0]);
                    this.snackBarSvc.openSnackBar('Team ' + this.teamName + ' was deleted successfully.');
                    this.appInsights.logUserAction('User deleted team ' + this.teamName + ' on group ' + this.selectedGroupName + ' on ' + this.month + '/' + this.year, this.user);
                },
                err => {
                    this.subscribeService.onError(err, this.team.id.toString(), 'deleteTeam');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    onSelectManager(manager: Employee) {
        manager.selected = !manager.selected;
        this.managers.forEach(mgr => {
            if (mgr !== manager) {
                mgr.selected = false;
            }
        });
    }

    onSelectEmployee(employee: Employee) {
        employee.selected = !employee.selected;
    }
}
