import { Employee } from '../incoming-dtos/employee';
import { SalesMilestone } from '../incoming-dtos/sales-milestone';
import { BuyerTier } from '../incoming-dtos/buyer-tier';

export class EUPayPlanDto {
    id: number;
    basePay: number;
    profitBonusAmount: number;
    totalGroupProfit: number;
    salesVolumeTarget: number;
    salesVolumePayPerUnit: number;
    employeeMonthId: number;
    locked: boolean;
    employee: Employee;
    salesMilestones: SalesMilestone[];
    buyerTiers: BuyerTier[];
    buyerAverageTarget: number;
    updatedBy: string;
    createdBy: string;
    updatedTime: Date;
    expiryDate: Date;
    effectiveDate: Date;
    volumePay: string;
    milestonePay: string;
    buyerAveragePay: string;
    buyerAveragePayTimePeriod: string;
    buyerAveragePayTimePeriodTeam: string;
    buyerAverageTargetTeam: number;
    salesVolumeTargetTeam: number;
    salesMilestonesTeam: SalesMilestone[];
    buyerTiersTeam: BuyerTier[];

}
