import { Component, OnInit, Inject, Input } from '@angular/core';
import { PayMemo } from '../../../models/incoming-dtos/pay-memo';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'scoreboard-pay-memo-modal',
  templateUrl: './pay-memo-modal.component.html',
  styleUrls: ['./pay-memo-modal.component.scss']
})
export class PayMemoModalComponent implements OnInit {
  isEurope: boolean;
  payMemo: PayMemo;
  dataSource = new MatTableDataSource();

  constructor(public dialogRef: MatDialogRef<PayMemoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    // Multiplying by 100 to match pay memo page and dashboard data.
    this.data.payMemo.agedPercentageActual = this.data.payMemo.agedPercentageActual * 100;
    this.data.payMemo.agedPercentageTarget = this.data.payMemo.agedPercentageTarget * 100;
    this.data.payMemo.localBoostPercentActual = this.data.payMemo.localBoostPercentActual * 100;
    this.data.payMemo.localBoostPercentTarget = this.data.payMemo.localBoostPercentTarget * 100;
    this.payMemo = this.data.payMemo;
    this.isEurope = this.data.isEurope;
  }

  onClose() {
    this.dialogRef.close();
  }
}
