import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { GoalAssignmentComponent } from './goal-assignment.component';
import { GoalAssignmentRouteActivator } from './goal-assignment-route-activator';
import { EmployeeGoalComponent } from './employee-goal/employee-goal.component';
import { GoalAssignmentService } from './goal-assignment.service';
import { EmployeeGoalService } from './employee-goal/employee-goal.service';
import { GroupPreferencesComponent } from './group-preferences/group-preferences.component';
import { GroupPreferencesService } from './group-preferences/group-preferences.service';
import { TeamGoalComponent } from './team-goal/team-goal.component';
import { TeamGoalService } from './team-goal/team-goal.service';
import { GroupGoalComponent } from './group-goal/group-goal.component';
import { GroupGoalService } from './group-goal/group-goal.service';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild([
            { path: 'goal-assignment', component: GoalAssignmentComponent, canActivate: [GoalAssignmentRouteActivator] }
        ])
    ],
    declarations: [
        GoalAssignmentComponent,
        EmployeeGoalComponent,
        GroupPreferencesComponent,
        TeamGoalComponent,
        GroupGoalComponent
    ],
    providers: [
        GoalAssignmentRouteActivator,
        GoalAssignmentService,
        EmployeeGoalService,
        GroupPreferencesService,
        TeamGoalService,
        GroupGoalService
    ]
})
export class GoalAssignmentModule { }
