import { Injectable } from '@angular/core';
import { EmployeeGoalSave } from './employee-goal-save';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { Employee } from '../../models/incoming-dtos/employee';
import { EmployeeGoal } from '../../models/incoming-dtos/employee-goal';

@Injectable()
export class EmployeeGoalService {

    constructor(public helperMethods: HelperMethodsService) {}

    checkIds(employeesForSave: EmployeeGoalSave[], employeeId: number, goalId: number): EmployeeGoalSave[] {
        let updatedEmployeesForSave = new Array<EmployeeGoalSave>();
        const empIds = [];
        employeesForSave.forEach(employee => {
            empIds.push(employee.employeeId);
        });


        if (empIds.includes(employeeId)) {
            const index = empIds.indexOf(employeeId);
            if (employeesForSave[index].employeeGoalIds.includes(goalId)) {
                updatedEmployeesForSave = employeesForSave;
                // Return unchagned list if user changes the same value more than once without clicking save.
            } else {
                employeesForSave[index].employeeGoalIds.push(goalId);
                updatedEmployeesForSave = employeesForSave;
                // Adds a goal record to an employee object if this is the first time the user changed the default value.
            }
        } else {
            const newIds = new EmployeeGoalSave();
            // Adds an employee that hasn't been recorded yet if the user changes a goal value for the first time in the page.
            newIds.employeeId = employeeId;
            newIds.employeeGoalIds = [goalId];
            employeesForSave.push(newIds);
            updatedEmployeesForSave = employeesForSave;
        }

        return updatedEmployeesForSave;
    }

    getGoals(employeeGoals: Employee[], employeesForSave: EmployeeGoalSave[]): EmployeeGoal[] {
        const goalsForSave = new Array<EmployeeGoal>();
        const empIds = [];
        employeesForSave.forEach(employee => {
            empIds.push(employee.employeeId);
        });

        employeeGoals.forEach(employee => {
            if (empIds.includes(employee.id)) {
                let goalIds = [];
                const index = empIds.indexOf(employee.id);
                goalIds = employeesForSave[index].employeeGoalIds;
                employee.employeeGoals.forEach(goal => {
                    if (goalIds.includes(goal.goalId)) {
                        goalsForSave.push(goal);
                    }
                });
            }
        });

        return goalsForSave;
    }
}
