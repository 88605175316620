import { Component } from '@angular/core';

@Component({
  selector: 'scoreboard-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {

  constructor() { }

}
