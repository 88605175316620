import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerDetectionService } from './spinner-detection.service';

@Component({
  selector: 'scoreboard-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
    isLoading: boolean;
    message: string;

    constructor(public dialogRef: MatDialogRef<LoadingSpinnerComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                public spinnerDetectSvc: SpinnerDetectionService) { }

        ngOnInit() {
            this.message = this.data;
            this.spinnerDetectSvc.isLoading$.subscribe(isLoading => {
                this.isLoading = isLoading;
                if (!this.isLoading) {
                    this.onDoneLoading();
                }
            });
        }

        onDoneLoading() {
            this.dialogRef.close();
        }
}
