import { Injectable } from '@angular/core';
import { XlsxExportService } from '../shared/xlsx-export/xlsx-export.service';
import { PaidTimeOff } from '../models/incoming-dtos/paid-time-off';
import { PaidTimeOffExport } from './paid-timeoff-export';

@Injectable()
export class PaidTimeOffExportService {

    constructor(private xlsxExportService: XlsxExportService) {
    }

    generateExport(exportData: PaidTimeOff[], levelValue: string, month: number, year: number) {
        const paidTimeOffForExport = [];
        for (let i = 0; i < exportData.length; i ++) {
            paidTimeOffForExport[i] = new PaidTimeOffExport();
            paidTimeOffForExport[i].name = exportData[i].firstName + ' ' + exportData[i].lastName;
            paidTimeOffForExport[i].august = exportData[i].daysUsed.august;
            paidTimeOffForExport[i].september = exportData[i].daysUsed.september;
            paidTimeOffForExport[i].october = exportData[i].daysUsed.october;
            paidTimeOffForExport[i].november = exportData[i].daysUsed.november;
            paidTimeOffForExport[i].december = exportData[i].daysUsed.december;
            paidTimeOffForExport[i].january = exportData[i].daysUsed.january;
            paidTimeOffForExport[i].february = exportData[i].daysUsed.february;
            paidTimeOffForExport[i].march = exportData[i].daysUsed.march;
            paidTimeOffForExport[i].april = exportData[i].daysUsed.april;
            paidTimeOffForExport[i].may = exportData[i].daysUsed.may;
            paidTimeOffForExport[i].june = exportData[i].daysUsed.june;
            paidTimeOffForExport[i].july = exportData[i].daysUsed.july;
            paidTimeOffForExport[i].yearDaysUsed = exportData[i].yearDaysUsed;
        }
        this.setHeadersForPaidTimeOffExport(paidTimeOffForExport, levelValue, month, year);
    }

    setHeadersForPaidTimeOffExport(paidTimeOffData: PaidTimeOff[], levelValue: string, month: number, year: number) {
        const dataWithNewHeaders = [];
        paidTimeOffData.forEach(element => {
            dataWithNewHeaders.push(JSON.parse(JSON.stringify(element).replace('groupName', 'Group')
            .replace('name', 'Name')
            .replace('august', 'August')
            .replace('september', 'September')
            .replace('october', 'October')
            .replace('november', 'November')
            .replace('december', 'December')
            .replace('january', 'January')
            .replace('february', 'February')
            .replace('march', 'March')
            .replace('april', 'April')
            .replace('may', 'May')
            .replace('june', 'June')
            .replace('july', 'July')
            .replace('yearDaysUsed', 'Totals')));
        });
        const fileName = this.buildFileName();
        const fiscalYear = this.findFiscalYear(month, year);
        const title = 'PAID TIME OFF/Group' + ' ' + levelValue + '/' + fiscalYear;
        this.xlsxExportService.generateExport(dataWithNewHeaders, fileName, title);
    }

    buildFileName(): string {
        const timeOfExport = new Date();
        const year = timeOfExport.getFullYear();
        const month = timeOfExport.getMonth() + 1;
        const day = timeOfExport.getDate();
        const hour = timeOfExport.getHours();
        const minute = timeOfExport.getMinutes();
        const second = timeOfExport.getSeconds();
        const monthString = this.checkNumber(month);
        const dayString = this.checkNumber(day);
        const hourString = this.checkNumber(hour);
        const minuteString = this.checkNumber(minute);
        const secondString = this.checkNumber(second);
        return 'PaidTimeOff_' + year + '_' + monthString + '_' + dayString + '_' + hourString + '_' + minuteString + '_' + secondString;
    }

    checkNumber(num: number): string {
        if (num > 9) {
            return num.toString();
        } else {
            return '0' + num.toString();
        }
    }

    findFiscalYear(month: number, year: number): string {
        if (month >= 8) {
            return 'FY ' + year.toString();
        } else {
            const previousYear = year - 1;
            return 'FY ' + previousYear.toString();
        }
    }
}
