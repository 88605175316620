import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { groupLevels } from '../static-data/group-levels';
import { GroupFilterService } from './group-filter.service';
import { HelperMethodsService } from '../services/helper-methods.service';
import { SubscriptionService } from '../services/subscription.service';
import { User } from '../../security/user';
import { Subscription, Observable } from 'rxjs';
import { startWith, map, finalize } from 'rxjs/operators';
import { SecuredObjectService } from '../../security/secured-object.service';

@Component({
  selector: 'scoreboard-group-filter',
  templateUrl: './group-filter.component.html',
  styleUrls: ['./group-filter.component.scss']
})
export class GroupFilterComponent implements OnChanges {
  filters: Array<string>;
  selectedFilter: string;
  filterValues: Array<string>;
  filterValues$: Observable<Array<string>>;
  europeLocations: Array<string>;
  europeLocations$: Observable<Array<string>>;
  groupSubscription = new Subscription();
  europeLocationSubscription = new Subscription();
  isApplyDisabled = false;
  query: string;
  initialLoad: boolean;
  isEurope: boolean;

  locationControl: UntypedFormControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;

  @Input() isGroupLevel: boolean;
  @Input() user: User;
  @Output() filtersChanged: EventEmitter<[string, string, boolean]>;
  @Output() isApplyDisabledChanged: EventEmitter<boolean>;

  constructor(public groupFilterService: GroupFilterService,
    public helperMethods: HelperMethodsService,
    private subscriptionService: SubscriptionService,
    public secObjService: SecuredObjectService) {
    this.filtersChanged = new EventEmitter<[string, string, boolean]>();
    this.isApplyDisabledChanged = new EventEmitter<boolean>();
  }

  ngOnChanges() {
    this.getEuropeLocations();
  }

  getEuropeLocations() {
    this.europeLocations$ = this.groupFilterService.getEuropeLocations$();
    this.subscribeToEuropeLocationsObservable();
  }

  setInitialSelections() {
    this.initialLoad = true;
    if (this.secObjService.hasCorpAccess()) {

      if (this.isGroupLevel) {
        this.filters = [groupLevels.group];
        this.selectedFilter = groupLevels.group;

        if ((this.user.group.match('98') === null) && (this.user.group.match('V8') === null) && (this.user.group.match('77') === null) && (this.user.group.match('UK') === null)) {
          this.locationControl.setValue(this.user.group);
        } else {
            this.locationControl.setValue('01');
            this.isEurope = false;
        }
      } else {
        if (this.user.country === 'USA' || this.user.country === 'CAN') {
          this.filters = [groupLevels.country, groupLevels.svpTeam, groupLevels.group];
          this.selectedFilter = groupLevels.country;
          this.locationControl.setValue(this.user.country);
        } else {
          this.filters = [groupLevels.group];
          this.selectedFilter = groupLevels.group;
          this.locationControl.setValue(this.user.group);
        }
      }
      this.filterValues$ = this.groupFilterService.getFilterValues(this.selectedFilter);
      this.subscribeToFilterValuesObservable();
    } else {
      this.filters = [groupLevels.group];
      this.filterValues = [this.user.group];
      this.selectedFilter = groupLevels.group;
      this.locationControl.setValue(this.filterValues[0]);
      this.isApplyDisabled = false;
      this.emitFilter();
      this.isApplyDisabledChanged.emit(this.isApplyDisabled);
    }
  }

  subscribeToFilterValuesObservable() {
    this.filterValues = new Array<string>();
    this.groupSubscription.unsubscribe();
    this.isApplyDisabled = true;
    this.isApplyDisabledChanged.emit(this.isApplyDisabled);

    this.groupSubscription = this.filterValues$
      .pipe(
        finalize(() => {
          this.setupAutoSelect();
          this.isApplyDisabled = false;
          this.emitFilter();
          this.isApplyDisabledChanged.emit(this.isApplyDisabled);
        })
      )
      .subscribe(
        filters => {
          filters.forEach(filter => {
            if (!this.helperMethods.isStringNullOrEmpty(filter)) {
              this.filterValues.push(filter);
            }
          });
          if (this.isGroupLevel && this.initialLoad) {
            this.locationControl.setValue(this.setGroupBasedOnUser());
          }
        },
        err => {
          this.subscriptionService.onError(err, 'getFilterValues');
        }
      );
  }

  subscribeToEuropeLocationsObservable() {
    this.europeLocations = new Array<string>();
    this.europeLocationSubscription.unsubscribe();

    this.europeLocationSubscription = this.europeLocations$
      .pipe(
        finalize(() => {
          this.setInitialSelections();
        })
      )
      .subscribe(
        locations => {
          if (!this.helperMethods.isArrayNullOrEmpty(locations)) {
            locations.forEach(location => {
              if (!this.helperMethods.isStringNullOrEmpty(location)) {
                this.europeLocations.push(location);
              }
            }); // end foreach
          }
        }, // end locations
        err => {
          this.subscriptionService.onError(err, 'getEuropeLocations');
        }  // end err
      ); // end subscribe
  }

  setupAutoSelect() {
    let startWithVal = '';
    if (this.initialLoad && (this.selectedFilter === groupLevels.country)) {
      startWithVal = this.user.country;
      this.initialLoad = false;
    }
    if (this.initialLoad && (this.selectedFilter === groupLevels.group)) {
      startWithVal = this.setGroupBasedOnUser();
      this.initialLoad = false;
    }
    this.filteredOptions = this.locationControl.valueChanges
      .pipe(
        startWith(startWithVal),
        map(val => {
            this.checkIfValShouldBeEmitted(val);
            return this.filter(val);
        })
      );
  }

  filter(val: string): string[] {
    const matches =  this.filterValues.filter(option =>
      option.toLowerCase().indexOf(val.toLowerCase()) !== -1);
    if (matches.length > 1) { // This causes the dropdown of options to dissappear after clicking on an item in the list, the page loads, and focus is brought back to input field.
      return matches;
    }
  }

    checkIfValShouldBeEmitted(val: string) {
        if (this.filterValues.includes(val.toUpperCase())) {
            this.emitFilter();
        }
    }

  selectFilter() {
    this.filterValues$ = this.groupFilterService.getFilterValues(this.selectedFilter);
    this.subscribeToFilterValuesObservable();
    this.locationControl.setValue('');
    this.emitFilter();  // Emit filter id as invalid string when null to allow proper error handling in parent component.
  }

  emitFilter() {
    this.checkForEurope();
    if (this.helperMethods.isStringNullOrEmpty(this.locationControl.value)
    || !this.filterValues.includes(this.locationControl.value.toUpperCase())) {
      this.filtersChanged.emit([this.selectedFilter, groupLevels.invalid, false]);
    } else {
      this.filtersChanged.emit([this.selectedFilter, this.locationControl.value, this.isEurope]);
    }
  }

  checkForEurope() {
    if (this.europeLocations.includes(this.locationControl.value.toUpperCase())) {
      this.isEurope = true;
    } else {
      this.isEurope = false;
    }
  }

  setGroupBasedOnUser(): string {
    if ((this.filterValues.includes(this.user.group)) && (this.user.group !== '98') && (this.user.group !== '77') && (this.user.group !== 'UK')) {
      return this.user.group;
    } else if (this.europeLocations.includes(this.user.group)) {
      return this.filterValues.find(x => this.europeLocations.includes(x));
    } else {
      return this.filterValues[0];
    }
  }

  hideGroupFilterFromNonCorpUser() {
    if (!this.secObjService.hasCorpAccess()) {
      return { 'display': 'none' };
    }

    return {};
  }
}
