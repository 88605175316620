import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { GroupGoalService } from './group-goal.service';
import { CustomCurrencyPipe } from '../../shared/pipes/custom-currency.pipe';
import { Group } from '../../models/incoming-dtos/group';
import { GroupGoal } from '../../models/incoming-dtos/group-goal';
import { GroupGoalSave } from './group-goal-save';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { StyleHelperService } from '../../shared/services/style-helper.service';
import { SpcBaseComponent } from '../../shared/base/spc-base.component';
import { AppInsightsService } from '../../shared/services/app-insights.service';

@Component({
  selector: 'scoreboard-group-goal',
  templateUrl: './group-goal.component.html',
  styleUrls: ['./group-goal.component.scss']
})
export class GroupGoalComponent extends SpcBaseComponent implements OnInit {
    @Input() groupGoals: Group[];
    grossSpcTargetValues = new Array<string>();
    groupsForSave = new Array<GroupGoalSave>();
    goalsToBeSaved = new Array<GroupGoal>();
    isSaveReady = false;
    @Output() groupGoalsChanged: EventEmitter<GroupGoal[]>;

    constructor(public helperMethods: HelperMethodsService,
                public groupGoalSvc: GroupGoalService,
                private customCurrencyPipe: CustomCurrencyPipe,
                public loadingSpinnerSvc: LoadingSpinnerService,
                public styleHelper: StyleHelperService,
                public appInsights: AppInsightsService) {
                    super(appInsights);
                    this.groupGoalsChanged = new EventEmitter<GroupGoal[]>();
                    styleHelper.maxWidth = 680;
    }

    ngOnInit() {
        this.removeEmptyValuesFromGoalValue();
    }

    recordGoalId(groupId: number, goalId: number) {
        this.isSaveReady = true;
        this.removeEmptyValuesFromGoalValue();
        if (this.helperMethods.isArrayNullOrEmpty(this.groupsForSave)) {
            const ids = new GroupGoalSave();
            ids.groupId = groupId;
            ids.groupGoalIds = [goalId];
            this.groupsForSave.push(ids);
        } else {
            this.groupsForSave = this.groupGoalSvc.checkIds(this.groupsForSave, groupId, goalId);
        }
    }

    onSave() {
        this.loadingSpinnerSvc.turnSpinnerOn('Saving...');
        this.goalsToBeSaved = this.groupGoalSvc.getGoals(this.groupGoals, this.groupsForSave);
        this.groupGoalsChanged.emit(this.goalsToBeSaved);
        this.groupsForSave = [];
        this.isSaveReady = false;
    }



    removeEmptyValuesFromGoalValue() {
        this.groupGoals.forEach(group => {
            group.groupGoals.forEach(goal => {
                if (!goal.isSpc && goal.goalValue !== null) {
                    if (goal.goalName.startsWith('Age') || goal.goalName.startsWith('Delete')) {
                        goal.goalValue = this.customCurrencyPipe.transform(goal.goalValue, '1.1-1');
                    } else {
                        goal.goalValue = this.customCurrencyPipe.transform(goal.goalValue, '1.0-0');
                    }
                }
            });
        });
    }
}
