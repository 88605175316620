export class GroupGoalUpdateDto {
    id: number;
    ehiGroupId: number;
    goalId: number;
    goalName: string;
    goalValue: string;
    updatedBy: string;
    month: number;
    year: number;
    recordStatus: boolean;
}
