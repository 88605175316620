import { Employee } from './employee';
import { VacationDaysType } from './vacation-days-type';
import { SpcType } from '../enums/spc-type';

export class PayPlan {
    id: number;
    targetCompensation: number;
    vacationPay: number;
    vacationDaysTypeId: number;
    basePay: number;
    profitBonusAmount: number;
    profitBonusPercent: number;
    salesVolumeTarget: number;
    salesVolumePayPerUnit: number;
    employeeMonthId: number;
    locked: boolean;
    netSpcTarget: number;
    netSpcPayout: number;
    grossSpcTarget: number;
    grossSpcPayout: number;
    quadGrowthTarget: number;
    quadGrowthPayout: number;
    dealersSoldToGrowthTarget: number;
    dealersSoldToGrowthPayout: number;
    daysToDispositionTarget: number;
    daysToDispositionPayout: number;
    agedPercentageTarget: number;
    agedPercentagePayout: number;
    localBoostPercentTarget: number;
    localBoostPercentPayout: number;
    purchaseVolumeTarget: number;
    purchaseVolumePayPerUnit: number;
    dealerSoldToAllTarget: number;
    dealerSoldToAllPayout: number;
    targetSpcType: SpcType;
    employee: Employee;
    availableSpcTargets: string[];
    availableVacationDayTypes: VacationDaysType[];
    updatedTime: Date;
    updatedBy: string;
    expiryDate: Date;
    effectiveDate: Date;
}
