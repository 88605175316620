import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ErrorHandlingService } from '../shared/services/error-handling.service';
import { Group } from '../models/incoming-dtos/group';
import { UserService } from '../security/user.service';
import { groupLevels } from '../shared/static-data/group-levels';
import { Team } from '../models/incoming-dtos/team';
import { Employee } from '../models/incoming-dtos/employee';
import { CreateOrUpdateTeamDto } from '../models/outgoing-dtos/create-or-update-team-dto';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TeamAssignmentService {
    private baseUrl: string = environment.dotNetCoreUrl + 'coreapi/';
    private groupsForCountryUrl: string = this.baseUrl + 'TeamAssignment/country';
    private groupsForSvpTeamUrl: string = this.baseUrl + 'TeamAssignment/svpTeam';
    private groupForGroupUrl: string = this.baseUrl + 'TeamAssignment/group';
    private getTeamDetailsUrl: string = this.baseUrl + 'TeamAssignment/teamDetails';
    private updateTeamUrl: string = this.baseUrl + 'TeamAssignment/updateTeam';
    private createNewTeamUrl: string = this.baseUrl + 'TeamAssignment/createTeam';
    private deleteTeamUrl: string = this.baseUrl + 'TeamAssignment/deleteTeam';

    groups$ = new Observable<Group[]>();

    constructor(private httpClient: HttpClient,
                private userService: UserService,
                private errorService: ErrorHandlingService) { }

    getGroupsAndTeams(level: string, levelId: string, month: number, year: number): Observable<Group[]> {
        switch (level) {
            case groupLevels.country:
                return this.groups$ = this.getGroupsAndTeamsForCountry$(levelId, month, year);
            case groupLevels.svpTeam:
                return this.groups$ = this.getGroupsAndTeamsForSvpTeam$(levelId, month, year);
            case groupLevels.group:
                return this.groups$ = this.getGroupsAndTeamsForGroup$(levelId, month, year);
        }
    }

    deleteTeam(teamId: number, updatedBy: string): Observable<boolean> {
        const url = this.deleteTeamUrl + '/' + teamId + '?updatedBy=' + updatedBy;
        return this.httpClient.delete<boolean>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    createNewTeam(teamName: string, manager: Employee, groupName: string, userId: string, employees: Employee[], month: number, year: number): Observable<number> {
        const url = this.createNewTeamUrl;
        const params = this.buildCreateParams(teamName, manager, groupName, userId, employees, month, year);
        return this.httpClient.post<number>(url, params, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    updateTeamDetails(teamName: string, manager: Employee, groupName: string, userId: string, employees: Employee[], month: number, year: number, teamId: number): Observable<number> {
        const url = this.updateTeamUrl + '/' + teamId;
        const params = this.buildUpdateParams(teamName, manager, groupName, userId, employees, month, year);
        return this.httpClient.put<number>(url, params, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getTeamDetails(teamId: number, groupName: string, month: number, year: number): Observable<Team> {
        const url = this.getTeamDetailsUrl + '/' + teamId + '/' + year + '/' + month + '/' + groupName;
        return this.httpClient.get<Team>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getGroupsAndTeamsForCountry$(levelId: string, month: number, year: number): Observable<Group[]> {
        const url = this.buildGroupAndTeamUrl(this.groupsForCountryUrl, levelId, month, year);
        return this.httpClient.get<Group[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getGroupsAndTeamsForSvpTeam$(levelId: string, month: number, year: number): Observable<Group[]> {
        const url = this.buildGroupAndTeamUrl(this.groupsForSvpTeamUrl, levelId, month, year);
        return this.httpClient.get<Group[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getGroupsAndTeamsForGroup$(levelId, month, year): Observable<Group[]> {
        const url = this.buildGroupAndTeamUrl(this.groupForGroupUrl, levelId, month, year);
        return this.httpClient.get<Group[]>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    buildGroupAndTeamUrl(url: string, levelId: string, month: number, year: number): string {
        const urlWithParams = url + '/' + levelId + '/' + year + '/' + month;
        return urlWithParams;
    }

    buildUpdateParams(teamName: string, manager: Employee, groupName: string, userId: string, employees: Employee[], month: number, year: number): CreateOrUpdateTeamDto {
        const createOrUpdateTeamDto = new CreateOrUpdateTeamDto();
        createOrUpdateTeamDto.groupName = groupName;
        createOrUpdateTeamDto.month = month;
        createOrUpdateTeamDto.year = year;
        createOrUpdateTeamDto.teamName = teamName;
        createOrUpdateTeamDto.employees = employees;
        createOrUpdateTeamDto.manager = manager;
        createOrUpdateTeamDto.updatedBy = userId;
        return createOrUpdateTeamDto;
    }

    buildCreateParams(teamName: string, manager: Employee, groupName: string, userId: string, employees: Employee[], month: number, year: number): CreateOrUpdateTeamDto {
        const createOrUpdateTeamDto = new CreateOrUpdateTeamDto();
        createOrUpdateTeamDto.groupName = groupName;
        createOrUpdateTeamDto.month = month;
        createOrUpdateTeamDto.year = year;
        createOrUpdateTeamDto.teamName = teamName;
        createOrUpdateTeamDto.employees = employees;
        createOrUpdateTeamDto.manager = manager;
        createOrUpdateTeamDto.createdBy = userId;
        return createOrUpdateTeamDto;
    }
}
