import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
@Injectable()
export class GlobalizationService {
  currencyList: Array<CurrencyMap>;
  getCountryCurrency(str: string): string {
    this.currencyList = environment.currencyMap;
    return this.currencyList.find(x => x.ehiCode === str).ngCode;
  }
}
export interface CurrencyMap {
  ngCode: string;
  ehiCode: string;
}
