import { Injectable } from '@angular/core';

@Injectable()
export class StyleHelperService {
    maxWidth: number;

    constructor() { }

    isMobile(maxWidth: number): any {
        return window.innerWidth <= maxWidth;
    }

    changeClass(mobileClassChange: string, desktopClassChange: string) {
        if (this.isMobile(this.maxWidth)) {
            return mobileClassChange;
        } else {
            return desktopClassChange;
        }
    }
}
