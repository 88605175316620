import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlingService } from '../shared/services/error-handling.service';
import { UserService } from '../security/user.service';
import { environment } from '../../environments/environment';
import { DashboardEmployee } from '../models/incoming-dtos/dashboard-employee';
import { DashboardTeam } from '../models/incoming-dtos/dashboard-team';
import { PayMemo } from '../models/incoming-dtos/pay-memo';
import { GoalActual } from '../models/incoming-dtos/goal-actual';
import { catchError } from 'rxjs/operators';
import { DashboardGroup } from '../models/incoming-dtos/dashboard-group';

@Injectable()
export class DashboardService {
    private baseUrl: string = environment.dotNetCoreUrl + 'coreapi/';
    private dashboardEmployeeUrl: string = this.baseUrl + 'Dashboard/EmployeeView';
    private dashboardTeamUrl: string = this.baseUrl + 'Dashboard/TeamView';
    private dashboardGroupUrl: string = this.baseUrl + 'Dashboard/GroupView';
    private employeePayMemoUrl: string = this.baseUrl + 'PayMemo/employee';
    private groupMetrics = { aged: 'Aged %', deleteToSaleDays: 'Delete To Sale Days', localBoost: 'Local Boost as a % of DR Fleet', spcCountryGross: 'SPC Country Gross' };

    constructor(private httpClient: HttpClient, private userService: UserService, private errorService: ErrorHandlingService) { }

    getDashboardEmployeeData(groupName: string, year: number, month: number): Observable<DashboardEmployee> {
        const url = this.dashboardEmployeeUrl + '/' + groupName + '/' + year + '/' + month;
        return this.httpClient.get<DashboardEmployee>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getDashboardTeamData(groupName: string, year: number, month: number): Observable<DashboardTeam> {
        const url = this.dashboardTeamUrl + '/' + groupName + '/' + year + '/' + month;
        return this.httpClient.get<DashboardTeam>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getDashboardGroupData(groupName: string, year: number, month: number): Observable<DashboardGroup> {
        const url = this.dashboardGroupUrl + '/' + groupName + '/' + year + '/' + month;
        return this.httpClient.get<DashboardGroup>(url, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getPayMemoForSelectedEmployee(eId: string, year: number, month: number): Observable<PayMemo> {
        const employeeId = eId.substring(1);
        const url = this.employeePayMemoUrl + '/' + employeeId + '/' + year + '/' + month;
        return this.httpClient.post<PayMemo>(url, null, this.userService.getHttpOptions())
            .pipe(
                catchError(this.errorService.handleError)
            );
    }

    getAsterisk(goalActual: GoalActual): boolean {
        switch (goalActual.goalName) {
            case this.groupMetrics.aged:
            case this.groupMetrics.deleteToSaleDays:
            case this.groupMetrics.localBoost:
            case this.groupMetrics.spcCountryGross:
                return true;
            default:
                return false;
        }
    }
}
