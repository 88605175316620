import { Employee } from './employee';

export class PayMemo {
    id: number;
    teamName: string;
    payPlanId: number;
    ptoDaysUsed: number;
    ptoRate: number;
    ptoPay: number;
    actualSalesVolume: number;
    actualSalesVolumeTeam: number;
    payoutPerCar: number;
    salesVolumePayout: number;
    grossSpcActual: number;
    grossSPCNationalActual: number;
    adjustedGrossSPCActual: number;
    grossSpcTarget: number;
    grossSpcPayout: number;
    netSpcActual: number;
    netSpcTarget: number;
    netSpcPayout: number;
    quadGrowthActual: number;
    quadGrowthTarget: number;
    quadGrowthPayout: number;
    dealersSoldToGrowthActual: number;
    dealersSoldToGrowthTarget: number;
    dealersSoldToGrowthPayout: number;
    daysToDispositionActual: number;
    daysToDispositionTarget: number;
    daysToDispositionPayout: number;
    agedPercentageActual: number;
    agedPercentageTarget: number;
    agedPercentagePayout: number;
    purchaseVolumeActual: number;
    purchaseVolumeActualTeam: number;
    purchaseVolumePayPerUnit: number;
    purchaseVolumePayPayout: number;
    localBoostPercentActual: number;
    localBoostPercentTarget: number;
    localBoostPercentPayout: number;
    dealerSoldToAllTarget:	number;
    dealerSoldToAllActual:	number;
    dealerSoldToAllPayout:	number;
    projectedPay: number;
    adjustments: string;
    adjustedPay: number;
    comments: string;
    locked: boolean;
    employee: Employee;
    ehiGroupId: number;
    milestoneBonus: number;
    milestoneBonusTeam: number;
    buyerAverage: number;
    buyerAverageTeam: number;
    averageBuyerGoalBonus: number;
    averageBuyerGoalBonusTeam: number;
    reversalAdjustment: number;
    updatedTime: Date;
}
