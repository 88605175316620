import { Employee } from '../incoming-dtos/employee';

export class CreateOrUpdateTeamDto {
    groupName: string;
    month: number;
    year: number;
    teamName: string;
    manager: Employee;
    employees: Employee[];
    createdBy: string;
    updatedBy: string;
}
