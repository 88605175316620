export class PayMemoProBusinessExport {
    compId: string;
    peopleSoftId: number;
    name: string;
    earnCode: string;
    amount: string;
    hrlyRate: string;
    codeType: string;
    amtType: string;
    checkNo: string;
    hrBUnitWorkDiv: string;
    glProductWorkDept: string;
    glDeptIdWorkJob: string;
    glGroup: string;
    checkMessage: string;
    taxFrequency: string;
    oldRate: string;
    totals: string;
    bonusPerStartDate: string;
    bonusPerEndDate: string;
    weekIndicator: string;
}
