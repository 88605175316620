import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ErrorHandlingService } from '../services/error-handling.service';
import { UserService } from '../../security/user.service';
import { environment } from '../../../environments/environment';
import { Team } from '../../models/incoming-dtos/team';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TeamFilterService {
  private baseUrl: string = environment.dotNetCoreUrl + 'coreapi/';
  private teamUrl: string = this.baseUrl + 'Dashboard/Teams';

  constructor(private httpClient: HttpClient, private userService: UserService,
    private errorService: ErrorHandlingService) { }

  getTeams(groupName: string, year: number, month: number): Observable<Team[]> {
    return this.httpClient.get<Team[]>(this.teamUrl + '/' + groupName + '/' + year + '/' + month, this.userService.getHttpOptions())
      .pipe(
        catchError(this.errorService.handleError)
      );
  }
}
