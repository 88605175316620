import { AbstractControl, ValidatorFn } from '@angular/forms';

export class NumberValidator {

    static range(min: number, max: number): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (c.value && (isNaN(c.value.replace(/,/g, '')) || +c.value.replace(/,/g, '') < min || +c.value.replace(/,/g, '') > max)) {
                return { 'range': true };
            }
            return null;
        };
    }

    static isPositive(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (isNaN(c.value.replace(/,/g, '')) || +c.value.replace(/,/g, '') < 0) {
                return { 'isNegative': true };
            }
            return null;
        };
    }

    static equal100Percent(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (isNaN(c.value.replace(/,/g, '')) || +c.value.replace(/,/g, '') !== 100) {
                return { 'equal100Percent': true };
            }
            return null;
        };
    }

    static greaterThan25(): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (!isNaN(c.value.replace(/,/g, '')) && +c.value.replace(/,/g, '') > 25) {
                return { 'greaterThan25': true };
            }
            return null;
        };
    }

    static greaterThan70(groupName: string): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (groupName !== '36' && !isNaN(c.value.replace(/,/g, '')) && +c.value.replace(/,/g, '') > 70) {
                return { 'greaterThan70': true };
            }
            return null;
        };
    }
}
