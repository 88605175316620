import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

// This pipe overrides the built-in currency pipe feature where values have decimal points.
@Pipe({ name: 'customCurrency' })
export class CustomCurrencyPipe implements PipeTransform {
    transform(value: any, decimalFormat: string): string {
        let strValue = value.toString();

        let isNegative = false;
        if (strValue.length > 1 && strValue.substring(0, 1) === '-') { // check for a negative
            isNegative = true;
        }
        strValue = strValue.replace(/[^\d.]/g, '');  // Removing non-numeric (excl dashes and decimal points) before re-piping.

        if (isNegative) { // add back the negative if needed
            strValue = '-' + strValue;
        }
        if (strValue === '') {  // Set to 0 if empty
            strValue = '0';
        }

        return CurrencyPipe.prototype.transform(strValue, 'USD', 'symbol', decimalFormat, 'en-US').replace('$', '');
    }
}
