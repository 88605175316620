import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Team } from '../../models/incoming-dtos/team';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { TeamEmployeesService } from './team-employees.service';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';

@Component({
    selector: 'scoreboard-team-employees',
    templateUrl: './team-employees.component.html',
    styleUrls: ['./team-employees.component.scss']
})
export class TeamEmployeesComponent implements OnChanges {
    @Input() teams: Team[];
    @Input() isEurope: boolean;
    @Input() selectedEmployeeData: [boolean, number];
    @Output() selectedEmployeeChanged: EventEmitter<[number, number, boolean, string]>;

    selectedTeams = new Array<boolean>();
    selectedEmployees = new Array<boolean>();
    currentTeamIndex: number;
    currentEmployeeIndex: number;
    countryCode: string;

    constructor(public helperMethods: HelperMethodsService,
        private teamEmployeesSvc: TeamEmployeesService,
        public loadingSpinnerSvc: LoadingSpinnerService) {
        this.selectedEmployeeChanged = new EventEmitter<[number, number, boolean, string]>();
    }

    ngOnChanges() {
        if (!this.selectedEmployeeData[0]) {
            this.setDefaultEmployee();
        } else {
            this.selectNewPayPlanEmployee();
        }
    }

    setDefaultEmployee() {
        if (!this.helperMethods.isArrayNullOrEmpty(this.teams)) {
            // setting first team in first group as selected team by default when loading into the page.
            this.selectedTeams[0] = true;
            this.selectedEmployees[0] = true;
            const payPlanId = this.teams[0].employees[0].payPlanId;
            const employeeMonthId = this.teams[0].employees[0].employeeMonthId;
            this.countryCode = this.teams[0].employees[0].country;
            this.selectedEmployeeChanged.emit([payPlanId, employeeMonthId, this.isEurope, this.countryCode]);
        }
    }

    checkSelectedEmployee(teamIndex: number, employeeIndex: number) {
        this.loadingSpinnerSvc.turnSpinnerOn('Loading...');
        this.selectedTeams = [];
        this.selectedEmployees = [];
        this.currentTeamIndex = teamIndex;
        this.currentEmployeeIndex = employeeIndex;
        this.selectedTeams[teamIndex] = true;
        this.selectedEmployees[employeeIndex] = true;
        const payPlanId = this.teamEmployeesSvc.findPayPlanId(teamIndex, employeeIndex, this.teams);
        const employeeMonthId = this.teamEmployeesSvc.findEmployeeMonthId(teamIndex, employeeIndex, this.teams);
        this.countryCode = this.teams[0].employees[0].country;
        this.selectedEmployeeChanged.emit([payPlanId, employeeMonthId, this.isEurope, this.countryCode]);
    }

    selectNewPayPlanEmployee() {
        this.selectedTeams = [];
        this.selectedEmployees = [];
        let teamIndex: number;
        let employeeIndex: number;
        teamIndex = this.teamEmployeesSvc.findTeamIndex(this.teams, this.selectedEmployeeData[1]);
        employeeIndex = this.teams[teamIndex].employees.findIndex(e => e.employeeMonthId === this.selectedEmployeeData[1]);
        this.selectedTeams[teamIndex] = true;
        this.selectedEmployees[employeeIndex] = true;
        const payPlanId = this.teamEmployeesSvc.findPayPlanId(teamIndex, employeeIndex, this.teams);
        this.countryCode = this.teams[0].employees[0].country;
        this.selectedEmployeeChanged.emit([payPlanId, this.selectedEmployeeData[1], this.isEurope, this.countryCode]);
    }
}
