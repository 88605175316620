import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '../security/user.service';
import { SecuredObjectService } from '../security/secured-object.service';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminRouteActivator implements CanActivate {

    constructor(private userService: UserService,
                private secObjService: SecuredObjectService,
                private router: Router) {}

    canActivate() {
        return this.userService.userSource$.pipe(map((user) => {
            if (user) {
                if (this.hasAccess()) {
                    return true;
                } else {
                    this.router.navigate(['page-not-found']);
                }
            }
            return false;
        }));
    }

    hasAccess(): boolean {
        return this.secObjService.hasAdminPageAccess();
    }
}
