import { Injectable } from '@angular/core';
import { XlsxExportService } from '../../shared/xlsx-export/xlsx-export.service';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';
import { DashboardEmployeeOverview } from '../../models/incoming-dtos/dashboard-employee-overview';
import { DashboardEmployeeExport } from './dashboard-employee-export';
import { DashboardGoalExport } from './dashboard-goal-export';

@Injectable()
export class DashboardEmployeeExportService {
    dashboardEmployee: Object;
    title: string;
    isEurope: boolean;

    constructor(private xlsxExportSvc: XlsxExportService,
                private helperMethods: HelperMethodsService) {}

    buildDashboardEmployeeExport(dashboardEmployeeOverview: DashboardEmployeeOverview[], levelValue: string, month: number, year: number) {
        const dashboardEmployeeExport = new Array<DashboardEmployeeExport>();
        for (let i = 0; i < dashboardEmployeeOverview.length; i++) {
            const dashboardEmployee = new DashboardEmployeeExport();
            dashboardEmployee.employeeName = dashboardEmployeeOverview[i].employeeDto.firstName
             + ' ' + dashboardEmployeeOverview[i].employeeDto.lastName;
            dashboardEmployee.dailySalesPace = dashboardEmployeeOverview[i].dailySalesPace;
            dashboardEmployee.rank = dashboardEmployeeOverview[i].rank;
            dashboardEmployee.totalRankPoints = dashboardEmployeeOverview[i].totalRankPoints;

            dashboardEmployee.dashboardGoalExports = [];
            dashboardEmployeeOverview[i].goalActualDtos.forEach(goal => {
                const dashboardGoalExport: DashboardGoalExport = {
                    goalName: goal.goalName,
                    prefix: goal.prefix,
                    rankPoint: goal.rankPoint,
                    suffix: goal.suffix,
                    value: goal.actualValue + goal.pendingValue
                };

                if (!this.helperMethods.isStringNullOrEmpty(dashboardGoalExport.prefix)) {
                    dashboardGoalExport.prefix = '       ' + dashboardGoalExport.prefix + dashboardGoalExport.value;
                    dashboardEmployee.dashboardGoalExports.push(dashboardGoalExport);

                } else if (!this.helperMethods.isStringNullOrEmpty(dashboardGoalExport.suffix)) {
                    dashboardGoalExport.suffix = '       ' + dashboardGoalExport.value + dashboardGoalExport.suffix;
                    dashboardEmployee.dashboardGoalExports.push(dashboardGoalExport);

                } else {
                    if (dashboardGoalExport.goalName === 'Delete To Sale Days') {
                        dashboardGoalExport.value = '       ' + dashboardGoalExport.value.toFixed(1);
                    }
                    dashboardEmployee.dashboardGoalExports.push(dashboardGoalExport);
                }
            });
            dashboardEmployeeExport.push(dashboardEmployee);

            if (this.isEurope) {
                dashboardEmployeeExport.sort((a, b) => {
                    let firstName: string, lastName: string;
                    [firstName, lastName] = a.employeeName.split(' ');
                    const x = (lastName + firstName).toLowerCase();
                    [firstName, lastName] = b.employeeName.split(' ');
                    const y = (lastName + firstName).toLowerCase();
                    if (x < y) { return -1; }
                    if (x > y) { return 1; }
                    return 0;
                });
            } else {
                dashboardEmployeeExport.sort((a, b) => this.helperMethods.compareNumbers(b.rank, a.rank));
            }
        }

        const exportData = [];
        for (let indexEmployee = 0; indexEmployee < dashboardEmployeeExport.length; indexEmployee++) {
            if (this.isEurope) {
                this.dashboardEmployee = {
                    'Name': dashboardEmployeeExport[indexEmployee].employeeName
                };
            } else {
                this.dashboardEmployee = {
                    'Name': dashboardEmployeeExport[indexEmployee].employeeName,
                    'Total Rank' : dashboardEmployeeExport[indexEmployee].rank,
                    'Total Points': dashboardEmployeeExport[indexEmployee].totalRankPoints,
                    'Daily Sales Pace' : dashboardEmployeeExport[indexEmployee].dailySalesPace,
                };
            }

            for (let indexGoal = 0; indexGoal < dashboardEmployeeExport[indexEmployee].dashboardGoalExports.length; indexGoal++) {
                // tslint:disable-next-line:max-line-length
                if (!this.helperMethods.isStringNullOrEmpty(dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].prefix)) {
                    this.dashboardEmployee[dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].goalName] =
                    dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].prefix;
                    this.dashboardEmployee[dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].goalName + ' ' + 'Rank'] =
                     dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].rankPoint;

                // tslint:disable-next-line:max-line-length
                } else if (!this.helperMethods.isStringNullOrEmpty(dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].suffix)) {
                    this.dashboardEmployee[dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].goalName] =
                    dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].suffix;
                    this.dashboardEmployee[dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].goalName
                     + ' ' + 'Rank'] = dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].rankPoint;
                } else {
                    this.dashboardEmployee[dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].goalName] =
                    dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].value;
                    this.dashboardEmployee[dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].goalName
                     + ' ' + 'Rank'] = dashboardEmployeeExport[indexEmployee].dashboardGoalExports[indexGoal].rankPoint;
                }
            }
            exportData.push(this.dashboardEmployee);
        }

        const fileName = this.buildFileName('Dashboard Employees_');
        const date = this.getMonthWord(month, year);

        if (this.isEurope) {
            this.title = 'Dashboard Employees' + ' - ' + date;

        } else {
            this.title = 'Dashboard Employees - Group' + ' ' + levelValue + ' - ' + date;
        }
        this.xlsxExportSvc.generateExport(exportData, fileName, this.title);
    }


    buildFileName(title: string): string {
        const timeOfExport = new Date();
        const year = timeOfExport.getFullYear();
        const month = timeOfExport.getMonth() + 1;
        const day = timeOfExport.getDate();
        const monthString = this.checkNumber(month);
        const dayString = this.checkNumber(day);
        return title + year + '_' + monthString + '_' + dayString;
    }

    checkNumber(num: number): string {
        if (num > 9) {
            return num.toString();
        } else {
            return '0' + num.toString();
        }
    }

    getMonthWord(month: number, year: number) {
        switch (month) {
            case 1:
                return 'January ' + year.toString();
            case 2:
                return 'February ' + year.toString();
            case 3:
                return 'March ' + year.toString();
            case 4:
                return 'April ' + year.toString();
            case 5:
                return 'May ' + year.toString();
            case 6:
                return 'June ' + year.toString();
            case 7:
                return 'July ' + year.toString();
            case 8:
                return 'August ' + year.toString();
            case 9:
                return 'September ' + year.toString();
            case 10:
                return 'October ' + year.toString();
            case 11:
                return 'November ' + year.toString();
            case 12:
                return 'December ' + year.toString();
        }
    }
}
