import { Component, Input } from '@angular/core';
import { DashboardTeamGraph } from '../../models/incoming-dtos/dashboard-team-graph';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { DashboardTeamGraphService } from './dashboard-team-graph.service';
import { DashboardGraphData } from '../dashboard-graph-data';
import { StyleHelperService } from '../../shared/services/style-helper.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'scoreboard-dashboard-team-graph',
  templateUrl: './dashboard-team-graph.component.html',
  styleUrls: ['./dashboard-team-graph.component.scss']
})
export class DashboardTeamGraphComponent {
  @Input() dashboardTeamGraphData: DashboardTeamGraph[];
  @Input() levelValue: string;
  @Input() year: number;
  @Input() month: number;

  selectedGoalId: number;
  orderOfMeritList: Array<number>;
  results = new Array<DashboardGraphData>();
  view: any;
  filteredDashboardGraphData = new Array<DashboardTeamGraph>();
  minValue: any;
  maxValue: any;
  gapValue = 10;
  isOrderOfMerit: boolean;

  constructor(public loadingSpinnerSvc: LoadingSpinnerService,
    public dashboardTeamGraphSvc: DashboardTeamGraphService,
    public styleHelper: StyleHelperService) {
        styleHelper.maxWidth = 1023;
     }

  filterData() {
    this.filteredDashboardGraphData = this.dashboardTeamGraphData.filter(x => x.goalActual.goalId === this.selectedGoalId); // filtering master list of data on selected goal
    this.filteredDashboardGraphData = this.dashboardTeamGraphSvc.sortByRank(this.filteredDashboardGraphData);
    this.plugGraphData();
  }

  plugGraphData() {
    this.results = [];
    this.results = this.dashboardTeamGraphSvc.plugGraphData(this.filteredDashboardGraphData);
    const values = [];

    this.filteredDashboardGraphData.forEach((x) => {
      values.push(x.goalActual.goalValue);
      values.push(x.goalActual.actualValue + x.goalActual.pendingValue);
    });

    values.sort((x, y) => {
      return x - y;
    });
    this.minValue = values[0] - this.gapValue;
    values.reverse();
    this.maxValue = values[0];
    this.loadingSpinnerSvc.turnSpinnerOff();
  }

  onGoalFilterChanged(value: number) {
    this.selectedGoalId = value;
    this.filterData();
    this.orderOfMeritList = environment.orderOfMeritgoalIDs;

    if (this.orderOfMeritList.indexOf(this.selectedGoalId) > -1) {
      this.isOrderOfMerit = true;
  } else { this.isOrderOfMerit = false; }
  }

  getPaceArrow(isOnTrack: boolean): string {
    if (isOnTrack) {
      return 'icon-arrow-up';
    } else {
      return 'icon-arrow-down';
    }
  }

  calculateActualWidth(actualValue: number): string {
    return this.getPercentage(actualValue) + '%';
  }

  private getPercentage(value: number) {
    return (Math.abs(this.minValue) + +value) / (Math.abs(this.minValue) + this.maxValue) * 100;
  }

  calculateGoalWidth(goalValue: number): string {
    if (goalValue.toString() !== '-') {
      return this.getPercentage(goalValue) + '%';
    } else {
      return 0 + '%';
    }
  }

  calculatePendingWidth(actualValue: number, pendingValue: number): string {
    return this.getPercentage(+actualValue + +pendingValue) + '%';
  }

  isOnTrack(onTrack: boolean): string {
    if (onTrack) {
      return '#4CB050';
    } else {
      return '#F44236';
    }
  }

  isPendingOnTrack(onTrack: boolean): string {
    if (onTrack) {
      return '#A5D6A7';
    } else {
      return '#EF9A9A';
    }
  }
}
