import { Component, Input } from '@angular/core';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { StyleHelperService } from '../../shared/services/style-helper.service';
import { DashboardGroupGraph } from '../../models/incoming-dtos/dashboard-group-graph';
import { DashboardGroupGraphService } from './dashboard-group-graph.service';
import { DashboardGraphData } from '../dashboard-graph-data';

@Component({
    selector: 'scoreboard-dashboard-group-graph',
    templateUrl: './dashboard-group-graph.component.html',
    styleUrls: ['./dashboard-group-graph.component.scss']
})
export class DashboardGroupGraphComponent {
    @Input() dashboardGroupGraphData: DashboardGroupGraph[];
    @Input() levelValue: string;
    filteredDashboardGraphData = new Array<DashboardGroupGraph>();
    selectedGoalId: number;
    results = new Array<DashboardGraphData>();
    minValue: number;
    gapValue = 10;
    maxValue: any;

    constructor(public loadingSpinnerSvc: LoadingSpinnerService,
        public dashboardGroupGraphSvc: DashboardGroupGraphService,
        public styleHelper: StyleHelperService) {
        styleHelper.maxWidth = 1023;
    }

    filterData() {
        this.filteredDashboardGraphData = this.dashboardGroupGraphData.filter(x => x.goalActual.goalId === this.selectedGoalId); // filtering master list of data on selected goal
        this.filteredDashboardGraphData = this.dashboardGroupGraphSvc.sortByRank(this.filteredDashboardGraphData);
        this.plugGraphData();
    }

    plugGraphData() {
        this.results = [];
        this.results = this.dashboardGroupGraphSvc.plugGraphData(this.filteredDashboardGraphData);
        const values = [];

        this.filteredDashboardGraphData.forEach((x) => {
            values.push(x.goalActual.goalValue);
            values.push(x.goalActual.actualValue + x.goalActual.pendingValue);
        });

        values.sort((x, y) => {
            return x - y;
        });
        this.minValue = values[0] - this.gapValue;
        values.reverse();
        this.maxValue = values[0];
        this.loadingSpinnerSvc.turnSpinnerOff();
    }

    onGoalFilterChanged(value: number) {
        this.selectedGoalId = value;
        this.filterData();
    }

    getPaceArrow(isOnTrack: boolean): string {
        if (isOnTrack) {
          return 'icon-arrow-up';
        } else {
          return 'icon-arrow-down';
        }
      }

      calculateActualWidth(actualValue: number): string {
        return this.getPercentage(actualValue) + '%';
      }

      private getPercentage(value: number) {
        return (Math.abs(this.minValue) + +value) / (Math.abs(this.minValue) + this.maxValue) * 100;
      }

      calculateGoalWidth(goalValue: number): string {
        if (goalValue.toString() !== '-') {
          return this.getPercentage(goalValue) + '%';
        } else {
          return 0 + '%';
        }
      }

      calculatePendingWidth(actualValue: number, pendingValue: number): string {
        return this.getPercentage(+actualValue + +pendingValue) + '%';
      }

      isOnTrack(onTrack: boolean): string {
        if (onTrack) {
          return '#4CB050';
        } else {
          return '#F44236';
        }
      }

      isPendingOnTrack(onTrack: boolean): string {
        if (onTrack) {
          return '#A5D6A7';
        } else {
          return '#EF9A9A';
        }
      }
}
