import { Employee } from './employee';
import { TeamGoal } from './team-goal';

export class Team {
    id: number;
    name: string;
    groupName: string;
    employees: Employee[];
    managers: Employee[];
    teamGoals: TeamGoal[];
    availableSpcTargets: number[];
}
