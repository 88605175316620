import { Component, OnInit, OnDestroy } from '@angular/core';
import { AppInsightsService } from '../shared/services/app-insights.service';
import { UserService } from '../security/user.service';
import { HelperMethodsService } from '../shared/services/helper-methods.service';
import { BaseComponent } from '../shared/base/base.component';
import { groupLevels } from '../shared/static-data/group-levels';
import { Team } from '../models/incoming-dtos/team';
import { Observable } from 'rxjs';
import { PayplanSetupService } from './payplan-setup.service';
import { Subscription } from 'rxjs';
import { SubscriptionService } from '../shared/services/subscription.service';
import { LoadingSpinnerService } from '../shared/loading-spinner/loading-spinner.service';
import { PayPlan } from '../models/incoming-dtos/payplan';
import { PayPlanSaveDto } from '../models/outgoing-dtos/payplan-save-dto';
import { SnackBarService } from '../shared/services/snack-bar.service';
import { CoreFiltersService } from '../shared/services/core-filters.service';
import { finalize } from 'rxjs/operators';
import { EUPayPlanDto } from '../models/outgoing-dtos/eupayplan-dto';

@Component({
    selector: 'scoreboard-payplan-setup',
    templateUrl: './payplan-setup.component.html',
    styleUrls: ['./payplan-setup.component.scss']
})

export class PayplanSetupComponent extends BaseComponent implements OnInit, OnDestroy {
    selectedPayPlanId: number;
    countryCode: string;
    selectedEmployeeMonthId: number;
    teams$ = new Observable<Team[]>();
    teams = new Array<Team>();
    teamsSubscription = new Subscription();
// North America
    payPlan$ = new Observable<PayPlan>();
    payPlan: PayPlan = new PayPlan();
    payPlanSubscription = new Subscription();
// EU Pay Plan
    payPlanEU$ = new Observable<EUPayPlanDto>();
    payPlanEU: EUPayPlanDto = new EUPayPlanDto();
    payPlanEUSubscription = new Subscription();

    selectedEmployeeData: [boolean, number] = [false, null];
    createPayPlan$ = new Observable<boolean>();
    updatePayPlan$ = new Observable<boolean>();
    coreFilterSubscription = new Subscription();

    showNorthAmerica = false;
    constructor(public appInsights: AppInsightsService,
                public userService: UserService,
                public helperMethods: HelperMethodsService,
                private subscribeService: SubscriptionService,
                private payplanSetupSvc: PayplanSetupService,
                public loadingSpinnerSvc: LoadingSpinnerService,
                public snackBarSvc: SnackBarService,
                public coreFilterSvc: CoreFiltersService) {
                super(appInsights, userService);
                this.countryCode = this.userService.user.country;
    }

    ngOnInit() {
        this.trackUser();
        this.getFilterValues();
    }

    ngOnDestroy() {
        this.coreFilterSubscription.unsubscribe();
    }

    getFilterValues() {
        this.coreFilterSubscription = this.coreFilterSvc.filterValues$.subscribe(filterValues => {
            this.level = filterValues[0].level;
            this.levelValue = filterValues[0].levelValue;
            this.month = filterValues[0].month;
            this.year = filterValues[0].year;
            this.isEurope = filterValues[0].isEurope;
            if (filterValues[1] === '/payplan-setup') {
              this.onSubmitSearch();
            }
        });
      }

    onSelectedEmployeeChanged([payPlanId, employeeMonthId, isEU, code]) {
        this.selectedPayPlanId = payPlanId;
        this.countryCode = code;
        this.selectedEmployeeMonthId = employeeMonthId;
        if (isEU) {
            this.showNorthAmerica = false;
            this.payPlanEU = new EUPayPlanDto();
            this.getEUPayPlan();
        } else {
            this.showNorthAmerica = true;
            this.payPlan = new PayPlan();
             this.getPayPlan();
        }
    }

    onSavePayPlan(payPlanDto: PayPlanSaveDto) {
        if (this.payPlan.id > 0) {
            this.updatePayPlan$ = this.payplanSetupSvc.updatePayPlan(payPlanDto);
            this.subscribeToUpdatePayPlan();
        } else {
            this.createPayPlan$ = this.payplanSetupSvc.createPayPlan(payPlanDto);
            this.subscribeToCreatePayPlan();
        }
    }
    onSavePayPlanEU(payPlanDto: EUPayPlanDto) {
        if (payPlanDto.id > 0) {
            this.updatePayPlan$ = this.payplanSetupSvc.updateEUPayPlan(payPlanDto);
            this.subscribeToUpdateEUPayPlan();
        } else {
            this.createPayPlan$ = this.payplanSetupSvc.createEUPayPlan(payPlanDto);
            this.subscribeToCreateEUPayPlan();
       }
    }
    subscribeToUpdatePayPlan() {
        this.payPlanSubscription.unsubscribe();

        this.payPlanSubscription = this.updatePayPlan$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                () => {
                    this.snackBarSvc.openSnackBar(this.payPlan.employee.firstName + ' ' + this.payPlan.employee.lastName + '\'s ' + 'Pay Plan Saved Successfully');
                    this.appInsights.logUserAction('User updated a Pay Plan for ' + this.payPlan.employee.firstName + ' ' + this.payPlan.employee.lastName +
                                                   ' for ' + this.level + ': ' + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'UpdatePayPlan');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }
    subscribeToUpdateEUPayPlan() {
        this.payPlanEUSubscription.unsubscribe();

        this.payPlanEUSubscription = this.updatePayPlan$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                () => {
                    this.snackBarSvc.openSnackBar(this.payPlanEU.employee.firstName + ' ' + this.payPlanEU.employee.lastName + '\'s ' + 'Pay Plan Saved Successfully');
                    this.appInsights.logUserAction('User updated a Pay Plan for ' + this.payPlanEU.employee.firstName + ' ' + this.payPlanEU.employee.lastName +
                                                   ' for ' + this.level + ': ' + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'UpdatePayPlan');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToCreatePayPlan() {
        this.payPlanSubscription.unsubscribe();

        this.payPlanSubscription = this.createPayPlan$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                () => {
                    this.snackBarSvc.openSnackBar(this.payPlan.employee.firstName + ' ' + this.payPlan.employee.lastName + '\'s ' + 'Pay Plan Saved Successfully');
                    this.appInsights.logUserAction('User created a Pay Plan for ' + this.payPlan.employee.firstName + ' ' + this.payPlan.employee.lastName +
                                                   ' for ' + this.level + ': ' + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
                                                   this.getTeamsAndEmployees();
                                                   this.selectedEmployeeData = [false, null];
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'CreatePayPlan');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    subscribeToCreateEUPayPlan() {
        this.payPlanEUSubscription.unsubscribe();

        this.payPlanEUSubscription = this.createPayPlan$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                () => {
                    this.snackBarSvc.openSnackBar(this.payPlanEU.employee.firstName + ' ' + this.payPlanEU.employee.lastName + '\'s ' + 'Pay Plan Saved Successfully');
                    this.appInsights.logUserAction('User created a Pay Plan for ' + this.payPlanEU.employee.firstName + ' ' + this.payPlanEU.employee.lastName +
                                                   ' for ' + this.level + ': ' + this.levelValue + ' on ' + this.month + '/' + this.year, this.user);
                                                   this.getTeamsAndEmployees();
                                                   this.selectedEmployeeData = [false, null];
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'CreatePayPlan');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }
    onPayPlanSaved([payPlanId]) {
        this.selectedPayPlanId = payPlanId;
    }

    onSubmitSearch() {
        if (this.helperMethods.isStringNullOrEmpty(this.levelValue) || this.levelValue === groupLevels.invalid) {
        this.filterErrorMessage = 'You must select a valid ' + this.level + ' to submit your search.';
        this.userService.alertUser(this.filterErrorMessage);
        } else {
            this.getTeamsAndEmployees();
            this.selectedEmployeeData = [false, null];
        }
    }

    getTeamsAndEmployees() {
        this.payPlan = new PayPlan();
        this.payPlanEU = new EUPayPlanDto();
        this.loadingSpinnerSvc.shouldShowLoading();
        this.teams$ = this.payplanSetupSvc.getTeamsAndEmployees(this.level, this.levelValue, this.month, this.year);
        this.subscribeToTeamEmployees();
    }
    subscribeToTeamEmployees() {
        this.teamsSubscription.unsubscribe();
        this.teams = [];

        this.teamsSubscription = this.teams$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                teams => {
                    this.teams = teams;
                    if (this.helperMethods.isArrayNullOrEmpty(teams)) {
                        this.userService.alertUser('No data found for the selected filters');
                    }
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'getTeamsAndEmployees');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }

    getPayPlan() {
        this.payPlan$ = this.payplanSetupSvc.getPayPlan(this.selectedPayPlanId, this.selectedEmployeeMonthId);
        this.subscribeToPayPlan();
    }

    getEUPayPlan() {
        this.payPlanEU$ = this.payplanSetupSvc.getEUPayPlan(this.selectedPayPlanId, this.selectedEmployeeMonthId);
        this.subscribeToEUPayPlan();
    }


    subscribeToPayPlan() {
        this.payPlanSubscription.unsubscribe();

        this.payPlanSubscription = this.payPlan$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                payPlan => {
                    payPlan.profitBonusPercent *= 100;
                    this.payPlan = payPlan;
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'getPayPlan');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }
    subscribeToEUPayPlan() {
        this.payPlanEUSubscription.unsubscribe();

        this.payPlanEUSubscription = this.payPlanEU$
            .pipe(
                finalize(() => {
                    this.loadingSpinnerSvc.turnSpinnerOff();
                })
            )
            .subscribe(
                payPlanEU => {
                    this.payPlanEU = payPlanEU;
                },
                err => {
                    this.subscribeService.onError(err, this.level, 'getPayPlan');
                    this.loadingSpinnerSvc.turnSpinnerOff();
                }
            );
    }
}
