import { Injectable } from '@angular/core';
import { DashboardGroupOverview } from '../../models/incoming-dtos/dashboard-group-overview';

@Injectable()
export class DashboardGroupOverviewService {
    filterOverviewData(selectedGroupId: string, dashboardOverviewData: DashboardGroupOverview[]): DashboardGroupOverview {
        let groupOverviewData = new DashboardGroupOverview();
        dashboardOverviewData.forEach(group => {
            if (group.ehiGroup.name.toLowerCase() === selectedGroupId.toLowerCase()) {
                groupOverviewData = group;
                group.goalActuals = group.goalActuals.filter(x => !x.goalName.includes('Growth Champion') && x.goalName !== 'Order of Merit')
            }
        })
        return groupOverviewData;
    }
}
