import { Injectable } from '@angular/core';
import { Employee } from '../../models/incoming-dtos/employee';
import { Group } from '../../models/incoming-dtos/group';
import { HelperMethodsService } from '../../shared/services/helper-methods.service';

@Injectable()
export class TeamDetailsService {

    constructor(private helperMethods: HelperMethodsService) {}

    getSelectedEmployees(employees: Employee[]): Employee[] {
        const selectedEmployees = new Array<Employee>();
        employees.forEach(employee => {
            if (employee.selected) {
                selectedEmployees.push(employee);
            }
        });
        return selectedEmployees;
    }

    validateTeamName(teamName: string, groups: Group[], selectedGroupName: string, originalTeamName: string): number {
        let outcome: number;
        if (this.helperMethods.isStringNullOrEmpty(teamName)) {
            outcome = 1;
        }
        if (this.helperMethods.isNumberNullOrUndefined(outcome)) {
            outcome = this.checkIfNameAlreadyExists(groups, teamName, selectedGroupName, originalTeamName);
        }
        if (this.helperMethods.isNumberNullOrUndefined(outcome)) {
            outcome = this.checkForInvalidCharacters(teamName);
        }
        return outcome;
    }

    checkIfNameAlreadyExists(groups: Group[], teamName: string, selectedGroupName: string, originalTeamName: string): number {
        const teamNames = new Array<string>();
        if (originalTeamName !== teamName) {
            groups[0].teams.forEach(team => {
                teamNames.push(team.name);
            });
        }

        if (teamNames.includes(teamName)) {
            return 0;
        }
    }

    checkForInvalidCharacters(teamName: string): number {
        const nameCheck = teamName.match(/[^+*!@#$%^&()_=?.,<>|~`]+/);
        if (nameCheck === null) {
            return 2;
        }
        if (nameCheck['0'] !== teamName) {
            return 2;
        } else {
            return 3;
        }
    }
}
