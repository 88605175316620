import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PaidTimeoffComponent } from './paid-timeoff.component';
import { PaidTimeoffRouteActivator } from './paid-timeoff-route-activator';
import { PaidTimeoffTableComponent } from './paid-timeoff-table/paid-timeoff-table.component';
import { PaidTimeOffTableService } from './paid-timeoff-table/paid-timeoff-table.service';
import { PaidTimeOffService } from './paid-timeoff.service';
import { PaidTimeOffExportService } from './paid-timeoff-export.service';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild([
      { path: 'paid-timeoff', component: PaidTimeoffComponent, canActivate: [PaidTimeoffRouteActivator] }
    ])
  ],
  declarations: [
    PaidTimeoffComponent,
    PaidTimeoffTableComponent
  ],
  providers: [
    PaidTimeoffRouteActivator,
    PaidTimeOffTableService,
    PaidTimeOffService,
    PaidTimeOffExportService
  ]
})
export class PaidTimeoffModule { }
