import { Component, Input } from '@angular/core';
import { DashboardTeamOverview } from '../../models/incoming-dtos/dashboard-team-overview';
import { GoalActual } from '../../models/incoming-dtos/goal-actual';
import { DashboardTeamOverviewService } from './dashboard-team-overview.service';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { DashboardService } from '../dashboard.service';
import { StyleHelperService } from '../../shared/services/style-helper.service';

@Component({
    selector: 'scoreboard-dashboard-team-overview',
    templateUrl: './dashboard-team-overview.component.html',
    styleUrls: ['./dashboard-team-overview.component.scss']
})
export class DashboardTeamOverviewComponent {
    @Input() dashboardTeamOverviewData: DashboardTeamOverview[];
    @Input() levelValue: string;
    @Input() month: number;
    @Input() year: number;
    @Input() isEurope: boolean;
    selectedTeamId: number;
    goalPrefix = new Array<string>();
    goalSuffix = new Array<string>();
    isAllNeeded = false;
    domWidth: number;

    dashboardSelectedTeam = new DashboardTeamOverview();

    constructor(public dashboardTeamOverviewSvc: DashboardTeamOverviewService,
        public loadingSpinnerSvc: LoadingSpinnerService,
        public dashboardService: DashboardService,
        public styleHelper: StyleHelperService) {
        styleHelper.maxWidth = 1023;
    }

    onTeamChange(selectedTeamId: number) {
        this.selectedTeamId = selectedTeamId;
        this.filterOverviewData();
        this.checkNullForGoalValues();
    }

    checkNullForGoalValues() {
        this.dashboardSelectedTeam.goalActuals.forEach(goal => {
            const index = this.dashboardSelectedTeam.goalActuals.indexOf(goal);
            if (goal.goalValue === null) {
                goal.goalValue = '-';
                this.goalPrefix[index] = '';
                this.goalSuffix[index] = '';
            } else {
                this.goalPrefix[index] = goal.prefix;
                this.goalSuffix[index] = goal.suffix;
            }
        });
        if (this.dashboardSelectedTeam.salesPaceGoal === null) {
            this.dashboardSelectedTeam.salesPaceGoal = '-';
        }
    }

    getPaceArrow(): string {
        if (this.dashboardSelectedTeam.salesPaceOnTrack) {
            return this.styleHelper.changeClass('col-6', 'col-2') + ' icon-arrow icon-arrow-up';
        } else {
            return this.styleHelper.changeClass('col-6', 'col-2') + ' icon-arrow icon-arrow-down';
        }
    }

    getSymbol(goalActual: GoalActual): string {
        if (goalActual.onTrack) {
            if (goalActual.goalName.includes('Reversal')) {
                if (Number(goalActual.goalValue) === goalActual.actualValue) {
                    return this.styleHelper.changeClass('col-2', 'col-1') + ' icon icon-arrow-up';
                }
                return this.styleHelper.changeClass('col-2', 'col-1') + ' icon icon-arrow-down';
            } else {
                return this.styleHelper.changeClass('col-2', 'col-1') + ' icon icon-arrow-up';
            }

        } else {
            if (goalActual.goalName.includes('Reversal')) {
                return this.styleHelper.changeClass('col-2', 'col-1') + ' icon icon-arrow-up';
            } else {
                return this.styleHelper.changeClass('col-2', 'col-1') + ' icon icon-arrow-down';
            }
        }
    }

    isReversedMetric(goalActual: GoalActual): boolean {
        return this.dashboardService.getAsterisk(goalActual);
    }

    filterOverviewData() {
        this.dashboardSelectedTeam = this.dashboardTeamOverviewSvc.filterOverviewData(this.selectedTeamId,
            this.dashboardTeamOverviewData);
    }

    checkColumnSize(): string {
        const domWidth = document.body.offsetWidth;
        this.domWidth = domWidth;
        return this.determineScreenSize();
    }

    determineScreenSize(): string {
        if (this.domWidth >= 1500) {
            return 'col-sm-2'; // Desktop view
        } else if (this.domWidth <= 1499 && this.domWidth >= 1100) {
            return 'col-md-3'; // Laptop view
        } else if (this.domWidth <= 1099 && this.domWidth >= 800) {
            return 'col-3'; // Tablet View
        } else {
            return 'col-5'; // Mobile view
        }
    }

}
