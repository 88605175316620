import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { PayMemoComponent } from './pay-memo.component';
import { PayMemoRouteActivator } from './pay-memo-route-activator';
import { PayMemoTableComponent } from './pay-memo-table/pay-memo-table.component';
import { PayMemoService } from './pay-memo.service';
import { PayMemoExportService } from './pay-memo-table/pay-memo-export.service';
import { PayMemoTableService } from './pay-memo-table/pay-memo-table.service';
import { DecisionModalService } from '../shared/decision-modal/decision-modal.service';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild([
            { path: 'pay-memo', component: PayMemoComponent, canActivate: [PayMemoRouteActivator] }
        ])
    ],
    declarations: [
        PayMemoComponent,
        PayMemoTableComponent
    ],
    providers: [
        PayMemoRouteActivator,
        PayMemoService,
        PayMemoExportService,
        PayMemoTableService,
        DecisionModalService
    ]
})
export class PayMemoModule { }
