import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[scoreboardMyNumberOnly]'
    })
    export class NumberOnlyDirective {
    // Allow decimal numbers and negative values
    private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = [ 'Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', '-', 'Delete', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0' ];

    constructor(private el: ElementRef) {
    }
    @HostListener('keydown', [ '$event' ])
    onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1) {
    return;
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
    event.preventDefault();
    }
    }
}
