import { Component, Input, EventEmitter, Output, OnChanges } from '@angular/core';
import { TeamFilterService } from './team-filter.service';
import { Team } from '../../models/incoming-dtos/team';
import { Observable } from 'rxjs';
import { SubscriptionService } from '../services/subscription.service';
import { Subscription } from 'rxjs';
import { HelperMethodsService } from '../services/helper-methods.service';

@Component({
  selector: 'scoreboard-team-filter',
  templateUrl: './team-filter.component.html',
  styleUrls: ['./team-filter.component.scss']
})
export class TeamFilterComponent implements OnChanges {
  @Input() levelValue: string;
  @Input() year: number;
  @Input() month: number;
  @Input() isAllNeeded: boolean;
  @Output() filterChanged: EventEmitter<number>;
  teams = new Array<Team>();
  teams$: Observable<Team[]>;
  teamSubscription = new Subscription;
  selectedTeamId: number;
  @Input() isEurope: boolean;

  constructor(public teamFilterService: TeamFilterService,
    private subscriptionService: SubscriptionService,
    private helperMethods: HelperMethodsService) {
    this.filterChanged = new EventEmitter<number>();
  }

  ngOnChanges() {
    this.teams$ = this.teamFilterService.getTeams(this.levelValue, this.year, this.month);
    this.subscribeToTeamsObservable();
  }

  subscribeToTeamsObservable(): any {
    this.teams = [];
    this.checkIfAllTeamsIsNeeded();
    this.checkIfAllOfGroupIsNeeded();
    this.teamSubscription.unsubscribe();
    this.teamSubscription = this.teams$
      .subscribe(
        teams => {
          if (teams) {
            this.teams = this.teams.concat(teams);
            this.selectedTeamId = this.teams[0].id;
            this.filterChanged.emit(this.selectedTeamId);
          }
        },
        err => {
          this.subscriptionService.onError(err, 'getTeams');
        }
      );
  }

  selectFilter(team: Team): any {
    this.selectedTeamId = team.id;
    this.filterChanged.emit(this.selectedTeamId);
  }

  checkIfAllTeamsIsNeeded() {
    if (this.isAllNeeded) {
      this.teams.push({
        id: 0,
        name: 'All Teams',
        groupName: null,
        availableSpcTargets: null,
        employees: null,
        managers: null,
        teamGoals: null
      });
    }
  }

  checkIfAllOfGroupIsNeeded() {
    if (this.isEurope) {
      this.teams.push({
        id: -1,
        name: 'All of Group',
        groupName: null,
        availableSpcTargets: null,
        employees: null,
        managers: null,
        teamGoals: null
      });
    }
  }
}
